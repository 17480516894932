import React from 'react'

import { useHistory } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import { apiCreateBlog } from '../../../api/Blog'

import snackbar from '../../../utils/Snackbar'
import { useUserContext } from '../../../utils/context/UserContext'

import {
  EntityReferenceItem,
  ReferenceableEntityType
} from '../../entityReference/types'
import blogIcon from '../assets/v2/blog_article.svg'

import { BaseToolCard, ToolCardWithMetadata } from './BaseToolCard'

const TITLE = 'Blog Article'
const SUBTITLE =
  'Write SEO optimized blog articles with factual real time research.'

const IMDA_SPEECH_TITLE = 'IMDA Speech'
const IMDA_SPEECH_SUBTITLE =
  'Write a speech from start to finish with a guided workflow.'

const BlogArticleCard = () => {
  const history = useHistory()

  const { user } = useUserContext()
  const isPapaPlanUser = !!user?.service_configs?.feature_flags?.is_papa_user
  const isImdaUser = !!user?.service_configs?.feature_flags?.is_imda_user

  // TODO: Move this to the DocumentOverviewPage
  // folder id is the id of which the adtext is created in, from the folders
  // in the documents page
  const [currentFolderId] = useQueryParam('folderId', StringParam)
  const [currentTopicRecommendationId] = useQueryParam(
    'topicRecommendationId',
    StringParam
  )

  const onClick = async () => {
    try {
      const targetEntityReferences: EntityReferenceItem[] = []

      if (currentTopicRecommendationId) {
        targetEntityReferences.push({
          id: currentTopicRecommendationId,
          type: ReferenceableEntityType.topicRecommendation
        })
      }

      const blog = await apiCreateBlog(
        currentFolderId || undefined,
        targetEntityReferences
      )

      let queryParams = `?blogId=${blog.blogId}`
      if (currentFolderId) {
        queryParams += `&folderId=${currentFolderId}`
      }

      history.push({
        pathname: '/blog-post',
        search: queryParams
      })
    } catch (error) {
      snackbar.show('Failed to create blog. Please contact our support team', {
        variant: 'error'
      })
    }
  }

  const title = isImdaUser
    ? IMDA_SPEECH_TITLE
    : isPapaPlanUser
    ? 'PAP Article'
    : TITLE

  const subtitle = isImdaUser ? IMDA_SPEECH_SUBTITLE : SUBTITLE

  return (
    <BaseToolCard
      title={title}
      subtitle={subtitle}
      onClick={onClick}
      logoSrc={blogIcon}
    />
  )
}

const metadata = {
  id: 'blog_article',
  searchTerms: [TITLE, SUBTITLE]
}

export const BlogArticleCardWithMetadata: ToolCardWithMetadata = {
  card: <BlogArticleCard />,
  metadata
}

const imdaMetadata = {
  id: 'imda_speech',
  searchTerms: [IMDA_SPEECH_TITLE, IMDA_SPEECH_SUBTITLE]
}

export const IMDASpeechArticleCardWithMetadata: ToolCardWithMetadata = {
  card: <BlogArticleCard />,
  metadata: imdaMetadata
}
