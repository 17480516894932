import React from 'react'

import {
  AnalyticsConfigurator,
  AnalyticsProvider
} from '@hypotenuse/common/src/analytics/Analytics'
import { RequiresAPIAccessToken } from '@hypotenuse/common/src/components/auth/RequiresAPIAccessToken'
import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

import { usePremiumAuth } from './hooks/usePremiumAuth'

import {
  GOOGLE_ANALYTICS_TRACK_CODE,
  IS_PRODUCTION,
  LOGROCKET_APP_ID,
  USE_JWT_AUTH
} from './utils/Constants'
import { GlobalAppProvider } from './utils/context/GlobalAppContext'
import { TrialUserProvider } from './utils/context/TrialUserContext'

import App from './App'

/**
 * A container for all portions of the app that require user authentication.
 * Nothing outside this component should require user authentication.
 */
const AuthenticatedApp = () => {
  // Note: We only have access to user info from the Identity provider (Auth0) here.
  //  We don't yet have access to user info from our backend API because we haven't fetched an API access token.
  //  This doesn't apply if JWT auth is disabled, because the cookie sent in backend requests allows us
  //  to access user info from the API, but it's a good idea to treat it as such anyway.
  const {
    isAuthLoading,
    isAuthenticated,
    login,
    getAccessToken
  } = usePremiumAuth()

  return (
    <RequiresAPIAccessToken
      isAuthLoading={isAuthLoading}
      isAuthenticated={isAuthenticated}
      redirectToLogin={login}
      getAccessToken={getAccessToken}
      apiClient={apiClient}
      disabled={!USE_JWT_AUTH}
    >
      {/*
      Components inside RequiresApiAccessToken are not loaded until an
      API access token has been fetched and attached to the axios API client.
      */}
      <AnalyticsProvider
        disableTracking={!IS_PRODUCTION}
        logRocketAppId={LOGROCKET_APP_ID}
        logRocketExcludedUsernamePatterns={[/\S+@hypotenuse\.ai/]}
        googleAnalyticsTrackingCode={GOOGLE_ANALYTICS_TRACK_CODE}
      >
        <AnalyticsConfigurator />
        <GlobalAppProvider>
          <TrialUserProvider>
            <App />
          </TrialUserProvider>
        </GlobalAppProvider>
      </AnalyticsProvider>
    </RequiresAPIAccessToken>
  )
}

export default AuthenticatedApp
