import { useEffect, useLayoutEffect, useRef, useState } from 'react'

function useInterval<T = void>(
  callback: () => T | undefined,
  delay: number | null
) {
  const savedCallback = useRef(callback)
  const [value, setValue] = useState<T>()

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => {
      setValue(savedCallback.current())
    }, delay)

    return () => clearInterval(id)
  }, [delay])

  return value
}

export default useInterval
