import { DocumentType } from '../documents/types'
import { TopicRecommendationType } from '../liveFeed/types'

/**
 * This type represents all entity types that can be referenced for
 * content generation.
 */
export enum ReferenceableEntityType {
  document = 'document',
  topicRecommendation = 'topic_recommendation',
  image = 'image',
  generated_image = 'generated_image'
}

export type ReferenceableEntitySubType = DocumentType | TopicRecommendationType

/**
 * This class represents an item that is being referenced by another item.
 *
 * It is used while creating an entity.
 */
export interface EntityReferenceItem {
  id: string
  type: ReferenceableEntityType
  subType?: ReferenceableEntitySubType
}

/**
 * Common interface used to render the referenced target item.
 */
export interface EntityReferenceItemInfo extends EntityReferenceItem {
  /**
   * Name of the item
   */
  title: string

  /**
   * Description of the item
   */
  description?: string
}

/**
 * Entity reference
 */
export interface EntityReference {
  /**
   * Unique identifier for the entity reference
   */
  id: string

  /**
   * The organization ID that the entity reference belongs to
   */
  organization_id: string

  /**
   * The user ID that the entity reference belongs to
   */
  user_id: string

  /**
   * ID of the item which is referencing another item. Can be a document or template
   */
  reference_source_item_id: string

  /**
   * Type of the item which is referencing another item.
   */
  reference_source_item_type: ReferenceableEntityType

  /**
   * ID of the item which is being referenced. Can be a document or template
   */
  reference_target_item_id: string

  /**
   * Type of the item which is being referenced.
   */
  reference_target_item_type: ReferenceableEntityType

  /**
   * When the entity type reference was created
   */
  created_at: number

  /**
   * When the entity type reference was last updated
   */
  updated_at: number

  /**
   * Whether the entity reference has been deleted (soft delete)
   */
  deleted: boolean

  /**
   * When the entity reference was deleted
   */
  deleted_at?: number

  /**
   * Metadata for the entity reference. Can be used to store additional information about the reference
   */
  metadata?: Record<string, any>
}
