import {
  Blog,
  BlogConclusionGenerationPayload,
  BlogEnrichmentContentResponse,
  BlogGenerationResponse,
  BlogIntroGenerationPayload,
  BlogOutlineGenerationPayload,
  BlogOutlineGenerationResponse,
  BlogOverviewResponse,
  BlogOverviewSearchQuery,
  BlogParagraphsGenerationPayload,
  BlogStepper,
  BlogStreamErrorResponse,
  BlogUpdateForm,
  BlogWebEnrichmentLinksConfig,
  IArticlePreview,
  OutlineSection
} from '../interfaces/Blog'
import {
  ContentGenerationCreditCostInfo,
  ContentGenerationType
} from '../interfaces/ContentGeneration'

import { apiClient } from '../utils/ApiClient'
import { SERVER } from '../utils/Constants'
import { SSE, SSEOptionsMethod } from '../utils/SSE'

import { EntityReferenceItem } from '../components/entityReference/types'

export const apiGetUserLatestBlog = (): Promise<Blog> => {
  return apiClient.get('/blog').then((resp) => resp.data)
}

export const apiGetBlogsCount = (): Promise<number> => {
  return apiClient.get('/blog/count').then((resp) => resp.data)
}

export const apiGetBlogById = (blogId: string): Promise<Blog> => {
  return apiClient.get(`/blog/${blogId}`).then((resp) => resp.data)
}

interface BlogOverviewPayload {
  blogOverviewResponses: Array<BlogOverviewResponse>
  totalBlogs: number
}

export const apiGetBlogs = async (query: BlogOverviewSearchQuery) => {
  const response = await apiClient.post<BlogOverviewPayload>(
    '/blog/browse',
    query
  )
  let { blogOverviewResponses, totalBlogs } = response.data
  return { blogOverviewResponses, totalBlogs }
}

export const apiCreateBlog = (
  folderId?: string,
  targetEntityReferences?: EntityReferenceItem[]
): Promise<Blog> => {
  return apiClient
    .post('/blog', {
      folder_id: folderId,
      target_entity_references: targetEntityReferences
    })
    .then((resp) => resp.data)
}

export const apiDeleteBlog = (blogId: string) => {
  return apiClient.delete(`/blog/${blogId}`)
}

export const apiUpdateBlog = (
  blogUpdateForm: BlogUpdateForm
): Promise<Blog> => {
  const blogId = blogUpdateForm.blogId
  return apiClient
    .put(`/blog/${blogId}`, blogUpdateForm)
    .then((resp) => resp.data)
}

export const apiBlogDeleteOutline = (
  blogId: string,
  outlineId: string
): Promise<void> => {
  return apiClient
    .delete(`/blog/${blogId}/outline/${outlineId}`)
    .then((resp) => resp.data)
}

export const apiBlogAddOutlineSection = (blogId: string): Promise<void> => {
  return apiClient
    .post(`/blog/${blogId}/outline/section`)
    .then((resp) => resp.data)
}

export const apiBlogDeleteOutlineSection = (
  blogId: string,
  index: number
): Promise<void> => {
  return apiClient
    .delete(`/blog/${blogId}/outline/section/${index}`)
    .then((resp) => resp.data)
}

export const apiUpdateBlogOutlineDetails = (
  blogId: string,
  index: string,
  blog_outline_details: string[]
) => {
  return apiClient.put(
    `/blog/${blogId}/update_outline_details`,
    blog_outline_details,
    {
      params: {
        blogId: blogId,
        index: index
      }
    }
  )
}

export const apiBulkUpdateBlogOutlineDetails = (
  blogId: string,
  blog_outline_details: string[][]
) => {
  return apiClient.put(
    `/blog/${blogId}/bulk_update_outline_details`,
    blog_outline_details
  )
}

/**
 * Get the content generation cost info for a given blog
 */
export const apiGetBlogGenCost = async ({
  blogId,
  generationType,
  subSteps
}: {
  blogId: string
  generationType: ContentGenerationType
  subSteps: [
    BlogStepper.INTRO | BlogStepper.PARAGRAPH | BlogStepper.CONCLUSION,
    number
  ][]
}) => {
  return apiClient
    .post<ContentGenerationCreditCostInfo>(
      `/blog/${blogId}/generate/cost`,
      {
        stepper_tuples: subSteps
      },
      {
        params: { generation_type: generationType }
      }
    )
    .then((resp) => resp.data)
}

/**
 * Create a new publicly shareable blog preview
 */
export const apiBlogCreatePreview = async (blogId: string) => {
  return apiClient
    .post<string>(`/blog/${blogId}/preview`)
    .then((resp) => resp.data)
}

/**
 * Get a blog preview by the preview ID
 */
export const apiGetBlogPreviewById = async (previewId: string) => {
  return apiClient
    .get<IArticlePreview>(`/blog/preview/${previewId}`)
    .then((resp) => resp.data)
}
/**
 * Get a blog's most recently generated preview ID.
 * Null if no preview has been published yet.
 */
export const apiGetLatestBlogPreviewId = async (blogId: string) => {
  return apiClient
    .get<string | null>(`/blog/${blogId}/preview/latest`)
    .then((resp) => resp.data)
}

export const apiGetEnrichedContentForBlogGeneration = async ({
  blogId,
  blogTitle,
  blogTopic,
  blogOutline,
  webEnrichmentLinksConfig
}: {
  blogId: string
  blogTitle: string
  blogTopic: string
  blogOutline: OutlineSection[]
  webEnrichmentLinksConfig: BlogWebEnrichmentLinksConfig
}) => {
  const response = await apiClient.post<BlogEnrichmentContentResponse>(
    `/blog/${blogId}/enriched-content`,
    {
      blog_title: blogTitle,
      blog_topic: blogTopic,
      blog_outline: blogOutline,
      web_enrichment_links_config: webEnrichmentLinksConfig
    }
  )
  return response.data
}

export const apiGenerateBlogOutline = async (
  payload: BlogOutlineGenerationPayload
) => {
  return apiClient
    .post<BlogOutlineGenerationResponse>(
      `${SERVER}/api/v2/generation/blog/outline`,
      payload
    )
    .then((resp) => resp.data)
}

export const apiGenerateBlogIntroEventSource = (
  payload: BlogIntroGenerationPayload
) => {
  const es = new SSE<BlogGenerationResponse, BlogStreamErrorResponse>(
    `${SERVER}/api/v2/generation/blog/intro`,
    {
      method: SSEOptionsMethod.POST,
      payload: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' },
      withCredentials: true
    }
  )
  return es
}

export const apiGenerateBlogConclusionEventSource = (
  payload: BlogConclusionGenerationPayload
) => {
  const es = new SSE<BlogGenerationResponse, BlogStreamErrorResponse>(
    `${SERVER}/api/v2/generation/blog/conclusion`,
    {
      method: SSEOptionsMethod.POST,
      payload: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' },
      withCredentials: true
    }
  )
  return es
}

export const apiGenerateBlogParagraphsEventSource = (
  payload: BlogParagraphsGenerationPayload
) => {
  const es = new SSE<BlogGenerationResponse, BlogStreamErrorResponse>(
    `${SERVER}/api/v2/generation/blog/paragraphs`,
    {
      method: SSEOptionsMethod.POST,
      payload: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' },
      withCredentials: true
    }
  )
  return es
}

export const apiCheckRateLimitForBlogEnrichment = (): Promise<boolean> => {
  return apiClient
    .get('/api/v2/generation/blog/check-rate-limit')
    .then((resp) => resp.data)
}
