import React, { useCallback } from 'react'

import palette from '../../atoms/Colors'
import Stack from '../atoms/Stack'
import { Grid, Tab, Tabs, createStyles, makeStyles } from '@material-ui/core'

import { SearchBarWithDebounce } from '../SearchBar'

import { ToolCardGroupType } from './mappings'
import { useStore } from './useStore'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      margin: 0
    },
    tabBar: {
      marginTop: theme.spacing(3.5),
      '& .MuiTab-root': {
        fontSize: '0.875rem',
        minWidth: 'fit-content', // NOTE: Overrides the default absolute minWidth set by MUI
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0, 2.5),
        minHeight: theme.spacing(4.5)
      },
      '& .Mui-selected': {
        backgroundColor: palette.primary[50],
        border: 'none',
        transition: 'background-color color 0.2s ease-out',
        color: palette.primary[700]
      },
      '& .MuiTab-wrapper': {
        // NOTE: MUI v4 does not support suffix icon for tabs
        // This is a workaround to display the icon after the label
        flexDirection: 'row-reverse',
        gap: theme.spacing(1)
      }
    }
  })
)

const TabsValues: string[] = Object.values(ToolCardGroupType)

const ToolLibraryHeader = () => {
  const classes = useStyles()
  const tabValue = useStore((store) => store.tab)
  const onTabChange = useStore((store) => store.setTab)
  const search = useStore((store) => store.search)
  const setSearch = useStore((store) => store.setSearch)

  /**
   * Searches through the templates by title and subtitle and filters them based on the search value
   * @param value
   *
   * @note If the search result is empty, it will return suggestion and ask ai anything cards
   */
  const handleOnSearch = useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  return (
    <Stack>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <h5 className={classes.header}>Tool library</h5>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SearchBarWithDebounce
            variant="outlined"
            size="small"
            handleOnSearch={handleOnSearch}
            debouncedTiming={0}
            placeholder={'Search tools'}
            searchTerm={search}
          />
        </Grid>
      </Grid>
      <Tabs
        variant="scrollable"
        className={classes.tabBar}
        value={tabValue}
        TabIndicatorProps={{
          hidden: true
        }}
        onChange={(_, value) => {
          onTabChange(value)
        }}
        selectionFollowsFocus
      >
        {TabsValues.map((tab) => (
          <Tab key={tab} label={tab} value={tab} />
        ))}
      </Tabs>
    </Stack>
  )
}

export default ToolLibraryHeader
