import React from 'react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Box, Theme, Tooltip } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'

import { ReactComponent as CheckTapered } from '@hypotenuse/common/src/assets/CheckTapered.svg'
import { HelpIcon } from '@hypotenuse/common/src/icons/StyledIcons'
import { useUserContext } from '@hypotenuse/common/src/utils/context/UserContext'

interface PlanDetailsListProps {
  benefits: readonly string[]
  /**
   * If true, the benefits will be displayed in light/white text.
   */
  lightText?: boolean
  /**
   * Indicates if the plan is the unlimited plan.
   */
  isUnlimitedPlan?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkIcon: {
      color: palette.primary[500],
      fontSize: '0.9rem'
    },
    text: {
      fontWeight: 400,
      paddingLeft: theme.spacing(1.75)
    },
    lightText: {
      color: palette.gray['100']
    },
    darkText: {
      color: palette.gray['500']
    }
  })
)

/**
 * Renders a list of details for plan with check icons next to each item.
 */
export default function PlanDetailsList(props: PlanDetailsListProps) {
  const { benefits, lightText, isUnlimitedPlan = false } = props
  const { user } = useUserContext()
  const classes = useStyles()
  const theme = useTheme()
  const lastIndex = benefits.length - 1
  return (
    <Stack spacing={2}>
      {benefits.map((benefit, index) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline', // Ensures the check icon is aligned with the first line of text.
            gap: theme.spacing(0.25)
          }}
          key={index} // Safe to use index as key because benefits is a static array
        >
          <SvgIcon
            component={CheckTapered}
            viewBox="0 0 21 19"
            className={classes.checkIcon}
          />
          <Typography
            variant="body2"
            className={clsx(
              classes.text,
              lightText ? classes.lightText : classes.darkText
            )}
          >
            {benefit}
          </Typography>
          {index === lastIndex && isUnlimitedPlan && (
            <Tooltip
              title={
                <Typography variant="body2">
                  Supports up to{' '}
                  {
                    user.service_configs.bulk_product_descriptions
                      .max_items_per_job
                  }{' '}
                  items for bulk generation at a time
                </Typography>
              }
              placement="top"
              style={{ minWidth: 'fit-content', zIndex: 1000 }}
            >
              <Box>
                <HelpIcon
                  style={{
                    fontSize: '1.2rem',
                    color: palette.gray[400]
                  }}
                />
              </Box>
            </Tooltip>
          )}
        </div>
      ))}
    </Stack>
  )
}
