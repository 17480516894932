import React from 'react'

import palette from '../../atoms/Colors'
import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: palette.gray[700],
      borderColor: palette.gray[300]
    }
  })
)

interface Props extends ButtonProps {}

/**
 * Secondary action button (for actions like "Cancel")
 */
export const SecondaryButton: React.FC<Props> = React.memo((props) => {
  const classes = useStyles()
  return <Button className={classes.root} {...props} />
})
SecondaryButton.defaultProps = {
  variant: 'outlined'
}
