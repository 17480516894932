import React from 'react'

import { SuperTokensWrapper } from 'supertokens-auth-react'
import { AuthRecipeComponentsOverrideContextProvider } from 'supertokens-auth-react/ui'

import { Icon } from '@hypotenuse/common/src/atoms'

import { ReactComponent as Logo } from '@hypotenuse/platform/public/logo.svg'

const SuperTokensProvider: React.FC<React.PropsWithChildren<{}>> = React.memo(
  (props) => (
    <SuperTokensWrapper>
      <AuthRecipeComponentsOverrideContextProvider
        components={{
          AuthPageHeader_Override: ({ DefaultComponent, ...props }) => {
            return (
              <div>
                <Icon
                  icon={Logo}
                  width={32}
                  height={32}
                  iconProps={{ 'aria-label': 'Hypotenuse AI Logo' }}
                />
                <DefaultComponent {...props} />
              </div>
            )
          }
        }}
      >
        {props.children}
      </AuthRecipeComponentsOverrideContextProvider>
    </SuperTokensWrapper>
  )
)

export default SuperTokensProvider
