import React, { useEffect, useState } from 'react'

import axios from 'axios'

import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

import { User } from './utils/Interfaces'

import { TrialEndedModal } from './main/trial/TrialEndedModal'

interface Props {
  user?: User
  refreshUser: () => void
}

/**
 * A function that takes a callback and returns a function that can be used as an axios error interceptor.
 */
export const makeAxiosErrorInterceptor = (onError: () => void) => (
  error: any
) => {
  if (axios.isAxiosError(error)) {
    const { status } = error.response ?? {}
    if (status === 402) {
      onError()
    }
  }
  // Never remove this. Please.
  throw error
}

export function ApiErrorModal(props: Props) {
  // This modal renders different types of modal depending on the error status code returned by the frontend.
  // This component is meant to catch app wide response errors through the use of axios interceptors.
  // Reference: https://axios-http.com/docs/interceptors

  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    apiClient.interceptors.response.use(
      (res) => res,
      makeAxiosErrorInterceptor(() => setError(true))
    )
  }, [])

  return props.user && error ? (
    <TrialEndedModal user={props.user} refreshUser={props.refreshUser} />
  ) : null
}
