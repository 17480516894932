import { LongFormContentLength } from '../components/longFormContent/interfaces'
import { AdTextLength, AdTextType } from '../interfaces/AdvertisingText'
import { ContentGenerationType } from '../interfaces/ContentGeneration'
import { ProductDatabaseConfig } from '../pages/ProductDatabase/data/interfaces/base'

export interface PageStatus {
  totalItems: number
  currentPage: number
  pageSize: number
}

/**
 * This enum stores all common error types that are
 * shared between shopify and premium app.
 */
export enum ServerErrorType {
  outOfCredits = 'Out of credits',
  tooManyProductsSpecified = 'Too many products specified',
  noPermissionsDescribe = 'Describe Permission Error'
}

export enum DescribeErrorType {
  adTextFieldsError = 'Ad text fields error',
  blogFieldsError = 'Blog fields error',
  productFieldsError = 'Product fields error'
}

export interface DescribeError {
  product_id: string
  error: string
}

export enum JobStatus {
  loading = 'loading',
  success = 'success',
  new = 'new',
  failed = 'failed',
  notLoaded = 'not-loaded',
  error = 'error'
}

export interface Job {
  jobId: string
  totalCount: number
  currentCount: number
  status: JobStatus
  message?: string
  expiryStatus?: JobStatus
  expiryMessage?: string
  updatedAt: string
  createdAt: string
  metadata?: DescribeError[]
  jobName: string
  catalogueId?: string
}

export enum BulkEnrichStrategy {
  SINGLE_SOURCE = 'single_source',
  MULTI_SOURCE = 'multi_source'
}

export const bulkEnrichmentStrategies = [
  BulkEnrichStrategy.MULTI_SOURCE,
  BulkEnrichStrategy.SINGLE_SOURCE
]

export const BulkEnrichStrategyLabel: Record<BulkEnrichStrategy, string> = {
  [BulkEnrichStrategy.SINGLE_SOURCE]: 'Use the first available source',
  [BulkEnrichStrategy.MULTI_SOURCE]: 'Use all selected sources'
}

export const BulkEnrichStrategySubtitle: Record<BulkEnrichStrategy, string> = {
  [BulkEnrichStrategy.SINGLE_SOURCE]:
    'For each product, AI tries the selected sources in order.',
  [BulkEnrichStrategy.MULTI_SOURCE]:
    'For each product, AI gathers data from every selected source.'
}

export interface DashboardIncentiveOptions {
  /**
   * This interface contains the feature flags to show on the dashboard.
   */
  // NOTE: This is actually being used to enable all social media sharing
  linkedin_post?: boolean
}

export interface FeatureFlags {
  /* NOTE: All attributes set to optional, as the default is already set by the backend
   * meaning that until the backend loads, all attributes can be blank
   * No Default set on the frontend so we don't have to sync default values between the frontend and backend
   **/
  white_label_ui?: boolean
  /**
   * Users with this flag set to True should see no Hypotenuse branding on the UI
   */
  multi_step_products?: boolean
  add_import_export_products?: boolean
  add_single_product?: boolean
  minimize_product_card?: boolean
  hide_navbar_logo?: boolean
  show_import_template?: boolean
  show_export_advanced_options?: boolean
  enable_insights_page?: boolean
  product_card_brand_text?: string
  catalogue_tags_input_placeholder_text?: string
  select_description_style_text?: string
  bubble_selected_descriptions_on_done_tab?: boolean
  enable_seo_keywords_highlighting?: boolean
  enable_catalogue_lvl_keywords_to_avoid?: boolean
  enable_product_lvl_seo_keywords?: boolean
  enable_product_lvl_keywords_to_avoid?: boolean
  enable_review?: boolean
  show_hershey_copy_type?: boolean
  show_ubs_copy_type?: boolean
  disable_add_product_if_no_products?: boolean
  enable_words?: boolean
  enable_brand_voice_usage?: boolean
  show_brand_voice?: boolean
  /**
   * Enables the new style guide implemented as part of Project Windmill
   * Currently only a frontend change.
   */
  enable_style_guide_v2?: boolean
  enable_templates_length_options?: boolean
  /**
   * Enables features specifically built for IMDA users.
   * This is a temporary flag that should be removed once we have per-feature
   * toggles to configure these features more granularly.
   */
  is_imda_user?: boolean
  /**
   * Enables features specifically for users with the papa custom plan id
   * TODO: Find a better way to have feature flags for specific users.
   * Ideally for each feature given to the special user, we should have a feature flag for it.
   * So we know what feature is enabled for what kinds of users.
   */
  is_papa_user?: boolean
  /**
   * Enables file upload in templates
   */
  enable_file_upload_in_templates?: boolean
  enable_style_guide?: boolean
  enable_blog_generation_streaming?: boolean
  enable_knowledge_base?: boolean
  disable_export_options?: boolean
  /**
   * Whether writer selection is enabled for all users
   */
  enable_writer_selection?: boolean

  /**
   * Whether the custom workflow for Icecube is enabled
   */
  enable_icecube_workflow?: boolean
  /**
   * Whether users can upload images on the instagram captions template
   */
  enable_instagram_image_upload?: boolean
  /**
   * Whether HypoFeed is enabled
   */
  enable_hypofeed?: boolean
  /**
   * Whether referencing galactus in content generation tools
   * is enabled
   */
  enable_galactus_referencing?: boolean
  /**
   * Whether the auto-internal link finder feature is enabled
   */
  enable_internal_link_suggestions?: boolean
  /**
   * Whether the `What's Next?` feed is enabled.
   *
   * This feed is different from live feed. It suggests new topics
   * to write about based on the user's existing content.
   */
  enable_whats_next_feed?: boolean
  /**
   * Enable output color targeting on HypoArt
   */
  enable_hypoart_color_control?: boolean

  /** Seo Mode feature flags */
  enable_seo_mode?: boolean
  allow_toggle_seo_mode?: boolean

  /**
   * Enable users access to the Keyword Finder feature in product descriptions
   */
  enable_keyword_finder?: boolean

  enable_webflow_integration?: boolean
  /**
   * Whether Shopify integration should be shown to users
   */
  show_shopify_integration?: boolean
  /**
   * Whether Shopify integration should be enabled
   */
  enable_shopify_integration?: boolean
  /**
   * Whether the user can generate cover image for blog
   */
  enable_cover_image_blog_generation?: boolean
  /**
   * Whether the user can use highlight research feature
   */
  enable_highlight_research?: boolean
  /**
   * Whether the user can use enrich product details from web in product description
   */
  enable_enrich_from_web?: boolean
  /**
   * Whether the user can use enrich product details from image in product description
   */
  enable_enrich_from_image?: boolean
  /*
   * Whether the user can use enrich product details from upc in product description
   */
  enable_enrich_from_upc?: boolean
  enable_galactus_referencing_in_template?: boolean
  enable_product_tracking?: boolean
  /**
   * Whether the user can configure the GenMills brand voice
   */
  enable_granular_brand_voice_configuration?: boolean
  /**
   * Whether the user sees a hierarchical writer selection field on product description
   */
  show_custom_writer_selection?: boolean
  /**
   * Whether the brand AI evaluation feature is enabled
   */
  enable_brand_ai_evaluation?: boolean

  /**
   * Whether access control is enabled for the user
   */
  enable_access_control?: boolean
  /**
   * Whether the user can rewrite existing product descriptions in the product description page
   */
  enable_rewriting_existing_product_descriptions?: boolean
  /**
   * Whether the user can view the blog enrichment feature
   */
  show_blog_enrichment_feature?: boolean
  /**
   * Whether the user can toggle the blog enrichment feature
   */
  enable_parallel_blog_web_enrichment?: boolean
  /**
   * Whether the user can view show create table button
   */
  show_create_table_button?: boolean
  /**
   * Whether the blog outline generation pipeline is enabled
   */
  enable_blog_outline_generation_pipeline?: boolean
  /**
   * Whether SKU is required for product import
   */
  enable_sku_required_for_import?: boolean
  /**
   * Whether catalogue templates are enabled
   */
  enable_catalogue_templates?: boolean
  /**
   * Whether the user can use custom Instagram post template
   */
  enable_custom_instagram_caption?: boolean
  /**
   * Whether the user can see SEO keyword suggestions for product descriptions
   */
  show_seo_keyword_suggestions?: boolean
  /**
   * Whether SEO keyword suggestions for product descriptions is enabled
   */
  enable_seo_keyword_suggestions?: boolean

  /**
   * Whether the user can toggle between different platforms (Google, Amazon, etc.) for SEO keyword suggestions
   */
  enable_platform_selection_for_seo_keyword_suggestions?: boolean
  /**
   * Whether the user can see the ecommerce tab in the premium drawer
   */
  show_ecommerce_tab?: boolean
  /**
   * Whether the product database is integrated to the catalogue
   */
  enable_product_database_for_catalogue?: boolean
  /**
   * Whether the product mapping can be done
   */
  enable_product_field_mapping?: boolean
  /**
   * Whether the user can see the character count for each section in rich text editor
   */
  enable_section_character_count?: boolean
  /**
   * Whether the product description pipeline is enabled
   */
  enable_product_description_pipeline?: boolean
  /**
   * Whether the user can use bulk seo keyword suggestions
   */
  enable_bulk_seo_keyword_suggestions?: boolean
  /**
   * Whether the bulk enrichment feature on product description is enabled
   */
  enable_bulk_enrichment?: boolean
  /**
   * Whether the action item recommendation is enabled
   */
  enable_action_item_recommendation?: boolean
  /**
   * Whether rewrite for SEO tool is enabled
   */
  enable_rewrite_for_seo?: boolean
  /**
   * Whether importing existing outline to blog outline is enabled
   */
  enable_import_blog_outline?: boolean
  /**
   * Whether numbered citations should be added to blog
   */
  enable_numbered_blog_citations?: boolean
  /*
   * Whether the user can see and edit the word count under catalog settings
   */
  enable_catalogue_word_count?: boolean
  /**
   * Whether the new product description topbar is enabled
   */
  enable_new_pdt_desc_topbar?: boolean
  /**
   * Whether the bulk rewriter card is visible to the user
   */
  show_bulk_rewriter_card?: boolean
  /**
   * Whether Calendly integration for enterprise contact is enabled
   */
  enable_calendly_integration?: boolean
  /**
   * Whether the user can see the bulk education page
   * as compared to the legacy bulk workflow page
   */
  show_bulk_education_page?: boolean
  /**
   * Whether the image workflow tab should be enabled
   */
  enable_image_workflow_tab?: boolean
  /**
   * Whether the user can use image editing
   */
  enable_image_editing?: boolean
  /**
   * Whether to use the improved image background generation algorithm
   */
  enable_image_background_v2?: boolean
  /**
   * Whether users will be shown the option to link images to products
   */
  show_link_product_dam?: boolean
  /**
   * Whether PostHog is enabled for analytics monitoring
   */
  enable_posthog_monitoring?: boolean
  /**
   * Whether the user can import products without title specified in import mapping
   * Legacy code dictates that all catalog products should title specified in import mapping for catalogue products.
   * We want to disable that for certain very specific cases (e.g. certain enterprise customers)
   */
  enable_missing_title_mapping?: boolean
  /***
   * Whether the user can go to the product database page
   */
  enable_product_database_tab?: boolean
  /**
   * Whether the user can go to the assets management page
   */
  enable_assets_management_tab?: boolean
  /**
   * Whether the user can go to the product database page
   */
  enable_product_monitoring_tab?: boolean
  /**
   * Whether the user should be able to use structured enrichment in pdb
   */
  enable_structured_enrichment_pdb?: boolean
  /**
   * Whether the user can translate content in product catalog
   */
  enable_catalog_content_translation?: boolean
  /**
   * Whether the combined enrich + bulk generate flow is enabled
   */
  enable_enrich_before_bulk_generation?: boolean
  /**
   * Whether the user can use the command highlighter on product descriptions
   */
  enable_pdt_command_highlighter?: boolean
  /*
   * Whether the AI consistency checker is enabled in the PDB
   */
  enable_pdb_ai_consistency_checker?: boolean
}

// region Charts & Chart Data
export interface Scalar {
  value: number | string
  label: string
}

export type LineDatum = string | number

export interface Point2D {
  x: LineDatum
  y: LineDatum
}

export interface LineData {
  id: string
  data: Point2D[]
}

export interface LineChartData {
  lines: LineData[]
}

export interface PieSector {
  id: string
  label: string
  value: number
  color?: string
}

export interface PieChartData {
  sectors: PieSector[]
}

export enum TimePeriod {
  week = 'week',
  month = 'month',
  year = 'year'
}

export interface CreditsHistoryLineChartData {
  xLabel: string
  yLabel: string
  lines: LineData[]
}

export interface CreditsHistory {
  timePeriod: TimePeriod
  // TODO: Refactor LineChart on Account page to use the LineChartData interface
  lineChart: CreditsHistoryLineChartData
}
// endregion

// region Action Points
export enum ActionPointSeverity {
  info = 'Info',
  warning = 'Warning',
  severe = 'Severe'
}

/**
 * The entity that an action point refers to (e.g. product, store, etc.)
 */
export interface ActionPointSubject {
  /**
   * Unique identifier for the entity
   */
  id: string
  /**
   * Human-readable name for the entity
   */
  display_name?: string
}

export interface ActionPoint {
  /**
   * Short description of the action point
   */
  issue: string
  /**
   * The numeric score assigned to the action point
   */
  score: number
  severity: ActionPointSeverity
  /**
   * The entity that the action point refers to
   */
  subject: ActionPointSubject
  /**
   * URL for the action point to lead to
   */
  url?: string
  /**
   * Additional information about the action point
   */
  detail?: string
}
// endregion

export interface OrganizationUpdateForm {
  displayName: string
}

export enum PlanCreditProvisionType {
  add = 'add',
  reset = 'reset'
}

export enum PlatformType {
  shopify = 'shopify',
  premium = 'premium'
}

/**
 * Defines a 'writer' used for generating content.
 * This is normally a purely backend concept, but we expose it to the
 * frontend to allow superusers to configure writers for other users
 * via the admin panel.
 * Refer to the backend class `WriterConfig` for more information.
 */
export interface WriterConfig {
  max_tokens: number | null
  counts: number | null
  writer: string | null
  writer_group: string | null
  temperature: number | null
}

/**
 * Ad text Config Interface
 */
export interface AdTextConfigEntry {
  type: string
  product_id: string
}

export interface BlogGenHelperText {
  accordionSubtitleOneText?: string
  accordionSubtitleTwoText?: string
  accordionSubtitleThreeText?: string
  brandBackgroundHelperText?: string
  brandBackgroundPlaceholder?: string
  productDetailsHelperText?: string
  productDetailsPlaceholder?: string
  titleHelperText?: string
  titlePlaceholder?: string
  topicHelperText?: string
  topicPlaceholder?: string
  targetAudienceHelperText?: string
  targetAudiencePlaceholder?: string
  outlineHelperText?: string
  outlinePlaceholders?: string[]
  outlineDetailsPlaceholder?: string
  outlineHeadingPlaceholder?: string
  keywordsHelperText?: string
  seoKeywordsPlaceholder?: string
  keywordsPlaceholder?: string
}

export enum ReferenceFileInputType {
  UploadFile = 'Upload file',
  ChooseFile = 'Existing docs',
  UploadLink = 'Link'
}

export interface LongFormContentLengthOption {
  /**
   * length option(can be short, medium, long, etc)
   * TODO: Rename to `length` for generality
   */
  blog_length: LongFormContentLength
  /**
   * The estimated word count for each paragraph for the length option
   */
  estimated_word_count: number
  /**
   * The estimated number of headings for the length option
   */
  h2_number: number
}

export type AdTextToLengthOptionsMap = {
  [key in AdTextType]?: { [key in AdTextLength]: number }
}

export interface GlobalAppData {
  default_description_downvote_reasons: string[]
  blog_gen_helper_text: BlogGenHelperText
  blog_tone_options: ToneOption[]
  imda_tone_options: (SectionOption | ToneOption | string)[]
  imda_target_audience_options: string[]
  blog_length_options: LongFormContentLengthOption[]
  ad_text_to_length_options_map: AdTextToLengthOptionsMap
  global_feature_flags: FeatureFlags
}

export interface SectionOption {
  group_name: string
  options: string[]
}

export interface LanguageDetail {
  code: string
  name: string
}

export interface TranslationLanguages {
  input: LanguageDetail[]
  output: LanguageDetail[]
}

export interface TranslationConfig {
  readonly show?: boolean
  language_from?: string
  language_to?: string
}

export interface TranslationConfigUpdateForm {
  language_from?: string
  language_to?: string
}

export interface PaginateableEntity<K> {
  last_key: K
}

/**
 * Social share
 */

export enum SocialShareMedia {
  facebook = 'Facebook',
  twitter = 'Twitter',
  linkedin = 'LinkedIn'
}

//============= Useful Abstract Types =============

/**
 * Extract a type containing un-assignable properties of T not assignable to U
 * e.g. Without<{a: number, b: string}, {a: number}> = {b: never}
 *
 * Source: https://stackoverflow.com/a/53229567
 */
export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }
/**
 * Extract a type containing either properties of T or U but not both and at least one of T or U
 *
 * e.g.
 *
 * const foo: XOR<{a: number}, {c: number}> = {a: 1} // OK
 *
 * const bar: XOR<{a: number}, {c: number}> = {c: 2} // OK
 *
 * const foobar: XOR<{a: number}, {c: number}> = {a: 1, c: 2} // Error
 *
 * const barfoo: XOR<{a: number}, {c: number}> = {} // Error
 *
 * Source: https://stackoverflow.com/a/53229567
 */
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U

/**
 * Extract a type containing union of keys of T
 *
 * Source: https://tsplay.dev/wgLpBN
 */
export type UnionKeys<T> = T extends T ? keyof T : never

/**
 * Expand T to help intellisense.
 *
 * Source: https://tsplay.dev/wgLpBN
 */
export type Expand<T> = T extends T ? { [K in keyof T]: T[K] } : never

/**
 * Extract a type allowing exactly one type passed in the generic types array.
 *
 * e.g.
 *
 * const foo: OneOf<[{a: number}, {b: string}, {c: boolean}]> = {a: 1} // OK
 *
 * const bar: OneOf<[{a: number}, {b: string}, {c: boolean}]> = {b: '1'} // OK
 *
 * const foobar: OneOf<[{a: number}, {b: string}, {c: boolean}]> = {a: 1, b: '1'} // Error
 *
 * const barfoo: OneOf<[{a: number}, {b: string}, {c: boolean}]> = {} // Error
 */
export type OneOf<T extends {}[]> = {
  [K in keyof T]: Expand<
    T[K] & Partial<Record<Exclude<UnionKeys<T[number]>, keyof T[K]>, never>>
  >
}[number]

/**
 * Onboarding form
 */

type ValueOf<T> = T[keyof T]

/**
 * This is to specify what the user is working on
 */
export enum UserRole {
  ecommerce_product = 'ecommerce_product',
  blog = 'blog',
  marketing = 'marketing',
  general_writing = 'general_writing',
  school = 'school',
  others = 'others',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  e_commerce_brand = 'e_commerce_brand',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  education = 'education',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  software_company = 'software_company',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  personal = 'personal',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  agency = 'agency',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  small_team = 'small_team',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  seo_team = 'seo_team',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  large_company = 'large_company'
}

export const UserRoleLabel: Record<UserRole, string> = {
  [UserRole.ecommerce_product]: 'Create ecommerce product and website content',
  [UserRole.blog]: 'Write SEO-friendly blog posts',
  [UserRole.marketing]: 'Brainstorm and craft marketing content',
  [UserRole.general_writing]: 'Just need some help with general writing',
  [UserRole.school]: 'School projects or assignments',
  [UserRole.others]: 'Others',
  // the following is preserved for legacy reasons
  [UserRole.e_commerce_brand]: 'E-commerce brand',
  [UserRole.education]: 'Education',
  [UserRole.software_company]: 'Software company',
  [UserRole.personal]: 'Personal',
  [UserRole.agency]: 'Agency',
  [UserRole.small_team]: 'Small team',
  [UserRole.seo_team]: 'SEO team',
  [UserRole.large_company]: 'Large company'
}

export const UserRoleSubTitle: Record<UserRole, string> = {
  [UserRole.ecommerce_product]: 'Create ecommerce product and website content',
  [UserRole.blog]: 'Write SEO-friendly blog posts',
  [UserRole.marketing]: 'Brainstorm and craft marketing content',
  [UserRole.general_writing]: 'Just need some help with general writing',
  [UserRole.school]: 'School projects or assignments',
  [UserRole.others]: 'Others',
  // the following is preserved for legacy reasons
  [UserRole.e_commerce_brand]:
    'Generate on-brand content for your products and e-commerce website.',
  [UserRole.seo_team]: 'Rise the ranks with SEO-friendly content.',
  [UserRole.education]: 'Power up your research and classroom material.',
  [UserRole.software_company]:
    'Generate content for your product and marketing pages.',
  [UserRole.personal]:
    'Tap into your creativity for personal writing and side projects.',
  [UserRole.small_team]:
    'Expand your content possibilities with ready-made content templates.',
  [UserRole.agency]:
    'Streamline content creation for your clients and their campaigns.',
  [UserRole.large_company]:
    'Accelerate your content velocity in a cohesive brand voice.'
}

export enum OnboardingPageAt {
  first_page = 1,
  second_page = 2,
  third_page = 3
}

/**
 * This is to specify the company type of the user
 */
export enum UserWork {
  // This is used in the onboarding form
  ecommerce = 'ecommerce',
  agency = 'agency',
  travel = 'travel',
  health = 'health',
  finance_or_law = 'finance_or_law',
  saas = 'saas',
  others = 'others',
  // the following is preserved for legacy reasons
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  product_descriptions = 'product_descriptions',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  social_and_ads = 'social_and_ads',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  marketer = 'marketer',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  writer = 'writer',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  operations = 'operations',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  sales = 'sales',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  support = 'support',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  hr_and_legal = 'hr_and_legal',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  engineering = 'engineering',
  /**
   * @deprecated
   * This is preserved for backwards compatibility
   * with old users.
   */
  manager = 'manager'
}

export const UserWorkLabel: Record<UserWork, string> = {
  // This is used in the onboarding form
  [UserWork.ecommerce]: 'Ecommerce',
  [UserWork.agency]: 'Agency',
  [UserWork.travel]: 'Travel',
  [UserWork.health]: 'Health',
  [UserWork.finance_or_law]: 'Finance/Law',
  [UserWork.saas]: 'SaaS',
  [UserWork.others]: 'Others',
  // the following is preserved for legacy reasons
  [UserWork.product_descriptions]: 'Product descriptions',
  [UserWork.social_and_ads]: 'Social and ads',
  [UserWork.marketer]: 'Marketer',
  [UserWork.writer]: 'Writer',
  [UserWork.operations]: 'Operations',
  [UserWork.sales]: 'Sales',
  [UserWork.support]: 'Support',
  [UserWork.manager]: 'Management',
  [UserWork.hr_and_legal]: 'HR & Legal',
  [UserWork.engineering]: 'Engineering'
}

export const UserWorkLabelOnboardingOptions: Partial<
  Record<UserWork, string>
> = {
  [UserWork.ecommerce]: '🛍 Ecommerce',
  [UserWork.agency]: '💼 Agency',
  [UserWork.travel]: '✈ Travel',
  [UserWork.health]: '🩺 Health',
  [UserWork.finance_or_law]: '⚖ Finance/Law',
  [UserWork.saas]: '💻 SaaS',
  [UserWork.others]: 'Others'
}

/**
 * Enumeration of company sizes.
 *
 * This is used in the onboarding form.
 */
export enum UserCompanySize {
  one_to_nine = 'one_to_nine',
  ten_to_forty_nine = 'ten_to_forty_nine',
  fifty_to_two_hundred_forty_nine = 'fifty_to_two_hundred_forty_nine',
  two_hundred_fifty_to_one_thousand = 'two_hundred_fifty_to_one_thousand',
  one_thousand_plus = 'one_thousand_plus',
  // the following is preserved for legacy reasons
  // this option is not shown in the onboarding form
  two_hundred_fifty_plus = 'two_hundred_fifty_plus'
}

export const UserCompanySizeLabel: Record<UserCompanySize, string> = {
  [UserCompanySize.one_to_nine]: '1-9',
  [UserCompanySize.ten_to_forty_nine]: '10-49',
  [UserCompanySize.fifty_to_two_hundred_forty_nine]: '50-249',
  [UserCompanySize.two_hundred_fifty_to_one_thousand]: '250-1000',
  [UserCompanySize.one_thousand_plus]: '1000+',
  // the following is preserved for legacy reasons
  // this option is not shown in the onboarding form
  [UserCompanySize.two_hundred_fifty_plus]: '250+'
}

/**
 * Enumeration of the channels via which users hear about us
 *
 * This is used in the onboarding form.
 */
export enum UserHeardAboutUsChannel {
  search_engine = 'search_engine',
  linkedin = 'linkedin',
  social_media = 'social_media',
  colleague_or_friend = 'colleague_or_friend',
  others = 'others'
}

export const UserHeardAboutUsChannelLabel: Record<
  UserHeardAboutUsChannel,
  string
> = {
  [UserHeardAboutUsChannel.search_engine]: 'Search engine (Google, Yahoo, etc)',
  [UserHeardAboutUsChannel.linkedin]: 'LinkedIn',
  [UserHeardAboutUsChannel.social_media]:
    'Social media (Facebook, Instagram, etc)',
  [UserHeardAboutUsChannel.colleague_or_friend]:
    'Recommended by colleague or friend',
  [UserHeardAboutUsChannel.others]: 'Others'
}

export enum ProductType {
  fashion = 'fashion',
  home_living = 'home_living',
  hobbies = 'hobbies',
  electronics = 'electronics',
  others = 'others'
}

export const ProductTypeLabel: Record<ProductType, string> = {
  [ProductType.fashion]: 'Fashion',
  [ProductType.home_living]: 'Home and Living',
  [ProductType.hobbies]: 'Hobbies',
  [ProductType.electronics]: 'Electronics',
  [ProductType.others]: 'Others'
}

/**
 * Enumeration used to display all the features of our App, mainly during Onboarding.
 * In the backend, the closest equivalent is UserOnboardingSelectedFeatures.
 * TODO: Refactor this to use ContentGenerationType instead.
 */
export enum AppFeatures {
  product_feature = 'product_feature',
  blog_feature = 'blog_feature',
  ads_feature = 'ads_feature',
  image_gen_feature = 'image_gen_feature',
  insta_caption = 'insta_caption',
  rewrite = 'rewrite',
  summarise = 'summarise',
  headline = 'headline',
  ask_anything = 'ask_anything',
  hypo_chat = 'hypo_chat',
  hypodoc = 'hypodoc',
  website_content = 'website_content',
  social_posts = 'social_posts',
  other = 'other'
}

// List of features that are not included in the App usage form, but included in the Sniper Links Only
export const SniperLinkOnlyAppFeatures: AppFeatures[] = [
  AppFeatures.image_gen_feature,
  AppFeatures.insta_caption,
  AppFeatures.rewrite,
  AppFeatures.headline,
  AppFeatures.ask_anything,
  AppFeatures.hypo_chat
]
// Map the sniper features to the appropiate URL to redirect the user to
export const SniperLinkFeaturesToUrlSlugMap: Partial<
  Record<AppFeatures, string>
> = {
  [AppFeatures.product_feature]: '/product-descriptions',
  [AppFeatures.insta_caption]: '/advertising/instagram',
  [AppFeatures.image_gen_feature]: '/image-generation',
  [AppFeatures.rewrite]: '/advertising/rewriter',
  [AppFeatures.hypodoc]: '/hypochat',
  [AppFeatures.hypo_chat]: '/hypochat',
  [AppFeatures.headline]: '/advertising/headline',
  [AppFeatures.summarise]: '/advertising/summarise_copy'
}

export type UserOnboardingSelectedFeatures = Partial<
  Record<AppFeatures, boolean>
>

export interface UserOnboardingInfoForm {
  user_display_name?: string
  user_organization_display_name?: string
  user_role?: ValueOf<typeof UserRoleLabel>[]
  user_role_others?: string
  product_type?: ValueOf<typeof ProductTypeLabel>[]
  user_others?: string
  product_type_others?: string
  selected_features?: UserOnboardingSelectedFeatures
  about_us?: string
  has_completed?: boolean
  first_generation_type?: string
  user_website?: string
  user_work?: ValueOf<typeof UserWorkLabel>
  user_work_others?: string
  user_company_size?: ValueOf<typeof UserCompanySizeLabel>
  user_heard_about_us_channel?: string
  path_name?: string
  // The following fields track the DISPLAYED values of the user's selections
  user_role_options?: string[]
  user_work_options?: string[]
  page_at?: OnboardingPageAt
}

// ==== User ====

export interface Credits {
  left: number
  total: number
  monthly: number
}

export interface CatalogueIdName {
  catalogueId: string
  catalogueName: string
}

export interface InputFieldMappingConfig {
  skip_input_field_mapping: boolean
}

export interface ProductDescriptionConfig {
  num_free_regenerates: number
  can_edit_writers?: boolean
  /**
   * Mapping between writer IDs and the number of descriptions to export from them
   * Stored in a list to maintain ordering
   * If undefined, we only export a single description
   */
  writer_description_export_count_mapping?: Record<string, number>[]
}

interface BulkProductDescriptionsConfig {
  /**
   * The maximum number of items that can be scheduled for generation in a single job.
   */
  max_items_per_job: number
}

export interface BlogGenConfig {
  show: boolean
}

export interface ContentBrandingConfig {
  show: boolean
  logo_url?: string
}

interface TextToImageGenerationConfig {
  model: number
}

export interface ServiceConfigs {
  input_field_mapping?: InputFieldMappingConfig
  product_descriptions?: ProductDescriptionConfig
  bulk_product_descriptions: BulkProductDescriptionsConfig
  blog_gen_config?: BlogGenConfig
  feature_flags?: FeatureFlags
  content_branding_config?: ContentBrandingConfig
  dashboard_incentive_options?: DashboardIncentiveOptions
  is_referencing_knowledge_base?: boolean
  text_to_image_generation?: TextToImageGenerationConfig
  has_input_nps?: boolean
  has_validated_g2?: boolean
  is_showing_g2_review_drawer_items?: boolean
  max_num_of_blog_references?: number
  pdb_config?: ProductDatabaseConfig
}

export interface Dialog {
  id: string
  show: boolean
}

/**
 * Enum for user roles within an organization.
 */
export enum OrganizationMemberRole {
  owner = 'owner',
  member = 'member'
}

export enum CancellationReason {
  difficult_to_use = 'difficult_to_use',
  i_cant_afford = 'i_cant_afford',
  missing_features_i_need = 'missing_features_i_need',
  dont_need_it_anymore = 'dont_need_it_anymore',
  bad_content_quality = 'bad_content_quality',
  will_be_back = 'will_be_back',
  others = 'others',
  // Legacy fields
  only_needed_it_once = 'only_needed_it_once', // Changed to dont_need_it_anymore
  didnt_have_time_to_use = 'didnt_have_time_to_use', // Changed to dont_need_it_anymore
  found_a_better_tool = 'found_a_better_tool', // Removed
  too_expensive = 'too_expensive' // Changed to i_cant_afford
}

export interface User {
  /**
   * The user's unique username (currently email address)
   */
  username: string
  /**
   * The user's human name
   */
  displayName?: string
  /**
   * Whether the user is an admin (superuser)
   */
  admin: boolean
  /**
   * Whether the user's email address is verified
   */
  email_verified: boolean
  /**
   * Whether the user is required to verify their phone number
   */
  requires_phone_verification: boolean
  /**
   * Whether the user is required to verify their email address
   */
  is_anti_spam_config_updated: boolean
  catalogueIdNames: Array<CatalogueIdName>
  credits: Credits
  showTagImage?: boolean
  showImageTags: boolean
  showWriterStyles: boolean
  tagImageOnUpload: boolean
  showSEORelatedKeywords: boolean
  showApiKeys: boolean
  editorType: string
  dialogs: Dialog[]
  completedToursIds: string[]
  ad_text: AdTextConfigEntry[]
  role: OrganizationMemberRole
  organizationName: string
  creditsLeft?: number
  creditsTotal?: number
  creditsMonthly?: number
  showProductMetadata: boolean
  onlyOneSelectedDescriptionPerDisplay: boolean
  onlyOneSelectedDescriptionPerProduct: boolean
  selectTitleDescriptionWillUpdateTitle: boolean
  service_configs: ServiceConfigs
  /**
   * Whether the user has visited the meta description page.
   */
  has_visited_meta_desc?: boolean
  /**
   * Whether the user is locked out due to their team's member capacity being exceeded.
   */
  is_locked_out?: boolean
  /**
   * Whether the user is shadow banned. User is banned because they generate content with banned phrases.
   */
  is_shadow_banned?: boolean
  /**
   * True if the user if on a free plan AND is in their trial period
   */
  is_trial: boolean
  /**
   * True if the user is on a free plan AND their trial period has elapsed.
   * Note: This property is currently exclusive to Phoenix users, and does not exist for Tako users.
   */
  has_trial_expired: boolean
  /**
   * True if the user is on a free plan.
   * Note: This property is currently exclusive to Phoenix users, and does not exist for Tako users.
   */
  is_free_user: boolean
  cancellation_reason?: CancellationReason
  cancellation_description?: string
  createdAt?: string

  /**
   * Hypochat document ID for the user
   * @note This document ID is used to persist the user's hypochat document
   */
  hypochat_document_id?: string

  platform_type?: PlatformType
}

type CurrencySymbolMapping = {
  [key: string]: string
}

export const CURRENCY_SYMBOL_MAPPING: CurrencySymbolMapping = {
  usd: '$',
  inr: '₹',
  mxn: 'MX$',
  cop: 'COP',
  brl: 'R$',
  pkr: '₨',
  php: '₱'
}

export interface OrganizationInfo {
  name: string
  displayName?: string
  creditsTotal: number
  creditsLeft: number
  creditsMonthly: number
  catalogueIds: string[]
  stripe_customer_id?: string
  /**
   * How many more members can be added to the organization.
   * If undefined, there's no limit on the number of members.
   */
  remaining_seats?: number | null
  /**
   * Whether any of the organization's members are locked out
   * due to the org's member capacity being exceeded.
   */
  has_locked_members?: boolean
}

/**
 * Interface for an invite for a user to join an organization.
 */
export interface OrganizationInvite {
  id: string
  /**
   * User who created the invite
   */
  created_by: string
  /**
   * Organization that the invite is for
   */
  organization_id: string
  /**
   * When the invite was created (UNIX timestamp)
   */
  created_at: number
  /**
   * When the invite expires (UNIX timestamp).
   * If empty, the invite does not expire.
   */
  expires_at?: number
  /**
   * The email address that this invite is for.
   * For public invites, this field is empty.
   */
  email?: string
  /**
   * The role that a user will have when they join the organization using this invite
   */
  role: OrganizationMemberRole
  /**
   * Link to a page that triggers the flow to join the organization
   */
  url: string
  /**
   * Whether the invite is public (i.e. anyone with the link can join the organization)
   */
  public: boolean
  /**
   * True if the invite has already been used
   */
  is_spent?: boolean
  /**
   * True if the invite has been deleted/revoked
   */
  is_deleted?: boolean
  /**
   * True if the invite has expired
   */
  expired: boolean
  /**
   * True if the invite is valid (i.e. not spent, deleted, or expired)
   */
  valid: boolean
}

/**
 * Info about a user in an organization.
 */
export interface OrganizationMember {
  /**
   * The organization's unique ID
   */
  organization_id: string
  /**
   * The user's unique ID (currently email address)
   */
  user_id: string
  /**
   * The user's role within the organization
   */
  role: OrganizationMemberRole
  /**
   * UNIX timestamp of when the user joined the organization
   */
  joined_at: number
  /**
   * Whether the member is active in the organization (i.e. not locked out due to member capacity)
   */
  is_active: boolean

  // Legacy fields from `User` interface
  username: string
  displayName?: string
  creditsMonthly: number
  creditsLeft: number
  creditsTotal: number
}

/**
 * Word usage state for a user in an organization.
 */
export interface OrganizationMemberWordUsageState {
  organization_id: string
  user_id: string
  /**
   * How many words the user has used in this billing period
   */
  words_used: number
  /**
   * (Optional) The maximum number of words the user can use
   * from the org's word pool in this billing period.
   */
  words_usage_limit?: number
  // There are other fields on the backend that I haven't
  // included here for brevity, but they can be added later.
}
/**
 * Mapping from user ID to word usage state for that user.
 */
export type OrganizationMemberWordUsageStateMap = {
  [userId: string]: OrganizationMemberWordUsageState
}

export interface OrganizationUsers {
  numUsers: number
  users: User[]
}
export enum PlanType {
  free = 'free',
  basic = 'basic',
  starter = 'starter',
  premium = 'premium',
  unlimited = 'unlimited',
  enterprise = 'enterprise'
}

export enum PlanCategoryTab {
  seo = 'seo',
  eCommerce = 'ecommerce'
}

export enum Currency {
  usd = 'usd',
  inr = 'inr',
  mxn = 'mxn',
  cop = 'cop',
  brl = 'brl',
  pkr = 'pkr',
  php = 'php'
}
/**
 * This is the information of the price and currency for specific country
 */
export interface PlanPriceCountry {
  price: number
  currency: Currency
}
/**
 * This is the mapping of the country code to the specific country price info for the plan
 */
export type PlanPriceMapping = {
  [countryCode: string]: PlanPriceCountry
}
export enum PlanBillingPeriod {
  monthly = 'monthly',
  yearly = 'yearly',
  custom = 'custom'
}
export interface PriceInfo {
  price: number
  credit_amount: number
}
export interface PaymentFailureInfo {
  invoice_id: string
  earliest_invoice_failure_timestamp: string
  decline_code: string
  restrictToPricingPage: boolean
  overdueDays: number
}
export enum StripeSessionMode {
  payment = 'payment',
  subscription = 'subscription'
}
export interface StripeUpcomingInvoice {
  /** ID is optional because the invoice may not have been created yet. */
  id?: string
  amount_due: number
  billing_reason: string
  /** UNIX timestamp when Invoice was created */
  created: number
  /** Stripe Customer ID */
  customer: string
  status?: string // TODO: Enum-ify this
  /** The ID of the payment intent associated with the invoice. */
  payment_intent?: string // TODO: Enum-ify this
  deleted?: boolean
}

export enum PlanTierTheme {
  light = 'light',
  dark = 'dark'
}

export interface ShopifySubscriptionDetails {
  shopify_subscription_id?: string
  billing_period_end?: Date
  /** The billing period end date of the most recent shopify subscription */
}

export interface PlanTier {
  plan_id: string
  plan_type: PlanType
  plan_display_name: string
  plan_description: string
  plan_price: number
  plan_display_price?: string
  plan_price_prefix?: string
  plan_price_mapping?: PlanPriceMapping
  plan_monthly_credit_amount: number
  plan_monthly_chip_amount?: number
  plan_incentives: readonly string[]
  plan_billing_period?: PlanBillingPeriod
  pay_as_you_go_prices: readonly PriceInfo[]
  currency: Currency
  credit_provision_type: PlanCreditProvisionType
  forced_plan_type?: PlanType
  stripe_checkout_session_mode: StripeSessionMode
  plan_start_datetime?: Date
  stripe_price_id?: string
  trial_plan_start_datetime?: Date
  trial_plan_length?: number
  previously_trialed_plan_ids?: string[]
  cancel_at_period_end?: boolean
  shopify_subscription?: ShopifySubscriptionDetails
  payment_failure_info?: PaymentFailureInfo
  is_paused?: boolean
  paused_plan_id?: string
  has_churn_discount_applied?: boolean
  estimated_blog_post_amount?: number
  estimated_product_description_amount?: number
  entitlements?: {
    max_organization_members?: number
  }
  theme_override?: PlanTierTheme
  plan_category_tab?: PlanCategoryTab
  cta_name?: string
  is_trialable_plan?: boolean
  trial_duration?: number
  should_show_price_for_enterprise_plan?: boolean
  cancellable_by_user?: boolean
  contact_us_link?: string
}

/**
 * This Nudge should follow the Nudge enum in the backend
 * This will be used in the monetization awareness modal as part of Tier 3 paid features
 */
export enum Nudge {
  PlagiarismChecker = 'plagiarism_checker',
  BlogSeoMode = 'seo_mode',
  FactualResearch = 'blog_enrichment',
  ProductDetailsEnrichmentFromWeb = 'enrich_from_web',
  ProductDetailsEnrichmentFromImage = 'enrich_from_image',
  WebflowIntegration = 'webflow_integration',
  BrandVoiceLite = 'brand_voice_lite',
  RelatedKeywordAndSmartSuggest = 'related_keyword_and_smart_suggest',
  InstagramImageCaption = 'instagram_image_caption',
  ProductDetailsBulkEnrichment = 'bulk_enrichment',
  BlogKnowledge = 'blog_knowledge'
}

/**
 * Enterpise feature nudge
 */
export enum EnterpriseNudgeFeature {
  ProductDetailsEnrichment = 'product_details_enrichment',
  SmartSuggest = 'smart_suggest',
  CustomContentType = 'custom_content_type',
  ProductDescriptionRewriter = 'product_description_rewriter',
  ImageEditing = 'image_editing'
}

export enum RateLimitedServiceName {
  instagram_image_caption = 'instagram_image_caption',
  blog = 'blog',
  advertising = 'advertising',
  product_descriptions = 'product_descriptions',
  mfa_auth_request_challenge = 'mfa_auth_request_challenge',
  plagiarism_checker = 'plagiarism_checker',
  text_to_image_generation = 'text_to_image_generation',
  blog_write_more = 'blog_write_more',
  research_query = 'research_query',
  generate_chat_response = 'generate_chat_response',
  hypochat_document_upload = 'hypochat_document_upload',
  enrich_from_web_or_url = 'enrich_from_web_or_url',
  enrich_from_image = 'enrich_from_image',
  generate_product_description_from_webflow = 'generate_product_description_from_webflow',
  related_keywords_for_product_description = 'related_keywords_for_product_description',
  smart_suggest = 'smart_suggest',
  blog_enrichment = 'blog_enrichment',
  rewrite_product_description = 'rewrite_product_description'
}

export interface ServiceFixedRateLimitUsage {
  service_name: RateLimitedServiceName
  raw_usage: number
  actions_limit: number
  time_window: number
}

export type PlanTierList = {
  [orgName: string]: PlanTier | null
}

export interface TrialBannerCopy {
  trial_title: string
  trial_subtitle: string
  post_trial_title: string
  post_trial_subtitle: string
}

export interface PremiumPlanTierList {
  plan_list_id: string
  plan_tier_ids: string[]
  platform_type: PlatformType
  discount_percentage?: number
  has_plan_category_tabs?: boolean
  nudge_to_plan_tier_mapping?: Record<Nudge, string[]>
  trial_banner_copy?: TrialBannerCopy
}

export enum ExportType {
  copy = 'copy',
  cut = 'cut',
  right_click = 'right_click',
  export_to_txt = 'export_to_txt',
  export_to_html = 'export_to_html',
  export_to_docx = 'export_to_docx',
  export_to_pdf = 'export_to_pdf',
  export_to_wordpress = 'export_to_wordpress',
  share = 'share'
}

export enum AdditionalExportDocumentType {
  /**
   * This type is not a real document type. We name it document type since we have used "document_type" as event property
   */
  text_to_image = 'text_to_image'
}

/**
 * Enum of the possible tone sources used by our content
 */
export enum ToneSource {
  generic = 'generic',
  custom = 'custom'
}

/**
 * Interface for the tone options used by our content
 * This is used to standardise the data format for the tone options
 */
export interface ToneOption {
  tone_name: string
  tone_id: string
  tone_source: ToneSource
}

/**
 * Interface for socket IO events
 */
export enum SocketIOEvents {
  joinRoom = 'join-room',
  leaveRoom = 'leave-room',
  newDescription = 'new-description',
  descriptionGenerationFinished = 'description-generation-finished',

  batchSeoKeywordSingleProductFinished = 'batch-seo-keyword-single-product-finished',
  batchSeoKeywordSuggestionFinished = 'batch-seo-keyword-suggestion-finished',
  batchAddSeoKeywordSuggestion = 'batch-add-seo-keyword-suggestion',

  batchSingleProductFinished = 'batch-single-product-finished',
  batchAddProductResearch = 'batch-add-product-research',
  batchEnrichFromWebFinished = 'batch-enrich-from-web-finished',
  batchEnrichFromUrlFinished = 'batch-enrich-from-url-finished',
  batchEnrichFromImageFinished = 'batch-enrich-from-image-finished',
  bulkEnrichWithFallbackFinished = 'bulk-enrich-with-fallback-finished',
  batchEnrichFromUPCFinished = 'batch-enrich-from-upc-finished',
  pdbImportJobStatusChanged = 'pdb-import-job-status-changed',
  pdbImportJobSyncToPdbCountChanged = 'pdb-import-job-sync-to-pdb-count-changed',
  pdbImportJobTotalSyncToPdbCountChanged = 'pdb-import-job-total-sync-to-pdb-count-changed',
  pdbImportJobSyncFromPdbCountChanged = 'pdb-import-job-sync-from-pdb-count-changed',
  pdbImportJobTotalSyncFromPdbCountChanged = 'pdb-import-job-total-sync-from-pdb-count-changed',
  shopifyExportJobStatusChanged = 'shopify-export-job-status-changed'
}

/**
 * Interface for country details exposed by the backend
 */
export interface CountryDetail {
  iso_code: string
  country_name: string
}

export enum PerformanceMetricName {
  compose = 'compose',
  chatMessageGeneration = 'chatMessageGeneration',
  adTextGeneration = 'adTextGeneration',
  blogTitleGeneration = 'blogTitleGeneration',
  blogOutlineGeneration = 'blogOutlineGeneration',
  imageGeneration = 'imageGeneration',
  blogParagraphsGenerationStreaming = 'blogParagraphsGenerationStreaming',
  blogParagraphsGenerationStreamingFirstResult = 'blogParagraphsGenerationStreamingFirstResult'
}

export enum AccessControlPermission {
  full_access = 'full_access',
  no_access = 'no_access'
}

export const generationTypeToAdTextMap: Partial<
  Record<ContentGenerationType, AdTextType>
> = {
  [ContentGenerationType.advertising_text_instagram]: AdTextType.instagram,
  [ContentGenerationType.advertising_text_google]: AdTextType.google,
  [ContentGenerationType.advertising_text_facebook]: AdTextType.facebook,
  [ContentGenerationType.advertising_text_headline]: AdTextType.headline,
  [ContentGenerationType.advertising_text_linkedin]: AdTextType.linkedin,
  [ContentGenerationType.advertising_text_rewriter]: AdTextType.rewriter,
  [ContentGenerationType.advertising_text_category_page]:
    AdTextType.category_page,
  [ContentGenerationType.advertising_text_landing_page]:
    AdTextType.landing_page,
  [ContentGenerationType.advertising_text_eli5]: AdTextType.eli5,
  [ContentGenerationType.advertising_text_email]: AdTextType.email,
  [ContentGenerationType.advertising_text_video_intro]: AdTextType.video_intro,
  [ContentGenerationType.advertising_text_meta_desc]: AdTextType.meta_desc,
  [ContentGenerationType.advertising_text_marketing_angles]:
    AdTextType.marketing_angles,
  [ContentGenerationType.advertising_text_press_release]:
    AdTextType.press_release,
  [ContentGenerationType.advertising_text_summarise]: AdTextType.summarise,
  [ContentGenerationType.advertising_text_youtube_title]:
    AdTextType.youtube_title,
  [ContentGenerationType.advertising_text_bio_writer]: AdTextType.bio_writer,
  [ContentGenerationType.advertising_text_ask_anything]: AdTextType.ask_anything
}

export interface IncludeExcludeLinksResponse {
  include_links?: string[]
  exclude_links?: string[]
}
