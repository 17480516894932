import { AnyColor, Colord, colord } from 'colord'

import { Color } from '@material-ui/core'

/**
 * Given a primary color (500), generate a full material design color
 * palette using colord.
 */
export const makeMaterialPalette: (color: AnyColor | Colord) => Color = (
  mainColor
) => {
  const main = colord(mainColor)
  return {
    '50': main.lighten(0.35).toHex(),
    '100': main.lighten(0.25).toHex(),
    '200': main.lighten(0.18).toHex(),
    '300': main.lighten(0.12).toHex(),
    '400': main.lighten(0.06).toHex(),
    '500': main.toHex(),
    '600': main.darken(0.06).toHex(),
    '700': main.darken(0.12).toHex(),
    '800': main.darken(0.18).toHex(),
    '900': main.darken(0.25).toHex(),
    // Accents have a slightly rotated hue
    A100: main.rotate(5).lighten(0.24).toHex(),
    A200: main.rotate(5).lighten(0.16).toHex(),
    A400: main.rotate(5).darken(0.1).toHex(),
    A700: main.rotate(5).darken(0.12).toHex()
  }
}

/**
 * Given a primary color (500), compute the color at
 * the specified Material Design shade (e.g. 50, 700, A100)
 */
export const toMaterialShade: (
  primaryColor: AnyColor | Colord,
  materialShade: keyof Color
) => string = (primaryColor, materialShade) => {
  const palette = makeMaterialPalette(primaryColor)
  return palette[materialShade]
}
