import * as Sentry from '@sentry/react'
import axios from 'axios'
import TagManager from 'react-gtm-module'
import { v4 as uuidv4 } from 'uuid'

import { handleClickSubscribe } from '@hypotenuse/common/src/api/Analytics'
import {
  GtmDataLayerTypes,
  GtmEvents
} from '@hypotenuse/common/src/integrations/GoogleTagManager/Interfaces'
import {
  CancellationReason,
  PlanTier
} from '@hypotenuse/common/src/utils/Interfaces'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'

import { handleCreateShopifyCheckoutSession } from '../../../../../api/Billing'
import { updatePremiumPlanTier } from '../../../../../api/PlanTier'

import { ShopifyBillingPlanIds } from '../../../../../utils/Constants'
import { redirectToStripeCheckoutSession } from '../../../../../utils/Functions'
import {
  CancellationPlanForm,
  PlanChangeType,
  StripeBillingInfo,
  StripePaymentFailureErrorType
} from '../../../../utils/Interfaces'

interface UseUpdateUserPlanProps {
  username?: string
  organizationName?: string
  selectedReason?: CancellationReason
  cancellationDescription: string
  activePlanTier?: PlanTier
  billingInfo?: StripeBillingInfo
  openPaymentFailureModal: (message: string) => void
}

const useUpdateUserPlan = (props: UseUpdateUserPlanProps) => {
  const {
    username,
    organizationName,
    selectedReason,
    cancellationDescription,
    activePlanTier,
    openPaymentFailureModal
  } = props

  const handleCancellation = async (
    selectedPlanTierId: string,
    planChangeType: PlanChangeType
  ) => {
    if (planChangeType !== PlanChangeType.cancellation || !selectedReason)
      return

    const form: CancellationPlanForm = {
      cancellation_reason: selectedReason,
      cancellation_description: cancellationDescription
    }

    await updatePremiumPlanTier(selectedPlanTierId, planChangeType, form)
    snackbar.show('Your plan has been cancelled', {
      variant: 'warning',
      autoHideDuration: 5000
    })
  }

  const handleNewSubscription = async (selectedPlanTierId: string) => {
    try {
      // Track New Subscriptions in Google Tag Manager
      TagManager.dataLayer({
        dataLayer: {
          // Type is GtmDataLayer
          event: GtmEvents.PHOENIX_CHECKOUT_STARTED,
          username: username,
          organization: organizationName,
          transactionId: uuidv4(), // Must be unique so that each transaction is unique
          planType: activePlanTier?.plan_type,
          oldPlanId: activePlanTier?.plan_id,
          newPlanId: selectedPlanTierId
        },
        dataLayerName: GtmDataLayerTypes.GTM_SUBSCRIPTION_LAYER
      })
    } catch (e) {
      // App should not break if TagManager fails
      Sentry.captureException(e)
    }
    if (ShopifyBillingPlanIds.includes(selectedPlanTierId)) {
      // This check is not ideal, but it is only used to pass the reviewer test.
      await handleCreateShopifyCheckoutSession()
    } else {
      // If a user does not have active subscription, create a new Stripe Checkout session.
      await redirectToStripeCheckoutSession(selectedPlanTierId)
    }
    return true
  }

  const handlePlanChange = async (
    selectedPlanTierId: string,
    planChangeType: PlanChangeType
  ) => {
    await updatePremiumPlanTier(selectedPlanTierId, planChangeType)
    return true
  }

  const updateUserPlan = async (
    selectedPlanTierId: string,
    planListId: string,
    planChangeType: PlanChangeType
  ) => {
    let updateSuccess = false
    try {
      if (planChangeType === PlanChangeType.cancellation && selectedReason) {
        await handleCancellation(selectedPlanTierId, planChangeType)
      } else if (planChangeType === PlanChangeType.new_subscription) {
        handleClickSubscribe(
          selectedPlanTierId,
          planListId,
          window.location.pathname
        )
        updateSuccess = await handleNewSubscription(selectedPlanTierId)
      } else {
        handleClickSubscribe(
          selectedPlanTierId,
          planListId,
          window.location.pathname
        )
        updateSuccess = await handlePlanChange(
          selectedPlanTierId,
          planChangeType
        )
      }
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        e.response?.data?.detail?.error in StripePaymentFailureErrorType
      ) {
        openPaymentFailureModal(
          e.response?.data?.detail?.error_message ?? 'Please try again'
        )
        snackbar.show('Your payment did not go through', {
          variant: 'error',
          autoHideDuration: 5000
        })
      } else {
        throw e
      }
    }

    if (updateSuccess) {
      if (
        planChangeType === PlanChangeType.annualToAnnualDowngrade ||
        planChangeType === PlanChangeType.annualToMonthlyUpgrade ||
        planChangeType === PlanChangeType.annualToMonthlyDowngrade ||
        planChangeType === PlanChangeType.monthlyToMonthlyDowngrade
      ) {
        snackbar.show('Your plan has been successfully scheduled', {
          variant: 'success',
          autoHideDuration: 5000
        })
      } else if (
        // For new subscriptions, we don't show a snackbar. Users are redirected to Stripe Checkout
        // where upon successful payment, they will be redirected to a thank you page.
        planChangeType !== PlanChangeType.new_subscription
      ) {
        snackbar.show('Your plan has been successfully changed', {
          variant: 'success',
          autoHideDuration: 5000
        })
      }
    }
  }

  return { updateUserPlan }
}

export default useUpdateUserPlan
