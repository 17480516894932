import React from 'react'

import palette from '../../atoms/Colors'
import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: palette.red[600],
      '&:hover': {
        backgroundColor: palette.red[700]
      },
      color: theme.palette.getContrastText(palette.red[600])
    }
  })
)

interface Props extends ButtonProps {}

/**
 * 'Danger' action button for use in BaseModal.
 * For actions like 'Delete'
 */
export const DangerButton: React.FC<Props> = React.memo((props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  return <Button className={clsx(classes.root, className)} {...rest} />
})
