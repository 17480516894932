export enum GLOBAL_APP_ACTION {
  UPDATE_CATALOGUES = 'update_catalogues',
  DELETE_CATALOGUES = 'delete_catalogues',
  UPDATE_TOP_THREE_PRODUCTS = 'update_top_three_products',
  CATALOGUES_LOADING = 'catalogues_loading',
  CLEAR_CATALOGUES = 'clear_catalogues',
  DEFAULT_DOWNVOTE_REASONS = 'defaultDownvoteReasons',
  BRAND_PLACEHOLDER_TEXT = 'brandPlaceholderText',
  BRAND_HELPER_TEXT = 'brandHelperText',
  BLOG_TONE_OPTIONS = 'blogToneOptions',
  BLOG_LENGTH_OPTIONS = 'blogLengthOptions',
  GLOBAL_FEATURE_FLAGS = 'globalFeatureFlags'
}
