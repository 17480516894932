import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { ToolCardGroupType } from './mappings'

interface StoreState {
  tab: string
  search: string
}
interface StoreActions {
  setTab: (newTab: ToolCardGroupType) => void
  setSearch: (search: string) => void
}
type Store = StoreState & StoreActions

export const useStore = create(
  immer<Store>((set) => ({
    tab: ToolCardGroupType.featured,
    search: '',
    setTab: (newTab: ToolCardGroupType) => {
      set({ tab: newTab })
      // Note: the normal transition for Material UI is 300ms, so we need to wait for that to finish before scrolling
      // We set the timeout to 500ms for a little extra buffer time to improve the UX
      setTimeout(() => {
        // ref is not used because a list of templates headings are rendered
        // If templateId is not given, scroll to the section header
        const elementToScrollTo = document.getElementById(newTab)
        if (elementToScrollTo)
          elementToScrollTo.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
      }, 500)
    },
    setSearch: (search: string) => set({ search })
  }))
)
