import { create, createStore } from 'zustand'

import { WordsUsageInResponse, apiGetWordUsage } from '../api/Word'

export interface WordUsage {
  /**
   * The number of words used by the user.
   */
  user_words_used: number

  /**
   * Individual usage limit set on the user.
   * This is the maximum number of words the user can use (if set).
   */
  user_words_usage_limit: number | null

  /**
   * Total number of words used by organization
   * to which the user belongs.
   */
  org_words_used: number

  /**
   * Usage limit set on the organization to which the user belongs.
   * This is set based on the organization's subscription plan.
   */
  org_words_usage_limit: number | null

  /**
   * Total number of words used by an  organization from grants.
   *
   * Grants is a separate words usage pool which does not
   * expire and is not affected by the organization's subscription plan.
   */
  grant_org_words_used: number

  /**
   * Usage limit set on the organization from grants.
   *
   * Grants is a separate words usage pool which does not
   * expire and is not affected by the organization's subscription plan.
   */
  grant_org_words_usage_limit: number | null
}

export interface IWordUsageStore {
  wordUsage: WordUsage
  isWordUsageLoading: boolean
  isWordUsageExceeded: boolean
  getWordUsage: (
    organizationId: string,
    userId: string
  ) => Promise<WordsUsageInResponse | void>
}

const initialWordUsage: WordUsage = {
  user_words_used: 0,
  user_words_usage_limit: 0,
  org_words_used: 0,
  org_words_usage_limit: 0,
  grant_org_words_used: 0,
  grant_org_words_usage_limit: 0
}

const wordUsageStore = createStore<IWordUsageStore>((set) => ({
  wordUsage: initialWordUsage,
  isWordUsageLoading: false,
  isWordUsageExceeded: false,
  getWordUsage: async (organizationId: string, userId: string) => {
    try {
      set({
        isWordUsageLoading: true
      })
      const data = await apiGetWordUsage(organizationId, userId)
      set({
        wordUsage: data
      })
      if (
        data.org_words_usage_limit &&
        data.grant_org_words_usage_limit &&
        data.org_words_used + data.grant_org_words_used >
          data.org_words_usage_limit + data.grant_org_words_usage_limit
      ) {
        set({
          isWordUsageExceeded: true
        })
      }
      set({
        isWordUsageLoading: false
      })
      return data
    } catch (e) {
      set({
        isWordUsageLoading: false
      })
    }
  }
}))

export const useWordUsage = create(wordUsageStore)
