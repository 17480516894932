import * as Sentry from '@sentry/react'

import { Message } from '@papercups-io/chat-widget/dist/api'

import { BlogWriteMoreAction } from '../interfaces/Blog'
import { ContentGenerationType } from '../interfaces/ContentGeneration'
import { Description, ProductImportSource } from '../interfaces/Products'
import { ImageType } from '../interfaces/TextToImage'

import { apiClient } from '../utils/ApiClient'
import {
  ActionPoint,
  AdditionalExportDocumentType,
  ExportType
} from '../utils/Interfaces'

import { DocumentType } from '../components/documents/types'
import { TopicRecommendationTriggerSource } from '../components/liveFeed/types'

export const handleTrackAdvertisingTextAdvancedOptionsClick = (
  isOpen: boolean
) => {
  return apiClient.post<void>('/analytics/advertising/advanced_options', null, {
    params: {
      is_open: isOpen
    }
  })
}

export const trackCustomerSupportMessageReceived = (message: Message) => {
  const messageReceived = message.body
  return apiClient.post<void>(
    '/analytics/customer_support_message',
    JSON.stringify(messageReceived)
  )
}

export const handleTrackDescriptionSelect = (
  description: Description,
  product_id: string,
  catalogueTemplateName?: string
) => {
  const data = {
    description: description,
    product_id: product_id,
    catalogue_template_name: catalogueTemplateName
  }
  return apiClient.post<void>('/analytics/product/description_select', data)
}

export const handleTrackDescriptionCopy = (
  description: Description,
  productId: string,
  catalogueTemplateName?: string
) => {
  return apiClient.post<void>('/analytics/product/description_copy', {
    description: description,
    product_id: productId,
    catalogue_template_name: catalogueTemplateName
  })
}

export const handleTrackDescriptionDelete = (
  description: Description,
  productId: string,
  catalogueTemplateName?: string
) => {
  return apiClient.post<void>('/analytics/product/description_delete', {
    description: description,
    product_id: productId,
    catalogue_template_name: catalogueTemplateName
  })
}

export const handleTrackCustomerContactUs = () => {
  return apiClient.post<void>('/analytics/customer_contact_us')
}

/**
 * Log to analytics that a user clicked 'learn more' on a feature.
 * @param {string} feature The feature the user clicked 'learn more' on
 */
export const handleTrackCustomerLearnMore = (feature?: string) => {
  return apiClient.post<void>('/analytics/customer_learn_more', null, {
    params: { feature }
  })
}

export const handleTrackCustomerViewApiDocs = () => {
  return apiClient.post<void>('/analytics/customer_view_api_docs')
}

// ===== Insights dashboard analytics =====
/**
 * To be invoked when the action button on an action point is clicked
 * @param {ActionPoint} action_point The action point that was clicked
 */
export const handleTrackInsightsActionPointClick = async (
  action_point: ActionPoint
) => {
  return apiClient.post<void>(
    '/analytics/insights_action_point_click',
    action_point
  )
}

// ===== General Analytics =====
export const handleTrackPageVisit = async (pathname: string) => {
  return apiClient
    .post('/analytics/page_visit', null, {
      params: {
        pathname: pathname
      }
    })
    .then((_resp) => {})
    .catch((error) => {
      console.log(error)
    })
}

/**
 * A general purpose endpoint for tracking user clicks on the frontend.
 */
export const handleUserClick = async (
  elementId: string,
  pathname?: string,
  sectionId?: string
) => {
  return apiClient.post('/analytics/user-click', null, {
    params: {
      element_id: elementId,
      pathname: pathname,
      section_id: sectionId
    }
  })
}
/**
 * Tracks user clicks on the plan subscribe buttons.
 */
export const handleClickSubscribe = async (
  planId: string,
  planListId: string,
  pathname?: string
) => {
  return apiClient.post('/analytics/click-subscribe', null, {
    params: {
      plan_id: planId,
      plan_list_id: planListId,
      pathname: pathname
    }
  })
}
/**
 * A general purpose endpoint for tracking element shown on the frontend.
 */
export const handleElementShown = async (
  elementId: string,
  sectionId?: string,
  pathname?: string
) => {
  return apiClient.post('/analytics/element-shown', null, {
    params: {
      element_id: elementId,
      section_id: sectionId,
      pathname: pathname
    }
  })
}

export const handleChurnModalShown = async () => {
  return apiClient.post('/analytics/churn-modal-shown')
}

interface TrackBlogPostsExportProps {
  blogId: string
  exportType: string
}

interface TrackDocumentsExportProps {
  documentId: string
  exportType: string
}

// ===== Blog Analytics =====
export const handleTrackExportBlogPosts = async ({
  blogId,
  exportType
}: TrackBlogPostsExportProps): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/blog/export_posts',
    {},
    {
      params: {
        blog_id: blogId,
        export_type: exportType
      }
    }
  )
}

export const handleTrackExportDocuments = async ({
  documentId,
  exportType
}: TrackDocumentsExportProps): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/document/export',
    {},
    {
      params: {
        document_id: documentId,
        export_type: exportType
      }
    }
  )
}

export const handleTrackWriteMoreAction = async (
  blogId: string,
  blogWriteMoreAction: BlogWriteMoreAction
): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/blog/blog_write_more_action',
    {},
    {
      params: { blog_id: blogId, blog_write_more_action: blogWriteMoreAction }
    }
  )
}

export const handleTrackBlogLinkSettingsSaved = async (
  blogId: string
): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/blog/link-settings-saved',
    {},
    {
      params: { blog_id: blogId }
    }
  )
}

// ===== Text to Image Generation Analytics =====
export const handleTrackTextToImageDownload = async (
  text_prompt: string,
  imageId: string
): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/text_to_image/text_to_image_download',
    {},
    {
      params: { text_prompt, image_id: imageId }
    }
  )
}

// ===== Referral Analytics =====
export const handleTrackSocialPostLinkSubmitted = async (
  shareUrl: string
): Promise<void> => {
  await apiClient.post<void>(
    '/analytics/blog/blog_social_post_link_submitted',
    {},
    {
      params: { share_url: shareUrl }
    }
  )
}

// ===== Content Tool Sidebar Analytics =====
export const apiTrackContentRepurposingEvent = async ({
  contentTypeFrom,
  contentToolId,
  contentTypeTo,
  choicesShown
}: {
  contentTypeFrom: string
  contentToolId: string
  contentTypeTo: string
  choicesShown: string[]
}) => {
  try {
    return apiClient.post(`/analytics/content-repurposing`, {
      content_type_from: contentTypeFrom,
      content_from_id: contentToolId,
      content_type_to: contentTypeTo,
      choices_shown: choicesShown
    })
  } catch (e) {
    console.error('Failed to track content repurposing event')
  }
}

// ===== Export Content Disabled Analytics =====
export const handleTrackExportDocumentDisabled = async (
  exportType?: ExportType,
  documentType?: DocumentType | AdditionalExportDocumentType
) => {
  try {
    return apiClient.post('/analytics/disable_export_content', {
      export_type: exportType,
      document_type: documentType
    })
  } catch (e) {
    console.error('Could not track export document disabled')
  }
}

// ===== Export Content Attemp Analytics =====
export const handleTrackExportDocumentAttempt = async (
  exportType?: ExportType,
  documentType?: DocumentType | AdditionalExportDocumentType
) => {
  try {
    return apiClient.post('/analytics/attempt_export_content', {
      export_type: exportType,
      document_type: documentType
    })
  } catch (e) {
    console.error('Could not track export document attempt')
  }
}

// ===== Delete Generated Image Analytics =====
export const handleTrackDeleteGeneratedImage = async (
  generationId: string,
  documentType: DocumentType,
  imageType: ImageType
) => {
  try {
    return apiClient.post('/analytics/text-to-image/generated-image-deleted', {
      generation_id: generationId,
      document_type: documentType,
      image_type: imageType
    })
  } catch (e) {
    console.error('Could not track delete generated image')
  }
}

// ===== Add Outline Header Analytics =====
export const handleTrackBlogAddOutlineHeader = async () => {
  try {
    return apiClient.post('/analytics/blog/add-outline-header')
  } catch (e) {
    console.error('Could not track adding blog outline header')
  }
}

// ===== Toggle SEO Mode Analytics =====
export const handleTrackBlogToggleSeoMode = async (seo_toggle: boolean) => {
  try {
    return apiClient.post('/analytics/blog/toggle-seo-mode', {
      seo_toggle: seo_toggle
    })
  } catch (e) {
    console.error('Could not track toggling blog SEO mode')
  }
}

export const handleTrackBlogToggleHighlightSeoIssues = async (
  highlight_toggle: boolean
) => {
  try {
    return apiClient.post('/analytics/blog/toggle-highlight-seo-issues', {
      highlight_toggle: highlight_toggle
    })
  } catch (e) {
    console.error('Could not track toggling blog highlight SEO issues')
  }
}

export const handleTrackSeoSuggestionImprove = async (suggestion: string) => {
  try {
    return apiClient.post('/analytics/seo-suggestion-improve', {
      suggestion: suggestion
    })
  } catch (e) {
    console.error('Could not track blog SEO suggestion improve')
  }
}

// ===== Add Outline Header Analytics =====
export const handleTrackPricePlanModalOpen = async ({
  planListId,
  elementId,
  pathname
}: {
  planListId: string
  elementId?: string
  pathname?: string
}) => {
  try {
    return apiClient.post('/analytics/price-plan-modal-open', {
      plan_list_id: planListId,
      element_id: elementId,
      pathname: pathname
    })
  } catch (e) {
    console.error('Could not track price plan modal open')
  }
}

export const handleTrackEnterpriseFeatureModalOpen = async ({
  planListId,
  elementId,
  pathname
}: {
  planListId: string
  elementId: string
  pathname: string
}) => {
  try {
    return apiClient.post('/analytics/enterprise-feature-modal-open', {
      plan_list_id: planListId,
      element_id: elementId,
      pathname: pathname
    })
  } catch (error) {
    console.error('Could not track enterprise feature modal open')
    Sentry.captureException(error)
  }
}

// ===== Add View Plans Analytics =====
export const handleTrackPremiumViewPlans = async (
  pathname: string,
  elementId: string
) => {
  try {
    return apiClient.post('/analytics/premium-view-plans', {
      pathname: pathname,
      element_id: elementId
    })
  } catch (e) {
    console.error('Could not track premium view plans event')
  }
}

/**
 * Handles analytics tracking for when clients contact us regarding enterprise plans.
 *
 * @param elementId The ID of the element which triggered this contact us workflow.
 * @param redirectType Type / source that triggered this contact us workflow.
 * @param pathname The pathname which triggered this contact us workflow.
 *                 If left unspecified, defaults to `window.location.pathname`.
 * @param integrationOption
 * @param planIdOnCard The plan ID on which the card is based if the trigger is from a pricing plan card.
 *
 * @returns Promise to record event to analytics.
 */
export const handlePremiumCustomerEnterprisePlanContactUs = async ({
  elementId,
  redirectType,
  pathname,
  integrationOption,
  planIdOnCard
}: {
  elementId: string
  redirectType?: string
  pathname?: string
  integrationOption?: ProductImportSource
  planIdOnCard?: string
}) => {
  try {
    return apiClient.post(
      '/analytics/premium-customer-enterprise-plan-contact-us',
      {
        pathname: pathname || window.location.pathname,
        redirect_type: redirectType,
        element_id: elementId,
        integration_option: integrationOption,
        plan_id_on_card: planIdOnCard
      }
    )
  } catch (e) {
    console.error('Could not track premium customer enterprise plan contact us')
  }
}

// ===== Add Word Count Limit Analytics =====
export const handleTrackWordCountLimitModalOpen = async ({
  elementId,
  pathname
}: {
  elementId: string
  pathname: string
}) => {
  try {
    return apiClient.post('/analytics/word-count-limit-modal', {
      element_id: elementId,
      pathname: pathname
    })
  } catch (e) {
    console.error('Could not track word count limit modal open')
  }
}

export const handleTrackContentSuggestionRemoved = async (
  topicRecommendationId: string,
  contentGenerationTool: ContentGenerationType,
  url: string,
  isUrlUsedForRecommendation: boolean,
  generationTriggerSource?: TopicRecommendationTriggerSource
) => {
  try {
    return apiClient.post('/analytics/content-suggestion-removed', {
      topic_recommendation_id: topicRecommendationId,
      content_generation_tool: contentGenerationTool,
      url: url,
      is_url_used_for_recommendation: isUrlUsedForRecommendation,
      generation_trigger_source: generationTriggerSource
    })
  } catch (e) {
    console.error('Could not track content suggestion removed')
  }
}

export const handleTrackContentSuggestionUsed = async (
  topicRecommendationId: string,
  contentGenerationTool: ContentGenerationType,
  url: string,
  isUrlUsedForRecommendation: boolean,
  generationTriggerSource?: TopicRecommendationTriggerSource
) => {
  try {
    return apiClient.post('/analytics/content-suggestion-used', {
      topic_recommendation_id: topicRecommendationId,
      content_generation_tool: contentGenerationTool,
      url: url,
      is_url_used_for_recommendation: isUrlUsedForRecommendation,
      generation_trigger_source: generationTriggerSource
    })
  } catch (e) {
    console.error('Could not track content suggestion removed')
  }
}

export const handleTrackDocumentCreated = async (
  documentType: DocumentType,
  topicRecommendationId: string,
  topicRecommendationTitle: string,
  recommendationSource: string
) => {
  try {
    return apiClient.post('/analytics/document-created', {
      document_type: documentType,
      topic_recommendation_id: topicRecommendationId,
      topic_recommendation_title: topicRecommendationTitle,
      recommendation_source: recommendationSource
    })
  } catch (e) {
    console.error('Could not track document created')
  }
}

export const handleTrackTryExampleUsed = async (
  documentType: DocumentType,
  sourceUrl: string,
  inputs: Record<string, any>
) => {
  try {
    return apiClient.post('/analytics/try-example-used', {
      document_type: documentType,
      source_url: sourceUrl,
      inputs: inputs
    })
  } catch (e) {
    console.error('Could not track try example used')
  }
}
