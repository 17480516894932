import React from 'react'

import useSWR from 'swr'

import palette from '@hypotenuse/common/src/atoms/Colors'
import { Button, CircularProgress, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { handleContactUs } from '@hypotenuse/common/src/analytics/utils'
import { apiFetchPlanTierListObject } from '@hypotenuse/common/src/api/PlanTier'
import { useAsyncFn } from '@hypotenuse/common/src/hooks'
import { useUserContext } from '@hypotenuse/common/src/utils/context/UserContext'

interface PricingPlanCardCTAButtonProps {
  isCustom?: boolean
  isActive?: boolean
  isCancelAtPeriodEnd?: boolean
  isPaused?: boolean
  loading?: boolean
  onSelect: () => Promise<void>
  onCancel: () => Promise<void>
  /**
   * Element ID for used for tracking purposes, used by enterprise plan card.
   */
  trackingId: string
  /**
   * Whether to show a dark theme button
   */
  isDarkTheme?: boolean
  /**
   * Overrides the default call to action name
   */
  ctaName?: string
  /**
   * Whether the plan is cancellable by the user
   */
  cancellableByUser: boolean
  /**
   * Custom contact us link for the CTA (defaults to CONTACT_US_LINK if not provided)
   */
  contactUsLink?: string
  /**
   * Whether the plan is a trialable plan
   */
  isTrialablePlan?: boolean
  /**
   * Whether the user has trialed the plan
   */
  hasTrialedPlan?: boolean
  /**
   * The duration of the trial in days
   */
  trialDuration?: number
  /**
   * The plan ID on which the card is based
   */
  planId: string
}

const useStyles = makeStyles((theme: Theme) => {
  const darkThemeColor = theme.palette.text.primary
  return createStyles({
    buttonDarkTheme: {
      color: darkThemeColor,
      backgroundColor: theme.palette.getContrastText(darkThemeColor),
      '&:hover': {
        backgroundColor: palette.gray['300']
      },
      '&:disabled': {
        color: darkThemeColor,
        backgroundColor: palette.gray['500']
      }
    }
  })
})

export const PricingPlanCardCTAButton = (
  props: PricingPlanCardCTAButtonProps
) => {
  const {
    isCustom,
    isActive,
    isCancelAtPeriodEnd,
    isPaused,
    loading,
    onSelect: _onSelect,
    onCancel: _onCancel,
    trackingId,
    isDarkTheme,
    ctaName,
    cancellableByUser,
    contactUsLink,
    isTrialablePlan,
    hasTrialedPlan,
    trialDuration,
    planId
  } = props

  const { user } = useUserContext()

  const [{ loading: loadingSelect }, onSelect] = useAsyncFn(_onSelect, [
    _onSelect
  ])
  const [{ loading: loadingCancel }, onCancel] = useAsyncFn(_onCancel, [
    _onCancel
  ])

  const classes = useStyles()

  const {
    data: planTierListObject,
    isLoading: isFetchingPlanTierListObjectLoading
  } = useSWR('planTierListObject', apiFetchPlanTierListObject)

  if (isCustom) {
    return (
      <span>
        <Button
          fullWidth
          color="primary"
          variant={isDarkTheme ? 'text' : 'contained'}
          className={clsx(isDarkTheme && classes.buttonDarkTheme)}
          disabled={isFetchingPlanTierListObjectLoading}
          onClick={() => {
            handleContactUs({
              pathname: window.location.pathname,
              elementId: trackingId,
              userId: user.username,
              planIdOnCard: planId,
              planListId: planTierListObject?.plan_list_id || '',
              contactUsLink: contactUsLink
            })
          }}
          data-testid="pricingPlanCard_callToAction"
        >
          {ctaName ? ctaName : 'Talk to sales'}
        </Button>
      </span>
    )
  } else if (isTrialablePlan && !hasTrialedPlan) {
    return (
      <span>
        <Button
          fullWidth
          color="primary"
          variant={isDarkTheme ? 'text' : 'contained'}
          className={clsx(isDarkTheme && classes.buttonDarkTheme)}
          disabled={isFetchingPlanTierListObjectLoading}
          onClick={
            user.is_free_user
              ? onSelect
              : () => {
                  handleContactUs({
                    pathname: window.location.pathname,
                    elementId: trackingId,
                    userId: user.username,
                    planListId: planTierListObject?.plan_list_id || '',
                    contactUsLink: contactUsLink
                  })
                }
          }
          data-testid="pricingPlanCard_callToAction"
        >
          {`Start ${trialDuration}d free trial`}
        </Button>
      </span>
    )
  } else if (isActive && isPaused) {
    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={onSelect}
        data-testid="pricingPlanCard_callToAction"
        endIcon={
          loadingSelect && <CircularProgress color="inherit" size={14} />
        }
        disabled={loading}
      >
        Resume subscription
      </Button>
    )
  } else if (isActive && !cancellableByUser) {
    return <></> // Do not show any CTA button if not cancellable by user
  } else if (isActive && !isCancelAtPeriodEnd) {
    return (
      <Button
        data-testid="pricingPlanCard_callToAction"
        fullWidth
        variant="outlined"
        color="primary"
        onClick={onCancel}
        endIcon={
          loadingCancel && <CircularProgress color="inherit" size={14} />
        }
        disabled={loading}
      >
        Cancel subscription
      </Button>
    )
  } else {
    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={onSelect}
        data-testid="pricingPlanCard_callToAction"
        endIcon={
          loadingSelect && <CircularProgress color="inherit" size={14} />
        }
        disabled={loading}
      >
        {isCancelAtPeriodEnd && isActive ? 'Resubscribe' : 'Select'}
      </Button>
    )
  }
}
