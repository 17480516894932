import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

export const apiShouldShowNpsBannerForUser = async (): Promise<boolean> => {
  const resp = await apiClient.get(
    `/net_promoter_score/show_net_promoter_score_banner`
  )
  return resp.data
}

export const apiBonusWordsForG2Review = async (): Promise<number> => {
  const resp = await apiClient.get(
    `/net_promoter_score/bonus_words_for_g2_review`
  )
  return resp.data
}
