import useSWR from 'swr'

import { apiGetUserCountryCode } from '../api/User'

const useUserCountryCode = () => {
  const { data: userCountryCode = '' } = useSWR(
    '/user_country_code',
    apiGetUserCountryCode
  )

  const isUserSingaporean = userCountryCode === 'SG'

  return { userCountryCode, isUserSingaporean }
}

export default useUserCountryCode
