import { useContext } from 'react'

import { makePropInjectorHOCFromHook } from '../../hooks/hocify'

import { BeaconContext, BeaconProps } from './BeaconProvider'

export const useBeacon = (): BeaconProps => {
  const beaconContext = useContext(BeaconContext)
  if (beaconContext === undefined) {
    throw new Error(`useBeacon must be used within a BeaconProvider`)
  }
  return beaconContext
}

export const withBeacon = makePropInjectorHOCFromHook(useBeacon)
