import React, { useCallback, useContext, useMemo, useState } from 'react'

import axios, { AxiosResponse } from 'axios'
import { Subtract } from 'utility-types'

import {
  ComposeContext,
  GenerateBatchInRequest,
  HighlightCommandContext,
  HighlightResearchCommandContext,
  ToneAnalyzerContext,
  apiAnalyzeTone,
  apiCompose,
  apiGenerateBatch,
  apiGenerateBlogAdText,
  apiGenerateContent,
  apiHighlightCommand,
  apiHighlightResearchCommand
} from '../api/LanguageModel'

import { AdTextType, generationTypeMap } from '../interfaces/AdvertisingText'
import { BlogStepper } from '../interfaces/Blog'
import { ContentGenerationType } from '../interfaces/ContentGeneration'
import { Description } from '../interfaces/Products'
import { ToneFields } from '../interfaces/Tone'

import { ContactUsProps, handleContactUs } from '../analytics/utils'
import { apiClient } from '../utils/ApiClient'
import {
  CountryDetail,
  EnterpriseNudgeFeature,
  Nudge,
  RateLimitedServiceName,
  ServerErrorType
} from '../utils/Interfaces'

import { EntityReference } from '../components/entityReference/types'

import {
  EnterpriseFeatureModalProps,
  useEnterpriseFeatureModal
} from './useEnterpriseFeatureModal'
import { useMonetizationAwarenessModal } from './useMonetizationAwarenessModal'

// ===== Types & Interfaces =====
/**
 * This defines the type of the object returned by the useGenerationAPI hook.
 * NOTE: we have inconsistent naming for interfaces. Can consider abandoning the "I" prefix?
 * See https://softwareengineering.stackexchange.com/questions/117348/should-interface-names-begin-with-an-i-prefix
 */
export interface IGenerationAPIContext {
  // LM generation methods
  apiGenerateAdvertisingText: ({
    productId,
    adTextType,
    splitOutput,
    referenceLinks,
    referenceFileIds,
    numOutputs,
    entityReferences,
    isReferencingKnowledgeBase
  }: {
    productId: string
    adTextType: AdTextType
    splitOutput?: boolean
    referenceLinks?: string[]
    referenceFileIds?: string[]
    numOutputs?: number
    entityReferences?: readonly EntityReference[]
    isReferencingKnowledgeBase?: boolean
  }) => Promise<{ warning_message: string } | undefined>
  apiGenerateBlogAdtext: (productId: string) => Promise<Description>
  apiGenerateBlog: (
    productId: string,
    blogStepper: BlogStepper,
    is_team_knowledge_enabled: boolean,
    subSteps?: [number, number][],
    numOutputs?: number,
    entityReferences?: readonly EntityReference[],
    generationType?: ContentGenerationType,
    isSeoModeEnabled?: boolean,
    outlineHeaders?: string[],
    referenceLinks?: string[],
    referenceFileIds?: string[],
    primaryKeywordResearchResultId?: string,
    seoRegion?: CountryDetail
  ) => Promise<{ warning_message: string } | undefined>
  apiGenerateComposeContextual: (
    context: ComposeContext
  ) => Promise<AxiosResponse<Description> | undefined>
  apiGenerateHighlightCommand: (
    context: HighlightCommandContext
  ) => Promise<AxiosResponse<Description> | undefined>
  apiGenerateHighlightResearchCommand: (
    context: HighlightResearchCommandContext
  ) => Promise<AxiosResponse<Description> | undefined>
  apiGenerateToneAnalysis: (
    context: ToneAnalyzerContext
  ) => Promise<AxiosResponse<ToneFields> | undefined>
  apiGenerateProductDescription: (
    productId: string,
    catalogueTemplateName?: string,
    isReferencingKnowledgeBase?: boolean
  ) => Promise<AxiosResponse<void> | undefined>
  apiCancelDescribeProduct: (productId: string) => Promise<AxiosResponse<void>>
  apiGenerateBatchContextual: (
    requestConfig: GenerateBatchInRequest
  ) => Promise<AxiosResponse<void> | undefined>

  // Credit-related methods
  showInsufficientCreditsModal: boolean
  setShowInsufficientCreditsModal: (show: boolean) => void

  // Word-related methods

  /**
   * Checks if the user has enough words to generate content
   */
  showInsufficientWordsModal: boolean
  /**
   * Sets whether the user has enough words to generate content
   */
  setShowInsufficientWordsModal: (show: boolean) => void

  /**
   * Opens the RateLimitCTAModal
   *
   * While most content types have this method triggered within this hook,
   * HypoChat does not use any generation methods within it, and relies on
   * this method being passed to it externally
   */
  onRateLimitExceeded: () => void
}

/**
 * Props that are common to the generation API hook and provider
 */
export interface GenerationAPIHookAndProviderProps {}

/**
 * Props that are exclusive to the generation API context provider
 */
export interface GenerationAPIProviderProps
  extends GenerationAPIHookAndProviderProps {
  /**
   * Callback when a rate-limit is exceeded
   */
  onRateLimitExceeded: () => void
  username?: string
  planListId?: string
}

/**
 * Props that are exclusive to the generation API hook
 */
export interface GenerationAPIHookProps
  extends GenerationAPIHookAndProviderProps {}

// ===== Context & Provider =====
/**
 * Generation API context
 */
export const GenerationAPIContext = React.createContext<
  IGenerationAPIContext | undefined
>(undefined)

/**
 * Generation API Context Provider
 */
export const GenerationAPIProvider: React.FC<GenerationAPIProviderProps> = (
  props
) => {
  const { children, onRateLimitExceeded, username, planListId } = props

  const {
    openModal: openMonetizationAwarenessModal
  } = useMonetizationAwarenessModal((state) => ({
    openModal: state.openModal
  }))

  const { openModal: openEnterpriseFeatureModal } = useEnterpriseFeatureModal(
    (state) => ({
      openModal: state.openModal
    })
  )

  // Controls display of CTA modal when user runs out of credits
  // TODO: Move this out so this provider can deal purely with the REST API
  const [
    showInsufficientCreditsModal,
    setShowInsufficientCreditsModal
  ] = useState<boolean>(false)

  const [
    showInsufficientWordsModal,
    setShowInsufficientWordsModal
  ] = useState<boolean>(false)

  /**
   * Handle known errors from generation API endpoints
   */
  const handleKnownGenerationErrors = useCallback(
    async (error: unknown) => {
      // Check if it's an Axios error first
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 429) {
          if (
            // If the rate-limit error is for Instagram image caption, show the modal
            error.response?.data.detail?.service_name ===
            RateLimitedServiceName.instagram_image_caption
          ) {
            openMonetizationAwarenessModal({
              featureName: Nudge.InstagramImageCaption,
              trackingId: 'instagram-image-caption-enterprise-price-plan-modal',
              elementId: 'instagram-image-caption',
              pathname: window.location.pathname
            })
          } else if (
            error.response?.data.detail?.service_name ===
            RateLimitedServiceName.rewrite_product_description
          ) {
            const contactUsProps: ContactUsProps = {
              elementId: 'product-description-rewriter-feature-modal',
              userId: username ?? '',
              planListId: planListId ?? ''
            }
            const modalProps: EnterpriseFeatureModalProps = {
              featureName: EnterpriseNudgeFeature.ProductDescriptionRewriter,
              elementId: 'product-description-rewriter-rate-limit',
              handleCTAClick: () => handleContactUs(contactUsProps)
            }
            openEnterpriseFeatureModal(modalProps)
          } else {
            // If a rate-limit error is exceeded, this callback should notify the user
            await onRateLimitExceeded()
          }
        } else if (
          error.response?.data.detail?.error === ServerErrorType.outOfCredits
        ) {
          // If not enough credits, show the modal
          setShowInsufficientCreditsModal(true)
        } else if (
          error.response?.status === 403 &&
          error.response?.data?.detail?.url
        ) {
          window.location.href = error.response.data.detail.url
          return
        } else if (error.response?.status === 403) {
          // If not enough words, show the modal
          setShowInsufficientWordsModal(true)
        } else {
          // Not a known Axios error; rethrow
          throw error
        }
        // Return response from error so that the caller can handle it
        return error.response
      } else {
        // Not an Axios error; rethrow
        throw error
      }
    },
    [
      onRateLimitExceeded,
      openMonetizationAwarenessModal,
      openEnterpriseFeatureModal,
      username,
      planListId
    ]
  )

  /**
   * Generate an advertisement
   * @param productId
   * @param {AdTextType} adTextType The type of ad to generate (instagram, google, etc)
   */
  const apiGenerateAdvertisingText = useCallback(
    async ({
      productId,
      adTextType,
      splitOutput,
      referenceLinks,
      referenceFileIds,
      numOutputs,
      entityReferences,
      isReferencingKnowledgeBase
    }: {
      productId: string
      adTextType: AdTextType
      splitOutput?: boolean
      referenceLinks?: string[]
      referenceFileIds?: string[]
      numOutputs?: number
      entityReferences?: readonly EntityReference[]
      isReferencingKnowledgeBase?: boolean
    }) => {
      // Compute generationType based on adTextType (this is a shim until we directly accept generationType as a param)
      const generationType: ContentGenerationType =
        generationTypeMap[adTextType]

      let payload = {
        entity_references: entityReferences,
        reference_links: referenceLinks,
        reference_file_ids: referenceFileIds
      }
      const response = await apiGenerateContent({
        data: payload,
        params: {
          productId,
          generation_type: generationType,
          num_outputs: numOutputs,
          split_output: splitOutput,
          is_referencing_knowledge_base: isReferencingKnowledgeBase
        }
      }).catch(handleKnownGenerationErrors)

      return response?.data
    },
    [handleKnownGenerationErrors]
  )

  const apiGenerateBlogAdtext = useCallback(
    async (productId: string) => {
      const response = await apiGenerateBlogAdText({
        params: {
          generation_type: ContentGenerationType.advertising_text_blog,
          productId
        }
      }).catch(handleKnownGenerationErrors)

      return response?.data
    },
    [handleKnownGenerationErrors]
  )

  /**
   * Generate content for (one step of) a blog
   * @param {string} productId
   * @param {BlogStepper} blogStepper Which step of the blog to generate for
   * @param {[number, number][]} subSteps Only for blogStepper.FULL_ARTICLE; payload for which parts of article to generate
   */
  const apiGenerateBlog = useCallback(
    async (
      productId: string,
      blogStepper: BlogStepper,
      is_referencing_knowledge_base?: boolean,
      subSteps?: [number, number][],
      numOutputs?: number,
      entityReferences?: readonly EntityReference[],
      generationType?: ContentGenerationType,
      isSeoModeEnabled?: boolean,
      outlineHeaders?: string[],
      referenceLinks?: string[],
      referenceFileIds?: string[],
      primaryKeywordResearchResultId?: string,
      seoRegion?: CountryDetail
    ) => {
      let finalGenerationType: ContentGenerationType
      if (!generationType) {
        // Compute generationType based on blogStepper (TODO: pass generationType directly as param, rather than computing it here)
        const generationTypeMap: {
          [key in BlogStepper]: ContentGenerationType
        } = {
          [BlogStepper.TITLE]: ContentGenerationType.blog_title,
          [BlogStepper.OUTLINE]: ContentGenerationType.blog_outline,
          [BlogStepper.INTRO]: ContentGenerationType.blog_introduction,
          // Note this duplicate is intentional
          [BlogStepper.FULL_ARTICLE]: ContentGenerationType.blog_paragraph,
          [BlogStepper.PARAGRAPH]: ContentGenerationType.blog_paragraph,
          [BlogStepper.CONCLUSION]: ContentGenerationType.blog_conclusion
        }
        finalGenerationType = generationTypeMap[blogStepper]
      } else {
        finalGenerationType = generationType
      }

      const stepperTuples: [number, number][] = [[blogStepper, -1]]
      let payload = {
        stepper_tuples: stepperTuples, // [[step, subStep], ...]
        entity_references: entityReferences,
        outline_headers: outlineHeaders,
        reference_file_ids: referenceFileIds,
        reference_links: referenceLinks,
        primary_keyword_research_result_id: primaryKeywordResearchResultId,
        seo_region: seoRegion
      }

      // For full article, specify whether to generate intro, any number of paras, and conclusion
      if (
        blogStepper === BlogStepper.FULL_ARTICLE ||
        blogStepper === BlogStepper.PARAGRAPH
      ) {
        if (subSteps === undefined) {
          throw new Error(
            'subSteps must be specified when generating paragraphs'
          )
        }
        payload.stepper_tuples = subSteps
      }

      const response = await apiGenerateContent({
        data: payload,
        params: {
          productId,
          generation_type: finalGenerationType,
          num_outputs: numOutputs,
          is_seo_mode_enabled: isSeoModeEnabled,
          is_referencing_knowledge_base
        }
      }).catch(handleKnownGenerationErrors)

      return response?.data
    },
    [handleKnownGenerationErrors]
  )

  /**
   * Request the generation API to generate descriptions for a product
   * @param {string} productId
   */
  const apiGenerateProductDescription = useCallback(
    async (
      productId: string,
      catalogueTemplateName?: string,
      isRewriterGeneration?: boolean
    ) => {
      return await apiGenerateContent({
        data: { catalogue_template_name: catalogueTemplateName },
        params: {
          productId,
          generation_type: ContentGenerationType.product_descriptions,
          is_rewriter_generation: isRewriterGeneration
        }
      }).catch(handleKnownGenerationErrors)
    },
    [handleKnownGenerationErrors]
  )

  /**
   * Cancel content generation
   * @param {string} productId
   */
  const apiCancelDescribeProduct = useCallback(async (productId: string) => {
    const config = {
      params: {
        productId
      }
    }
    const data = {}
    return apiClient.post<void>(
      '/describe/cancel/',
      data, // Can be just 'null'
      config
    )
  }, [])

  const apiGenerateBatchContextual = useCallback(
    async (requestConfig: GenerateBatchInRequest) => {
      return await apiGenerateBatch(requestConfig).catch(
        handleKnownGenerationErrors
      )
    },
    [handleKnownGenerationErrors]
  )

  /** Request the contextual compose API to generate content and return it
   * immediately as a Description object.
   *
   * @param context Context required for generation e.g. prefix, -> ..., suffix -> ...
   * @returns       A Description object with generated content
   */
  const apiGenerateComposeContextual = useCallback(
    async (context: ComposeContext) => {
      const response = await apiCompose({
        data: context
      }).catch(handleKnownGenerationErrors)

      return response
    },
    [handleKnownGenerationErrors]
  )

  /** Request the highlight command API to generate content and return it
   * immediately as a Description object.
   *
   * @param context Context required for generation e.g. prefix, selection, suffix.
   * @returns       A Description object with generated content
   */
  const apiGenerateHighlightCommand = useCallback(
    async (context: HighlightCommandContext) => {
      const response = await apiHighlightCommand({
        data: context
      }).catch(handleKnownGenerationErrors)

      return response
    },
    [handleKnownGenerationErrors]
  )

  /** Request the highlight research command API to generate content and return it
   * immediately as a Description object.
   *
   * @param context Context required for generation e.g. prefix, selection, suffix.
   * @returns       A Description object with generated content
   */
  const apiGenerateHighlightResearchCommand = useCallback(
    async (context: HighlightResearchCommandContext) => {
      const response = await apiHighlightResearchCommand({
        data: context
      }).catch(handleKnownGenerationErrors)

      return response
    },
    [handleKnownGenerationErrors]
  )

  const apiGenerateToneAnalysis = useCallback(
    async (context: ToneAnalyzerContext) => {
      const response = await apiAnalyzeTone({
        data: context
      }).catch(handleKnownGenerationErrors)

      return response
    },
    [handleKnownGenerationErrors]
  )

  const providerValue = useMemo(
    () => ({
      apiGenerateComposeContextual,
      apiGenerateHighlightCommand,
      apiGenerateHighlightResearchCommand,
      apiGenerateToneAnalysis,
      apiGenerateAdvertisingText,
      apiGenerateBlogAdtext,
      apiGenerateBlog,
      apiGenerateProductDescription,
      apiCancelDescribeProduct,
      apiGenerateBatchContextual,
      showInsufficientCreditsModal,
      setShowInsufficientCreditsModal,
      showInsufficientWordsModal,
      setShowInsufficientWordsModal,
      onRateLimitExceeded
    }),
    [
      apiGenerateComposeContextual,
      apiGenerateHighlightCommand,
      apiGenerateHighlightResearchCommand,
      apiGenerateToneAnalysis,
      apiGenerateAdvertisingText,
      apiGenerateBlogAdtext,
      apiGenerateBlog,
      apiGenerateProductDescription,
      apiCancelDescribeProduct,
      apiGenerateBatchContextual,
      showInsufficientCreditsModal,
      showInsufficientWordsModal,
      onRateLimitExceeded
    ]
  )

  return (
    <GenerationAPIContext.Provider value={providerValue}>
      {children}
    </GenerationAPIContext.Provider>
  )
}

// ===== Hook & HOC =====
/**
 * Hook for requesting various content types from the Generation API.
 */
export const useGenerationAPI = (_props?: GenerationAPIHookProps) => {
  // NOTE: props currently unused, but can be used to override the provider's defaults in the future
  const context = useContext(GenerationAPIContext)
  if (context === undefined) {
    throw new Error(
      'useGenerationAPI must be used within a GenerationAPIProvider'
    )
  }
  return context
}

export const withGenerationAPI = (props?: GenerationAPIHookProps) => {
  return <Props extends IGenerationAPIContext>(
    Component: React.ComponentType<Props>
  ) => {
    const Wrapper: React.FC<Subtract<Props, IGenerationAPIContext>> = (
      componentProps
    ) => {
      const generationAPIHookResult = useGenerationAPI(props)
      return (
        <Component
          {...generationAPIHookResult}
          {...(componentProps as Props)}
        />
      )
    }
    return Wrapper
  }
}
