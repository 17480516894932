import React, { useCallback, useEffect, useMemo } from 'react'

import { Edit3 } from '@styled-icons/feather/Edit3'
import { FileText } from '@styled-icons/feather/FileText'
import { Globe } from '@styled-icons/feather/Globe'
import { Grid } from '@styled-icons/feather/Grid'
import { Instagram } from '@styled-icons/feather/Instagram'
import { Layers } from '@styled-icons/feather/Layers'
import { Zap } from '@styled-icons/feather/Zap'
import { MegaphoneLoud } from '@styled-icons/fluentui-system-regular/MegaphoneLoud'
import {
  FormProvider,
  UseFormMethods,
  useForm,
  useWatch
} from 'react-hook-form'
import useSWR from 'swr'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'

import { apiFetchOrganizationInfo } from '@hypotenuse/common/src/api/Organization'
import {
  UserRole,
  UserRoleLabel,
  UserRoleSubTitle
} from '@hypotenuse/common/src/utils/Interfaces'

import {
  AppFeatures,
  AppFeaturesItem,
  OnboardingFormInputs,
  ProductTypeLabel,
  User,
  UserOnboardingInfoForm,
  UserRoleItem
} from '../../components/utils/Interfaces'

import theme from '../Theme'

import explorationIcon from './assets/exploration-others.svg'
import keysSmallTeamIcon from './assets/keys-small-team.svg'
import magnetAgencyIcon from './assets/magnet-agency.svg'
import pattyPaintBrushIcon from './assets/patty-paintbrush.svg'
import publicSpeakingIcon from './assets/public-speaking-education.svg'

const AppFeaturesTitle: Record<AppFeatures, React.ReactNode> = {
  [AppFeatures.product_feature]: 'Product descriptions',
  [AppFeatures.blog_feature]: 'Blog articles',
  [AppFeatures.other]: 'Not sure yet',
  [AppFeatures.website_content]: 'Website content',
  [AppFeatures.image_gen_feature]: 'HypoArt',
  [AppFeatures.insta_caption]: 'Instagram Captions',
  [AppFeatures.headline]: 'Headlines',
  [AppFeatures.rewrite]: 'Rewriter',
  [AppFeatures.summarise]: 'Summarize content',
  [AppFeatures.ask_anything]: 'Ask AI anything',
  [AppFeatures.hypo_chat]: 'HypoChat',
  [AppFeatures.social_posts]: 'Social media posts',
  [AppFeatures.ads_feature]: 'Ads',
  [AppFeatures.hypodoc]: (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      {'Extract insights'}
      <br />
      {'from PDFs'}
    </Stack>
  )
}

export interface OnboardingProps {
  defaultValues: OnboardingFormInputs
  watchUser: string | undefined
  watchUserDisplayName: string | undefined
  watchUserOrganizationDisplayName: string | undefined
  watchProductTypeFields: { [p: string]: boolean | undefined } | undefined
  watchUserRole: string | undefined
  watchUserWebsite: string | undefined
  appMainFeatureItems: AppFeaturesItem[]
  userRoleItems: UserRoleItem[]
  methods: UseFormMethods<OnboardingFormInputs>
  onOnboardingStepSubmit: (
    info: UserOnboardingInfoForm,
    isOnboardingFormSubmission: boolean
  ) => Promise<void>
  onboarding_info?: UserOnboardingInfoForm
}

const OnboardingContext = React.createContext<OnboardingProps | undefined>(
  undefined
)

interface ProviderProps {
  user: User
  onOnboardingStepSubmit: (
    info: UserOnboardingInfoForm,
    isOnboardingFormSubmission: boolean
  ) => Promise<void>
  onboarding_info?: UserOnboardingInfoForm
}

const getOnboardFormDefault = (
  onboarding_info?: UserOnboardingInfoForm,
  default_organization_display_name?: string
): OnboardingFormInputs => {
  return {
    ...onboarding_info?.selected_features,
    userDisplayName: onboarding_info?.user_display_name ?? '',
    userOrganizationDisplayName:
      onboarding_info?.user_organization_display_name ??
      default_organization_display_name ??
      '',
    userRole: onboarding_info?.user_role ?? [],
    userWork: onboarding_info?.user_work ?? '',
    userCompanySize: onboarding_info?.user_company_size ?? '',
    userWebsite: onboarding_info?.user_website,
    productTypeState: Object.keys(ProductTypeLabel).reduce(
      (obj: Record<string, boolean>, key) => {
        obj[key] = onboarding_info?.product_type
          ? onboarding_info?.product_type?.includes(key)
          : false
        return obj
      },
      {}
    ),
    userOthers: onboarding_info?.user_others ?? '',
    productTypeOthers: onboarding_info?.product_type_others ?? '',
    aboutUs: onboarding_info?.about_us ?? '',
    userWorkOthers: onboarding_info?.user_work_others ?? ''
  }
}
/**
 * The Onboarding Context acts as the Model for the onboarding forms.
 * Model - data related to the different onboarding forms should go here
 */
export const OnboardingContextProvider: React.FC<ProviderProps> = (props) => {
  const { children, onboarding_info, onOnboardingStepSubmit, user } = props

  const { organizationName } = user
  const {
    data: organizationDetails
  } = useSWR(`/organization/${organizationName}`, () =>
    apiFetchOrganizationInfo(organizationName)
  )

  const defaultValues = useMemo(
    () =>
      getOnboardFormDefault(onboarding_info, organizationDetails?.displayName),
    [onboarding_info, organizationDetails?.displayName]
  )
  const methods = useForm<OnboardingFormInputs>({ defaultValues })

  const { control, reset } = methods
  // Need to reset form defaultValues so that when we go back, the form initial state is updated
  useEffect(() => {
    reset({ ...defaultValues })
  }, [defaultValues, reset])

  // monitor form input changes.
  // NOTE: These fields are referenced by name downstream in `Controller` components
  const watchUserDisplayName: string | undefined = useWatch({
    control,
    name: 'userDisplayName'
  })
  const watchUserOrganizationDisplayName: string | undefined = useWatch({
    control,
    name: 'userOrganizationDisplayName'
  })
  const watchUser: string | undefined = useWatch({
    control,
    name: 'userWork'
  })
  const watchUserRole: string | undefined = useWatch({
    control,
    name: 'userRole'
  })
  const watchUserWebsite: string | undefined = useWatch({
    control,
    name: 'userWebsite'
  })
  const watchProductTypeFields:
    | { [p: string]: boolean | undefined }
    | undefined = useWatch({
    control,
    name: `productTypeState`
  })
  const watchHypoDocFeature = useWatch({
    control,
    name: AppFeatures.hypodoc
  })
  const watchBlogFeature = useWatch({
    control,
    name: AppFeatures.blog_feature
  })
  const watchAdsFeature = useWatch({
    control,
    name: AppFeatures.ads_feature
  })
  const watchWebsiteContent = useWatch({
    control,
    name: AppFeatures.website_content
  })
  const watchProductFeature = useWatch({
    control,
    name: AppFeatures.product_feature
  })
  const watchSummarise = useWatch({
    control,
    name: AppFeatures.summarise
  })
  const watchSocialPosts = useWatch({
    control,
    name: AppFeatures.social_posts
  })
  const watchOther = useWatch({
    control,
    name: AppFeatures.other
  })
  const APP_FEATURE_ICON_SIZE = 35
  const APP_FEATURE_STROKE_WIDTH = 2
  const getAppFeatureStrokeColor = useCallback(
    (isSelected: boolean, isDisabled: boolean) => {
      return isDisabled
        ? palette.gray[300]
        : isSelected
        ? theme.palette.background.paper
        : palette.primary[600]
    },
    []
  )
  const appMainFeatureItems: AppFeaturesItem[] = [
    {
      title: AppFeaturesTitle[AppFeatures.blog_feature],
      image: ({ isSelected, isDisabled }) => (
        <FileText
          size={APP_FEATURE_ICON_SIZE}
          stroke={getAppFeatureStrokeColor(isSelected, isDisabled)}
          strokeWidth={APP_FEATURE_STROKE_WIDTH}
        />
      ),
      formField: AppFeatures.blog_feature,
      formFieldValue: !!watchBlogFeature
    },
    {
      title: AppFeaturesTitle[AppFeatures.hypodoc],
      image: ({ isSelected, isDisabled }) => (
        <Zap
          size={APP_FEATURE_ICON_SIZE}
          stroke={getAppFeatureStrokeColor(isSelected, isDisabled)}
          strokeWidth={APP_FEATURE_STROKE_WIDTH}
        />
      ),
      formField: AppFeatures.hypodoc,
      formFieldValue: !!watchHypoDocFeature
    },
    {
      title: AppFeaturesTitle[AppFeatures.ads_feature],
      image: ({ isSelected, isDisabled }) => (
        <MegaphoneLoud
          size={APP_FEATURE_ICON_SIZE}
          stroke={getAppFeatureStrokeColor(isSelected, isDisabled)}
          fill={getAppFeatureStrokeColor(isSelected, isDisabled)}
        />
      ),
      formField: AppFeatures.ads_feature,
      formFieldValue: !!watchAdsFeature
    },
    {
      title: AppFeaturesTitle[AppFeatures.website_content],
      image: ({ isSelected, isDisabled }) => (
        <Globe
          size={APP_FEATURE_ICON_SIZE}
          stroke={getAppFeatureStrokeColor(isSelected, isDisabled)}
          strokeWidth={APP_FEATURE_STROKE_WIDTH}
        />
      ),
      formField: AppFeatures.website_content,
      formFieldValue: !!watchWebsiteContent
    },
    {
      title: AppFeaturesTitle[AppFeatures.product_feature],
      image: ({ isSelected, isDisabled }) => (
        <Layers
          size={APP_FEATURE_ICON_SIZE}
          stroke={getAppFeatureStrokeColor(isSelected, isDisabled)}
          strokeWidth={APP_FEATURE_STROKE_WIDTH}
        />
      ),
      formField: AppFeatures.product_feature,
      formFieldValue: !!watchProductFeature
    },
    {
      title: AppFeaturesTitle[AppFeatures.summarise],
      image: ({ isSelected, isDisabled }) => (
        <Edit3
          size={APP_FEATURE_ICON_SIZE}
          stroke={getAppFeatureStrokeColor(isSelected, isDisabled)}
          strokeWidth={APP_FEATURE_STROKE_WIDTH}
        />
      ),
      formField: AppFeatures.summarise,
      formFieldValue: !!watchSummarise
    },
    {
      title: AppFeaturesTitle[AppFeatures.social_posts],
      image: ({ isSelected, isDisabled }) => (
        <Instagram
          size={APP_FEATURE_ICON_SIZE}
          stroke={getAppFeatureStrokeColor(isSelected, isDisabled)}
          strokeWidth={APP_FEATURE_STROKE_WIDTH}
        />
      ),
      formField: AppFeatures.social_posts,
      formFieldValue: !!watchSocialPosts
    },
    {
      title: AppFeaturesTitle[AppFeatures.other],
      image: ({ isSelected, isDisabled }) => (
        <Grid
          size={APP_FEATURE_ICON_SIZE}
          stroke={getAppFeatureStrokeColor(isSelected, isDisabled)}
          strokeWidth={APP_FEATURE_STROKE_WIDTH}
        />
      ),
      formField: AppFeatures.other,
      formFieldValue: !!watchOther
    }
  ]

  const userRoleItems: UserRoleItem[] = [
    {
      title: UserRoleLabel[UserRole.e_commerce_brand],
      subTitle: UserRoleSubTitle[UserRole.e_commerce_brand],
      image: pattyPaintBrushIcon,
      formField: UserRole.e_commerce_brand
    },
    {
      title: UserRoleLabel[UserRole.seo_team],
      subTitle: UserRoleSubTitle[UserRole.seo_team],
      image: keysSmallTeamIcon,
      formField: UserRole.seo_team
    },
    {
      title: UserRoleLabel[UserRole.education],
      subTitle: UserRoleSubTitle[UserRole.education],
      image: publicSpeakingIcon,
      formField: UserRole.education
    },
    {
      title: UserRoleLabel[UserRole.software_company],
      subTitle: UserRoleSubTitle[UserRole.software_company],
      image: magnetAgencyIcon,
      formField: UserRole.software_company
    },
    {
      title: UserRoleLabel[UserRole.personal],
      subTitle: UserRoleSubTitle[UserRole.personal],
      image: pattyPaintBrushIcon,
      formField: UserRole.personal
    },
    {
      title: UserRoleLabel[UserRole.others],
      subTitle: UserRoleSubTitle[UserRole.others],
      image: explorationIcon,
      formField: UserRole.others
    }
  ]

  return (
    <OnboardingContext.Provider
      value={{
        defaultValues,
        watchUser,
        watchUserDisplayName,
        watchUserOrganizationDisplayName,
        watchProductTypeFields,
        watchUserRole,
        watchUserWebsite,
        appMainFeatureItems,
        onboarding_info,
        userRoleItems,
        onOnboardingStepSubmit,
        methods: { ...methods }
      }}
    >
      <FormProvider {...methods}>{children}</FormProvider>
    </OnboardingContext.Provider>
  )
}

export const useOnboardingContext = () => {
  const context = React.useContext(OnboardingContext)
  if (context === undefined) {
    throw new Error(
      'useOnboardingContext must be used within a OnboardingContextProvider'
    )
  }
  return context
}
