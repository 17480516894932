import React, { useCallback, useMemo, useState } from 'react'

import * as Sentry from '@sentry/react'
import moment from 'moment'
import { useIntercom } from 'react-use-intercom'
import useSWR from 'swr'
import { StringParam, useQueryParam } from 'use-query-params'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  SvgIcon,
  Theme,
  useMediaQuery
} from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  useTheme,
  withStyles
} from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Alert, AlertTitle } from '@material-ui/lab'

import { useAnalytics } from '@hypotenuse/common/src/analytics/Analytics'
import {
  handleChurnModalShown,
  handleElementShown,
  handleUserClick
} from '@hypotenuse/common/src/api/Analytics'
import { apiGetUpcomingInvoice } from '@hypotenuse/common/src/api/Organization'
import plan_cancellation_icon from '@hypotenuse/common/src/assets/plan-cancellation-icon.svg'
import { userLocale } from '@hypotenuse/common/src/components/assetsDisplay/utils'
import SimpleRedirectDialog from '@hypotenuse/common/src/components/molecules/SimpleRedirectDialog'
import { useAsyncFn } from '@hypotenuse/common/src/hooks'
import { PROMOTION_COUNTRY_CODES } from '@hypotenuse/common/src/utils/Constants'
import {
  OrganizationMemberRole,
  PlanCategoryTab,
  PremiumPlanTierList
} from '@hypotenuse/common/src/utils/Interfaces'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'
import { useUserContext } from '@hypotenuse/common/src/utils/context/UserContext'
import { ReactComponent as FreePlanIcon } from '@hypotenuse/platform/src/assets/icons/pricing/FreePlanIcon.svg'
import { ReactComponent as IndividualPlanIcon } from '@hypotenuse/platform/src/assets/icons/pricing/Individual-plan-icon.svg'
import { ReactComponent as TeamPlanIcon } from '@hypotenuse/platform/src/assets/icons/pricing/Team-plan-icon.svg'
import { ReactComponent as BasicPlanIcon } from '@hypotenuse/platform/src/assets/icons/pricing/basic-plan-icon.svg'
import { ReactComponent as EnterprisePlanIcon } from '@hypotenuse/platform/src/assets/icons/pricing/enterprise-plan-icon.svg'

import usePremiumPlanTier from '../../../../hooks/usePremiumPlanTier'
import useUserCountryCode from '../../../../hooks/useUserCountryCode'

import {
  fetchUserBillingInfo,
  handleCreateStripeCustomerPortal
} from '../../../../api/Billing'
import {
  apiFetchPlanTierListObject,
  apiPausePlanTier,
  fetchOrganizationPlanTierList,
  fetchPremiumPlanTier,
  fetchPricingFAQs
} from '../../../../api/PlanTier'
import { apiGetUserCountryCode } from '../../../../api/User'

import {
  DEFAULT_PAYMENT_FAILURE_MESSAGE,
  ShopifyPlanListId
} from '../../../../utils/Constants'
import {
  isWordsEnabled,
  paidAndActivePlansPredicate
} from '../../../../utils/Functions'
import { useTrialUserContext } from '../../../../utils/context/TrialUserContext'
import {
  CancellationReason,
  PlanBillingPeriod,
  PlanChangeType,
  PlanType,
  PremiumPlanTier,
  StripeBillingInfo,
  StripeSubscriptionStatus,
  User
} from '../../../utils/Interfaces'

import PayInvoiceModal from '../../../main/payment/PayInvoiceModal'
import PaymentFailureBanner from '../../../main/payment/PaymentFailureBanner'
import TrialBanner from '../../../main/trial/TrialBanner'

import MultiPricingPlanCard from './MultiPricingPlanCard'
import { SocialProof } from './SocialProof'
import ChangePlanDialog from './components/ChangePlanDialog'
import DiscountPlanDialog from './components/DiscountPlanDialog'
import PausePlanDialog from './components/PausePlanDialog'
import { PlanCategoryTabSelector } from './components/PlanCategoryTabSelector'
import PlanDurationTabs from './components/PlanDurationTabs'
import useUpdateUserPlan from './components/useUpdateUserPlan'

export const PLAN_TYPE_TO_PRICING_CARD_ICON_MAP: {
  [key in PlanType]: React.FC<React.SVGProps<SVGSVGElement>>
} = {
  [PlanType.free]: FreePlanIcon,
  [PlanType.basic]: BasicPlanIcon,
  [PlanType.starter]: IndividualPlanIcon,
  [PlanType.premium]: TeamPlanIcon,
  [PlanType.unlimited]: EnterprisePlanIcon,
  [PlanType.enterprise]: EnterprisePlanIcon
} as const

const ZeroMarginAccordion = withStyles({
  root: {
    margin: '0 !important',
    width: '100%'
  }
})(Accordion)

interface PricingPageProps {
  user: User
  refreshUser: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(5)
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: '100%'
    },
    textMargins: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    socialProofContainer: {
      marginTop: '0px',
      marginBottom: '100px',
      width: '100%'
    },
    tabButton: {
      fontSize: '1rem',
      minWidth: 0,
      padding: '0 1rem',
      zIndex: 1
    },
    hoverUnderlineText: {
      '&:hover': {
        textDecoration: 'underline',
        textDecorationThickness: '1px'
      }
    }
  })
)

export default function PricingPage(props: PricingPageProps) {
  /**
   * Styling
   */
  const classes = useStyles()
  const theme = useTheme()

  /**
   * Hooks for metadata
   */
  const {
    refreshUser,
    user: { username, organizationName }
  } = props
  const { user } = props
  const isOwner = user.role === OrganizationMemberRole.owner
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openPauseDialog, setOpenPauseDialog] = useState<boolean>(false)
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState<boolean>(
    false
  )
  const [selectedPlanTierId, setSelectedPlanTierId] = useState<string>('')
  const [hasPaymentFailed, setHasPaymentFailed] = useState<boolean>(false)
  const [paymentFailureMessage, setPaymentFailureMessage] = useState<string>(
    DEFAULT_PAYMENT_FAILURE_MESSAGE
  )
  const [selectedReason, setSelectedReason] = useState<
    CancellationReason | undefined
  >(undefined)
  const [
    cancellationDescription,
    setCancellationDescription
  ] = useState<string>('')
  const [pauseDuration, setPauseDuration] = useState<number>(1)
  const { refreshTrialStatus } = useTrialUserContext()
  const { setUserPlanType } = useAnalytics()
  const [billingPeriod] = useQueryParam('billingPeriod', StringParam)
  const initialBillingPeriod =
    billingPeriod &&
    (billingPeriod === PlanBillingPeriod.monthly ||
      billingPeriod === PlanBillingPeriod.yearly)
      ? (billingPeriod as PlanBillingPeriod)
      : undefined

  const isWordsFeatureEnabled = isWordsEnabled(user)
  const {
    data: planTierListObject,
    isLoading: isFetchingPlanTierListObject
  } = useSWR('planTierListObject', apiFetchPlanTierListObject)

  const [
    currentPlanCategoryTab,
    setCurrentPlanCategoryTab
  ] = useState<PlanCategoryTab>(PlanCategoryTab.seo)
  // Get Plan Tiers to populate the plans.
  const { data: orgPlanTierList } = useSWR(
    [organizationName, 'activePlanTierList'],
    ([orgName]) => fetchOrganizationPlanTierList(orgName, false)
  )

  const { userCountryCode } = useUserCountryCode()

  // Get the current active plan tier for the organization
  const { data: activePlanTier, mutate } = usePremiumPlanTier()
  const isPromotionCountryCode = PROMOTION_COUNTRY_CODES.includes(
    userCountryCode
  )

  const isUserOnShopifyPlanTierList =
    planTierListObject?.plan_list_id === ShopifyPlanListId

  // Handle tab change
  const [_currentTab, setCurrentTab] = useState<PlanBillingPeriod | undefined>(
    undefined
  )
  const currentTab = useMemo(
    () =>
      isUserOnShopifyPlanTierList
        ? PlanBillingPeriod.monthly // for Shopify plan tier list, we only show monthly plans
        : _currentTab ??
          initialBillingPeriod ??
          // We want to direct free user to annual tab to encourage them to upgrade to annual
          (activePlanTier?.plan_type === PlanType.free
            ? PlanBillingPeriod.yearly
            : activePlanTier?.plan_billing_period) ??
          PlanBillingPeriod.monthly,
    [
      _currentTab,
      activePlanTier?.plan_billing_period,
      activePlanTier?.plan_type,
      initialBillingPeriod,
      isUserOnShopifyPlanTierList
    ]
  )

  const handleSetCurrentTab = (tab: PlanBillingPeriod) => {
    setCurrentTab(tab)
  }

  const [isShowingPayInvoiceModal, setIsShowingPayInvoiceModal] = useState(
    false
  )

  const isEnterpriseClient =
    activePlanTier && activePlanTier.plan_type === PlanType.enterprise

  // Get the user's billing info to check if has active stripe subscription
  const {
    data: stripeBillingInfo,
    mutate: mutateStripeBillingInfo,
    isLoading: isFetchingStripeBillingInfo
  } = useSWR('stripeBillingInfo', fetchUserBillingInfo)

  const nextBillingDate =
    stripeBillingInfo?.subscription_info?.next_billing_date
  const nextBillingDateString = nextBillingDate?.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })

  const _handleSelectPlan = async (plan_id: string) => {
    setSelectedPlanTierId(plan_id)
    if (
      stripeBillingInfo?.subscription_info.status ===
        StripeSubscriptionStatus.unpaid ||
      stripeBillingInfo?.subscription_info.status ===
        StripeSubscriptionStatus.past_due
    ) {
      setIsShowingPayInvoiceModal(true)
    } else if (
      activePlanTier?.plan_type === PlanType.free &&
      !activePlanTier?.is_paused
    ) {
      // If current user is on free plan, skip dialog and direct straight
      // to stripe checkout
      await updateUserPlan(
        plan_id,
        planTierListObject?.plan_list_id || '',
        PlanChangeType.new_subscription
      )
    } else {
      // Else, show the subscription confirmation dialog box
      setOpenDialog(true)
    }
  }

  const [
    { loading: loadingSelect },
    handleSelectPlan
  ] = useAsyncFn(_handleSelectPlan, [_handleSelectPlan])

  const showCancelPlanConfirmation = useCallback(async () => {
    // Cancel plan means switching it back to the free plan
    //TODO: Abstract this search to a method to return default plan
    const freePlanTier = orgPlanTierList?.find(
      (planTier) => planTier.plan_type === PlanType.free
    )
    //TODO: Make a default plan function so we don't risk crashing if we remove a free plan ever
    if (!freePlanTier) return
    setSelectedPlanTierId(freePlanTier.plan_id)
    setOpenDialog(true)
  }, [orgPlanTierList])

  const handleOpenDiscountDialog = useCallback(async () => {
    setIsDiscountDialogOpen(true)
    void handleElementShown('churn-reduction-discount-modal')
    await handleChurnModalShown()
  }, [])
  const handleCloseDiscountDialog = useCallback(
    () => setIsDiscountDialogOpen(false),
    []
  )

  const openPaymentFailureModal = (message: string) => {
    setHasPaymentFailed(true)
    setPaymentFailureMessage(message)
  }

  const { updateUserPlan } = useUpdateUserPlan({
    username,
    organizationName,
    selectedReason,
    cancellationDescription,
    activePlanTier,
    billingInfo: stripeBillingInfo,
    openPaymentFailureModal
  })

  /**
   * Updates the user's plan based on their selected plan. If the user's organization
   * has a stripe_customer_id, it will silently update user's subscription.
   * Else, it will redirect to Stripe checkout
   */
  const _handleUpdateUserPlan = async (
    selectedPlanTierId: string,
    planChangeType: PlanChangeType
  ) => {
    if (!selectedPlanTierId) return
    try {
      await updateUserPlan(
        selectedPlanTierId,
        planTierListObject?.plan_list_id || '',
        planChangeType
      )
      const { plan_type, plan_billing_period } = await fetchPremiumPlanTier()
      setUserPlanType(plan_type)
      setCurrentTab(plan_billing_period ?? PlanBillingPeriod.monthly) // set the tab to the new plan billing period
      setOpenDialog(false) // close explicitly to avoid modal to be opened while refreshing
      setOpenPauseDialog(false)
      await mutate()
      await mutateStripeBillingInfo()
      refreshTrialStatus()
    } catch (error) {
      snackbar.show(
        'There was an error selecting this plan. Please try again or contact our customer support.',
        { variant: 'error', autoHideDuration: 5000 }
      )
      Sentry.captureException(error)
    } finally {
      refreshUser()
      setOpenDialog(false)
      setOpenPauseDialog(false)
    }
  }

  const [
    { loading: loadingUpdate },
    handleUpdateUserPlan
  ] = useAsyncFn(_handleUpdateUserPlan, [_handleUpdateUserPlan])

  const handleCancelPlan = useCallback(async () => {
    return await updateUserPlan(
      selectedPlanTierId,
      planTierListObject?.plan_list_id || '',
      PlanChangeType.cancellation
    )
  }, [planTierListObject?.plan_list_id, selectedPlanTierId, updateUserPlan])

  const _pauseUserPlan = async () => {
    try {
      await apiPausePlanTier(pauseDuration)
      snackbar.show('Your plan has been paused', {
        variant: 'warning',
        autoHideDuration: 5000
      })
    } catch (error) {
      snackbar.show(
        'There was an error pausing this plan. Please try again or contact our customer support.',
        { variant: 'error', autoHideDuration: 5000 }
      )
      Sentry.captureException(error)
    } finally {
      setOpenPauseDialog(false)
      await mutate()
    }
  }
  const [{ loading: loadingPause }, pauseUserPlan] = useAsyncFn(
    _pauseUserPlan,
    [_pauseUserPlan]
  )

  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))

  // Shown to non-owners who don't have permission to manage the team's plan
  const noPermissionPageContent = useMemo(
    () => (
      <div style={{ textAlign: 'initial' }}>
        <Alert severity="info">
          <AlertTitle>Contact your team owner to manage your plan</AlertTitle>
          You do not have permission to change this team's plan.
        </Alert>
      </div>
    ),
    []
  )

  return (
    <>
      {isOwner ? (
        <>
          <PayInvoiceModal
            isOpen={isShowingPayInvoiceModal}
            setIsOpen={setIsShowingPayInvoiceModal}
          />
          <SimpleRedirectDialog
            open={hasPaymentFailed}
            title={'Payment Failed'}
            icon_src={plan_cancellation_icon}
            message={paymentFailureMessage}
            rightButtonText={'Update Billing Info'}
            onClose={() => setHasPaymentFailed(false)}
            onRedirect={async () =>
              handleCreateStripeCustomerPortal()
                .then((portalUrl: string) => {
                  window.location.href = portalUrl
                })
                .catch((error) => {
                  snackbar.show(
                    "Sorry there's an error, please contact our support team via chat or email for assistance.",
                    { variant: 'error' }
                  )
                  Sentry.captureException(error)
                })
            }
          />
          {openDialog && orgPlanTierList && activePlanTier && (
            <ChangePlanDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              loadingUpdate={loadingUpdate}
              selectedPlanTierId={selectedPlanTierId}
              isWordsFeatureEnabled={isWordsFeatureEnabled}
              orgPlanTierList={orgPlanTierList}
              activePlanTier={activePlanTier}
              userCountryCode={userCountryCode}
              setOpenPauseDialog={setOpenPauseDialog}
              onOpenDiscountDialog={handleOpenDiscountDialog}
              updateUserPlan={handleUpdateUserPlan}
              isPromotionCountryCode={isPromotionCountryCode}
              theme={theme}
              selectedReason={selectedReason}
              setSelectedReason={setSelectedReason}
              setCancellationDescription={setCancellationDescription}
              cancellationDescription={cancellationDescription}
              classes={classes}
              nextBillingDate={nextBillingDate}
            />
          )}
          <PausePlanDialog
            openPauseDialog={openPauseDialog}
            setOpenPauseDialog={setOpenPauseDialog}
            orgPlanTierList={orgPlanTierList}
            activePlanTier={activePlanTier}
            selectedPlanTierId={selectedPlanTierId}
            loadingPause={loadingPause}
            pauseDuration={pauseDuration}
            setPauseDuration={setPauseDuration}
            updateUserPlan={handleUpdateUserPlan}
            theme={theme}
            classes={classes}
            pauseUserPlan={pauseUserPlan}
            nextBillingDateString={nextBillingDateString}
            isWordsFeatureEnabled={isWordsFeatureEnabled}
            loadingUpdate={loadingUpdate}
          />
          <DiscountPlanDialog
            open={isDiscountDialogOpen}
            onClose={handleCloseDiscountDialog}
            onCancelPlan={handleCancelPlan}
          />
          {orgPlanTierList && activePlanTier && !isEnterpriseClient && (
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={0}
            >
              <div style={{ padding: theme.spacing(4.5, 9) }}>
                <h5 style={{ textAlign: 'left' }}>Plans and pricing</h5>
                <PaymentFailureBanner />
                <TrialBanner hideCtaButton />
              </div>
              <div>
                {planTierListObject?.has_plan_category_tabs && (
                  <PlanCategoryTabSelector
                    currentPlanCategoryTab={currentPlanCategoryTab!}
                    setCurrentPlanCategoryTab={setCurrentPlanCategoryTab}
                  />
                )}

                {planTierListObject?.has_plan_category_tabs ? (
                  <PricingPlanCardGroup
                    planTierList={orgPlanTierList.filter(
                      (planTier) => planTier.plan_type !== PlanType.free
                    )}
                    planTierListObject={planTierListObject}
                    userCountryCode={userCountryCode}
                    activePlanTier={activePlanTier}
                    currentTab={currentTab}
                    onSelectPlanTab={handleSetCurrentTab}
                    onSelectPlan={handleSelectPlan}
                    onCancelActivePlan={showCancelPlanConfirmation}
                    loading={
                      loadingUpdate ||
                      loadingSelect ||
                      isFetchingStripeBillingInfo ||
                      isFetchingPlanTierListObject
                    }
                    currentPlanCategoryTab={currentPlanCategoryTab}
                    isFetchingStripeBillingInfo={isFetchingStripeBillingInfo}
                    stripeBillingInfo={stripeBillingInfo}
                  />
                ) : (
                  <PricingPlanCardGroup
                    planTierList={orgPlanTierList.filter(
                      (planTier) => planTier.plan_type !== PlanType.free
                    )}
                    planTierListObject={planTierListObject}
                    userCountryCode={userCountryCode}
                    activePlanTier={activePlanTier}
                    currentTab={currentTab}
                    onSelectPlanTab={handleSetCurrentTab}
                    onSelectPlan={handleSelectPlan}
                    onCancelActivePlan={showCancelPlanConfirmation}
                    loading={
                      loadingUpdate ||
                      loadingSelect ||
                      isFetchingStripeBillingInfo
                    }
                    stripeBillingInfo={stripeBillingInfo}
                    isFetchingStripeBillingInfo={isFetchingStripeBillingInfo}
                  />
                )}
              </div>
            </Stack>
          )}
        </>
      ) : (
        <>{noPermissionPageContent}</>
      )}
      {!isEnterpriseClient && (
        <Stack
          style={{
            padding: isMediumScreen
              ? theme.spacing(0, 10)
              : theme.spacing(0, 2.5)
          }}
        >
          <Typography
            align="left"
            variant="h5"
            style={{
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: theme.spacing(6)
            }}
          >
            Frequently asked questions
          </Typography>
          <PricingFAQs />
          <div className={classes.socialProofContainer}>
            <SocialProof />
          </div>
        </Stack>
      )}
    </>
  )
}

/**
 * An accordion container with frequently asked questions about app pricing
 */
export const PricingFAQs = () => {
  // Get the FAQs from DB
  const { data: pricingFAQs } = useSWR('pricingFAQs', fetchPricingFAQs)
  return (
    <>
      {pricingFAQs && (
        <Grid
          container
          style={{
            marginTop: '10px',
            marginBottom: '50px'
          }}
        >
          {pricingFAQs.map((mockFAQ, index) => {
            return (
              <ZeroMarginAccordion
                key={`pricingPlanFAQ_${index}`}
                style={{ width: '100%' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{
                    padding: '5px 30px'
                  }}
                >
                  <Typography variant="h6">{mockFAQ.question}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ paddingLeft: '30px' }}>
                  <Typography variant="body1">
                    <div
                      style={{ textAlign: 'left' }}
                      dangerouslySetInnerHTML={{ __html: mockFAQ.answer }}
                    />
                  </Typography>
                </AccordionDetails>
              </ZeroMarginAccordion>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export interface PricingPlanCardGroupProps {
  readonly planTierList: PremiumPlanTier[]
  readonly activePlanTier: PremiumPlanTier
  readonly userCountryCode: string
  readonly currentTab: PlanBillingPeriod
  readonly planTierListObject?: PremiumPlanTierList
  readonly currentPlanCategoryTab?: PlanCategoryTab
  readonly shouldOnlyShowEnterprisePlans?: boolean
  readonly stripeBillingInfo: StripeBillingInfo | undefined
  readonly isFetchingStripeBillingInfo: boolean
  onSelectPlan: (planId: string) => Promise<void>
  onCancelActivePlan: () => Promise<void>
  onSelectPlanTab: (tab: PlanBillingPeriod) => void
  loading?: boolean
  /**
   * Element ID for used for tracking purposes, used by enterprise plan card.
   * If not provided, the tracking ID will be the plan ID.
   */
  trackingId?: string
  /**
   * If true, if activePlanTier is not in planTierList, we don't show it
   */
  hideActivePlanIfNotInPlanTierList?: boolean
}

/**
 * Returns a list of Pricing Card components that are filtered based on whether enterprise or not.
 * Non-enterprise cards are either shown or stacked together, enterprise cards are ach shown separately.
 *
 * Step 1: Construct a table where the keys are plan types and values are Premium Plan Tiers
 * Step 2: For each plan type in our plan types enumeration, we will pick a default plan (parent plan tier),
 *         and also filter if enterprise or not.
 *   Step 2a: Parent plan tier is decided by a defaultTierOrdering method, that takes in a predicate to compare two plan tiers
 *            and returns the preferred one. The most preferred plan tier is returned in the end as the parent plan tier.
 *   Step 2b: If non-enterprise, we simply pass the array and parent plan tier (that will be shown first upon loading)
 *   Step 2c: If enterprise, we map each enterprise tier to its own card.
 *
 * @param pricingPlanCardGroupProps props passed to PricingPlanCardGroup.
 * @returns filteredPricingCards A list of Pricing Card components to be shown.
 */
const filterOutPricingCards = (
  pricingPlanCardGroupProps: PricingPlanCardGroupProps,
  planTierList: PremiumPlanTier[],
  isPromotionCountryCode: boolean,
  planBillingPeriod: PlanBillingPeriod,
  currTrialingPlan: string,
  trackingId?: string,
  currentPlanCategoryTab?: PlanCategoryTab,
  planTierListObject?: PremiumPlanTierList,
  timeToNextInvoice?: string,
  isLoadingUpcomingInvoice?: boolean,
  isFetchingStripeBillingInfo?: boolean,
  timeToBillingPeriodEnds?: string,
  currentBillingPeriodEndString?: string,
  upcomingInvoiceDate?: string
) => {
  const {
    activePlanTier,
    userCountryCode,
    onSelectPlan,
    onCancelActivePlan,
    loading,
    hideActivePlanIfNotInPlanTierList
  } = pricingPlanCardGroupProps

  const planTable: { [key: string]: PremiumPlanTier[] } = {}
  planTierList.forEach((planTier) => {
    // If an override plan type is provided, use that instead of the plan type
    const planType = planTier.forced_plan_type
      ? PlanType[planTier.forced_plan_type]
      : PlanType[planTier.plan_type]

    if (planType in planTable) {
      planTable[planType].push(planTier)
    } else {
      planTable[planType] = [planTier]
    }
  })

  const activePlanTierId = activePlanTier?.is_paused
    ? activePlanTier?.paused_plan_id || activePlanTier.plan_id
    : activePlanTier.plan_id

  const addActivePlanCard =
    !planTierList
      .map((planTier) => planTier.plan_id)
      .includes(activePlanTierId) &&
    planBillingPeriod === activePlanTier.plan_billing_period &&
    !hideActivePlanIfNotInPlanTierList

  let numberOfCards
  if (currentPlanCategoryTab) {
    numberOfCards =
      Object.values(planTable)
        .flat()
        .filter((plan) => plan.plan_category_tab === currentPlanCategoryTab)
        .length + (addActivePlanCard ? 1 : 0)
  } else {
    // NOTE: This assumes that each items is displayed as a single unique card
    numberOfCards =
      Object.values(planTable).reduce(
        (acc, planTiers) => acc + planTiers.length,
        0
      ) + (addActivePlanCard ? 1 : 0)
  }

  //TODO: Find a better way to auto-sized plan cards instead of hard coding md values
  const xsValue: 6 | 12 = numberOfCards === 1 ? 12 : 6
  const smValue: 6 | 12 = numberOfCards === 1 ? 12 : 6
  const mdValue: 6 | 12 = numberOfCards === 1 ? 12 : 6
  const lgValue: 3 | 4 | 6 | 12 =
    numberOfCards === 1
      ? 12
      : numberOfCards === 2
      ? 6
      : numberOfCards === 3
      ? 4
      : 3

  const filteredPricingCards: JSX.Element[] = []
  // let index = 0
  const planTypesList: readonly PlanType[] = [
    PlanType.free,
    PlanType.basic,
    PlanType.starter,
    PlanType.premium,
    PlanType.unlimited,
    PlanType.enterprise
  ] as const

  if (
    !planTierList
      .map((planTier) => planTier.plan_id)
      .includes(activePlanTierId) &&
    planBillingPeriod === activePlanTier.plan_billing_period &&
    !hideActivePlanIfNotInPlanTierList
  ) {
    filteredPricingCards.push(
      <Grid
        item
        xs={xsValue}
        sm={smValue}
        md={mdValue}
        lg={lgValue}
        key={`planTierCard_${activePlanTier.plan_type}`}
      >
        <MultiPricingPlanCard
          planTiers={[activePlanTier]}
          icon={
            <SvgIcon
              component={
                PLAN_TYPE_TO_PRICING_CARD_ICON_MAP[activePlanTier.plan_type]
              }
              viewBox="0 0 32 32"
              style={{ fontSize: '2rem', fill: 'none' }}
            />
          }
          activePlanId={activePlanTierId}
          isPaused={activePlanTier?.is_paused}
          userCountryCode={userCountryCode}
          onSelect={(plan_id) => () => onSelectPlan(plan_id)}
          onCancel={onCancelActivePlan}
          loading={loading}
          isCancelAtPeriodEnd={activePlanTier.cancel_at_period_end}
          isPromotionCountryCode={isPromotionCountryCode}
          isLegacyPlan={true}
          trackingId={trackingId}
          timeToNextInvoice={timeToNextInvoice}
          isLoadingUpcomingInvoice={isLoadingUpcomingInvoice}
          currTrialingPlan={currTrialingPlan}
          isFetchingStripeBillingInfo={!!isFetchingStripeBillingInfo}
          timeToBillingPeriodEnds={timeToBillingPeriodEnds}
          currentBillingPeriodEndString={currentBillingPeriodEndString}
          upcomingInvoiceDate={upcomingInvoiceDate}
        />
      </Grid>
    )
  }
  planTypesList.forEach((planType) => {
    if (planType in planTable) {
      const planTiers: PremiumPlanTier[] = planTable[
        planType
      ].filter((planTier) =>
        currentPlanCategoryTab
          ? planTier.plan_category_tab === currentPlanCategoryTab
          : true
      )
      if (planTiers.length <= 0) {
        return
      } else if (planType !== PlanType.enterprise) {
        if (planTierListObject?.has_plan_category_tabs) {
          planTiers.forEach((planTier) => {
            filteredPricingCards.push(
              <Grid
                item
                xs={xsValue}
                sm={smValue}
                md={mdValue}
                lg={lgValue}
                key={`planTierCard_${planType}_${planTier.plan_id}`}
              >
                <MultiPricingPlanCard
                  planTiers={[planTier]} // pass only the current planTier
                  icon={
                    <SvgIcon
                      component={PLAN_TYPE_TO_PRICING_CARD_ICON_MAP[planType]}
                      viewBox="0 0 32 32"
                      style={{ fontSize: '2rem', fill: 'none' }}
                    />
                  }
                  activePlanId={activePlanTierId}
                  isPaused={activePlanTier?.is_paused}
                  userCountryCode={userCountryCode}
                  onSelect={(plan_id) => () => onSelectPlan(plan_id)}
                  onCancel={onCancelActivePlan}
                  loading={loading}
                  isCancelAtPeriodEnd={activePlanTier.cancel_at_period_end}
                  isPromotionCountryCode={isPromotionCountryCode}
                  trackingId={trackingId}
                  timeToNextInvoice={timeToNextInvoice}
                  currTrialingPlan={currTrialingPlan}
                  isFetchingStripeBillingInfo={!!isFetchingStripeBillingInfo}
                  timeToBillingPeriodEnds={timeToBillingPeriodEnds}
                  currentBillingPeriodEndString={currentBillingPeriodEndString}
                  upcomingInvoiceDate={upcomingInvoiceDate}
                />
              </Grid>
            )
          })
        } else {
          filteredPricingCards.push(
            ...planTiers.map((planTier) => (
              <Grid
                item
                xs={xsValue}
                sm={smValue}
                md={mdValue}
                lg={lgValue}
                key={`planTierCard_${planTier.plan_type}_${planTier.plan_id}`}
              >
                <MultiPricingPlanCard
                  planTiers={[planTier]}
                  icon={
                    <SvgIcon
                      component={PLAN_TYPE_TO_PRICING_CARD_ICON_MAP[planType]}
                      viewBox="0 0 32 32"
                      style={{ fontSize: '2rem', fill: 'none' }}
                    />
                  }
                  activePlanId={activePlanTierId}
                  isPaused={activePlanTier?.is_paused}
                  userCountryCode={userCountryCode}
                  onSelect={(plan_id) => () => onSelectPlan(plan_id)}
                  onCancel={onCancelActivePlan}
                  loading={loading}
                  isCancelAtPeriodEnd={activePlanTier.cancel_at_period_end}
                  isPromotionCountryCode={isPromotionCountryCode}
                  trackingId={trackingId}
                  timeToNextInvoice={timeToNextInvoice}
                  currTrialingPlan={currTrialingPlan}
                  isFetchingStripeBillingInfo={!!isFetchingStripeBillingInfo}
                  timeToBillingPeriodEnds={timeToBillingPeriodEnds}
                  currentBillingPeriodEndString={currentBillingPeriodEndString}
                  upcomingInvoiceDate={upcomingInvoiceDate}
                />
              </Grid>
            ))
          )
        }
      } else {
        filteredPricingCards.push(
          ...planTiers.map((planTier) => (
            <Grid
              item
              xs={xsValue}
              sm={smValue}
              md={mdValue}
              lg={lgValue}
              key={`planTierCard_${planTier.plan_type}_${planTier.plan_id}`}
            >
              <MultiPricingPlanCard
                planTiers={[planTier]}
                icon={
                  <SvgIcon
                    component={
                      PLAN_TYPE_TO_PRICING_CARD_ICON_MAP[planTier.plan_type]
                    }
                    viewBox="0 0 32 32"
                    style={{ fontSize: '2rem', fill: 'none' }}
                  />
                }
                activePlanId={activePlanTierId}
                isPaused={activePlanTier?.is_paused}
                userCountryCode={userCountryCode}
                isCustom
                // Dummy Currying since we know which plan would be selected on clicking
                onSelect={(_plan_id) => () => onSelectPlan(planTier.plan_id)}
                onCancel={onCancelActivePlan}
                loading={loading}
                isCancelAtPeriodEnd={activePlanTier.cancel_at_period_end}
                isPromotionCountryCode={isPromotionCountryCode}
                trackingId={trackingId}
                ctaName={planTier.cta_name}
                shouldShowPriceForEnterprisePlan={
                  planTier.should_show_price_for_enterprise_plan
                }
                timeToNextInvoice={timeToNextInvoice}
                currTrialingPlan={currTrialingPlan}
                isFetchingStripeBillingInfo={!!isFetchingStripeBillingInfo}
                timeToBillingPeriodEnds={timeToBillingPeriodEnds}
                currentBillingPeriodEndString={currentBillingPeriodEndString}
                upcomingInvoiceDate={upcomingInvoiceDate}
              />
            </Grid>
          ))
        )
      }
    }
  })

  return filteredPricingCards
}

export const PricingPlanCardGroup: React.FC<PricingPlanCardGroupProps> = (
  props
) => {
  const {
    planTierList,
    planTierListObject,
    activePlanTier,
    userCountryCode,
    currentTab,
    onSelectPlanTab,
    trackingId,
    currentPlanCategoryTab,
    shouldOnlyShowEnterprisePlans,
    stripeBillingInfo,
    isFetchingStripeBillingInfo
  } = props

  const classes = useStyles()

  const orgMonthlyPlanTierList = planTierList.filter(
    paidAndActivePlansPredicate(activePlanTier, PlanBillingPeriod.monthly)
  )
  const orgYearlyPlanTierList = planTierList.filter(
    paidAndActivePlansPredicate(activePlanTier, PlanBillingPeriod.yearly)
  )
  const customPeriodPlanTierList = planTierList.filter(
    paidAndActivePlansPredicate(activePlanTier, PlanBillingPeriod.custom)
  )

  const isUserOnShopifyPlanTierList =
    planTierListObject?.plan_list_id === ShopifyPlanListId

  const planTierListMap: { [key in PlanBillingPeriod]: PremiumPlanTier[] } = {
    [PlanBillingPeriod.monthly]: orgMonthlyPlanTierList,
    [PlanBillingPeriod.yearly]: orgYearlyPlanTierList,
    [PlanBillingPeriod.custom]: customPeriodPlanTierList
  }

  const isPromotionCountryCode = PROMOTION_COUNTRY_CODES.includes(
    userCountryCode
  )

  const user = useUserContext()

  const { show: openIntercom } = useIntercom()

  const { data: billingInfo } = useSWR(
    'stripeBillingInfo',
    fetchUserBillingInfo
  )

  const currentBillingPeriodEnd =
    billingInfo?.subscription_info?.next_billing_date

  const timeToBillingPeriodEnds = useMemo(
    () =>
      currentBillingPeriodEnd
        ? moment.unix(currentBillingPeriodEnd.getTime() / 1000).fromNow()
        : '',
    [currentBillingPeriodEnd]
  )
  const currentBillingPeriodEndString = currentBillingPeriodEnd?.toLocaleDateString(
    userLocale,
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  )

  const {
    data: upcomingInvoice,
    isLoading: isLoadingUpcomingInvoice
  } = useSWR(
    [
      `/organization/${user.user.username}/upcoming_invoice`,
      user.user.username
    ],
    ([_, orgName]) => apiGetUpcomingInvoice(orgName)
  )

  const timeToNextInvoice = useMemo(
    () =>
      upcomingInvoice ? moment.unix(upcomingInvoice.created).fromNow() : '',
    [upcomingInvoice]
  )

  const upcomingInvoiceDate = useMemo(
    () =>
      upcomingInvoice
        ? moment.unix(upcomingInvoice.created).format('D MMM YYYY')
        : '',
    [upcomingInvoice]
  )

  const filteredPricingCards = filterOutPricingCards(
    props,
    planTierListMap[currentTab],
    isPromotionCountryCode,
    currentTab,
    stripeBillingInfo?.subscription_info.status ===
      StripeSubscriptionStatus.trialing
      ? activePlanTier.plan_id
      : '',
    trackingId,
    currentPlanCategoryTab,
    planTierListObject,
    timeToNextInvoice,
    isLoadingUpcomingInvoice,
    isFetchingStripeBillingInfo,
    timeToBillingPeriodEnds,
    currentBillingPeriodEndString,
    upcomingInvoiceDate
  )

  const theme = useTheme()

  const handleClick = useCallback(async () => {
    await handleUserClick(
      `pricing-${
        currentPlanCategoryTab === PlanCategoryTab.seo ? 'seo' : 'ecomm'
      }-tab`,
      window.location.pathname
    )
  }, [currentPlanCategoryTab])

  return (
    <Box
      mb={shouldOnlyShowEnterprisePlans ? 0 : 2}
      width="100%"
      textAlign="left"
      style={{
        backgroundColor: 'white',
        padding: theme.spacing(4, 3),
        borderTop: `1px solid ${palette.gray[300]}`
      }}
    >
      <Stack spacing={shouldOnlyShowEnterprisePlans ? 2 : 5}>
        <Box display="flex" justifyContent="center">
          {!shouldOnlyShowEnterprisePlans && !isUserOnShopifyPlanTierList && (
            <PlanDurationTabs
              currentTab={currentTab}
              setCurrentTab={onSelectPlanTab}
              discountPercentage={planTierListObject?.discount_percentage}
            />
          )}
        </Box>
        <Grid
          container
          spacing={2}
          key={currentTab + currentPlanCategoryTab}
          style={{ padding: theme.spacing(0, 6) }}
        >
          {filteredPricingCards}
        </Grid>
      </Stack>

      {planTierListObject?.plan_list_id !== ShopifyPlanListId && (
        <Stack style={{ textAlign: 'center', marginTop: theme.spacing(4) }}>
          <Typography variant="paragraph1Reg">
            View full list of plans and features{' '}
            <a
              style={{
                color: palette.blue[500]
              }}
              className={classes.hoverUnderlineText}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hypotenuse.ai/pricing"
              onClick={handleClick}
            >
              here
            </a>
            .
          </Typography>
          <Typography variant="paragraph1Reg">
            Need more seats or words? Contact{' '}
            <span
              onClick={openIntercom}
              style={{
                color: palette.blue[500],
                cursor: 'pointer'
              }}
              className={classes.hoverUnderlineText}
            >
              {' '}
              support
            </span>
            .
          </Typography>
        </Stack>
      )}
    </Box>
  )
}

export interface BaseDialogContentProps {
  loadingUpdate: boolean
  setOpenDialog: (open: boolean) => void
  isWordsFeatureEnabled: boolean
  selectedPlanTierId: string
  updateUserPlan: (planId: string, planChangeType: PlanChangeType) => void
}

export interface PlanSelectorProps {
  user?: User
  showPaidPlansOnly: boolean
  refreshUser: () => void
  /**
   * Element ID for used for tracking purposes, used by enterprise plan card.
   */
  trackingId: string
}

export const PlanSelector: React.FC<PlanSelectorProps> = (props) => {
  const { user, showPaidPlansOnly, refreshUser, trackingId } = props
  const username = user?.username
  const organizationName = user?.organizationName

  const theme = useTheme()
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openPauseDialog, setOpenPauseDialog] = useState<boolean>(false)
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState<boolean>(
    false
  )
  const [selectedReason, setSelectedReason] = useState<
    CancellationReason | undefined
  >(undefined)
  const [
    cancellationDescription,
    setCancellationDescription
  ] = useState<string>('')
  const [pauseDuration, setPauseDuration] = useState<number>(1)
  const [hasPaymentFailed, setHasPaymentFailed] = useState<boolean>(false)
  const [paymentFailureMessage, setPaymentFailureMessage] = useState<string>(
    DEFAULT_PAYMENT_FAILURE_MESSAGE
  )
  const [selectedPlanTierId, setSelectedPlanTierId] = useState<string>('')
  const { setUserPlanType } = useAnalytics()

  const { data: orgPlanTierList, isValidating: isLoadingPlans } = useSWR(
    user ? ['activePlanTierList', user.organizationName] : null,
    ([_, orgName]: [_: any, orgName: string]) =>
      fetchOrganizationPlanTierList(orgName, false)
  )
  const { data: activePlanTier, mutate } = usePremiumPlanTier()

  const [isShowingPayInvoiceModal, setIsShowingPayInvoiceModal] = useState(
    false
  )
  // Handle tab change
  const [_currentTab, setCurrentTab] = useState<PlanBillingPeriod | undefined>(
    undefined
  )
  const currentTab: PlanBillingPeriod = useMemo(
    () =>
      _currentTab ??
      activePlanTier?.plan_billing_period ??
      PlanBillingPeriod.monthly,
    [_currentTab, activePlanTier?.plan_billing_period]
  )

  const handleSetCurrentTab = (tab: PlanBillingPeriod) => {
    setCurrentTab(tab)
  }

  const {
    data: stripeBillingInfo,
    mutate: mutateStripeBillingInfo,
    isLoading: isFetchingStripeBillingInfo
  } = useSWR('stripeBillingInfo', fetchUserBillingInfo)

  const {
    data: planTierListObject,
    isLoading: isFetchingPlanTierListObject
  } = useSWR('planTierListObject', apiFetchPlanTierListObject)

  const { refreshTrialStatus } = useTrialUserContext()

  const nextBillingDate =
    stripeBillingInfo?.subscription_info?.next_billing_date
  const nextBillingDateString = nextBillingDate?.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })

  const isWordsFeatureEnabled = user ? isWordsEnabled(user) : false

  const _handleSelectPlan = async (plan_id: string) => {
    setSelectedPlanTierId(plan_id)
    if (
      stripeBillingInfo?.subscription_info.status ===
        StripeSubscriptionStatus.unpaid ||
      stripeBillingInfo?.subscription_info.status ===
        StripeSubscriptionStatus.past_due
    ) {
      setIsShowingPayInvoiceModal(true)
    } else if (
      activePlanTier?.plan_type === PlanType.free &&
      !activePlanTier?.is_paused
    ) {
      // If current user is on free plan, skip dialog and direct straight
      // to stripe checkout
      await handleUpdateUserPlan(plan_id, PlanChangeType.new_subscription)
    } else {
      // Else, show the subscription confirmation dialog box
      setOpenDialog(true)
    }
  }

  const [
    { loading: loadingSelect },
    handleSelectPlan
  ] = useAsyncFn(_handleSelectPlan, [_handleSelectPlan])

  const showCancelPlanConfirmation = useCallback(async () => {
    // Cancel plan means switching it back to the free plan
    //TODO: Abstract this search to a method to return default plan
    const freePlanTier = orgPlanTierList?.find(
      (planTier) => planTier.plan_type === PlanType.free
    )
    //TODO: Make a default plan function so we don't risk crashing if we remove a free plan ever
    if (!freePlanTier) return
    setSelectedPlanTierId(freePlanTier.plan_id)
    setOpenDialog(true)
  }, [orgPlanTierList])

  const handleOpenDiscountDialog = useCallback(async () => {
    setIsDiscountDialogOpen(true)
    void handleElementShown('churn-reduction-discount-modal')
    await handleChurnModalShown()
  }, [])
  const handleCloseDiscountDialog = useCallback(
    () => setIsDiscountDialogOpen(false),
    []
  )

  const openPaymentFailureModal = (message: string) => {
    setHasPaymentFailed(true)
    setPaymentFailureMessage(message)
  }

  const { updateUserPlan } = useUpdateUserPlan({
    username,
    organizationName,
    selectedReason,
    cancellationDescription,
    activePlanTier,
    billingInfo: stripeBillingInfo,
    openPaymentFailureModal
  })

  const _handleUpdateUserPlan = async (
    selectedPlanTierId: string,
    planChangeType: PlanChangeType
  ) => {
    if (!selectedPlanTierId) return

    try {
      updateUserPlan(
        selectedPlanTierId,
        planTierListObject?.plan_list_id || '',
        planChangeType
      )

      const { plan_type, plan_billing_period } = await fetchPremiumPlanTier()
      setUserPlanType(plan_type)
      setCurrentTab(plan_billing_period ?? PlanBillingPeriod.monthly) // set the tab to the new plan billing period
      setOpenDialog(false) // close explicitly to avoid modal to be opened while refreshing
      setOpenPauseDialog(false)
      await mutate()
      await mutateStripeBillingInfo()
      refreshTrialStatus()
    } catch (error) {
      snackbar.show(
        'There was an error selecting this plan. Please try again or contact our customer support.',
        { variant: 'error', autoHideDuration: 5000 }
      )
      Sentry.captureException(error)
    } finally {
      refreshUser()
      setOpenDialog(false)
      setOpenPauseDialog(false)
    }
  }

  const [
    { loading: loadingUpdate },
    handleUpdateUserPlan
  ] = useAsyncFn(_handleUpdateUserPlan, [_handleUpdateUserPlan])

  const handleCancelPlan = useCallback(async () => {
    return await updateUserPlan(
      selectedPlanTierId,
      planTierListObject?.plan_list_id || '',
      PlanChangeType.cancellation
    )
  }, [planTierListObject?.plan_list_id, selectedPlanTierId, updateUserPlan])

  const _pauseUserPlan = async () => {
    try {
      await apiPausePlanTier(pauseDuration)
      snackbar.show('Your plan has been paused', {
        variant: 'warning',
        autoHideDuration: 5000
      })
    } catch (error) {
      snackbar.show(
        'There was an error pausing this plan. Please try again or contact our customer support.',
        { variant: 'error', autoHideDuration: 5000 }
      )
      Sentry.captureException(error)
    } finally {
      setOpenPauseDialog(false)
      await mutate()
    }
  }

  const [{ loading: loadingPause }, pauseUserPlan] = useAsyncFn(
    _pauseUserPlan,
    [_pauseUserPlan]
  )

  const { data: userCountryCode = '' } = useSWR(
    '/user_country_code',
    apiGetUserCountryCode
  )

  const isPromotionCountryCode = PROMOTION_COUNTRY_CODES.includes(
    userCountryCode
  )

  var planTierListToShow = orgPlanTierList ?? []

  if (showPaidPlansOnly) {
    planTierListToShow = planTierListToShow.filter(
      (planTier) => planTier.plan_type !== PlanType.free
    )
  }
  return (
    <>
      <PayInvoiceModal
        isOpen={isShowingPayInvoiceModal}
        setIsOpen={setIsShowingPayInvoiceModal}
      />
      <SimpleRedirectDialog
        open={hasPaymentFailed}
        title={'Payment Failed'}
        icon_src={plan_cancellation_icon}
        message={paymentFailureMessage}
        rightButtonText={'Update Billing Info'}
        onClose={() => setHasPaymentFailed(false)}
        onRedirect={async () =>
          handleCreateStripeCustomerPortal()
            .then((portalUrl: string) => {
              window.location.href = portalUrl
            })
            .catch((error) => {
              snackbar.show(
                "Sorry there's an error, please contact our support team via chat or email for assistance.",
                { variant: 'error' }
              )
              Sentry.captureException(error)
            })
        }
      />
      {openDialog && orgPlanTierList && activePlanTier && (
        <ChangePlanDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          loadingUpdate={loadingUpdate}
          selectedPlanTierId={selectedPlanTierId}
          isWordsFeatureEnabled={isWordsFeatureEnabled}
          orgPlanTierList={orgPlanTierList}
          activePlanTier={activePlanTier}
          userCountryCode={userCountryCode}
          setOpenPauseDialog={setOpenPauseDialog}
          onOpenDiscountDialog={handleOpenDiscountDialog}
          updateUserPlan={handleUpdateUserPlan}
          isPromotionCountryCode={isPromotionCountryCode}
          theme={theme}
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
          setCancellationDescription={setCancellationDescription}
          cancellationDescription={cancellationDescription}
          classes={classes}
          nextBillingDate={nextBillingDate}
        />
      )}
      <PausePlanDialog
        openPauseDialog={openPauseDialog}
        setOpenPauseDialog={setOpenPauseDialog}
        orgPlanTierList={orgPlanTierList}
        activePlanTier={activePlanTier}
        selectedPlanTierId={selectedPlanTierId}
        loadingPause={loadingPause}
        pauseDuration={pauseDuration}
        setPauseDuration={setPauseDuration}
        updateUserPlan={handleUpdateUserPlan}
        theme={theme}
        classes={classes}
        pauseUserPlan={pauseUserPlan}
        nextBillingDateString={nextBillingDateString}
        isWordsFeatureEnabled={isWordsFeatureEnabled}
        loadingUpdate={loadingUpdate}
      />
      <DiscountPlanDialog
        open={isDiscountDialogOpen}
        onClose={handleCloseDiscountDialog}
        onCancelPlan={handleCancelPlan}
      />
      {orgPlanTierList && activePlanTier ? (
        <PricingPlanCardGroup
          planTierList={planTierListToShow}
          userCountryCode={userCountryCode}
          activePlanTier={activePlanTier}
          onSelectPlan={handleSelectPlan}
          onCancelActivePlan={showCancelPlanConfirmation}
          currentTab={currentTab}
          onSelectPlanTab={handleSetCurrentTab}
          loading={
            loadingUpdate ||
            loadingSelect ||
            isFetchingStripeBillingInfo ||
            isFetchingPlanTierListObject
          }
          trackingId={trackingId}
          isFetchingStripeBillingInfo={isFetchingStripeBillingInfo}
          stripeBillingInfo={stripeBillingInfo}
        />
      ) : (
        isLoadingPlans && <CircularProgress />
      )}
    </>
  )
}
