import React, { useMemo } from 'react'

import Stack from '../components/atoms/Stack'
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  createStyles,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'

import type { HelpArticle } from '../integrations/HelpScout'
import { HelpArticleLink } from '../integrations/HelpScout'

import palette from './Colors'
import Typography from './Typography'

const useStyles = makeStyles((theme) =>
  createStyles({
    baseTooltip: {
      padding: theme.spacing(1.25, 1.5),
      borderRadius: theme.shape.borderRadius, // 8px
      maxWidth: '300px'
    },
    darkTooltip: {
      color: 'white',
      backgroundColor: palette.gray[900]
    },
    darkTooltipLink: {
      color: `${palette.primary[200]} !important`,
      textDecoration: 'underline'
    },
    lightTooltip: {
      color: palette.gray[700],
      backgroundColor: 'white',
      boxShadow:
        '0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)'
    },
    lightTooltipLink: {
      color: `${palette.primary[700]} !important`,
      textDecoration: 'underline'
    }
  })
)

export interface TooltipProps extends Omit<MuiTooltipProps, 'title'> {
  /**
   * Title to display in the tooltip.
   */
  title?: React.ReactNode
  /**
   * Content to display in the tooltip.
   */
  body?: React.ReactNode
  /**
   * Determines the theme of the tooltip.
   * @default false
   */
  isLightTheme?: boolean
  /**
   * If provided, a HelpScout article link will be displayed
   * at the bottom of the tooltip.
   */
  helpArticle?: HelpArticle
}

/**
 * A tooltip component that displays a title, body, and a link to a
 * HelpScout article.
 */
export const Tooltip: React.FC<TooltipProps> = React.memo(
  (props: TooltipProps) => {
    const classes = useStyles()

    const {
      title,
      body,
      children,
      helpArticle,
      isLightTheme = false,
      classes: overridingClasses,
      ...rest
    } = props

    const tooltipContent = useMemo(
      () =>
        body || title || helpArticle ? (
          <Stack direction="column" spacing={0.5} alignItems="flex-start">
            {title && (
              <Typography
                variant="paragraph2Bold"
                style={{
                  color: 'inherit'
                }}
              >
                {title}
              </Typography>
            )}
            {body && (
              <Typography
                variant="paragraph2Reg"
                style={{
                  color: 'inherit'
                }}
              >
                {body}
              </Typography>
            )}
            {helpArticle && (
              <HelpArticleLink article={helpArticle}>
                <Typography
                  variant="paragraph2Reg"
                  className={
                    isLightTheme
                      ? classes.lightTooltipLink
                      : classes.darkTooltipLink
                  }
                >
                  Learn more -&gt;
                </Typography>
              </HelpArticleLink>
            )}
          </Stack>
        ) : (
          ''
        ),
      [
        body,
        classes.darkTooltipLink,
        classes.lightTooltipLink,
        helpArticle,
        isLightTheme,
        title
      ]
    )

    const { ...overridingProps } = rest
    const { tooltip: overridingTooltipClass, ...overridingRestClasses } =
      overridingClasses ?? {}

    return (
      <MuiTooltip
        title={tooltipContent}
        classes={{
          tooltip: clsx(
            classes.baseTooltip,
            isLightTheme ? classes.lightTooltip : classes.darkTooltip,
            overridingTooltipClass
          ),
          ...overridingRestClasses
        }}
        {...overridingProps}
      >
        {children}
      </MuiTooltip>
    )
  }
)
