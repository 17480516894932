import { DataLayerArgs } from 'react-gtm-module'

// Events
export enum GtmEvents {
  /**
   * When a user is redirected to Stripe Checkout
   */
  PHOENIX_CHECKOUT_STARTED = 'phoenixCheckoutStarted',
  /**
   * Triggered after successful checkout
   */
  PHOENIX_CHECKOUT_SUCCESS = 'phoenixCheckoutSuccess',
  /**
   * Triggered after user verifies their email
   */
  PHOENIX_EMAIL_VERIFIED = 'phoenixEmailVerified',
  /**
   * Stripe checkout failed.
   * Currently not used.
   */
  PHOENIX_CHECKOUT_FAILED = 'phoenixCheckoutFailed',
  TAKO_SUBSCRIPTION = 'takoSubscription'
}

// Data Layers
export enum GtmDataLayerTypes {
  GTM_SUBSCRIPTION_LAYER = 'SubscriptionLayer'
}

// Data Layer
export interface GtmDataLayer extends DataLayerArgs {
  event: GtmEvents
  username: string
  transactionId: string
  planType?: string // Must be unique so that each transaction is unique
}
