import { create, createStore } from 'zustand'

import {
  apiCreateContentRecommendation,
  apiGetContentRecommendation
} from '../components/liveFeed/api'

import { ContentGenerationType } from '../interfaces/ContentGeneration'

import {
  TopicRecommendation,
  TopicRecommendationCollectionType,
  TopicRecommendationTriggerSource
} from '../components/liveFeed/types'

export interface IContentRecommendationStore {
  recommendedContent: TopicRecommendation[]
  isLoadingRecommendation: boolean
  onSuggestContentIdeas: (
    userWebsite: string,
    recommendedTools: ContentGenerationType[],
    recommendationTriggerSource: TopicRecommendationTriggerSource,
    topicRecommendationCollectionType?: TopicRecommendationCollectionType
  ) => Promise<void>
  isScrapedFailed: boolean
  getRecommendedContent: () => Promise<void>
  setRecommendedContent: (content: TopicRecommendation[]) => void
}

const contentRecommendationStore = createStore<IContentRecommendationStore>(
  (set, get) => ({
    recommendedContent: [],
    isLoadingRecommendation: false,
    isScrapedFailed: false,
    onSuggestContentIdeas: async (
      userWebSite: string,
      recommendedTools: ContentGenerationType[],
      recommendationTriggerSource: TopicRecommendationTriggerSource,
      topicRecommendationCollectionType?: TopicRecommendationCollectionType
    ) => {
      try {
        set({
          isLoadingRecommendation: true
        })
        set({
          isScrapedFailed: false
        })
        if (get().recommendedContent.length === 0) {
          const recommendation = await apiGetContentRecommendation(
            topicRecommendationCollectionType
          )
          if (recommendation.length === 0) {
            const response = await apiCreateContentRecommendation(
              userWebSite,
              recommendedTools,
              recommendationTriggerSource,
              topicRecommendationCollectionType
            )
            const isScrapedFailed = !response.is_url_used_for_recommendation
            const recommendedContent = response.recommendations
            set({
              recommendedContent: recommendedContent
            })
            set({
              isScrapedFailed: isScrapedFailed
            })
          } else {
            set({
              recommendedContent: recommendation
            })
          }
        } else {
          const response = await apiCreateContentRecommendation(
            userWebSite,
            recommendedTools,
            TopicRecommendationTriggerSource.home_page_suggest,
            topicRecommendationCollectionType
          )
          const isScrapedFailed = !response.is_url_used_for_recommendation
          const newRecommendedContent = await apiGetContentRecommendation(
            topicRecommendationCollectionType
          )
          set({
            recommendedContent: newRecommendedContent
          })
          set({
            isScrapedFailed: isScrapedFailed
          })
        }
        set({
          isLoadingRecommendation: false
        })
      } catch (e) {
        set({
          isLoadingRecommendation: false
        })
        console.error(e)
      }
    },
    getRecommendedContent: async () => {
      try {
        set({
          isLoadingRecommendation: true
        })
        const recommendation = await apiGetContentRecommendation(
          TopicRecommendationCollectionType.content_feed
        )
        set({
          recommendedContent: recommendation
        })
        set({
          isLoadingRecommendation: false
        })
      } catch (e) {
        set({
          isLoadingRecommendation: false
        })
        console.error(e)
      }
    },
    setRecommendedContent: (content) => {
      set({
        recommendedContent: content
      })
    }
  })
)

export const useContentRecommendation = create(contentRecommendationStore)
