import React from 'react'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Container, useTheme } from '@material-ui/core'

import { LogoText } from '../Logos'

export enum AccountStatus {
  blocked = 'disabled',
  deleted = 'deleted',
  shadowBanned = 'shadowBanned'
}

interface AccountDisabledPageProps {
  accountStatus: AccountStatus
}

export const AccountDisabledPage = (props: AccountDisabledPageProps) => {
  const { accountStatus } = props
  const theme = useTheme()

  const headerText =
    accountStatus === AccountStatus.shadowBanned
      ? `You've reached the limit on your free trial.`
      : `This account has been ${accountStatus}.`

  return (
    <Container
      maxWidth="lg"
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Stack
        spacing={2}
        p={4}
        alignItems="center"
        border="1px solid"
        borderColor={theme.palette.divider}
        borderRadius={theme.shape.borderRadius}
      >
        <LogoText />
        <Stack spacing={1} alignItems="center" textAlign="center">
          <Typography>{headerText}</Typography>
          <Typography>
            Contact us at{' '}
            <a href="mailto:support@hypotenuse.ai">support@hypotenuse.ai</a> if
            you think this is a mistake.
          </Typography>
        </Stack>
      </Stack>
    </Container>
  )
}
