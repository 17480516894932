import { useCallback, useMemo, useRef, useState } from 'react'

interface IConfirmation {
  isConfirmed: boolean
}

interface Return {
  /**
   * Function that starts the confirmation process.
   * Call this when you want to get the user's confirmation before doing an action.
   * Returns a promise that resolves when the user accepts or rejects the action.
   */
  getConfirmation: () => Promise<IConfirmation>
  /**
   * True while the user is confirming the action.
   * Use this as a flag to e.g. open a confirmation modal.
   */
  isConfirming: boolean
  /**
   * Function to call when the user accepts the action.
   * Call this in your 'confirm' button's onClick handler.
   */
  onConfirm: () => void
  /**
   * Function to call when the user rejects/cancels the action.
   * Call this in your 'cancel' button's onClick handler and/or
   * your confirmation dialog's onClose handler.
   */
  onCancel: () => void
}

/**
 * Generic hook to get a user's confirmation before performing an action.
 * @example
 * // Inside some component:
 * const { getConfirmation, isConfirming, onConfirm, onCancel } = useConfirmation
 * const dangerousAction = async () => {
 *   const { isConfirmed } = await getConfirmation()
 *   if (isConfirmed) {
 *     await apiClient.delete('/everything')
 *   }
 * }
 * return (
 *   <>
 *     <button onClick={dangerousAction}>Do the thing!</button>
 *     <Modal open={isConfirming} onClose={onCancel}>
 *       Are you sure?
 *       <button onClick={onConfirm}>Yes</button>
 *       <button onClick={onCancel}>No</button>
 *     </Modal>
 *   </>
 * )
 * @see
 * Heavily inspired by {@link https://levelup.gitconnected.com/how-to-build-a-generic-reusable-synchronous-like-confirmation-dialog-in-react-js-71e32dfa495c}
 */
export const useConfirmation = (): Return => {
  const resolveCallback = useRef<((value: IConfirmation) => void) | null>(null)
  const [isConfirming, setIsConfirming] = useState<boolean>(false)

  const getConfirmation = useCallback(() => {
    setIsConfirming(true)

    return new Promise<IConfirmation>(function (resolve) {
      resolveCallback.current = resolve
    })
  }, [])

  const onConfirm = useCallback(() => {
    resolveCallback.current?.({ isConfirmed: true })
    setIsConfirming(false)
  }, [])

  const onCancel = useCallback(() => {
    resolveCallback.current?.({ isConfirmed: false })
    setIsConfirming(false)
  }, [])

  return useMemo(
    () => ({
      getConfirmation,
      isConfirming,
      onConfirm,
      onCancel
    }),
    [getConfirmation, isConfirming, onCancel, onConfirm]
  )
}
