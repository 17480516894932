import React, { useContext, useReducer } from 'react'

import { makePropInjectorHOCFromHook } from '@hypotenuse/common/src/hooks/hocify'

import { GlobalAppState } from '../../components/utils/Interfaces'

import { GlobalAppAction } from '../Interfaces'
import { globalAppReducer, initialState } from '../reducers/GlobalAppContext'

export type GlobalAppContextType = {
  state: GlobalAppState
  dispatch: React.Dispatch<GlobalAppAction>
}

// Set up a GlobalAppContext with default empty values
export const GlobalAppContext = React.createContext<
  GlobalAppContextType | undefined
>(undefined)

// Syntactic sugar so we dont need to call useContext(GlobalAppContext) everywhere
export const useGlobalAppContext = () => {
  const context = useContext(GlobalAppContext)
  if (context === undefined) {
    throw new Error(
      'useGlobalAppContext must be used within a GlobalAppContextProvider'
    )
  }
  return context
}

// HOC to wrap a component with GlobalAppContext
export const withGlobalAppContext = makePropInjectorHOCFromHook(
  useGlobalAppContext
)

// A cleaner way to add on future values and their modifiers to this GlobalAppContext
export const GlobalAppProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(globalAppReducer, initialState)
  return (
    <GlobalAppContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {props.children}
    </GlobalAppContext.Provider>
  )
}
