// import * as Sentry from '@sentry/react'

export const canShowRetryingMessage = (
  currentDate: Date,
  expiredAt?: Date,
  loadingAt?: Date
): boolean => {
  if (!expiredAt || !loadingAt) return false
  // Threshold to render time remaining
  const minTimeAfterGenerate = (expiredAt.getTime() - loadingAt.getTime()) / 2
  return currentDate.getTime() - loadingAt.getTime() > minTimeAfterGenerate
}

export const canShowCancelButton = (
  currentDate: Date,
  expiredAt?: Date,
  loadingAt?: Date
): boolean => {
  if (!expiredAt || !loadingAt) return false
  const minTimeAfterGenerate =
    (expiredAt.getTime() - loadingAt.getTime()) * (3 / 4)
  const showCancelButton =
    currentDate.getTime() - loadingAt.getTime() > minTimeAfterGenerate
  // Comment out because Sentry is getting spammed 10K+ events
  // if (showCancelButton) {
  //   Sentry.captureMessage(
  //     'Track Cancel generation (and refund) displayed to user',
  //     'warning'
  //   )
  // }
  return showCancelButton
}
