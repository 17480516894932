import { Tooltip } from '@material-ui/core'
import { Theme, withStyles } from '@material-ui/core/styles'

export const LightTooltipWithPadding = withStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(1.5),
    fontWeight: 400
  }
}))(Tooltip)
