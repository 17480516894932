import React from 'react'

import {
  InsufficientCreditsModal,
  InsufficientCreditsModalProps
} from '@hypotenuse/common/src/components/InsufficientCreditsModal'
import { useGenerationAPI } from '@hypotenuse/common/src/hooks/useGenerationAPI'

export interface PremiumInsufficientCreditsModalProps
  extends Partial<InsufficientCreditsModalProps> {}

/**
 * Wrapper around the generic InsufficientCreditsModal to customize it for the Premium app.
 *
 * Also serves as a workaround for the fact that useGenerationAPI() is not currently usable
 * directly inside the `App` component due to its Provider being further down in the tree.
 */
export const PremiumInsufficientCreditsModal: React.FC<PremiumInsufficientCreditsModalProps> = (
  props
) => {
  const {
    showInsufficientCreditsModal,
    setShowInsufficientCreditsModal
  } = useGenerationAPI()
  return (
    <InsufficientCreditsModal
      open={showInsufficientCreditsModal}
      topDivider={false}
      onClose={() => setShowInsufficientCreditsModal(false)}
      modalMessage={`You won't be able to generate any more content. Don't worry, your current content is still safe.\n\n
        To keep generating great content, upgrade your plan.`}
      redirectTo="/settings/pricing"
      primaryText="View Plans"
      {...props}
    />
  )
}
