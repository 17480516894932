/**
 * Cost info for generating content for a single product
 */
export interface ContentGenerationCreditCostInfo {
  product_id: string
  cost: number
  is_free_regenerate: boolean
}

/**
 * Cost info for generating content for a batch of products
 */
export interface BatchContentGenerationCreditCostInfo {
  total_cost: number
  product_id_cost_info_map: { string: ContentGenerationCreditCostInfo }
}

/**
 * 'Legacy' interface (still technically in use on the backend) for cost info
 * for generating content for products.
 *
 * @deprecated Use {@link BatchContentGenerationCreditCostInfo} or
 * {@link ContentGenerationCreditCostInfo} instead
 */
export interface ProductDescribeCostInResponse {
  describe_cost: number
  is_free_regenerate?: boolean
  product_ids: string[]
  error_message?: string
}

/**
 * Granular content generation subtypes
 */
export enum ContentGenerationType {
  // Product descriptions
  product_descriptions = 'product_descriptions',

  // Blog
  blog_title = 'blog_title',
  blog_outline = 'blog_outline',
  blog_introduction = 'blog_introduction',
  blog_paragraph = 'blog_paragraph',
  blog_conclusion = 'blog_conclusion',
  blog_write_more = 'blog_write_more',
  blog_outline_seo_automatic = 'blog_outline_seo_automatic',
  blog_outline_seo_improvement = 'blog_outline_seo_improvement',
  blog_outline_parse_existing = 'blog_outline_parse_existing',

  blog_article = 'blog_article',

  // Marketing Copy (formerly known as Ads)
  advertising_text_instagram = 'advertising_text_instagram',
  advertising_text_google = 'advertising_text_google',
  advertising_text_facebook = 'advertising_text_facebook',
  advertising_text_headline = 'advertising_text_headline',
  advertising_text_linkedin = 'advertising_text_linkedin',
  advertising_text_rewriter = 'advertising_text_rewriter',
  advertising_text_category_page = 'advertising_text_category_page',
  advertising_text_landing_page = 'advertising_text_landing_page',
  advertising_text_eli5 = 'advertising_text_eli5',
  advertising_text_email = 'advertising_text_email',
  advertising_text_video_intro = 'advertising_text_video_intro',
  advertising_text_meta_desc = 'advertising_text_meta_desc',
  advertising_text_marketing_angles = 'advertising_text_marketing_angles',
  advertising_text_press_release = 'advertising_text_press_release',
  advertising_text_summarise = 'advertising_text_summarise',
  advertising_text_youtube_title = 'advertising_text_youtube_title',
  advertising_text_bio_writer = 'advertising_text_bio_writer',
  advertising_text_ask_anything = 'advertising_text_ask_anything',
  advertising_text_blog = 'advertising_text_blog', // used for blog bulk gen
  advertising_text_rewrite_for_seo = 'advertising_text_rewrite_for_seo',

  // Image generation
  text_to_image_generation = 'text_to_image_generation',

  // Chat
  chat_message = 'chat_message',
  chat_message_file_upload = 'chat_message_file_upload'
}

export const ContentGenerationTypeAds = [
  ContentGenerationType.advertising_text_google,
  ContentGenerationType.advertising_text_facebook
]

export const ContentGenerationTypeSocialPosts = [
  ContentGenerationType.advertising_text_instagram,
  ContentGenerationType.advertising_text_linkedin,
  ContentGenerationType.advertising_text_youtube_title,
  ContentGenerationType.advertising_text_video_intro
]

export const ContentGenerationTypeWebsiteContent = [
  ContentGenerationType.advertising_text_headline,
  ContentGenerationType.advertising_text_landing_page,
  ContentGenerationType.advertising_text_meta_desc,
  ContentGenerationType.advertising_text_category_page,
  ContentGenerationType.advertising_text_bio_writer
]

export const ContentGenerationTypeSummarise = [
  ContentGenerationType.advertising_text_summarise
]

export enum FeedbackContentGenerationType {
  // Highlight commands
  highlight_command_keep_writing = 'highlight_command_keep_writing',
  highlight_command_create_list = 'highlight_command_create_list',
  highlight_command_add_example = 'highlight_command_add_example',
  highlight_command_develop_subheadings = 'highlight_command_develop_subheadings',
  highlight_command_weigh_pros_and_cons = 'highlight_command_weigh_pros_and_cons',
  highlight_command_transition_between_ideas = 'highlight_command_transition_between_ideas',
  highlight_command_add_call_to_action = 'highlight_command_add_call_to_action',
  highlight_command_improve = 'highlight_command_improve',
  highlight_command_simplify = 'highlight_command_simplify',
  highlight_command_shorten = 'highlight_command_shorten',
  highlight_command_expand = 'highlight_command_expand',
  highlight_command_rephrase = 'highlight_command_rephrase',
  // custom prompt
  highlight_command_prompt = 'highlight_command_prompt',

  // Research
  highlight_command_get_statistics = 'highlight_command_get_statistics',
  highlight_command_find_quotes = 'highlight_command_find_quotes',
  highlight_command_add_examples = 'highlight_command_add_examples',
  highlight_command_research_custom_prompt = 'highlight_command_research_custom_prompt',

  // Blog SEO rule fix
  blog_seo_fix_keyword_in_first_100_words = 'blog_seo_fix_keyword_in_first_100_words',
  blog_seo_fix_keyword_in_heading_2 = 'blog_seo_fix_keyword_in_heading_2',
  blog_seo_fix_first_paragraph_sentence_length = 'blog_seo_fix_first_paragraph_sentence_length',
  blog_seo_fix_long_paragraph_length = 'blog_seo_fix_long_paragraph_length',
  blog_seo_fix_keyword_density = 'blog_seo_fix_keyword_density',
  blog_seo_fix_target_h2_count = 'blog_seo_fix_target_h2_count',

  // Blog
  blog_write_more = 'blog_write_more',
  blog_title = 'blog_title',
  blog_outline = 'blog_outline',
  blog_outline_default = 'blog_outline_default',
  blog_outline_all_serp = 'blog_outline_all_serp',
  blog_outline_improve = 'blog_outline_improve',
  blog_outline_manual = 'blog_outline_manual',
  blog_article = 'blog_article',

  // Image generation
  text_to_image_generation = 'text_to_image_generation',
  text_to_image_generation_batch = 'text_to_image_generation_batch',

  // Ads
  advertising_text_instagram = 'advertising_text_instagram',
  advertising_text_google = 'advertising_text_google',
  advertising_text_facebook = 'advertising_text_facebook',
  advertising_text_headline = 'advertising_text_headline',
  advertising_text_linkedin = 'advertising_text_linkedin',
  advertising_text_rewriter = 'advertising_text_rewriter',
  advertising_text_category_page = 'advertising_text_category_page',
  advertising_text_landing_page = 'advertising_text_landing_page',
  advertising_text_eli5 = 'advertising_text_eli5',
  advertising_text_email = 'advertising_text_email',
  advertising_text_video_intro = 'advertising_text_video_intro',
  advertising_text_meta_desc = 'advertising_text_meta_desc',
  advertising_text_marketing_angles = 'advertising_text_marketing_angles',
  advertising_text_press_release = 'advertising_text_press_release',
  advertising_text_summarise = 'advertising_text_summarise',
  advertising_text_youtube_title = 'advertising_text_youtube_title',
  advertising_text_bio_writer = 'advertising_text_bio_writer',
  advertising_text_ask_anything = 'advertising_text_ask_anything',
  advertising_text_blog = 'advertising_text_blog',
  advertising_text_rewrite_for_seo = 'advertising_text_rewrite_for_seo',

  // HypoChat
  chat_message = 'chat_message',

  // Net promoter score
  platform = 'platform'
}

/**
 * Top-level content generation types
 */
export enum ContentGenerationCategory {
  product_descriptions = 'product_descriptions',
  blog = 'blog',
  advertising = 'advertising'
}

/**
 * Generation status filter key
 */
export enum GenerationStatusFilterKey {
  all = 'all',
  generated = 'generated',
  not_generated = 'not_generated',
  generation_error = 'generation_error',
  marked_as_done = 'marked_as_done'
}

export enum GenerationInputType {
  file = 'file',
  url = 'url',
  text = 'text'
}
