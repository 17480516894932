import React from 'react'

import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import { useLocalStorage } from '../hooks/useLocalStorage'

interface ProtectedRouteProps extends RouteProps {
  hasAccess: boolean
}

export interface ProtectedRouteWithRedirectProps extends ProtectedRouteProps {
  redirectUrl: string
}

export const ProtectedRouteWithRedirect: React.FC<ProtectedRouteWithRedirectProps> = (
  props
) => {
  const { hasAccess } = props

  const [redirectURL, setRedirectURL] = useLocalStorage<string>(
    'redirectURL',
    ''
  )
  const location = useLocation()

  if (hasAccess) {
    return <Route {...props} />
  } else {
    // TODO: Add documentation
    if (!hasAccess && !redirectURL) {
      setRedirectURL(location.pathname + location.search)
    }
    console.log(
      `User does not have access to ${props.path}. Redirecting to ${props.redirectUrl}`
    )
    return <Redirect to={props.redirectUrl} />
  }
}

export interface ProtectedRouteWithFallbackComponentProps
  extends ProtectedRouteProps {
  fallbackComponent: React.ReactNode
}

export const ProtectedRouteWithFallbackComponent: React.FC<ProtectedRouteWithFallbackComponentProps> = (
  props
) => {
  const { hasAccess, fallbackComponent } = props
  if (hasAccess) {
    return <Route {...props} />
  } else {
    return <>{fallbackComponent}</>
  }
}
