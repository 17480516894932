import { AdTextType } from '../../interfaces/AdvertisingText'

import ask_anything_logo from './assets/v2/ask_ai_anything.svg'
import bio_writer_logo from './assets/v2/bio_writer.svg'
import category_page_logo from './assets/v2/category_page_descriptions.svg'
import email_logo from './assets/v2/email_copy.svg'
import facebook_logo from './assets/v2/facebook_ads.svg'
import google_ads_logo from './assets/v2/google_ads.svg'
import instagram_logo from './assets/v2/instagram_caption.svg'
import landing_page_logo from './assets/v2/landing_page_content.svg'
import linkedin_logo from './assets/v2/linkedin_posts.svg'
import marketing_angles_logo from './assets/v2/marketing_angles.svg'
import meta_desc_logo from './assets/v2/meta_titles_and_descriptions.svg'
import press_release_logo from './assets/v2/press_release.svg'
import rewriter_logo from './assets/v2/rewriter.svg'
import eli5_logo from './assets/v2/simplify_language.svg'
import summarise_logo from './assets/v2/summarize_content.svg'
import video_intro_logo from './assets/v2/video_hook_and_introduction.svg'
import headlines_logo from './assets/v2/website_headlines_and_taglines.svg'
import youtube_title_logo from './assets/v2/youtube.svg'

/**
 * Enum for groups of tools in the tool library.
 */
export enum ToolCardGroupType {
  featured = 'Featured tools',
  general = 'General',
  ads_social = 'Ads & social',
  site = 'Site',
  newsletters_comms = 'Newsletters & comms'
}

export const marketingRoutes = {
  [AdTextType.facebook]: '/advertising/facebook',
  [AdTextType.google]: '/advertising/google',
  [AdTextType.instagram]: '/advertising/instagram',
  [AdTextType.headline]: '/advertising/headline',
  [AdTextType.linkedin]: '/advertising/linkedin',
  [AdTextType.rewriter]: '/advertising/rewriter',
  [AdTextType.category_page]: '/advertising/category_page',
  [AdTextType.landing_page]: '/advertising/landing_page',
  [AdTextType.eli5]: '/advertising/eli5',
  [AdTextType.email]: '/advertising/email_copy',
  [AdTextType.video_intro]: '/advertising/video_intro',
  [AdTextType.meta_desc]: '/advertising/meta_desc',
  [AdTextType.marketing_angles]: '/advertising/marketing_angles',
  [AdTextType.press_release]: '/advertising/press_release',
  [AdTextType.summarise]: '/advertising/summarise_copy',
  [AdTextType.youtube_title]: '/advertising/youtube_title',
  [AdTextType.bio_writer]: '/advertising/bio_writer',
  [AdTextType.ask_anything]: '/advertising/ask_anything',
  [AdTextType.rewrite_for_seo]: '/advertising/rewrite_for_seo'
}

export const toolLogos = {
  [AdTextType.facebook]: facebook_logo,
  [AdTextType.linkedin]: linkedin_logo,
  [AdTextType.google]: google_ads_logo,
  [AdTextType.instagram]: instagram_logo,
  [AdTextType.headline]: headlines_logo,
  [AdTextType.rewriter]: rewriter_logo,
  [AdTextType.category_page]: category_page_logo,
  [AdTextType.landing_page]: landing_page_logo,
  [AdTextType.eli5]: eli5_logo,
  [AdTextType.email]: email_logo,
  [AdTextType.video_intro]: video_intro_logo,
  [AdTextType.meta_desc]: meta_desc_logo,
  [AdTextType.marketing_angles]: marketing_angles_logo,
  [AdTextType.press_release]: press_release_logo,
  [AdTextType.summarise]: summarise_logo,
  [AdTextType.youtube_title]: youtube_title_logo,
  [AdTextType.bio_writer]: bio_writer_logo,
  [AdTextType.ask_anything]: ask_anything_logo,
  [AdTextType.rewrite_for_seo]: rewriter_logo
}
