import {
  LongFormContentLength,
  LongFormContentPOV
} from '../components/longFormContent/interfaces'

import { CountryDetail, ToneSource } from '../utils/Interfaces'

import { EntityReference } from '../components/entityReference/types'

import { FeedbackContentGenerationType } from './ContentGeneration'
import { Description, ProductErrorType } from './Products'
import { KeywordResearchResult } from './Research'

export enum BlogStepper {
  TITLE,
  OUTLINE,
  INTRO,
  PARAGRAPH,
  CONCLUSION,
  FULL_ARTICLE
}

export enum BlogCurrStep {
  NONE = -1,
  TITLE = 0,
  OUTLINES = 1,
  PARAGRAPHS = 2
}

export enum SelectedBlogTab {
  /**
   * Enum that is used to identify the tab that is currently selected within the blog title/outline stage
   */
  CONTENT_BRIEF = 'content brief',
  KNOWLEDGE = 'knowledge'
}

/**
 * The pattern used to split outline headings into an array.
 * Note: We include <SEP> for backward compatibility - we don't expect to see
 *  it in newly generated outlines but older outlines still contain it.
 */
export const BLOG_OUTLINE_SEPARATOR_PATTERN = /\n|<SEP>/
/**
 * The patter used to rejoin outline headings arrays into a string
 */
export const BLOG_OUTLINE_JOIN_TOKEN = '\n'

/**
 * Overrides the default timeout offset returned by backend via blog.expiredAt
 * as blog generation should have a longer timeout.
 */
export const BLOG_GENERATION_TIMEOUT_IN_SECONDS = 360

export enum BlogTheme {
  WRITER_BLOG_TITLE = 'blog_title',
  WRITER_BLOG_OUTLINE = 'blog_outline_General',
  WRITER_BLOG_INTRO = 'blog_introduction',
  WRITER_BLOG_PARAGRAPH = 'blog_paragraph',
  WRITER_BLOG_CONCLUSION = 'blog_conclusion',
  WRITER_BLOG_WRITE_MORE = 'blog_write_more' // TODO: consider different writers based on prefix or suffix
}

export enum OutlineDetailCreationType {
  BY_USER = 'by_user',
  BY_RESEARCH = 'by_research'
}
export interface OutlineDetail {
  outline_detail: string
  creation_type: OutlineDetailCreationType
  id: string
}

/**
 * The outline section is a representation of H2s + Ps
 */
export interface OutlineSection {
  /** This string stores the one heading (H2) */
  outline_heading: string
  /** This array of string stores the bullet points of each heading (H2) */
  details: OutlineDetail[]
  /** This id is used to identify the outline section */
  id: string
}

export enum OutlineCreationMethodType {
  default = 'default',
  all_serp = 'all_serp',
  improve = 'improve',
  manual = 'manual'
}

export const outlineCreationMethodToContentFeedbackTypeMap: {
  [key in OutlineCreationMethodType]: FeedbackContentGenerationType
} = {
  [OutlineCreationMethodType.default]:
    FeedbackContentGenerationType.blog_outline_default,
  [OutlineCreationMethodType.all_serp]:
    FeedbackContentGenerationType.blog_outline_all_serp,
  [OutlineCreationMethodType.improve]:
    FeedbackContentGenerationType.blog_outline_improve,
  [OutlineCreationMethodType.manual]:
    FeedbackContentGenerationType.blog_outline_manual
}

export interface OutlineWithDetails {
  /**
   * This list stores all the headings of an outline
   * and the details (bullet points) of each heading
   */
  list_of_outline_sections: OutlineSection[]
  /**
   *  This id is used to keep track of which outline is currently selected.
   * In Step 3 of Blog, we will render the
   * outline based on the selected_outline_id
   */
  outline_id: string
  outline_creation_method: OutlineCreationMethodType
}

export interface BlogPendingDetail {
  [key: string]: PendingDetail
}

export interface PendingDetail {
  /**
   * The index of the outline, as there can be multiple outlines
   */
  outline_idx: number
  /**
   * The index of the section in that outline, as there can be multiple sections
   */
  section_idx: number
  /**
   * The string of the pending detail of that section.
   * Pending details are details that have not been entered yet.
   */
  pending_detail: string
}

// All Descriptions inside Blog object will have a description_id
export interface BlogDescription extends Description {
  description_id: string
}

/**
 * Schema for the blog web enrichment links configuration.
 *
 * It is used to store the links to include/exclude in the
 * blog web enrichment process.
 */
export interface BlogWebEnrichmentLinksConfig {
  /**
   * The list of internal links to include in the blog enrichment process.
   */
  internal_links_to_include: string[]

  /**
   * The list of external links to include in the blog enrichment process.
   */
  external_links_to_include: string[]

  /**
   * The list of external links to exclude in the blog enrichment process.
   */
  external_links_to_exclude: string[]
}

export interface BlogEnrichmentContentResponse {
  /**
   * Map of outline section ID to enriched content to be used during generation
   */
  enriched_content_map: Record<string, string>

  /**
   * List of sources that are used to produce the enriched content
   */
  source_list: string[]
}

export interface Blog {
  username: string
  organizationName: string
  blogId: string
  product_details: string
  productId: string
  created_at: string
  modified_at: string
  loading: boolean
  descriptions: BlogDescription[]
  step: number
  target_audience: string
  brand_details: string
  title: string
  intro: string
  blog_outlines: string[]
  blog_pending_details: BlogPendingDetail
  blog_outlines_details: string[][]
  paragraphs: string[]
  blog_content: string
  keywords: string[]
  selected_keyword: string
  seo_keywords: string[]
  conclusion: string
  seo_region: CountryDetail
  loadingAt?: Date
  expiredAt?: Date
  productErrorType?: ProductErrorType
  blog_topic?: string
  blog_tone: string
  blog_tone_id?: string
  blog_tone_source?: ToneSource
  point_of_view: LongFormContentPOV
  outline_options: OutlineWithDetails[]
  selected_outline_id: string
  blog_length: LongFormContentLength
  reference_file_ids: string[] | null
  reference_links: string[] | null
  is_referencing_knowledge_base: boolean
  is_seo_outline_enabled: boolean
  keyword_search_results?: KeywordResearchResult
  is_keyword_search_loading?: boolean
  selected_organic_seo_headers?: string[]
  selected_paa_seo_headers?: string[]
  prev_selected_keyword_for_outline_gen?: string
  is_web_enrichment_mode_enabled: boolean
  web_enrichment_links_config: BlogWebEnrichmentLinksConfig
  existing_blog_outline?: string
  has_existing_outline_parsed?: boolean
  is_use_existing_outline_checked?: boolean
}

export interface BlogUpdateForm {
  blogId: string
  blog_topic?: string
  product_details?: string
  target_audience?: string
  brand_details?: string
  title?: string
  intro?: string
  blog_outlines?: string[]
  blog_pending_details?: BlogPendingDetail
  paragraphs?: string[]
  blog_content?: string
  descriptions?: BlogDescription[]
  keywords?: string[]
  selected_keyword?: string
  seo_keywords?: string[]
  conclusion?: string
  step?: BlogCurrStep
  productErrorType?: ProductErrorType
  blog_tone?: string
  blog_tone_id?: string
  blog_tone_source?: ToneSource
  point_of_view?: LongFormContentPOV
  outline_options?: OutlineWithDetails[]
  selected_outline_id?: string
  blog_length?: LongFormContentLength
  reference_file_ids?: string[]
  reference_links?: string[] | null
  is_referencing_knowledge_base?: boolean
  is_seo_outline_enabled?: boolean
  keyword_search_results?: KeywordResearchResult
  is_keyword_search_loading?: boolean
  selected_organic_seo_headers?: string[]
  selected_paa_seo_headers?: string[]
  prev_selected_keyword_for_outline_gen?: string
  is_web_enrichment_mode_enabled?: boolean
  web_enrichment_links_config?: BlogWebEnrichmentLinksConfig
  seo_region?: CountryDetail
  existing_blog_outline?: string
  has_existing_outline_parsed?: boolean
  is_use_existing_outline_checked?: boolean
}

export type SortByDate =
  | BlogOverviewSortBy.CREATED_AT
  | BlogOverviewSortBy.MODIFIED_AT

export enum BlogOverviewSortBy {
  SCORE = '_score',
  BLOG_ID = 'blogId',
  CREATED_AT = 'created_at',
  MODIFIED_AT = 'modified_at',
  BLOG_TITLE = 'title.raw'
}

export interface BlogOverviewSearchQuery {
  blogIds?: Array<string>
  search?: string
  scan?: boolean
  sortBy?: Array<BlogOverviewSortBy>
  start?: number
  end?: number
}

export interface BlogOverviewResponse {
  created_at: string
  blogId: string
  title: string
  keywords: Array<string>
  modified_at?: string
  blog_topic: string
  word_count: number
}

export enum BlogWriteMoreAction {
  APPROVE = 'approve',
  REJECT = 'reject'
}

/**
 * Schema for frozen blog article preview.
 * A blog article preview is a frozen version of a blog article that is
 * suitable for sharing.
 */
export interface IArticlePreview {
  id: string
  created_at: string
  blog_id: string
  title: string
  html_content: string
  author_display_name: string
}

/**
 * Common set of inputs for blog intro, conclusion, and paragraph generation.
 */
export interface BlogInputs {
  title: string
  blog_topic: string
  blog_tone: string
  blog_tone_id?: string
  blog_tone_source?: ToneSource
  seo_region?: CountryDetail
  target_audience: string
  point_of_view: string
  blog_length: LongFormContentLength
  product_details: string
  reference_file_ids: string[] | null
  reference_links: string[] | null
  is_referencing_knowledge_base: boolean
  brand_details: string
  entity_references?: readonly EntityReference[]
  primary_keyword_research_result_id?: string
  outline?: OutlineWithDetails
  keywords: string[]
  seo_keywords: string[]
  is_seo_outline_enabled: boolean
  outline_options: OutlineWithDetails[]
  selected_outline_id: string
  is_web_enrichment_mode_enabled: boolean
  web_enrichment_links_config: BlogWebEnrichmentLinksConfig
}

/**
 * Although this a generic interface, it is currently used only for blog.
 * @todo Move this interface to a more generic location if it is used for
 * other generations.
 */
export interface LanguageConfig {
  input_language: string
  output_language: string
}

/**
 * The input for generating a single paragraph in a blog.
 */
export interface BlogParagraphInputs {
  /**
   * The heading of the paragraph.
   */
  heading: string
  /**
   * Extra details used to generate the paragraph.
   *
   * These include information from content detective or user input.
   */
  extra_details: string
  /**
   * Position of the paragraph in the blog
   */
  paragraph_number: number
  /**
   * Number of words in the paragraph
   */
  paragraph_length: number
  /**
   * Enriched content to include in the paragraph
   */
  enriched_content?: string
}

/**
 * Common payload interface for blog generation request.
 */
export interface BlogGenerationPayload {
  blog_id: string
  language_config: LanguageConfig
  stream: boolean
}

export interface BlogIntroGenerationPayload extends BlogGenerationPayload {
  blog_generation_inputs: BlogInputs
}

export interface BlogParagraphsGenerationPayload extends BlogGenerationPayload {
  blog_generation_inputs: BlogInputs
  paragraphs_to_generate: BlogParagraphInputs[]
}

export interface BlogConclusionGenerationPayload extends BlogGenerationPayload {
  blog_generation_inputs: BlogInputs
  enriched_content_source_list?: string[]
}

export interface BlogOutlineGenerationInputs extends BlogInputs {
  num_outlines: number
}

export interface BlogOutlineGenerationPayload extends BlogGenerationPayload {
  blog_generation_inputs: BlogOutlineGenerationInputs
}

/**
 * Common response interface for blog generation request.
 *
 * @note All three generation types (intro, paragraphs, and conclusion) share
 * the same response interface.
 */
export interface BlogGenerationResponse {
  generation_id: string
  lm_generation_id: string
  content: string
  delta: string
  is_last_stream_response: boolean
}

export interface BlogOutlineGenerationResponse {
  generation_id: string
  outline?: OutlineWithDetails
}

/**
 * Error detail interface for blog streaming response.
 */
interface BlogStreamErrorDetail {
  error: string
  message: string
}

/**
 * Error interface for blog streaming response.
 */
export interface BlogStreamErrorResponse {
  detail?: BlogStreamErrorDetail
}
