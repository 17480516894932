import React, { useCallback } from 'react'

import palette from '../../../atoms/Colors'
import Overlay from '../../atoms/Overlay'
import PingDot from '../../atoms/PingDot'
import Stack from '../../atoms/Stack'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Box, CircularProgress, Link } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { useAsyncFn } from '../../../hooks'

import { handleUserClick } from '../../../api/Analytics'

import { useToolCardContext } from '../../../utils/context/ToolCardContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    mainCard: {
      transition: 'all 0.2s ease-out',
      '&:hover': {
        boxShadow: '0px 4px 8px rgba(145 158 171 / 16%)'
      },
      boxShadow: theme.shadows[4],
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2, 2),
      backgroundColor: theme.palette.common.white,
      width: '100%',
      cursor: 'pointer',
      flexDirection: 'row',
      flexGrow: 1
    }
  })
)

interface Props {
  title: string
  subtitle: string
  /**
   * Handler for when the card is clicked.
   */
  onClick: () => Promise<void>
  /**
   * URL for the page the card links to
   */
  href?: string
  /**
   * When true, the onClick handler will not be called.
   */
  disabled?: boolean
  logoSrc?: string
  /**
   * Show a loading indicator on the card.
   * Also disables the onClick handler.
   */
  loading?: boolean
  /**
   * Whether to render the ping dot on this card to highlight it.
   */
  isPingRendered?: boolean
}

export const BaseToolCard = (props: Props) => {
  const {
    title,
    subtitle,
    onClick: _onClick,
    href,
    logoSrc,
    loading: _loading,
    disabled,
    isPingRendered
  } = props
  const classes = useStyles()

  const { sectionId } = useToolCardContext() || {}

  const _onClickWithTracking = useCallback(async () => {
    const elementId = title.toLowerCase().replace(/ /g, '-')
    handleUserClick(`home-${elementId}`, window.location.pathname, sectionId)
    _onClick()
  }, [_onClick, title, sectionId])

  const [{ loading: loadingClick }, onClick] = useAsyncFn(
    _onClickWithTracking,
    [_onClickWithTracking]
  )
  const loading = _loading || loadingClick

  return (
    <Link
      href={href}
      style={{ textDecoration: 'none', display: 'flex', height: '100%' }}
    >
      <Stack
        className={classes.mainCard}
        spacing={1.5}
        style={{ position: 'relative' }}
        onClick={disabled || loading ? undefined : onClick}
      >
        {isPingRendered && (
          <PingDot size={15} color={palette.primary[300]} top={-8} right={-2} />
        )}
        {loading && (
          <Overlay open>
            <CircularProgress size={20} color="inherit" />
          </Overlay>
        )}
        {!loading && logoSrc && (
          <Box>
            <img
              src={logoSrc}
              alt="tool-icon"
              style={{ width: '40px', height: '40px' }}
            />
          </Box>
        )}
        <Stack textAlign="left" spacing={0.5}>
          <Typography
            variant="paragraph2Bold"
            style={{
              color: palette.gray['900']
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="captionReg"
            style={{
              color: palette.gray['600']
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Link>
  )
}

export type ToolCardMetadata = {
  /**
   * Unique identifier for this tool card.
   * Used to remove duplicates when searching over multiple tool card groups.
   */
  id: string
  /**
   * Search terms that can be used to match this card in the search bar.
   */
  searchTerms?: string[]
}

/**
 * Container for a tool card component and its metadata.
 * This can be used to declaratively define groups of tool cards.
 */
export type ToolCardWithMetadata = {
  card: React.ReactNode
  metadata: ToolCardMetadata
}
