import React from 'react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

interface PlanDescriptionProps {
  desc: string
  isDarkTheme?: boolean
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    greyText: {
      color: palette.gray['500'],
      fontWeight: 500
    },
    darkTheme: {
      color: palette.gray['200'],
      backgroundColor: theme.palette.text.primary
    }
  })
})

export const PlanDescriptionField = (props: PlanDescriptionProps) => {
  const { desc, isDarkTheme } = props

  const classes = useStyles()

  return (
    <Typography
      variant="body2"
      className={clsx(isDarkTheme && classes.darkTheme, classes.greyText)}
    >
      {desc}
    </Typography>
  )
}
