import React from 'react'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Theme
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ClassNameMap } from '@material-ui/styles/withStyles'
import clsx from 'clsx'

import plan_cancellation_icon from '@hypotenuse/common/src/assets/plan-cancellation-icon.svg'
import EnhancedButton from '@hypotenuse/common/src/components/EnhancedButton'

import { PlanChangeType, PremiumPlanTier } from '../../../../utils/Interfaces'

interface PausePlanDialogProps {
  openPauseDialog: boolean
  setOpenPauseDialog: (open: boolean) => void
  orgPlanTierList: PremiumPlanTier[] | undefined
  activePlanTier: PremiumPlanTier | undefined
  selectedPlanTierId: string
  loadingPause: boolean
  pauseDuration: number
  setPauseDuration: (duration: number) => void
  updateUserPlan: (
    selectedPlanTierId: string,
    planChangeType: PlanChangeType
  ) => Promise<void>
  theme: Theme
  classes: ClassNameMap<string>
  pauseUserPlan: () => Promise<void>
  nextBillingDateString: string | undefined
  isWordsFeatureEnabled: boolean
  loadingUpdate: boolean
}

const PausePlanDialog = React.memo(
  (pausePlanDialogProps: PausePlanDialogProps) => {
    const {
      openPauseDialog,
      setOpenPauseDialog,
      orgPlanTierList,
      activePlanTier,
      selectedPlanTierId,
      loadingPause,
      pauseDuration,
      setPauseDuration,
      updateUserPlan,
      theme,
      classes,
      pauseUserPlan,
      nextBillingDateString,
      isWordsFeatureEnabled,
      loadingUpdate
    } = pausePlanDialogProps
    return (
      <Dialog
        open={openPauseDialog}
        onClose={() => {
          if (!loadingPause) {
            setOpenPauseDialog(false)
          }
        }}
        maxWidth="sm"
      >
        {openPauseDialog && orgPlanTierList && activePlanTier && (
          <Box
            m={2}
            width={350}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box display="flex" flexDirection="row" justifyContent="center">
              <img
                src={plan_cancellation_icon}
                alt="plan_cancellation_icon"
                style={{ margin: 'auto' }}
              />
              <IconButton
                onClick={() => setOpenPauseDialog(false)}
                style={{ height: '10%', padding: '5px' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Stack spacing={2} style={{ paddingBottom: theme.spacing(2) }}>
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                Would you like to pause your plan instead?
              </Typography>
              <Typography style={{ textAlign: 'center' }}>
                You’ll not be charged for your subscription during your paused
                duration and you can use your remaining{' '}
                {isWordsFeatureEnabled ? 'words' : 'credits'} until the end of
                your billing cycle{' '}
                {nextBillingDateString && (
                  <>
                    (<b>{nextBillingDateString}</b>)
                  </>
                )}
                .
              </Typography>
              <Typography style={{ textAlign: 'center' }}>
                After your paused duration, your subscription will automatically
                resume.
              </Typography>
            </Stack>
            <Stack spacing={1} style={{ paddingBottom: theme.spacing(2) }}>
              <Typography variant="caption" style={{ textAlign: 'center' }}>
                Choose how long you'd like to pause
              </Typography>
              <Select
                value={pauseDuration}
                variant="outlined"
                inputProps={{
                  'data-testid': 'ad-tone-dropdown'
                }}
                margin="dense"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
                onChange={(event) => {
                  const duration = event.target.value as number
                  setPauseDuration(duration)
                }}
              >
                {[1, 2, 3].map((option) => (
                  <MenuItem
                    key={`type-${option}`}
                    value={option}
                    data-testid={`type-${option}`}
                  >
                    <Box display="flex" flexDirection="row">
                      <Typography>
                        {option} {option > 1 ? 'months' : 'month'}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              width="100%"
              justifyContent="space-between"
            >
              <Box width={1 / 2}>
                <EnhancedButton
                  onClick={() => {
                    updateUserPlan(
                      selectedPlanTierId,
                      PlanChangeType.cancellation
                    )
                  }}
                  variant={'outlined'}
                  color={'primary'}
                  className={clsx(classes.button)}
                  style={{ width: '100%' }}
                  disabled={loadingUpdate}
                  endIcon={
                    loadingUpdate && (
                      <CircularProgress color="inherit" size={14} />
                    )
                  }
                >
                  No, cancel my plan
                </EnhancedButton>
              </Box>
              <Box width={1 / 2}>
                <span>
                  <EnhancedButton
                    id="pause-subscription-button"
                    color="primary"
                    variant={'contained'}
                    onClick={() => pauseUserPlan()}
                    className={clsx(classes.button)}
                    style={{ width: '100%' }}
                    disabled={loadingPause}
                    endIcon={
                      loadingPause && (
                        <CircularProgress color="inherit" size={14} />
                      )
                    }
                  >
                    Pause subscription
                  </EnhancedButton>
                </span>
              </Box>
            </Stack>
          </Box>
        )}
      </Dialog>
    )
  }
)

export default PausePlanDialog
