import React from 'react'

import useSWR from 'swr'

import { palette } from '@hypotenuse/common/src/atoms'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import WhiteButton from '@hypotenuse/common/src/components/atoms/WhiteButton'
import Button from '@material-ui/core/Button'
import { Skeleton } from '@material-ui/lab'

import { handleUserClick } from '@hypotenuse/common/src/api/Analytics'
import { apiUpdateUserServiceConfigs } from '@hypotenuse/common/src/api/User'
import BaseModal from '@hypotenuse/common/src/components/BaseModal'

import { apiBonusWordsForG2Review } from '../../api/NetPromoterScore'

import theme from '../../utils/Theme'

import G2Svg from '../../assets/icons/G2.svg'

interface G2ModalProps {
  open: boolean
  setIsShowingG2Modal: (value: boolean) => void
  setIsShowingG2ReviewDrawerItems: (value: boolean) => void
}

export const G2Modal = (props: G2ModalProps) => {
  const { open, setIsShowingG2Modal, setIsShowingG2ReviewDrawerItems } = props
  const {
    data: bonusWordsForG2Review,
    isLoading: isBonusWordsLoading
  } = useSWR(
    '/net_promoter_score/bonus_words_for_g2_review',
    apiBonusWordsForG2Review
  )
  return (
    <BaseModal
      open={open}
      maxWidth={false}
      PaperProps={{ style: { maxWidth: '660px' } }}
      style={{ borderRadius: theme.spacing(1.5) }}
      padding={0}
      spacing={0}
    >
      <Stack spacing={1.25} padding={3} paddingBottom={0}>
        <img src={G2Svg} alt="G2" style={{ height: '65px', width: '65px' }} />
        <Stack>
          <Typography variant="paragraph1Bold">
            Enjoying Hypotenuse AI?
          </Typography>
          <Typography variant="paragraph1Bold">
            Leave us a review on G2 and we'd like to thank you with{' '}
            {isBonusWordsLoading ? (
              <Skeleton variant="text" width={60} />
            ) : (
              <span style={{ color: palette.primary[500] }}>
                {bonusWordsForG2Review?.toLocaleString()}
              </span>
            )}{' '}
            words!
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="paragraph2Reg">Here’s how:</Typography>
          <ol style={{ marginTop: 0 }}>
            <li>
              <Typography variant="paragraph2Reg">
                Write a review on G2 for Hypotenuse AI
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph2Reg">
                Take a screenshot of the submitted review
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph2Reg">
                Upload it{' '}
                <a
                  style={{
                    color: palette.blue[500],
                    textDecoration: 'underline'
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://form.typeform.com/to/TNlycZSR"
                >
                  here
                </a>
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph2Reg">
                Once it has been verified by G2 and we have validated it, we
                will add{' '}
                {isBonusWordsLoading ? (
                  <Skeleton variant="text" width={60} />
                ) : (
                  <span>{bonusWordsForG2Review?.toLocaleString()}</span>
                )}{' '}
                words into your account
              </Typography>
            </li>
          </ol>
        </Stack>
      </Stack>
      <hr
        style={{
          border: 'none',
          borderTop: `1px solid ${palette.gray[300]}`,
          width: '100%',
          padding: 0
        }}
      />
      <Stack padding={theme.spacing(1.5, 3, 3)}>
        <Stack flexDirection="row" spacing={1.2} justifyContent="end">
          <WhiteButton
            variant="contained"
            color="secondary"
            style={{
              padding: `${theme.spacing(0.7)}px ${theme.spacing(1.7)}px`,
              alignSelf: 'center',
              whiteSpace: 'nowrap',
              borderRadius: theme.spacing(1),
              borderColor: palette.gray[200],
              border: `1px solid ${palette.gray[300]}`
            }}
            onClick={async () => {
              await apiUpdateUserServiceConfigs(
                'is_showing_g2_review_drawer_items',
                true
              )
              setIsShowingG2Modal(false)
              setIsShowingG2ReviewDrawerItems(true)
            }}
          >
            Maybe later
          </WhiteButton>
          <a
            href="https://www.g2.com/products/hypotenuse-ai/reviews#reviews"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button
              style={{
                backgroundColor: palette.primary[600],
                color: 'white',
                padding: `${theme.spacing(0.8)}px ${theme.spacing(1.7)}px`
              }}
              onClick={async () => {
                await apiUpdateUserServiceConfigs(
                  'is_showing_g2_review_drawer_items',
                  true
                )
                setIsShowingG2ReviewDrawerItems(true)
                await handleUserClick(
                  'g2-review-modal',
                  window.location.pathname
                )
              }}
            >
              Leave a G2 review
            </Button>
          </a>
        </Stack>
      </Stack>
    </BaseModal>
  )
}
