import React, { useCallback, useEffect } from 'react'

import { Typography, palette } from '@hypotenuse/common/src/atoms'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Box,
  Tab,
  Tabs,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

import { handleUserClick } from '@hypotenuse/common/src/api/Analytics'
import { PlanCategoryTab } from '@hypotenuse/common/src/utils/Interfaces'

import { fetchDefaultPlanCategoryTab } from '../../../../../api/Pricing'

interface PlanCategoryTabSelectorProps {
  currentPlanCategoryTab: PlanCategoryTab
  setCurrentPlanCategoryTab: (tab: PlanCategoryTab) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      top: 0,
      height: 'auto',
      backgroundColor: '#fff',
      border: `1px solid ${palette.gray[300]}`,
      borderBottom: '1px solid white',
      borderRadius: theme.spacing(2, 2, 0, 0),
      zIndex: -1
    },
    flexContainer: {
      position: 'relative',
      height: theme.spacing(10)
    },
    root: {
      padding: 0,
      opacity: 1
    }
  })
)
export const PlanCategoryTabSelector = (
  props: PlanCategoryTabSelectorProps
) => {
  const { currentPlanCategoryTab, setCurrentPlanCategoryTab } = props

  useEffect(() => {
    const setDefaultPlanCategoryTab = async () => {
      const defaultPlanCategoryTab = await fetchDefaultPlanCategoryTab()
      setCurrentPlanCategoryTab(defaultPlanCategoryTab)
    }
    setDefaultPlanCategoryTab()
  }, [setCurrentPlanCategoryTab])

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: PlanCategoryTab) => {
      setCurrentPlanCategoryTab(
        newValue === PlanCategoryTab.seo
          ? PlanCategoryTab.seo
          : PlanCategoryTab.eCommerce
      )
      handleUserClick(
        newValue === PlanCategoryTab.seo
          ? 'pricing-seo-tab'
          : 'pricing-ecomm-tab',
        window.location.pathname
      )
    },
    [setCurrentPlanCategoryTab]
  )
  const theme = useTheme()
  const classes = useStyles()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Stack
      flexDirection="row"
      justifyContent="center"
      marginBottom="-1px"
      // Hacky fix to make sure the bottom border of the tab is not visible
      zIndex={10}
    >
      <Box width="96%">
        <Tabs
          value={currentPlanCategoryTab}
          onChange={handleTabChange}
          variant="fullWidth"
          classes={{
            indicator: classes.indicator,
            flexContainer: classes.flexContainer
          }}
          indicatorColor="primary"
        >
          <Tab
            label={
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={3}
                padding={theme.spacing(2.6, 3, 2)}
                width="100%"
              >
                <span
                  style={{
                    fontSize: isMediumScreen
                      ? theme.spacing(5.25)
                      : theme.spacing(3)
                  }}
                >
                  ✍️
                </span>
                <Stack style={{ textAlign: 'left' }}>
                  <Typography
                    variant={isMediumScreen ? 'heading3' : 'paragraph1Bold'}
                    style={{
                      color: palette.primary[600],
                      lineHeight: !isMediumScreen ? 1 : 'normal'
                    }}
                  >
                    Marketing and SEO content
                  </Typography>
                  {isLargeScreen && (
                    <Typography
                      variant="paragraph1Reg"
                      style={{ color: palette.gray[500] }}
                    >
                      SEO blog articles, website content, social media & more
                    </Typography>
                  )}
                </Stack>
              </Stack>
            }
            value={PlanCategoryTab.seo}
            classes={{
              root: classes.root
            }}
          />
          <Tab
            label={
              <Stack
                flexDirection="row"
                alignItems="center"
                spacing={3}
                padding={theme.spacing(2.5, 3, 2)}
                width="100%"
              >
                <span
                  style={{
                    fontSize: isMediumScreen
                      ? theme.spacing(5.25)
                      : theme.spacing(3)
                  }}
                >
                  🛒
                </span>
                <Stack style={{ textAlign: 'left' }}>
                  <Typography
                    variant={isMediumScreen ? 'heading3' : 'paragraph1Bold'}
                    style={{
                      color: palette.primary[600],
                      lineHeight: !isMediumScreen ? 1 : 'normal'
                    }}
                  >
                    Ecommerce content{' '}
                  </Typography>
                  {isLargeScreen && (
                    <Typography
                      variant="paragraph1Reg"
                      style={{ color: palette.gray[500] }}
                    >
                      Product descriptions, bulk workflows, digital shelves &
                      more
                    </Typography>
                  )}
                </Stack>
              </Stack>
            }
            value={PlanCategoryTab.eCommerce}
            classes={{
              root: classes.root
            }}
          />
        </Tabs>
      </Box>
    </Stack>
  )
}
