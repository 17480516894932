import React, { useMemo } from 'react'

import * as Sentry from '@sentry/react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Box, FormControl, Slider, Tooltip } from '@material-ui/core'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import clsx from 'clsx'

import { useUserContext } from '@hypotenuse/common/src/utils/context/UserContext'

import { isWordsEnabled } from '../../../../../utils/Functions'
import { PremiumPlanTier } from '../../../../utils/Interfaces'

// Note: very similar to the old PlanSelectorDropdown since we use discrete
// values rather than a continuous slider (sad real analyst noises)
interface PlanSelectorSliderProps {
  /** List of tiers for a given PlanType */
  planTiers: PremiumPlanTier[]
  /** Current PlanTier selected by user, state is coupled with Slider value. */
  currentPlanTier: PremiumPlanTier
  /** Sets currentPlanTier state which causes self and sibling components to re-render*/
  setCurrentPlanTier: (val: PremiumPlanTier) => void
  /** If true, the credits will be displayed in light/white text. */
  lightText?: boolean
  /**
   * Whether the plan is an unlimited plan.
   */
  isUnlimitedPlan?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      fontWeight: 400
    },
    lightText: {
      color: palette.gray['100']
    },
    darkText: {
      color: palette.gray['500']
    },
    // slider related styling below
    sliderRoot: {
      width: '90%',
      alignSelf: 'center'
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12
    },
    track: {
      height: 8,
      borderRadius: 4
    },
    rail: {
      height: 8,
      borderRadius: 4
    },
    mark: {
      backgroundColor: 'inherit'
    }
  })
)

export const PlanSelectorSlider = React.memo(
  (props: PlanSelectorSliderProps) => {
    const {
      planTiers,
      currentPlanTier,
      setCurrentPlanTier,
      lightText,
      isUnlimitedPlan
    } = props
    const classes = useStyles()
    const theme = useTheme()
    const { user } = useUserContext()
    const isWordsFeatureEnabled = isWordsEnabled(user)
    const planMonthlyChipAmount = currentPlanTier.plan_monthly_chip_amount

    const toolTipMessage = useMemo(() => {
      if (!isWordsFeatureEnabled) {
        return (
          <>
            Credits let you generate various content types, which cost different
            credit amounts.
          </>
        )
      } else if (!planMonthlyChipAmount) {
        return (
          <>Use words to generate or edit content with our suite of AI tools.</>
        )
      } else if (
        currentPlanTier.estimated_blog_post_amount &&
        currentPlanTier.estimated_product_description_amount
      ) {
        return (
          <p>
            {planMonthlyChipAmount.toLocaleString('en-US')} words is roughly{' '}
            {currentPlanTier.estimated_blog_post_amount.toLocaleString('en-US')}{' '}
            medium blog posts or{' '}
            {currentPlanTier.estimated_product_description_amount.toLocaleString(
              'en-US'
            )}{' '}
            short product descriptions.
            <a
              href="https://help.hypotenuse.ai/article/24-your-guide-to-words"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Learn more here.
            </a>
          </p>
        )
      }

      return (
        <>Use words to generate or edit content with our suite of AI tools.</>
      )
    }, [
      currentPlanTier.estimated_blog_post_amount,
      currentPlanTier.estimated_product_description_amount,
      isWordsFeatureEnabled,
      planMonthlyChipAmount
    ])

    const helpIconWithToolTip = (
      <Tooltip
        title={
          <Typography
            variant="body2"
            style={{
              fontWeight: theme.typography.fontWeightBold,
              textAlign: 'center'
            }}
          >
            {toolTipMessage}
          </Typography>
        }
        arrow
        placement="bottom"
        // make tooltip responsive on mobile
        enterTouchDelay={10}
        interactive
      >
        <HelpOutlineIcon
          color="disabled"
          style={{
            marginLeft: '0.25rem',
            alignSelf: 'center',
            cursor: 'pointer',
            fontSize: '1rem'
          }}
        />
      </Tooltip>
    )
    const creditsPerMonth = (
      <div
        style={{
          marginTop: '0.6rem',
          display: 'inline-flex'
        }}
      >
        <Typography
          className={clsx(
            classes.text,
            lightText ? classes.lightText : classes.darkText
          )}
        >
          <b>{currentPlanTier.plan_monthly_credit_amount} </b>
          credits/month
        </Typography>
        {helpIconWithToolTip}
      </div>
    )

    const wordsPerMonth = (
      <div
        style={{
          marginTop: '0.6rem',
          display: 'inline-flex'
        }}
      >
        <Typography
          className={clsx(
            classes.text,
            lightText ? classes.lightText : classes.darkText
          )}
        >
          <b>
            {!isUnlimitedPlan
              ? planMonthlyChipAmount?.toLocaleString('en-US')
              : 'Unlimited'}{' '}
          </b>
          words/month{isUnlimitedPlan && ' *'}
        </Typography>
        {!isUnlimitedPlan && helpIconWithToolTip}
      </div>
    )

    // used to get equal intervals between the discrete steps on the slider
    // Note, planTiers should be ordered by price and credits/month already.
    const marks = planTiers.map((_, i) => ({
      value: i
    }))

    // This is used to hide the slider on the Individual plan, which has only 1 possible tier.
    const multiTier = planTiers.length > 1

    const multiTierView = (
      <>
        <Slider
          // use `classes` instead of `className` to access deeper elements
          classes={{
            root: classes.sliderRoot,
            rail: classes.rail,
            track: classes.track,
            thumb: classes.thumb,
            mark: classes.mark
          }}
          data-testid={`pricingPlanCard_slider_${currentPlanTier.plan_type}`}
          defaultValue={planTiers.indexOf(currentPlanTier)}
          step={null}
          marks={marks}
          min={0}
          max={marks.length - 1}
          onChange={(_, newValue) => {
            if (typeof newValue === 'number') {
              setCurrentPlanTier(planTiers[newValue])
            } else {
              const err = new Error(`Expected number, got ${typeof newValue}`)
              console.error(err)
              Sentry.captureException(err)
            }
          }}
        />
        <div
          style={{
            justifyContent: 'center'
          }}
        >
          {isWordsFeatureEnabled ? wordsPerMonth : creditsPerMonth}
        </div>
      </>
    )

    const singleTierView = (
      <div
        className={clsx(
          classes.text,
          lightText ? classes.lightText : classes.darkText
        )}
      >
        <div
          style={{
            justifyContent: 'left'
          }}
        >
          {isWordsFeatureEnabled ? wordsPerMonth : creditsPerMonth}
          <br />
        </div>
      </div>
    )

    return (
      <Box>
        <FormControl size="medium" fullWidth>
          {multiTier ? multiTierView : singleTierView}
        </FormControl>
      </Box>
    )
  }
)
