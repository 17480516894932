import React from 'react'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import { TypographyProps } from '@material-ui/core'

interface Props extends TypographyProps {
  /**
   * Override for the component to use as the root.
   * This prop already exists on the Typography component, but I couldn't
   * figure out how to get this working 'automatically'.
   */
  component?: React.ElementType
}

/**
 * Title component for BaseModal.
 * This is a Typography component with some default prop overrides.
 */
export const ModalTitle: React.FC<Props> = React.memo((props) => {
  return <Typography {...props} />
})

ModalTitle.defaultProps = {
  variant: 'h6',
  component: 'h2'
}
