import { useCallback, useEffect, useRef } from 'react'

/**
 * Lifecycle hook providing ability to check component's mount state
 *
 * Usage: {@link https://github.com/streamich/react-use/blob/90e72a5340460816e2159b2c461254661b00e1d3/docs/useMountedState.md}
 *
 * Referenced from {@link https://github.com/streamich/react-use/blob/90e72a5340460816e2159b2c461254661b00e1d3/src/useMountedState.ts}
 */
export default function useMountedState(): () => boolean {
  const mountedRef = useRef<boolean>(false)
  const get = useCallback(() => mountedRef.current, [])

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  return get
}
