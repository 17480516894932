import axios from 'axios'
import Session from 'supertokens-auth-react/recipe/session'

import { handleDates } from '@hypotenuse/common/src/utils/Functions'

import { SERVER } from './Constants'

export const apiClient = axios.create({
  baseURL: SERVER,
  // Send browser cookies to the server
  withCredentials: true
})

/**
 * This interceptor casts valid date strings into the `Date` class.
 */
apiClient.interceptors.response.use(
  async (originalResponse) => {
    if (originalResponse.request.responseURL.includes('/refresh-session')) {
      await Session.attemptRefreshingSession()
      const config = originalResponse.config
      return new Promise((resolve) => {
        resolve(axios(config))
      })
    }
    handleDates(originalResponse.data)
    return originalResponse
  },
  async (error) => {
    if (
      error?.response?.status === 405 &&
      error?.response?.request.responseURL.includes('/refresh-session')
    ) {
      await Session.attemptRefreshingSession()
      const config = error.config
      return new Promise((resolve) => {
        resolve(axios(config))
      })
    }
    return error
  }
)
