import React from 'react'

import Carousel from 'react-material-ui-carousel'
import { Image } from 'semantic-ui-react'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Box, useMediaQuery, useTheme } from '@material-ui/core'

export const SocialProof = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // Get the social proof from the DB and render it in the form of slider

  const mockSocialProof = [
    {
      text:
        'This app is a game changer. Really impressed with the UI/UX,' +
        ' and I would absolutely recommend anyone with creative copy needs to give them a chance.',
      brand: '/assets/pricing/paneros.png'
    },
    {
      text:
        'Easy to use & saves you so much time to come up with content that allows your brand' +
        ' to stand out from the rest. The team also offers great support and a quick turnaround time.',
      brand: '/assets/pricing/tessellateco_logo.png'
    },
    {
      text:
        'I really like how simple it is to use, with clear quick tips and intuitive UI. ' +
        'This would save our business loads of brainwaves in generating creative content.',
      brand: '/assets/pricing/takachya.png'
    }
  ]
  return (
    <div>
      <Carousel
        autoPlay={true}
        animation="slide"
        indicators={false}
        interval={8000}
        navButtonsAlwaysVisible={!isMobile}
      >
        {mockSocialProof.map((data, index) => {
          return (
            <Box
              key={`socialProofCard_${index}`}
              py={5}
              px={isMobile ? 0 : 10}
              borderRadius={10}
              maxWidth={'100%'}
            >
              <Box pt={3} pb={9}>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    lineHeight: '2.5rem',
                    textAlign: 'left'
                  }}
                >
                  {data.text}
                </Typography>
              </Box>
              <div>
                <Image left src={data.brand} style={{ maxWidth: '200px' }} />
              </div>
            </Box>
          )
        })}
      </Carousel>
    </div>
  )
}
