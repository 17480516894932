import React from 'react'

/**
 * This is what's returned when you call `useCatalogueTemplateContext()`
 */
export interface ICatalogueTemplateContext {
  rewriterTemplateId?: string
}

const CatalogueTemplateContext = React.createContext<
  ICatalogueTemplateContext | undefined
>(undefined)

export const CatalogueTemplateProvider = CatalogueTemplateContext.Provider

export const useCatalogueTemplateContext = ():
  | ICatalogueTemplateContext
  | undefined => {
  // Allow returning undefined because we might not always have a
  // context provider, such as in tests
  const context = React.useContext(CatalogueTemplateContext)
  return context
}
