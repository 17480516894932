import { apiClient } from '../utils/ApiClient'

import type {
  DocumentQuery,
  DocumentWithWordCount,
  IDocument
} from '../components/documents/types'
import { EntityReferenceItem } from '../components/entityReference/types'

/**
 * Create a new document
 * @param documentType - the type of document
 * @param linkedItemId - the id of the linked item
 * @param folderId - the id of the folder
 * @param title - the title of the document
 * @param isHidden - whether the document is hidden, which is used to hide unedited documents/adtext. Defaults to false
 */
export const apiCreateDocument = async ({
  documentType,
  linkedItemId,
  folderId,
  folderType,
  title,
  isHidden = false,
  referencedItems,
  isPrivate,
  topicRecommendationId,
  topicRecommendationTitle,
  recommendationSource
}: {
  documentType?: string
  linkedItemId?: string
  folderId?: string
  folderType?: string
  title?: string
  isHidden?: boolean
  referencedItems?: EntityReferenceItem[]
  isPrivate?: boolean
  topicRecommendationId?: string
  topicRecommendationTitle?: string
  recommendationSource?: string
}) => {
  const response = await apiClient.post<IDocument>(`/document`, {
    document_type: documentType,
    linked_item_id: linkedItemId,
    folder_id: folderId,
    folder_type: folderType,
    title: title,
    is_hidden: isHidden,
    referenced_items: referencedItems,
    is_private: isPrivate,
    topic_recommendation_id: topicRecommendationId,
    topic_recommendation_title: topicRecommendationTitle,
    recommendation_source: recommendationSource
  })
  return response.data
}

/**
 * Get documents count.
 * If a folder id is provided, it will return the count of documents in that folder,
 */
export const apiGetDocumentsCount = async ({
  folderId,
  folderType,
  includeDeleted = false,
  includeHidden = false,
  includeNested = false
}: {
  folderId?: string
  folderType?: string
  includeDeleted?: boolean
  includeHidden?: boolean
  includeNested?: boolean
}) => {
  const url = '/document/count'
  const response = await apiClient.get<number>(url, {
    params: {
      folder_id: folderId,
      folder_type: folderType,
      include_deleted: includeDeleted,
      include_hidden: includeHidden,
      include_nested: includeNested
    }
  })
  return response.data
}

/**
 * Get a document by ID
 */
export const apiGetDocument = async (id: string) => {
  return await apiClient
    .get<IDocument>(`/document/${id}`)
    .then((resp) => resp.data)
}

/**
 * Get a document by linked item ID
 */
export const apiGetDocumentByLinkedItemId = async (id: string) => {
  return await apiClient
    .get<IDocument>(`/document/query-linked-item/${id}`)
    .then((resp) => resp.data)
}

/**
 * Get the path to the document with the given ID
 * Returns a list of documents leading to the document with the given ID
 */
export const apiGetDocumentPath = async (documentId?: string) => {
  const response = await apiClient.get<IDocument[]>(`/document/document-path`, {
    params: {
      document_id: documentId
    }
  })
  return response.data
}
/**
 * Update a document title by ID
 * @param id
 * @param title
 */
export const apiPutDocumentTitle = async (id: string, title: string) => {
  const response = await apiClient.put<IDocument>(`/document/${id}/title`, {
    title: title
  })
  return response.data
}

/**
 * Update a document by moving the document into a folder
 * @param id - document id
 * @param folderId - the folder id you want to put the document into
 */
export const apiPutDocumentFolder = async (id: string, folderId: string) => {
  const response = await apiClient.put<IDocument>(
    `/document/${id}/parent-folder`,
    {
      folder_id: folderId
    }
  )
  return response.data
}

/**
 * Update a document is_private by ID
 * @param id
 * @param is_private
 */
export const apiUpdateDocumentPrivacy = async (
  id: string,
  isPrivate: boolean
) => {
  const response = await apiClient.put<IDocument>(
    `/document/${id}/is-private`,
    {
      is_private: isPrivate
    }
  )
  return response.data
}
/**
 * Update a document by placing the document into the root folder
 * @param id - document id
 */
export const apiPutDocumentRootFolder = async (id: string) => {
  const response = await apiClient.put<IDocument>(`/document/${id}/root-folder`)
  return response.data
}

/**
 * Update a document's starred status by ID
 * @param id
 * @param starred
 */
export const apiStarDocument = async (id: string, starred: boolean) => {
  const response = await apiClient.put<IDocument>(`/document/${id}/star`, {
    starred
  })
  return response.data
}

/**
 * Replace a document's content
 */
export const apiPutDocumentContent = async (id: string, content: string) => {
  const response = await apiClient.put<IDocument>(`/document/${id}/content`, {
    content: content
  })
  return response.data
}

/**
 * Sets document ID that is persisted in HypoChat
 * @param id Document ID
 */
export const apiPostHypoChatDocumentId = async (id: string) => {
  const response = await apiClient.post(`/document/hypochat`, {
    document_id: id
  })
  return response.data
}

/**
 * Gets the documents given an ElasticSearch query
 * @param query - DocumentQuery object, which contains the query parameters
 */
export const apiGetDocuments = async (query: DocumentQuery) => {
  const response = await apiClient.post<DocumentWithWordCount[]>(
    `/document/browse`,
    query
  )
  return response.data
}

/**
 * Marks the document for soft deletion
 * @param id Document ID
 */
export const apiDeleteDocument = async (id: string) => {
  await apiClient.delete(`/document/${id}`)
}

/**
 * Unhides a document
 */
export const apiUnhideDocument = async (id: string) => {
  await apiClient.put<IDocument>(`/document/${id}/unhide`)
}
