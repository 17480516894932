import React from 'react'

import palette from '../../atoms/Colors'
import Stack from '../atoms/Stack'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Grid, createStyles, makeStyles } from '@material-ui/core'

import { ToolCardProvider } from '../../utils/context/ToolCardContext'

import { IToolCardGroup } from './CardGroups'

const useStyles = makeStyles((theme) =>
  createStyles({
    subHeader: {
      color: palette.gray[500],
      marginTop: theme.spacing(0.35),
      marginBottom: theme.spacing(1.55)
    }
  })
)

interface ToolCardGroupProps extends IToolCardGroup {}

export const ToolCardGroup = React.memo((props: ToolCardGroupProps) => {
  const { id, title, subTitle, cards } = props
  const classes = useStyles()

  const sectionId = id.toLowerCase().replace(/& /g, '').replace(/ /g, '-')

  return (
    <ToolCardProvider value={{ sectionId: sectionId }}>
      <Stack id={id}>
        <Typography variant="heading3">{title}</Typography>
        <Typography variant="body2" className={classes.subHeader}>
          {subTitle}
        </Typography>
        <ToolCardGroupBody key={title} cards={cards.map(({ card }) => card)} />
      </Stack>
    </ToolCardProvider>
  )
})

interface ToolCardGroupBodyProps {
  cards: readonly React.ReactNode[]
}

export const ToolCardGroupBody = React.memo((props: ToolCardGroupBodyProps) => {
  const { cards } = props
  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid item key={i} xs={12} sm={6} md={6}>
          {card}
        </Grid>
      ))}
    </Grid>
  )
})
