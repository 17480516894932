import React, { useEffect } from 'react'

import { SpinnerOnDimmer } from '@hypotenuse/common/src/components/Loaders'

import { usePremiumAuth } from '../../../hooks/usePremiumAuth'

/**
 * As soon as the user lands on this page, they are logged out.
 */
const LogoutPage = () => {
  const { logout } = usePremiumAuth()
  useEffect(() => {
    void logout()
  }, [logout])

  return <SpinnerOnDimmer />
}

export default LogoutPage
