/**
 * Stripe Decline Codes: https://stripe.com/docs/declines/codes
 * Only catching a few error codes, rest will map to a default message
 */
export enum DeclineCode {
  insufficient_funds = 'insufficient_funds',
  withdrawal_count_limit_exceeded = 'withdrawal_count_limit_exceeded',
  invalid_amount = 'invalid_amount',
  card_not_supported = 'card_not_supported',
  expired_card = 'expired_card',
  incorrect_cvc = 'incorrect_cvc',
  invalid_cvc = 'invalid_cvc',
  incorrect_number = 'incorrect_number',
  incorrect_pin = 'incorrect_pin',
  incorrect_zip = 'incorrect_zip'
}

/**
 * Final warning messages are shown when payment is due for more than 28 days
 */
export const finalWarningMessages: {
  [key in DeclineCode | 'default']: string
} = {
  [DeclineCode.insufficient_funds]:
    'You have been restricted to this page as your card has insufficient funds.\nTo regain access to your account, please reach out to ',
  [DeclineCode.withdrawal_count_limit_exceeded]:
    'You have been restricted to this page as your card has insufficient funds.\nTo regain access to your account, please reach out to ',
  [DeclineCode.invalid_amount]:
    "You have been restricted to this page as the payment amount we're trying to charge for your subscription exceeds the amount that's allowed on your card.\nTo regain access to your account, please reach out to ",
  [DeclineCode.card_not_supported]:
    "You have been restricted to this page as your card doesn't support this type of payment, which is a recurring payment.\nTo regain access to your account, please reach out to ",
  [DeclineCode.expired_card]:
    'You have been restricted to this page as your card has expired.\nTo regain access to your account, please reach out to ',
  [DeclineCode.incorrect_cvc]:
    'You have been restricted to this page as your CVC was incorrect.\nTo regain access to your account, please reach out to ',
  [DeclineCode.invalid_cvc]:
    'You have been restricted to this page as your CVC was invalid.\nTo regain access to your account, please reach out to ',
  [DeclineCode.incorrect_number]:
    'You have been restricted to this page as your card number was incorrect.\nTo regain access to your account, please reach out to ',
  [DeclineCode.incorrect_pin]:
    'You have been restricted to this page as your card PIN was incorrect.\nTo regain access to your account, please reach out to ',
  [DeclineCode.incorrect_zip]:
    'You have been restricted to this page as your card ZIP was incorrect.\nTo regain access to your account, please reach out to ',
  default:
    'You have been restricted to this page as your card payment failed.\nTo regain access to your account, contact your card issuer for more information, or reach out to '
}

/**
 * Final warning messages are shown when payment is due for less than 28 days
 */
export const warningMessages: {
  [key in DeclineCode | 'default']: string
} = {
  [DeclineCode.insufficient_funds]:
    "To avoid losing access to your account, use an alternative card or add funds to your card, and we'll retry the payment for this invoice.",
  [DeclineCode.withdrawal_count_limit_exceeded]:
    "To avoid losing access to your account, use an alternative card or add funds to your card, and we'll retry the payment for this invoice.",
  [DeclineCode.invalid_amount]:
    "The payment amount we're trying to charge for your subscription exceeds the amount that's allowed on your card. \n \n To avoid losing access to your account, contact your card issuer for more information, or use an alternative card and we'll retry the payment for this invoice.",
  [DeclineCode.card_not_supported]:
    "Your card doesn't support this type of payment, which is a recurring payment. \n \n To avoid losing access to your account, contact your card issuer for more information, or use an alternative card and we'll retry the payment for this invoice.",
  [DeclineCode.expired_card]:
    "Your card has expired. \n \n To avoid losing access to your account, use an alternative card and we'll retry the payment.",
  [DeclineCode.incorrect_cvc]:
    "Your card CVC was incorrect. \n \n To avoid losing access to your account, try again with the correct card details or use an alternative card, and we'll retry the payment for this invoice.",
  [DeclineCode.invalid_cvc]:
    "Your card CVC was invalid. \n \n To avoid losing access to your account, try again with the correct card details or use an alternative card, and we'll retry the payment for this invoice.",
  [DeclineCode.incorrect_number]:
    "Your card number was incorrect. \n \n To avoid losing access to your account, try again with the correct card details or use an alternative card, and we'll retry the payment for this invoice.",
  [DeclineCode.incorrect_pin]:
    "Your card PIN was incorrect. \n \n To avoid losing access to your account, try again with the correct card details or use an alternative card, and we'll retry the payment for this invoice.",
  [DeclineCode.incorrect_zip]:
    "Your card ZIP was incorrect. \n \n To avoid losing access to your account, try again with the correct card details or use an alternative card, and we'll retry the payment for this invoice.",
  default:
    "Your card payment failed. \n \n To avoid losing access to your account, contact your card issuer for more information, or use an alternative card and we'll retry the payment for this invoice."
}
