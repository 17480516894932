import React from 'react'

import { colord } from 'colord'

import { Button, ButtonProps } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '&:hover': {
        // Slightly darker background than normal
        backgroundColor: colord(theme.palette.background.paper)
          .darken(0.05)
          .toRgbString()
      },
      '&:disabled': {
        color: theme.palette.text.disabled
      }
    }
  })
)

const WhiteButton: React.FC<ButtonProps> = React.memo(
  React.forwardRef((props, ref) => {
    const { classes: propsClasses, ...restProps } = props
    const classes = useStyles()
    const finalClasses = {
      ...propsClasses,
      root: clsx(classes.root, propsClasses?.root)
    }
    return <Button classes={finalClasses} ref={ref} {...restProps} />
  })
)

export default WhiteButton
