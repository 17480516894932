import { handlePremiumCustomerEnterprisePlanContactUs } from '../api/Analytics'

import { ProductImportSource } from '../interfaces/Products'

import { CONTACT_US_LINK } from '../utils/Constants'

export interface ContactUsProps {
  /**
   * Source element from which the contact us event stems from. Used for tracking.
   */
  elementId: string
  /**
   * User ID of the user who triggered the contact us event.
   */
  userId: string
  /**
   * The current ID of the plan list the user uses.
   */
  planListId: string
  /**
   * The plan ID on which the card is based if the trigger is from a pricing plan card.
   */
  planIdOnCard?: string
  /**
   * The window location of the element triggering the contact us event. Used for tracking.
   *
   * If left unspecified, defaults to `window.location.pathname`.
   */
  pathname?: string
  /**
   * The base URL of the form to contact us.
   */
  contactUsLink?: string
  /**
   * Source of the bulk import of products, if any.
   */
  source?: ProductImportSource
}

/**
 * Handles the event where a user decides to contact us.
 *
 * The event is tracked via analytics.
 * The user is then redirected accordingly to the relevant link for further action.
 *
 * @param props Set of props required to handle the contact us event.
 */
export const handleContactUs = (props: ContactUsProps) => {
  // Prop destructuring
  const {
    elementId,
    userId,
    planListId,
    planIdOnCard,
    pathname = window.location.pathname,
    contactUsLink = CONTACT_US_LINK,
    source
  } = props

  // Handle event analytics tracking
  handlePremiumCustomerEnterprisePlanContactUs({
    elementId: elementId,
    pathname: pathname,
    integrationOption: source,
    planIdOnCard: planIdOnCard
  })

  // Handle redirection
  const parsedElementId = source ? `${elementId}-${source}` : elementId
  const link = `${contactUsLink}?planlistid=${encodeURIComponent(
    planListId
  )}&elementid=${encodeURIComponent(
    parsedElementId
  )}&pathname=${encodeURIComponent(pathname)}&userid=${encodeURIComponent(
    userId
  )}`
  window.open(link, '_blank')
}
