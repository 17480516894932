import React from 'react'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Box, BoxProps, TypographyProps } from '@material-ui/core'

type LabelFlagVariant = 'regular' | 'rectangular'
interface LabelFlagProps extends BoxProps {
  content: React.ReactNode
  variant: LabelFlagVariant
  leadingIcon?: React.ReactNode
  icon?: React.ReactNode
  typographyProps?: TypographyProps
}

const LabelFlag: React.FC<LabelFlagProps> = React.memo((props) => {
  const {
    content,
    variant,
    leadingIcon,
    icon,
    typographyProps,
    ...restProps
  } = props
  return (
    <Box
      borderRadius={variant === 'rectangular' ? '4px' : '16px'}
      height="min-content"
      width="min-content"
      display="inline-flex"
      flexDirection="row"
      padding={variant === 'rectangular' ? '0px 6px' : '0px 8px'}
      {...restProps}
    >
      {leadingIcon}
      <Typography
        variant={variant === 'rectangular' ? 'overline' : 'subtitle2'}
        // We want to allow overriding the variant, but want to apply the default styles unless overridden explicitly.
        // This is why the spread is after the variant prop but before the style prop.
        {...typographyProps}
        style={{
          height: 'min-content',
          whiteSpace: 'nowrap',
          lineHeight: '2',
          ...typographyProps?.style
        }}
      >
        {content}
      </Typography>
      {icon && (
        <Box ml={1} alignSelf="center">
          {icon}
        </Box>
      )}
    </Box>
  )
})
export default LabelFlag
