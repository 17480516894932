import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'
import { LineData, TimePeriod } from '@hypotenuse/common/src/utils/Interfaces'

/**
 * @type {WordsUsageInResponse}
 * @property {number} user_words_used - The number of words used by the user in the current organization.
 * @property {number} user_words_usage_limit - The number of words available for the user in the current organization. This is set by the organization admin.
 * @property {number} org_words_used - The number of words used by the organization in total. This is the sum of all the words used by all the users in the organization.
 * @property {number} org_words_usage_limit - The number of words available for the organization.
 * @property {number} grant_org_words_used - The number of bonus words used by the organization in total. This is the sum of all the words used by all the users in the organization.
 * @property {number} grant_org_words_usage_limit - The number of bonus words available for the organization.
 *
 * This is the response of the words usage endpoint for a user.
 * It contains the number of words used by an user in the current organization.
 */
export interface WordsUsageInResponse {
  user_words_used: number
  user_words_usage_limit: number | null
  org_words_used: number
  org_words_usage_limit: number | null
  grant_org_words_used: number
  grant_org_words_usage_limit: number | null
}

/**
 * @type {WordsHistoryLineChartData}
 * @property {string} xLabel - The label for the x-axis of the line chart. It is the date/month of the word usage.
 * @property {string} yLabel - The label for the y-axis of the line chart. It is the number of words used in that period.
 * @property {LineData[]} lines - The data for the line chart. It contains the data for each point in the line chart.
 *
 * This is the response of the words usage history endpoint for an organization.
 * It contains the word usage history of an organization.
 */
export interface WordsHistoryLineChartData {
  xLabel: string
  yLabel: string
  lines: LineData[]
}

/**
 * @returns {Promise<WordsUsageInResponse>}
 * This function returns the number of words used by a user in an organization.
 */
export const apiGetWordUsage = async (
  organizationId: string,
  userId: string
): Promise<WordsUsageInResponse> => {
  const response = await apiClient.get<WordsUsageInResponse>(
    `/organization/${organizationId}/user/${userId}/word-usage`
  )
  return response.data
}

/**
 * @param {string} organizationId
 * @param {string} userId
 * @returns {Promise<number>}
 *
 * Get the lifetime word usage of a user in an organization
 */
export const apiGetLifeTimeWordUsage = async (
  organizationId: string,
  userId: string
): Promise<number> => {
  const response = await apiClient.get<number>(
    `/organization/${organizationId}/user/${userId}/lifetime-word-usage`
  )
  return response.data
}

/**
 * @param {string} organizationName
 * @param {TimePeriod} timePeriod
 * @returns {Promise<WordsHistoryLineChartData>}
 * This function returns word usage history of an organization.
 * The data is returned in a format that can be used by the LineChart component.
 */
export const apiGetOrganizationWordUsageHistory = async (
  organizationName: string,
  timePeriod: TimePeriod
): Promise<WordsHistoryLineChartData> => {
  const resp = await apiClient.get<WordsHistoryLineChartData>(
    `/organization/${organizationName}/word-usage-history`,
    {
      params: {
        organization_id: organizationName,
        time_period: timePeriod
      }
    }
  )
  return resp.data
}
