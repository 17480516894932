import React, { useCallback, useMemo } from 'react'

import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Button, CircularProgress, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { handleUserClick } from '@hypotenuse/common/src/api/Analytics'
import piggy_bank_icon from '@hypotenuse/common/src/assets/piggy-bank.svg'
import BaseModal, {
  BaseModalProps,
  ModalActions,
  ModalContent,
  ModalTitle
} from '@hypotenuse/common/src/components/BaseModal'
import { useAsyncFn } from '@hypotenuse/common/src/hooks'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'

import usePremiumPlanTier from '../../../../../hooks/usePremiumPlanTier'

import { apiApplyChurnDiscount } from '../../../../../api/PlanTier'

interface DiscountPlanDialogProps extends BaseModalProps {
  /**
   * Callback to close the dialog.
   */
  onClose: () => void
  /**
   * Callback to cancel the user's plan.
   * Called when the discount offer is declined.
   */
  onCancelPlan: () => Promise<void>
}

const DiscountPlanDialog = React.memo((props: DiscountPlanDialogProps) => {
  const { open, onClose, onCancelPlan } = props
  const history = useHistory()
  const { mutate: refreshPlan } = usePremiumPlanTier()
  const _handleClaimDiscount = useCallback(async () => {
    try {
      await apiApplyChurnDiscount()
      snackbar.show('Discount applied successfully 🎉', {
        variant: 'success',
        autoHideDuration: 5000
      })
      await refreshPlan()
      onClose()
      history.push('/settings/billing')
    } catch (error) {
      snackbar.show(
        'There was an error claiming this offer. Please try again or contact our customer support.',
        { variant: 'error', autoHideDuration: 30_000 }
      )
      console.error(error)
      Sentry.captureException(error)
    }
  }, [history, onClose, refreshPlan])

  const [
    { loading: loadingDiscount },
    handleClaimDiscount
  ] = useAsyncFn(_handleClaimDiscount, [_handleClaimDiscount])

  const _handleDeclineDiscount = useCallback(async () => {
    await onCancelPlan()
    handleUserClick('decline-offer-discount-button', window.location.pathname)
    onClose()
  }, [onCancelPlan, onClose])
  const [
    { loading: loadingDecline },
    handleDeclineDiscount
  ] = useAsyncFn(_handleDeclineDiscount, [_handleDeclineDiscount])

  const loading = loadingDiscount || loadingDecline

  const handleClose = useCallback(() => {
    if (!loading) {
      // Don't let the user close the dialog while actions are being performed.
      onClose()
    }
  }, [loading, onClose])
  const closeIcon = useMemo(
    () => (
      <IconButton size="small" onClick={handleClose} disabled={loading}>
        <CloseIcon />
      </IconButton>
    ),
    [handleClose, loading]
  )

  return (
    <BaseModal open={open} onClose={handleClose}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ModalTitle>Get 30% off your next 2 months on us!</ModalTitle>
        {closeIcon}
      </Stack>
      <ModalContent>
        <img
          src={piggy_bank_icon}
          alt="Piggy Bank"
          style={{ margin: 'auto' }}
        />
        <Typography variant="body2">
          <p>
            Take advantage of our world-class AI & powerful features that are
            exclusive to our subscribers. Here's a special rate that can be
            applied to your next 2 months 💜
          </p>
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          id="decline-offer-discount-button"
          variant="outlined"
          disabled={loading}
          onClick={handleDeclineDiscount}
          endIcon={
            loadingDecline && <CircularProgress color="inherit" size={14} />
          }
        >
          No thanks, confirm cancellation
        </Button>
        <Button
          id="discount-subscription-button"
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={handleClaimDiscount}
          endIcon={
            loadingDiscount && <CircularProgress color="inherit" size={14} />
          }
        >
          Claim offer
        </Button>
      </ModalActions>
    </BaseModal>
  )
})

export default DiscountPlanDialog
