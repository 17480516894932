import React, { useEffect } from 'react'

import { Button } from '@material-ui/core'

import usePrevious from '@hypotenuse/common/src/hooks/usePrevious'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'

import useAppVersion from './useAppVersion'

const showRefreshPageSnackbar = () => {
  snackbar.show('Refresh the page to update to the latest version', {
    variant: 'info',
    persist: true,
    anchorOrigin: {
      horizontal: 'center',
      vertical: 'top'
    },
    action: () => (
      <>
        <Button
          color="inherit"
          size="small"
          onClick={() => window.location.reload()}
          style={{ fontWeight: 'bold' }}
        >
          Refresh
        </Button>
      </>
    )
  })
}

const useNewVersionRefreshSnackbar = () => {
  const appVersion = useAppVersion()
  const previousAppVersion = usePrevious<string>(appVersion)
  useEffect(() => {
    // prevent snackbar from showing up on app's first render as
    // previousAppVersion would be undefined initially.
    if (previousAppVersion && previousAppVersion !== appVersion) {
      showRefreshPageSnackbar()
    }
  }, [previousAppVersion, appVersion])
}

export default useNewVersionRefreshSnackbar
