// AdTextFormProduct max lengths
export const LONG_FORM_MAX_PRODUCT_CHARS = 15_000
export const EXTRA_LONG_FORM_MAX_PRODUCT_CHARS = 200_000 // For extra-long summarization
export const SHORT_FORM_MAX_PRODUCT_CHARS = 800
export const BLOG_MAX_PRODUCT_CHARS = 500

/**
 * Special case for press release – shorter input limit than the rest.
 */
export const PRESS_RELEASE_MAX_PRODUCT_CHARS = 400
export const LONG_FORM_MAX_ROWS = 30
export const LONG_FORM_MIN_ROWS = 5
export const SHORT_FORM_MIN_ROWS = 1
export const SHORT_FORM_MAX_ROWS = undefined

// AdTextFormTargetAudience max lengths
export const MAX_TARGET_AUDIENCE_CHARS = 200

// AdTextFormBrand max lengths
export const MAX_BRAND_CHARS = 200

// AdTextFormKeywords max lengths
export const MAX_TOTAL_KEYWORDS_CHARS = 1000

// Options for summarizing text
export const SUMMARIZE_OUTPUT_FORMAT_OPTIONS: [string, string] = [
  'Paragraph',
  'Bullet points'
]

// Options for bio writer
export const BIO_WRITER_TONE_OPTIONS: [string, string] = [
  'Third person',
  'First person'
]

// Default Ad Tone (for all templates besides summarizer and bio writer)
export const DEFAULT_AD_TONE = 'Conversational'

export const IMDA_DEFAULT_AD_TONE = 'Passionate'
export const IMDA_DEFAULT_TARGET_AUDIENCE = 'Corporates'

// Enums of all MIME file types that can be uploaded
export enum SupportedFileTypes {
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TXT = 'text/plain',
  CSV = 'text/csv',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PNG = 'image/png',
  WEBP = 'image/webp',
  JPEG = 'image/jpeg',
  TIFF = 'image/tiff',
  RAW = 'image/x-panasonic-raw', // there are actually multiple MIME types for raw images, but we support this for now
  AVIF = 'image/avif'
}

/**
 * Map supported file types to their corresponding user friendly names.
 */
export const SUPPORTED_FILE_TYPE_TO_NAME_MAP: {
  [key in SupportedFileTypes]: string
} = {
  [SupportedFileTypes.PDF]: 'PDF',
  [SupportedFileTypes.DOCX]: 'DOCX',
  [SupportedFileTypes.TXT]: 'TXT',
  [SupportedFileTypes.CSV]: 'CSV',
  [SupportedFileTypes.XLS]: 'XLS',
  [SupportedFileTypes.XLSX]: 'XLSX',
  [SupportedFileTypes.PNG]: 'PNG',
  [SupportedFileTypes.WEBP]: 'WEBP',
  [SupportedFileTypes.JPEG]: 'JPEG',
  [SupportedFileTypes.TIFF]: 'TIFF',
  [SupportedFileTypes.RAW]: 'RAW',
  [SupportedFileTypes.AVIF]: 'AVIF'
}

/**
 * The default list of MIME types that can be uploaded.
 */
export const DEFAULT_UPLOADABLE_FILE_TYPES: SupportedFileTypes[] = [
  SupportedFileTypes.PDF,
  SupportedFileTypes.DOCX,
  SupportedFileTypes.TXT,
  SupportedFileTypes.CSV,
  SupportedFileTypes.XLS,
  SupportedFileTypes.XLSX
]

export const IMAGE_FILE_TYPES = [
  SupportedFileTypes.JPEG,
  SupportedFileTypes.PNG
]
