import React from 'react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'

interface PricingPlanHeaderProps {
  isCustom?: boolean
  isActive?: boolean
  icon?: React.ReactNode
  planName: string
}

export const PricingPlanHeader = (props: PricingPlanHeaderProps) => {
  const { isCustom, isActive, icon, planName } = props

  return (
    <div
      style={{
        backgroundColor: isActive && !isCustom ? palette.primary[50] : undefined
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} p={2}>
        {icon}
        <Typography
          variant="body1"
          style={{
            color: isActive && !isCustom ? palette.primary[800] : undefined,
            fontWeight: isActive && !isCustom ? 600 : 500
          }}
        >
          {planName}
        </Typography>
      </Stack>
    </div>
  )
}
