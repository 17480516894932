import React from 'react'

import suggestion_logo from '../assets/default_template.svg'

import { BaseToolCard } from './BaseToolCard'

const TITLE = "Can't find a content type?"
const SUBTITLE =
  "Suggest what we should add next. We'll send our AI back to school to learn it!"

interface Props {
  onClick: () => void
}

export const SuggestionCard = (props: Props) => {
  const { onClick } = props

  return (
    <BaseToolCard
      title={TITLE}
      subtitle={SUBTITLE}
      onClick={async () => onClick()}
      logoSrc={suggestion_logo}
    />
  )
}
