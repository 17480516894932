import { ToneSource } from '../utils/Interfaces'

import {
  BLOG_MAX_PRODUCT_CHARS,
  LONG_FORM_MAX_PRODUCT_CHARS,
  SHORT_FORM_MAX_PRODUCT_CHARS
} from '../components/advertising/AdTextConstants'
import { DocumentType } from '../components/documents/types'

import { BulkWorkflowContentType } from './BulkWorkflow'
import {
  ContentGenerationType,
  FeedbackContentGenerationType,
  GenerationStatusFilterKey
} from './ContentGeneration'
import { Description, ProductErrorType } from './Products'

/**
 * Enumeration for different ad text types used internally in generation.
 * Note: The order here is important and intentionally done.
 */
export enum AdTextType {
  ask_anything = 'ask_anything',
  rewriter = 'rewriter',
  summarise = 'summarise',
  instagram = 'instagram',
  google = 'google',
  facebook = 'facebook',
  linkedin = 'linkedin',
  headline = 'headline',
  landing_page = 'landing_page',
  meta_desc = 'meta_desc',
  category_page = 'category_page',
  marketing_angles = 'marketing_angles',
  eli5 = 'eli5',
  email = 'email',
  youtube_title = 'youtube_title',
  video_intro = 'video_intro',
  bio_writer = 'bio_writer',
  press_release = 'press_release',
  rewrite_for_seo = 'rewrite_for_seo'
}

// Blog is defined as an AdText on the backend as it is used in Bulk Generation
export enum BulkWorkflowOtherContentTypes {
  blog = 'blog'
}

// This includes "blog", and is only used for CRUD for AdTextType
export type AdTextCrudType = AdTextType | BulkWorkflowOtherContentTypes

/**
 * Enumeration of fields in the advertising text form.
 * As we scale the number of content types, we need to categorize fields to easily include/exclude them.
 * Initialized in order of top to bottom (keep it that way please?)
 */
export enum AdTextFormField {
  product = 'product',
  image = 'image',
  moreDescriptions = 'moreDescriptions',
  keywords = 'keywords',
  tone = 'tone',
  outputLength = 'outputLength',
  advancedOptions = 'advancedOptions',
  targetAudience = 'targetAudience',
  brand = 'brand'
}

export const adTextFormFieldNameMap: {
  [key: string]: string
} = {
  [AdTextFormField.product]: 'product_type',
  [AdTextFormField.moreDescriptions]: 'more_description',
  [AdTextFormField.keywords]: 'keywords',
  [AdTextFormField.tone]: 'tone',
  [AdTextFormField.outputLength]: 'desired_output_length',
  [AdTextFormField.advancedOptions]: 'advanced_options',
  [AdTextFormField.targetAudience]: 'target_audience',
  [AdTextFormField.brand]: 'brand'
}

export const adTextTabs = Object.entries(AdTextType).map(([value, key]) => {
  return {
    key: key,
    path: value
  }
})

export type GenericTempateType = AdTextType | BulkWorkflowContentType

export const generationTypeMap: {
  [key in GenericTempateType]: ContentGenerationType
} = {
  [AdTextType.instagram]: ContentGenerationType.advertising_text_instagram,
  [AdTextType.google]: ContentGenerationType.advertising_text_google,
  [AdTextType.facebook]: ContentGenerationType.advertising_text_facebook,
  [AdTextType.headline]: ContentGenerationType.advertising_text_headline,
  [AdTextType.linkedin]: ContentGenerationType.advertising_text_linkedin,
  [AdTextType.rewriter]: ContentGenerationType.advertising_text_rewriter,
  [AdTextType.category_page]:
    ContentGenerationType.advertising_text_category_page,
  [AdTextType.landing_page]:
    ContentGenerationType.advertising_text_landing_page,
  [AdTextType.eli5]: ContentGenerationType.advertising_text_eli5,
  [AdTextType.email]: ContentGenerationType.advertising_text_email,
  [AdTextType.video_intro]: ContentGenerationType.advertising_text_video_intro,
  [AdTextType.meta_desc]: ContentGenerationType.advertising_text_meta_desc,
  [AdTextType.marketing_angles]:
    ContentGenerationType.advertising_text_marketing_angles,
  [AdTextType.press_release]:
    ContentGenerationType.advertising_text_press_release,
  [AdTextType.summarise]: ContentGenerationType.advertising_text_summarise,
  [AdTextType.youtube_title]:
    ContentGenerationType.advertising_text_youtube_title,
  [AdTextType.bio_writer]: ContentGenerationType.advertising_text_bio_writer,
  [AdTextType.ask_anything]:
    ContentGenerationType.advertising_text_ask_anything,
  [BulkWorkflowOtherContentTypes.blog]:
    ContentGenerationType.advertising_text_blog,
  [AdTextType.rewrite_for_seo]:
    ContentGenerationType.advertising_text_rewrite_for_seo
}

/**
 * Map ad types to their corresponding feedback type
 * so it can be recorded in the data for bulk workflow downvotes
 */
export const feedbackContentTypeMap: {
  [key in AdTextCrudType]: FeedbackContentGenerationType
} = {
  [AdTextType.instagram]:
    FeedbackContentGenerationType.advertising_text_instagram,
  [AdTextType.google]: FeedbackContentGenerationType.advertising_text_google,
  [AdTextType.facebook]:
    FeedbackContentGenerationType.advertising_text_facebook,
  [AdTextType.headline]:
    FeedbackContentGenerationType.advertising_text_headline,
  [AdTextType.linkedin]:
    FeedbackContentGenerationType.advertising_text_linkedin,
  [AdTextType.rewriter]:
    FeedbackContentGenerationType.advertising_text_rewriter,
  [AdTextType.category_page]:
    FeedbackContentGenerationType.advertising_text_category_page,
  [AdTextType.landing_page]:
    FeedbackContentGenerationType.advertising_text_landing_page,
  [AdTextType.eli5]: FeedbackContentGenerationType.advertising_text_eli5,
  [AdTextType.email]: FeedbackContentGenerationType.advertising_text_email,
  [AdTextType.video_intro]:
    FeedbackContentGenerationType.advertising_text_video_intro,
  [AdTextType.meta_desc]:
    FeedbackContentGenerationType.advertising_text_meta_desc,
  [AdTextType.marketing_angles]:
    FeedbackContentGenerationType.advertising_text_marketing_angles,
  [AdTextType.press_release]:
    FeedbackContentGenerationType.advertising_text_press_release,
  [AdTextType.summarise]:
    FeedbackContentGenerationType.advertising_text_summarise,
  [AdTextType.youtube_title]:
    FeedbackContentGenerationType.advertising_text_youtube_title,
  [AdTextType.bio_writer]:
    FeedbackContentGenerationType.advertising_text_bio_writer,
  [AdTextType.ask_anything]:
    FeedbackContentGenerationType.advertising_text_ask_anything,
  [BulkWorkflowOtherContentTypes.blog]:
    FeedbackContentGenerationType.advertising_text_blog,
  [AdTextType.rewrite_for_seo]:
    FeedbackContentGenerationType.advertising_text_rewrite_for_seo
}

export const AD_TEXT_TYPES_WITH_EXTRA_LONG_FORM_PRODUCT_TYPE_FIELD = new Set<AdTextType>(
  [AdTextType.summarise]
)
export const AD_TEXT_TYPES_WITH_LONG_FORM_PRODUCT_TYPE_FIELD = new Set<AdTextType>(
  [
    AdTextType.rewriter,
    AdTextType.eli5,
    AdTextType.landing_page,
    AdTextType.email,
    AdTextType.ask_anything,
    AdTextType.instagram,
    AdTextType.linkedin,
    AdTextType.meta_desc,
    AdTextType.bio_writer,
    AdTextType.rewrite_for_seo
  ]
)

export const AD_TEXT_TYPES_WITH_EXTRA_LONG_FORM_MAX_PRODUCT_CHARS = new Set<AdTextType>(
  [AdTextType.summarise]
)

export const AD_TEXT_TYPES_WITH_LONG_FORM_MAX_PRODUCT_CHARS = new Set<AdTextType>(
  [
    AdTextType.rewriter,
    AdTextType.eli5,
    AdTextType.landing_page,
    AdTextType.email,
    AdTextType.ask_anything
  ]
)

/**
 * Field mappings to map errors to the right field.
 * TODO: Use them
 */
export enum GenerateAdTextErrorType {
  empty_fields = 'empty_fields',
  target_audience_too_long = 'target_audience_too_long',
  brand_too_long = 'brand_too_long',
  keywords_too_long = 'keywords_too_long',
  product_type_too_long = 'product_type_too_long'
}

export const errorToFieldMap: {
  [key in GenerateAdTextErrorType]: AdTextFormField | null
} = {
  [GenerateAdTextErrorType.empty_fields]: null, // Empty Fields is specially mapped to null because this could be any field
  [GenerateAdTextErrorType.target_audience_too_long]:
    AdTextFormField.targetAudience,
  [GenerateAdTextErrorType.brand_too_long]: AdTextFormField.brand,
  [GenerateAdTextErrorType.keywords_too_long]: AdTextFormField.keywords,
  [GenerateAdTextErrorType.product_type_too_long]: AdTextFormField.product
}

export enum AdvertisementTextActions {
  copy = 'copy',
  edit = 'edit',
  delete = 'delete'
}

export interface AdText {
  username: string
  organizationName: string
  adTextId: string
  productId: string
  created_at: string
  ad_text_type: AdTextType
  loading: boolean
  descriptions: Description[] // TODO: Make readonly
  loadingAt?: Date
  expiredAt?: Date
  productErrorType?: ProductErrorType
  target_audience?: string
  brand?: string
  tone?: string
  tone_id?: string
  tone_source?: ToneSource
  product_type?: string
  more_description?: string
  keywords?: string[]
  desired_output_length?: AdTextLength
  isSaved?: boolean
  is_referencing_knowledge_base?: boolean
}

export enum AdTextLength {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long'
}

export interface AdTextLengthOption {
  /**
   * Length option (can be short, medium, long, etc)
   */
  length: AdTextLength
  /**
   * The estimated word count for the length option
   */
  estimated_word_count: number
}

export interface AdTextUpdateForm {
  adTextId: string
  target_audience?: string
  tone?: string
  tone_id?: string
  tone_source?: ToneSource
  more_description?: string
  brand?: string
  product_type?: string
  keywords?: string[]
  desired_output_length?: AdTextLength
  descriptions?: Description[]
  productErrorType?: ProductErrorType
  isSaved?: boolean
  is_referencing_knowledge_base?: boolean
}

// TODO: refactor this to inherit from AdTextUpdateForm
export interface AdTextUpdateFormWithProductId {
  productId: string
  target_audience?: string
  tone?: string
  tone_id?: string
  tone_source?: ToneSource
  more_description?: string
  brand?: string
  product_type?: string
  keywords?: string[]
  desired_output_length?: AdTextLength
  descriptions?: Description[]
  productErrorType?: ProductErrorType
  isSaved?: boolean
  is_referencing_knowledge_base?: boolean
}

export interface AdTextFolder {
  id: string
  username: string
  organizationName: string
  ad_text_type: BulkWorkflowContentType
  created_at: string
  updated_at: string
  folder_name: string
  item_count: number
  tone?: string
  tone_id?: string
  tone_source?: ToneSource
}

export interface AdTextFolderUpdateForm {
  folder_name: string
  tone: string
  tone_id: string
  tone_source: ToneSource
}

export interface AdTextFoldersCount {
  total_count: number
  ad_text_counts: { [key in BulkWorkflowContentType]: number }
}

export enum BeaconArticleIdSlots {
  googleKeywords = 'googleKeywords',
  googleTargetAudience = 'googleTargetAudience',
  facebookKeywords = 'facebookKeywords',
  facebookTargetAudience = 'facebookTargetAudience',
  adsGeneration = 'adsGeneration'
}

/**
 * Each ad text type is mapped to a set of fields that do NOT appear in the template.
 * Used to know which field(s) to not render for each ad text type.
 */
export const formExceptionMap: {
  [key in AdTextCrudType]: Set<AdTextFormField>
} = {
  [AdTextType.landing_page]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.outputLength
  ]),
  [AdTextType.bio_writer]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.outputLength
  ]),
  [AdTextType.google]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.instagram]: new Set<AdTextFormField>([
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.facebook]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.linkedin]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.moreDescriptions
  ]),
  [AdTextType.headline]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.category_page]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.video_intro]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.meta_desc]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.tone,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience,
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.marketing_angles]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience,
    AdTextFormField.outputLength
  ]),
  [AdTextType.press_release]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.targetAudience,
    AdTextFormField.tone,
    AdTextFormField.outputLength
  ]),
  [AdTextType.summarise]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience,
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.rewriter]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience,
    AdTextFormField.outputLength
  ]),
  [AdTextType.eli5]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.keywords,
    AdTextFormField.advancedOptions,
    AdTextFormField.brand,
    AdTextFormField.targetAudience,
    AdTextFormField.tone,
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.email]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.moreDescriptions,
    AdTextFormField.outputLength
  ]),
  [AdTextType.youtube_title]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.moreDescriptions,
    AdTextFormField.brand,
    AdTextFormField.outputLength
  ]),
  [AdTextType.ask_anything]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.advancedOptions,
    AdTextFormField.moreDescriptions,
    AdTextFormField.targetAudience,
    AdTextFormField.brand,
    AdTextFormField.outputLength
  ]),
  [BulkWorkflowOtherContentTypes.blog]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.moreDescriptions,
    AdTextFormField.advancedOptions,
    AdTextFormField.targetAudience,
    AdTextFormField.brand
  ]),
  [AdTextType.rewrite_for_seo]: new Set<AdTextFormField>([
    AdTextFormField.image,
    AdTextFormField.tone
  ])
}

/**
 * Each ad text type is mapped to a set of fields that are required.
 * Used to decide whether to disable the generate button or not.
 * Also used to render the asterisk on field label to indicate a required field.
 * Note that a field may not be required but not excepted either.
 */
export const requiredFieldMap: {
  [key in AdTextCrudType]: Set<AdTextFormField>
} = {
  [AdTextType.google]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.instagram]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.facebook]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.linkedin]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.landing_page]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.keywords
  ]),
  [AdTextType.headline]: new Set<AdTextFormField>([AdTextFormField.keywords]),
  [AdTextType.category_page]: new Set<AdTextFormField>([
    AdTextFormField.keywords
  ]),
  [AdTextType.video_intro]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.keywords
  ]),
  [AdTextType.meta_desc]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.keywords
  ]),
  [AdTextType.marketing_angles]: new Set<AdTextFormField>([
    AdTextFormField.product
  ]),
  [AdTextType.press_release]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.moreDescriptions,
    AdTextFormField.brand
  ]),
  [AdTextType.summarise]: new Set<AdTextFormField>([AdTextFormField.product]),
  [AdTextType.rewriter]: new Set<AdTextFormField>([AdTextFormField.product]),
  [AdTextType.eli5]: new Set<AdTextFormField>([AdTextFormField.product]),
  [AdTextType.email]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.keywords
  ]),
  [AdTextType.youtube_title]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.keywords
  ]),
  [AdTextType.bio_writer]: new Set<AdTextFormField>([AdTextFormField.product]),
  [AdTextType.ask_anything]: new Set<AdTextFormField>([
    AdTextFormField.product
  ]),
  [BulkWorkflowOtherContentTypes.blog]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.keywords
  ]),
  [AdTextType.rewrite_for_seo]: new Set<AdTextFormField>([
    AdTextFormField.product,
    AdTextFormField.keywords
  ])
}

/**
 * Custom mapping for ad text types that have different required fields
 * under different names.
 */
export const customAdTextCsvFieldsMap = {}

export type AdTextExceptionFieldTypes = keyof typeof formExceptionMap
export type AdTextRequiredFieldTypes = keyof typeof requiredFieldMap
export const getExcludedFieldsForAdTextType = (
  adTextType: AdTextCrudType
): Set<AdTextFormField> | undefined =>
  adTextType in formExceptionMap
    ? formExceptionMap[adTextType as AdTextExceptionFieldTypes]
    : undefined

export const getRequiredFieldsForAdTextType = (
  adTextType: AdTextCrudType
): Set<AdTextFormField> | undefined =>
  adTextType in requiredFieldMap
    ? requiredFieldMap[adTextType as AdTextRequiredFieldTypes]
    : undefined

export const adHeaders: {
  [key in AdTextCrudType]: string
} = {
  [AdTextType.google]: 'Google Ads',
  [AdTextType.instagram]: 'Instagram Captions',
  [AdTextType.facebook]: 'Facebook Ads',
  [AdTextType.headline]: 'Website Headlines and Taglines',
  [AdTextType.linkedin]: 'LinkedIn Posts',
  [AdTextType.rewriter]: 'Rewrite Content',
  [AdTextType.category_page]: 'Category Page Descriptions',
  [AdTextType.landing_page]: 'Landing Page Content',
  [AdTextType.eli5]: 'Simplify Language',
  [AdTextType.email]: 'Email Copy',
  [AdTextType.video_intro]: 'Video Hook and Introduction',
  [AdTextType.meta_desc]: 'Meta Titles and Descriptions (Bulk)',
  [AdTextType.marketing_angles]: 'Marketing Angles',
  [AdTextType.press_release]: 'Press Releases',
  [AdTextType.summarise]: 'Summarize Content', // Note the change in spelling (UK to US)
  [AdTextType.youtube_title]: 'YouTube Titles and Descriptions',
  [AdTextType.bio_writer]: 'Bio Writer',
  [AdTextType.ask_anything]: 'Ask AI Anything',
  [BulkWorkflowOtherContentTypes.blog]: 'Blog Article',
  [AdTextType.rewrite_for_seo]: 'SEO Content Optimizer'
}

export const BLOG_CONTENT_TYPE = 'blog_article'

export const adCrossWorkflowDescription: { [key: string]: string } = {
  [AdTextType.google]: 'Google Ad',
  [AdTextType.instagram]: 'Instagram Post',
  [AdTextType.facebook]: 'Facebook Ad',
  [AdTextType.headline]: 'Website Tagline',
  [AdTextType.linkedin]: 'LinkedIn Post',
  [AdTextType.category_page]: 'Category Page Description',
  [AdTextType.landing_page]: 'Landing Page Content',
  [AdTextType.email]: 'Email Copy',
  [AdTextType.video_intro]: 'Video Hook',
  [AdTextType.meta_desc]: 'Meta Description',
  [AdTextType.marketing_angles]: 'Marketing Angle',
  [AdTextType.press_release]: 'Press Release',
  [AdTextType.summarise]: 'Summary',
  [AdTextType.youtube_title]: 'YouTube Title',
  [AdTextType.bio_writer]: 'Bio',
  [BLOG_CONTENT_TYPE]: 'Blog Article',
  [AdTextType.rewrite_for_seo]: 'SEO Optimized Content'
}

export const adSubHeaders = {
  [AdTextType.google]:
    'Create effective and persuasive ads that generate leads and sales.',
  [AdTextType.instagram]: 'Generate engaging captions from images or texts.',
  [AdTextType.facebook]:
    'Craft powerful and compelling ads that speak to your target market.',
  [AdTextType.headline]:
    'Develop headlines and taglines that pack a punch and convert visitors into customers.',
  [AdTextType.linkedin]:
    'Engage your target audience with thought-provoking and persuasive content.',
  [AdTextType.rewriter]:
    'Paraphrase and repurpose content while making it more engaging and effective.',
  [AdTextType.category_page]:
    'Optimize your category pages with descriptions that increase organic traffic.',
  [AdTextType.landing_page]:
    'Create benefit-focused landing pages that entice visitors to take the next step.',
  [AdTextType.eli5]:
    'Make complex concepts easy to understand and jargon-free.',
  [AdTextType.email]:
    'Write copy to get your message across, no matter how crowded the inbox.',
  [AdTextType.video_intro]:
    'Write a catchy and intriguing hook to reel viewers in and keep them watching.',
  [AdTextType.meta_desc]:
    'Write meta titles and descriptions that are optimized for search engines.',
  [AdTextType.marketing_angles]:
    'Generate marketing angles that are persuasive and compelling.',
  [AdTextType.press_release]:
    'Write press releases that are newsworthy and engaging.',
  [AdTextType.summarise]:
    'Upload PDF, url or text to summarize and extract key points and insights.',
  [AdTextType.youtube_title]:
    'Optimize your videos for greater visibility and higher engagement.',
  [AdTextType.bio_writer]:
    'Elevate any page with a well-written bio that will make people want to know more.',
  [AdTextType.ask_anything]:
    'Instruct our AI, from answering questions to writing custom content for any project.',
  [AdTextType.rewrite_for_seo]:
    'Convert any text into SEO-friendly content with internal links, keywords, and effective CTAs.'
}

export const customProductFieldNames = new Set([
  'topic',
  'what is your post about',
  'input',
  'category'
])

export interface AdTextFolderSearchQuery {
  folderId?: string | null
  search?: string
  generationStatus?: GenerationStatusFilterKey
  keywords?: string[]
  scan?: boolean
  start?: number
  end?: number
}

export interface AdTextSearchResultInResponse {
  adTexts: AdText[]
  totalAdTexts: number
}

export interface AdTextFolderOverviewSearchQuery {
  ad_text_type: BulkWorkflowContentType
  search?: string
  scan?: boolean
  sort_by?: string[]
  start?: number
  end?: number
}

export interface AdTextFolderOverviewInResponse {
  adTextFolders: AdTextFolder[]
  totalFolders: number
}

export enum RewriterIntentionType {
  Improve = 'Improve',
  Simplify = 'Simplify',
  Shorten = 'Shorten',
  Expand = 'Expand',
  Rephrase = 'Rephrase',
  Paraphrase = 'Paraphrase'
}

/**
 * Types of content that be uploaded / provided as user input
 */
export enum UserInputType {
  UploadFile = 'Upload file',
  AddURL = 'Add URL',
  PasteText = 'Paste text'
}

/**
 * Warning message to display to user when PDF or transcript is too long for the summarize ad text type
 */
export interface AdTextWarningMessageType {
  inputType: UserInputType
  message: string
}

/**
 * Check if the ad text type is a long form type. This is used only on bulk workflows.
 * In the future, single ad text should also use this function to determine if the ad text is long form.
 */
export const isLongForm = (adTextType: AdTextCrudType): boolean => {
  return (
    adTextType === AdTextType.rewriter ||
    adTextType === AdTextType.eli5 ||
    adTextType === AdTextType.landing_page ||
    adTextType === AdTextType.summarise ||
    adTextType === AdTextType.marketing_angles ||
    adTextType === AdTextType.email ||
    adTextType === AdTextType.meta_desc ||
    adTextType === AdTextType.youtube_title ||
    adTextType === AdTextType.video_intro ||
    adTextType === AdTextType.ask_anything ||
    adTextType === BulkWorkflowOtherContentTypes.blog
  )
}

/**
 * Get the maximum chars for the ad text type. This is used only on bulk workflows at the moment.
 */
export const getMaxChars = (adTextType: AdTextCrudType) => {
  return adTextType === BulkWorkflowOtherContentTypes.blog
    ? BLOG_MAX_PRODUCT_CHARS
    : isLongForm(adTextType)
    ? LONG_FORM_MAX_PRODUCT_CHARS
    : SHORT_FORM_MAX_PRODUCT_CHARS
}

export const adTextToDocumentTypeMap = {
  [AdTextType.ask_anything]: DocumentType.ask_anything,
  [AdTextType.bio_writer]: DocumentType.bio_writer,
  [AdTextType.category_page]: DocumentType.category_page,
  [AdTextType.email]: DocumentType.email,
  [AdTextType.eli5]: DocumentType.eli5,
  [AdTextType.facebook]: DocumentType.facebook,
  [AdTextType.google]: DocumentType.google,
  [AdTextType.headline]: DocumentType.headline,
  [AdTextType.instagram]: DocumentType.instagram,
  [AdTextType.landing_page]: DocumentType.landing_page,
  [AdTextType.linkedin]: DocumentType.linkedin,
  [AdTextType.marketing_angles]: DocumentType.marketing_angles,
  [AdTextType.meta_desc]: DocumentType.meta_desc,
  [AdTextType.press_release]: DocumentType.press_release,
  [AdTextType.rewriter]: DocumentType.rewriter,
  [AdTextType.summarise]: DocumentType.summarise,
  [AdTextType.video_intro]: DocumentType.video_intro,
  [AdTextType.youtube_title]: DocumentType.youtube_title,
  [AdTextType.rewrite_for_seo]: DocumentType.rewrite_for_seo
}
