import React from 'react'

import palette from '../atoms/Colors'
import { SvgIconProps, createStyles, makeStyles } from '@material-ui/core'
import HelpOutline from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fontSize: '1rem',
      color: palette.gray[400],
      cursor: 'pointer',
      verticalAlign: 'middle',
      '&:hover': {
        color: palette.gray[500]
      }
    }
  })
)

const HelpIcon = (props: SvgIconProps) => {
  const classes = useStyles()

  return (
    <HelpOutline
      className={classes.icon}
      style={{
        ...props.style
      }}
      {...props}
    />
  )
}

export { HelpIcon }
