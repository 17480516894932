import palette from '@hypotenuse/common/src/atoms/Colors'
import {
  ThemeOptions,
  createTheme,
  responsiveFontSizes
} from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'

import { DISABLED_COLOR, SECONDARY_COLOR } from './Colors'

const theme = responsiveFontSizes(
  createTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true // Disable ripple on everything!
      },
      MuiButton: {
        disableElevation: true
      },
      MuiRadio: {
        color: 'primary'
      },
      MuiDialog: {
        scroll: 'body'
      },
      MuiSelect: {
        IconComponent: ExpandMore
      }
    },
    overrides: {
      // Drawer styling
      MuiDrawer: {
        paperAnchorDockedLeft: {
          borderRightColor: '#EAECF0'
        }
      },

      // Global list item styling
      MuiListItem: {
        root: {
          color: '#344054',
          '&.Mui-selected': {
            backgroundColor: '#F2F4F7'
          },
          '&.Mui-selected:hover': {
            color: palette.gray[900],
            backgroundColor: '#F2F4F7'
          }
        },
        button: {
          '&:hover': {
            backgroundColor: '#F2F4F7',
            color: palette.gray[900],
            borderRadius: 6
          }
        }
      },
      // Global tab styling
      MuiTab: {
        root: {
          fontSize: '14px'
        }
      },
      // Global button styling
      MuiButton: {
        label: {
          fontSize: '14px'
        },
        contained: {
          '&:disabled': {
            backgroundColor: palette.gray[200],
            outline: `1px solid ${palette.gray[300]}`
          }
        },
        outlined: {
          borderColor: palette.gray[300],
          '&:disabled': {
            backgroundColor: palette.white
          }
        }
      },
      // Global icon button styling
      MuiListItemIcon: {
        root: {
          color: '#667085',
          minWidth: '40px'
        }
      },
      // Global chip outline styling
      MuiChip: {
        root: {
          '& .MuiChip-outlined': {
            borderColor: '#D0D5DD'
          }
        }
      },
      //Global divider styling
      MuiDivider: {
        root: {
          backgroundColor: '#EAECF0'
        }
      },
      // Global input field styling
      MuiInputLabel: {
        root: {
          fontSize: '14px'
        }
      },
      MuiOutlinedInput: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD'
          },
          '&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD'
          },
          '&:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(169, 161, 253, 0.5)'
          }
        }
      },
      MuiInputBase: {
        root: {
          border: '#D0D5DD',
          fontSize: '14px',
          lineHeight: '1.2rem'
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#98A2B3'
          },
          fontSize: '14px'
        }
      },
      MuiSelect: {
        root: {
          fontSize: '14px'
        },
        select: {
          '&:active': {
            backgroundColor: '#F9FAFB'
          }
        }
      },
      MuiLinearProgress: {
        colorPrimary: {
          backgroundColor: '#EAECF0'
        },
        barColorPrimary: {
          backgroundColor: '#5148D7'
        }
      }
    },
    /**
     * These colours should not be directly imported or used in the application.
     * However, other components in MUI use these colours, so we cannot remove them.
     */
    palette: {
      primary: {
        main: palette.primary[500],
        light: palette.primary[50]
      },
      secondary: {
        main: SECONDARY_COLOR // TODO: deprecate secondary colour and its usage
      },
      success: {
        main: palette.success[500],
        light: palette.success[300],
        dark: palette.success[700],
        contrastText: palette.white
      },
      info: {
        main: palette.blue[300],
        light: palette.blue[100],
        dark: palette.blue[700],
        contrastText: palette.white
      },
      warning: {
        main: palette.warning[500],
        light: palette.warning[300],
        dark: palette.warning[700],
        contrastText: palette.white
      },
      error: {
        main: palette.error[500],
        light: palette.error[300],
        dark: palette.error[700],
        contrastText: palette.white
      },
      text: {
        primary: palette.gray[900],
        disabled: DISABLED_COLOR // TODO: replace disabled colour with one from the design system
      },
      background: {
        default: palette.gray[50]
      },
      grey: {
        50: palette.gray[50],
        100: palette.gray[100],
        200: palette.gray[200],
        300: palette.gray[300],
        400: palette.gray[400],
        500: palette.gray[500],
        600: palette.gray[600],
        700: palette.gray[700],
        800: palette.gray[800],
        900: palette.gray[900],
        A100: palette.gray[100],
        A200: palette.gray[200],
        A400: palette.gray[400],
        A700: palette.gray[700]
      }
    },

    shape: {
      borderRadius: 8
    },
    shadows: [
      'none',
      '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
      '0px 1px 2px rgba(100, 116, 139, 0.12)',
      '0px 1px 4px rgba(100, 116, 139, 0.12)',
      '0px 1px 5px rgba(100, 116, 139, 0.12)',
      '0px 1px 6px rgba(100, 116, 139, 0.12)',
      '0px 2px 6px rgba(100, 116, 139, 0.12)',
      '0px 3px 6px rgba(100, 116, 139, 0.12)',
      '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
      '0px 5px 12px rgba(100, 116, 139, 0.12)',
      '0px 5px 14px rgba(100, 116, 139, 0.12)',
      '0px 5px 15px rgba(100, 116, 139, 0.12)',
      '0px 6px 15px rgba(100, 116, 139, 0.12)',
      '0px 7px 15px rgba(100, 116, 139, 0.12)',
      '0px 8px 15px rgba(100, 116, 139, 0.12)',
      '0px 9px 15px rgba(100, 116, 139, 0.12)',
      '0px 10px 15px rgba(100, 116, 139, 0.12)',
      '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
      '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
      '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
      '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)',
      '0px 25px 50px rgba(100, 116, 139, 0.25)'
    ],
    typography: {
      fontFamily:
        '"InterVariable", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57
      },
      subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.75
      },
      subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1.57
      },
      overline: {
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: 2.5,
        textTransform: 'uppercase'
      },
      caption: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.66
      },
      h1: {
        fontWeight: 700,
        fontSize: '3.5rem',
        lineHeight: 1.375,
        letterSpacing: '-0.02rem'
      },
      h2: {
        fontWeight: 700,
        fontSize: '3rem',
        lineHeight: 1.375,
        letterSpacing: '-0.02rem'
      },
      h3: {
        fontWeight: 700,
        fontSize: '2.25rem',
        lineHeight: 1.375,
        letterSpacing: '-0.02rem'
      },
      h4: {
        fontWeight: 600,
        fontSize: '2rem',
        lineHeight: 1.375,
        letterSpacing: '-0.02rem'
      },
      h5: {
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: 1.375
      },
      h6: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: 1.375
      },
      button: {
        textTransform: 'none',
        fontSize: '1rem'
      }
    }
  })
)

// TODO: Replace these manual overrides with a configurable shadow generation system
theme.shadows[1] =
  'rgba(145, 158, 171, 0.3) 0px 1px 1px, rgba(145, 158, 171, 0.1) 0px 1px 2px'
theme.shadows[2] =
  'rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.1) 0px 12px 24px'
// Inset shadow
theme.shadows[3] =
  '0 -2 0 hsla(0, 0%, 100%, .15), inset 0px 2px 2px hsla(0, 0%, 0%, 0.1)'

const _tableThemeOverrides: ThemeOptions = {
  overrides: {
    MuiTableCell: {
      root: {
        /**
         * This ensures that the table cell does not collapse.
         */
        padding: '8px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        color: palette.gray[700],
        borderBottom: `1px solid ${palette.gray[200]}`,
        /**
         * The following styles are inherited from the table row
         * to ensure that the bg-color and its hover effect are
         * consistent.
         */
        backgroundColor: 'inherit',
        transition: 'background-color 0.2s ease-in-out',
        '& :hover': {
          backgroundColor: 'inherit'
        }
      },
      /**
       * Certain attributes might seem redundant (compared to root cell)
       * but these are necessary to override the default styles.
       */
      head: {
        fontWeight: 600,
        lineHeight: 1,
        color: palette.gray[700],
        backgroundColor: palette.gray[100],
        borderBottom: `1px solid ${palette.gray[300]}`
      },
      /**
       * Certain attributes might seem redundant (compared to root cell)
       * but these are necessary to override the default styles.
       */
      stickyHeader: {
        fontWeight: 600,
        color: palette.gray[700],
        backgroundColor: palette.gray[100],
        borderBottom: `1px solid ${palette.gray[300]}`
      },
      /**
       * This is a temporary fix for the checkbox
       * padding issue in the table.
       */
      paddingCheckbox: {
        padding: '0px',
        paddingLeft: '16px'
      }
    },
    MuiTableRow: {
      root: {
        backgroundColor: palette.white,
        '&:hover': {
          backgroundColor: palette.gray[50]
        }
      }
    },
    MuiTableHead: {
      root: {
        backgroundColor: palette.gray[100]
      }
    }
  }
}
export const tableTheme = createTheme(theme, _tableThemeOverrides)

export default theme
