import React from 'react'
import { useCallback } from 'react'

import { handleUserClick } from '../../../api/Analytics'

import { useCatalogueTemplateContext } from '../../../utils/context/CatalogueTemplateContext'

import productDescriptionRewriterIcon from '../assets/v2/product_description_rewriter.svg'

import { BaseToolCard, ToolCardWithMetadata } from './BaseToolCard'

const TITLE = 'Rewrite Product Descriptions (Bulk)'
const SUBTITLE = 'Enhance thousands of existing product descriptions in bulk.'
export const REWRITER_CARD_ID = 'rewrite_product_description'

const ProductDescriptionRewriterCard = () => {
  const { rewriterTemplateId } = useCatalogueTemplateContext() || {}

  const onClick = useCallback(async () => {
    handleUserClick(
      'marketing-dashboard-product-description-rewriter-card',
      window.location.pathname
    )
  }, [])
  return (
    <BaseToolCard
      title={TITLE}
      subtitle={SUBTITLE}
      onClick={onClick}
      logoSrc={productDescriptionRewriterIcon}
      href={`/product-descriptions?openCreateCatalogueModal=1&catTemplateId=${rewriterTemplateId}`}
    />
  )
}

const metadata = {
  id: REWRITER_CARD_ID,
  searchTerms: [TITLE, SUBTITLE]
}

export const ProductDescriptionRewriterCardWithMetadata: ToolCardWithMetadata = {
  card: <ProductDescriptionRewriterCard />,
  metadata
}
