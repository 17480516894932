import React from 'react'

import { makePropInjectorHOCFromHook } from '../../hooks/hocify'

import { User } from '../Interfaces'

/**
 * This is what's returned when you call `useUserContext()`
 */
export interface IUserContext {
  user: User
  refreshUser: () => Promise<void>
}

const UserContext = React.createContext<IUserContext | undefined>(undefined)

export const UserProvider = UserContext.Provider

export const useUserContext = (): IUserContext => {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

export const withUserContext = makePropInjectorHOCFromHook(useUserContext)
