import { apiClient } from '../utils/ApiClient'
import {
  OrganizationInfo,
  OrganizationInvite,
  OrganizationMember,
  OrganizationMemberRole,
  OrganizationMemberWordUsageState,
  OrganizationMemberWordUsageStateMap,
  PlanTier,
  StripeUpcomingInvoice
} from '../utils/Interfaces'
import {
  CreditsHistoryLineChartData,
  OrganizationUpdateForm,
  TimePeriod
} from '../utils/Interfaces'

/**
 * Remove a user from an organization
 */
export const apiDeleteOrganizationMember = async ({
  organizationId,
  userId
}: {
  organizationId: string
  userId: string
}) => {
  return apiClient.delete<void>(
    `/organization/${organizationId}/user/${userId}`
  )
}

/**
 * Update an organization member's role
 */
export const apiSetOrganizationMemberRole = async ({
  organizationId,
  userId,
  role
}: {
  organizationId: string
  userId: string
  role: OrganizationMemberRole
}) => {
  return apiClient.put<void>(
    `/organization/${organizationId}/user/${userId}/role`,
    null,
    {
      params: { role }
    }
  )
}

/**
 * Create a 'public' invite for users to join an organization.
 */
export const apiCreatePublicOrganizationInvite = async ({
  organizationId
}: {
  organizationId: string
}) => {
  return await apiClient.post<OrganizationInvite>(
    `/organization/${organizationId}/invite`
  )
}

/**
 * Revoke an organization invite.
 * This prevents the invite from being used to join the organization.
 */
export const apiRevokeOrganizationInvite = async ({
  organizationId,
  inviteId
}: {
  organizationId: string
  inviteId: string
}) => {
  return await apiClient.delete<void>(
    `/organization/${organizationId}/invite/${inviteId}`
  )
}

/**
 * Accept an organization invite & add the current user to the org.
 */
export const apiAcceptOrganizationInvite = async (inviteId: string) => {
  return await apiClient
    .post<OrganizationMember>(`/invite/${inviteId}/accept`)
    .then((resp) => resp.data)
}

/**
 * Send an email inviting a user to join an organization.
 */
export const apiSendOrganizationInviteEmail = async ({
  organizationId,
  role,
  email
}: {
  /**
   * The organization to send the invite for
   */
  organizationId: string
  /**
   * The role the user will have when they join the organization
   */
  role: OrganizationMemberRole
  /**
   * The email to send the invite to
   */
  email: string
}) => {
  return await apiClient.post<void>(
    `/organization/${organizationId}/invite-email`,
    null,
    { params: { role, email } }
  )
}

/**
 * Get information about an organization.
 * @param organizationId The organization to get information about
 */
export const apiFetchOrganizationInfo = (organizationId: string) => {
  return apiClient
    .get<OrganizationInfo>(`/organization/${organizationId}`)
    .then((resp) => resp.data)
}

/**
 * List the users in an organization.
 */
export const apiFetchOrganizationUsers = (organizationId: string) => {
  return apiClient
    .get<OrganizationMember[]>(`/organization/${organizationId}/users`)
    .then((resp) => resp.data)
}

/**
 * Get the word usage states for an organization's users.
 */
export const apiListOrganizationMemberWordUsageStates = async (
  organizationId: string
) => {
  const resp = await apiClient.get<OrganizationMemberWordUsageStateMap>(
    `/organization/${organizationId}/users/word-usage`
  )
  return resp.data
}

/**
 * Update the chip usage limit for a user in an organization.
 */
export const apiUpdateOrganizationMemberChipUsageLimit = async ({
  organizationId,
  userId,
  wordsUsageLimit
}: {
  organizationId: string
  userId: string
  wordsUsageLimit: number
}) => {
  const resp = await apiClient.put<OrganizationMemberWordUsageState>(
    `/organization/${organizationId}/user/${userId}/words-usage-limit`,
    null,
    {
      params: {
        words_usage_limit: wordsUsageLimit
      }
    }
  )
  return resp.data
}

/**
 * Remove the chip usage limit for a user in an organization.
 */
export const apiRemoveOrganizationMemberChipUsageLimit = async ({
  organizationId,
  userId
}: {
  organizationId: string
  userId: string
}) => {
  const resp = await apiClient.delete<OrganizationMemberWordUsageState>(
    `/organization/${organizationId}/user/${userId}/words-usage-limit`
  )
  return resp.data
}

export const apiHandleChangeUserCredits = (
  organizationName: string,
  username: string,
  creditsChange: number
) => {
  return apiClient.post<void>(
    `/organization/${organizationName}/user/${username}/change-credits`,
    null,
    {
      params: {
        credits_change: creditsChange
      }
    }
  )
}

/**
 * Get the organization's planTier.
 */
export const fetchOrganizationPlanTier = async (
  organizationId: string
): Promise<PlanTier> => {
  const response = await apiClient.get<PlanTier>(
    `/organization/${organizationId}/premium_plan_tier`
  )
  return response.data
}

/**
 * Get the organization's upcoming invoice details.
 */
export const apiGetUpcomingInvoice = async (
  organizationId: string
): Promise<StripeUpcomingInvoice | null> => {
  const response = await apiClient.get<StripeUpcomingInvoice | null>(
    `/organization/${organizationId}/upcoming-invoice`
  )
  return response.data
}

export const apiUpdateUserMonthlyAssignedCredits = async (
  username: string,
  new_credits_assigned_monthly: number
) => {
  return apiClient.post<void>(
    `/organization/user/${username}/set_new_credits_assigned_monthly`,
    null,
    {
      params: {
        username: username,
        new_credits_assigned_monthly: new_credits_assigned_monthly
      }
    }
  )
}

export const fetchOrganizationCreditHistory = async (
  organizationName: string,
  timePeriod: TimePeriod
): Promise<CreditsHistoryLineChartData> => {
  const resp = await apiClient.get<CreditsHistoryLineChartData>(
    `/organization/${organizationName}/credits_history`,
    {
      params: {
        time_period: timePeriod
      }
    }
  )
  return resp.data
}

export const apiUpdateOrganization = async (
  organizationName: string,
  organizationUpdateForm: OrganizationUpdateForm
) => {
  const response = await apiClient.put(
    `/organization/${organizationName}`,
    organizationUpdateForm
  )
  return response.data
}

export const apiGetOrganizationOwnerEmail = async (
  organizationName: string
) => {
  const response = await apiClient.get(
    `/organization/${organizationName}/owner-contact-details`
  )
  return response.data
}
