import { HelpArticle } from './types'

/**
 * Human-readable unique identifiers for help articles.
 */
export enum ArticleName {
  keywords = 'keywords',
  productImage = 'productImage',
  adTextTargetAudience = 'adTextTargetAudience',
  welcome = 'welcome',
  import = 'import',
  catalogs = 'catalogs',
  products = 'products',
  blog = 'blog',
  compose = 'compose',
  plagiarismChecker = 'plagiarismChecker',
  imageGen = 'imageGen',
  blogTone = 'blogTone',
  brandVoiceLite = 'brandVoiceLite',
  blogPOV = 'blogPOV',
  blogOutlineSeo = 'blogOutlineSeo',
  seoContentMetrics = 'seoContentMetrics',
  seoScore = 'seoScore',
  seoChecklist = 'seoChecklist',
  /**
   * SEO Keyword Finder on product descriptions page
   */
  seoKeywordFinder = 'seoKeywordFinder',
  /**
   * Enrich product data from image/web/URL
   */
  productDataEnrichment = 'productDataEnrichment',
  /**
   * Managing team members, access control, and the team's plan/billing
   */
  teamManagement = 'teamManagement',
  automaticLinking = 'automaticLinking'
}

/**
 * Help article IDs (can be specific to Phoenix, or common to both Phoenix and Tako)
 */
export const beaconArticles: { [key in ArticleName]: HelpArticle } = {
  [ArticleName.keywords]: {
    url: 'https://help.hypotenuse.ai/article/6-product-features',
    id: '612881f4f886c9486f8dca92'
  },
  [ArticleName.productImage]: {
    url: 'https://help.hypotenuse.ai/article/7-product-images',
    id: '612ded5200c03d6720752823'
  },
  [ArticleName.welcome]: {
    url: 'https://help.hypotenuse.ai/article/5-hypotenuse-ai-help-introduction',
    id: '611e6460b37d837a3d0e5500'
  },
  [ArticleName.adTextTargetAudience]: {
    url: 'https://help.hypotenuse.ai/article/9-advertising-target-audience',
    id: '612dee0e2b380503dfdec9c5'
  },
  [ArticleName.import]: {
    url: 'https://help.hypotenuse.ai/article/12-import-products',
    id: '613058e700c03d6720753377'
  },
  [ArticleName.catalogs]: {
    url: 'https://help.hypotenuse.ai/article/17-basics-level-4',
    id: '61308f3a2b380503dfded655'
  },
  [ArticleName.products]: {
    url: 'https://help.hypotenuse.ai/article/14-basics-level-1',
    id: '613082d900c03d6720753402'
  },
  [ArticleName.blog]: {
    url:
      'https://help.hypotenuse.ai/article/47-how-to-write-a-factual-blog-article',
    id: '615fae6812c07c18afddb7fd'
  },
  [ArticleName.compose]: {
    url: 'https://help.hypotenuse.ai/article/84-continue-writing-more-compose',
    id: '62f0852880fd5a31e7acfe50'
  },
  [ArticleName.plagiarismChecker]: {
    url: 'https://help.hypotenuse.ai/article/82-plagiarism-checker',
    id: '62dfa3c4b828a850e9e61301'
  },
  [ArticleName.imageGen]: {
    url: 'https://help.hypotenuse.ai/article/90-generating-images',
    id: '6302f04086337c0b7a87b321'
  },
  [ArticleName.blogTone]: {
    url: 'https://help.hypotenuse.ai/article/69-blog-tone',
    id: '62b90d87eabe9a7235b37ebb'
  },
  [ArticleName.brandVoiceLite]: {
    url:
      'https://help.hypotenuse.ai/article/137-how-does-brand-voice-lite-work',
    id: '666bf713f0fdf93e4cf0c259'
  },
  [ArticleName.blogPOV]: {
    url: 'https://help.hypotenuse.ai/article/70-blog-pov',
    id: '62c2a37c360964399e46d25a'
  },
  [ArticleName.blogOutlineSeo]: {
    url: 'https://help.hypotenuse.ai/article/124-seo-mode',
    id: '65b1e24cf1b9f821350d171f'
  },
  [ArticleName.seoContentMetrics]: {
    url: 'https://help.hypotenuse.ai/article/128-seo-mode-serp-metrics',
    id: '65bc44483fc90353e8123a0c'
  },
  [ArticleName.seoScore]: {
    url: 'https://help.hypotenuse.ai/article/127-seo-score',
    id: '65b71cacde63ff203b9e61f8'
  },
  [ArticleName.seoChecklist]: {
    url: 'https://help.hypotenuse.ai/article/125-seo-checklist',
    id: '65b703f57e38a0583d30ad58'
  },
  [ArticleName.seoKeywordFinder]: {
    url: 'https://help.hypotenuse.ai/article/139-seo-keyword-finder',
    id: '66977e9ad5f6cf2baa1f3be4'
  },
  [ArticleName.productDataEnrichment]: {
    url: 'https://help.hypotenuse.ai/article/138-enriching-product-data',
    id: '669757c78045147820734d90'
  },
  [ArticleName.teamManagement]: {
    url: 'https://help.hypotenuse.ai/article/123-managing-teams',
    id: '651a83fab0a42f719761a164'
  },
  [ArticleName.automaticLinking]: {
    url: 'https://help.hypotenuse.ai/article/132-automatic-linking',
    id: '662887f50cfcb4508af69586'
  }
}
