import React from 'react'

import { Avatar } from '@material-ui/core'
import { Theme, useTheme } from '@material-ui/core/styles'

import { COLOR_GRADIENTS_HEX_CODE } from '../../utils/Constants'

interface LetterAvatarProps {
  firstName: string
  width?: string
  height?: string
  overrideBackgroundColor?: string
  fontSize?: string
  fontWeight?: number
  color?: string
}

const getInitials = (firstName: string) => {
  const words = firstName.split(' ', 2)
  return words
    .map((word) => word.charAt(0))
    .join('')
    .toUpperCase()
}

// This function component takes in the user's first name as props and returns a beautifully colored avatar
export default function LetterAvatar(props: LetterAvatarProps) {
  const {
    firstName,
    width,
    height,
    overrideBackgroundColor,
    fontSize = '1rem',
    fontWeight,
    color
  } = props
  const theme = useTheme()
  const initials = getInitials(firstName)
  const getAvatarStyle = (firstName: string, theme: Theme) => {
    const index = firstName.toUpperCase().charCodeAt(0) % 5
    const hexCode = COLOR_GRADIENTS_HEX_CODE[index]
    const backgroundColor = overrideBackgroundColor ?? hexCode

    return {
      color: color ?? theme.palette.getContrastText(backgroundColor),
      backgroundColor: backgroundColor,
      width: width ?? theme.spacing(4),
      height: height ?? theme.spacing(4),
      fontSize: fontSize,
      fontWeight: fontWeight ?? 400
    }
  }

  return <Avatar style={getAvatarStyle(initials, theme)}>{initials}</Avatar>
}
