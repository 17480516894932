import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

import {
  CancellationPlanForm,
  PlanChangeType,
  PremiumFAQ,
  PremiumPlanTier
} from '../components/utils/Interfaces'

export { apiFetchPlanTierListObject } from '@hypotenuse/common/src/api/PlanTier'

export const fetchPremiumPlanTier = (): Promise<PremiumPlanTier> => {
  return apiClient.get('/active_premium_plan').then((resp) => resp.data)
}

export const fetchOrganizationPlanTierList = (
  organizationName: string,
  includeActivePlanTier: boolean = false
): Promise<PremiumPlanTier[]> => {
  return apiClient
    .get(`/organization_plan_tier_list/${organizationName}`, {
      params: { include_active_plan_tier: includeActivePlanTier }
    })
    .then((resp) => resp.data)
}

export const apiGetPlanTierDetails = async (
  planId: string
): Promise<PremiumPlanTier> => {
  const response = await apiClient.get(`/plan_tier_details/plan/${planId}`)
  return response.data
}

/**
 * Calls the backend API which checks if the users plan tier list contains the Apr24PremiumBlogPro plan.
 * if it does, it will return true, otherwise false.
 * @returns whether the blog pro pricing should be shown
 */

export const apiShouldShowBlogProPrice = async (): Promise<boolean> => {
  const resp = await apiClient.get(`/should_show_blog_pro_pricing`)
  return resp.data
}

/**
 * Calls the backend API to update the plan tier for the organization.
 *
 * @param plan_id the plan id to update the organization to
 * @param planChangeType the plan change type
 * @param cancellationForm cancellation form
 * @returns
 */
export const updatePremiumPlanTier = (
  plan_id: string,
  planChangeType: PlanChangeType,
  cancellationForm?: CancellationPlanForm
) => {
  return apiClient.put(`/change_plan/${plan_id}`, cancellationForm, {
    params: { subscription_type: planChangeType }
  })
}

export const fetchPricingFAQs = (): Promise<Array<PremiumFAQ>> => {
  return apiClient.get('/pricing_faqs').then((resp) => resp.data)
}

export const apiPausePlanTier = async (pauseDuration: number) => {
  return apiClient
    .put('/pause_plan', { duration: pauseDuration })
    .then((resp) => resp.data)
}

/**
 * Apply a discount to the user's subscription – offered when they're about to cancel.
 */
export const apiApplyChurnDiscount = async () => {
  return apiClient.put('/discount-subscription').then((resp) => resp.data)
}
