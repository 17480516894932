import { create, createStore } from 'zustand'

import { handleTrackPricePlanModalOpen } from '../api/Analytics'
import { apiFetchPlanTierListObject } from '../api/PlanTier'

import { Nudge } from '../utils/Interfaces'

export interface IMonetizationAwarenessModalStore {
  /**
   * Whether the modal is open or not
   */
  open: boolean
  /**
   * Name of the feature that the user is trying to access
   */
  featureName: Nudge | undefined
  /**
   * Pathname of the page that the user is on when they try to access the feature
   */
  pathname: string | undefined
  /**
   * This will be the elementId when the user clicks on the CTA button in a pricing plan card
   * It is only used for tracking purposes
   */
  trackingId: string | undefined
  /**
   * Opens the modal and sets the props to be sent to the modal
   */
  openModal: (modalProps: {
    featureName: Nudge
    elementId: string
    pathname: string
    trackingId?: string
  }) => void
  /**
   * Closes the modal and resets the props
   */
  closeModal: () => void
}

const monetizationAwarenessModalStore = createStore<IMonetizationAwarenessModalStore>(
  (set) => ({
    open: false,
    featureName: undefined,
    pathname: undefined,
    trackingId: undefined,
    openModal: (modalProps: {
      featureName: Nudge
      elementId: string
      pathname: string
      trackingId?: string
    }) => {
      set({
        open: true,
        featureName: modalProps.featureName,
        pathname: modalProps.pathname,
        trackingId: modalProps.trackingId
      })
      apiFetchPlanTierListObject().then((planTierList) => {
        const planListId = planTierList.plan_list_id
        handleTrackPricePlanModalOpen({
          planListId,
          elementId: modalProps.elementId,
          pathname: modalProps.pathname
        })
      })
    },
    closeModal: () => {
      set({
        open: false,
        featureName: undefined,
        pathname: undefined,
        trackingId: undefined
      })
    }
  })
)

export const useMonetizationAwarenessModal = create(
  monetizationAwarenessModalStore
)
