import React from 'react'

import palette from '../../atoms/Colors'
import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() =>
  createStyles({
    circle: {
      borderRadius: '50%'
    },
    // Sonar ping animation
    ping: {
      animation: '$ping 3s cubic-bezier(0, 0, 0.2, 1) infinite'
    },
    '@keyframes ping': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1
      },
      '75%, 100%': {
        transform: 'scale(2.5)',
        opacity: 0
      }
    }
  })
)

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: number | string
  color?: string
  top?: number | string
  right?: number | string
  bottom?: number | string
  left?: number | string
}

const PingDot: React.FC<Props> = React.memo((props) => {
  const {
    style: styleProp,
    size = 8,
    top,
    right,
    bottom,
    left,
    color = palette.green[400],
    ...restProps
  } = props

  const classes = useStyles()
  return (
    <div
      style={{
        height: size,
        width: size,
        position: 'absolute',
        top: top,
        right: right,
        bottom: bottom,
        left: left,
        ...styleProp
      }}
      {...restProps}
    >
      <div
        className={clsx(classes.circle)}
        style={{
          position: 'absolute',
          backgroundColor: color,
          height: size,
          width: size
        }}
      />
      <div
        className={clsx(classes.circle, classes.ping)}
        style={{
          position: 'absolute',
          backgroundColor: color,
          height: size,
          width: size
        }}
      />
    </div>
  )
})

export default PingDot
