import React from 'react'

import Stack, { StackProps } from '../atoms/Stack'

interface ModalActionsProps extends StackProps {}

/**
 * Container for action buttons in BaseModal
 */
export const ModalActions: React.FC<ModalActionsProps> = React.memo(
  (props: ModalActionsProps) => {
    return <Stack {...props} />
  }
)
ModalActions.defaultProps = {
  direction: 'row',
  spacing: 1.5,
  justifyContent: 'flex-end'
}
