import React, { useEffect } from 'react'

import Lottie from 'lottie-react'
import { useIntercom } from 'react-use-intercom'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

import { handleTrackPricePlanModalOpen } from '@hypotenuse/common/src/api/Analytics'
import { apiFetchPlanTierListObject } from '@hypotenuse/common/src/api/PlanTier'
import {
  SimpleDialog,
  SimpleDialogProps
} from '@hypotenuse/common/src/components/molecules/SimpleDialog'
import humanHandsUpErrorAnimation from '@hypotenuse/common/src/lottie/human-hands-up-error.json'
import { CONTACT_US_LINK } from '@hypotenuse/common/src/utils/Constants'

import { User } from '../../utils/Interfaces'

import { PlanSelector } from '../../pages/settings/pricing/PricingPage'

export enum UserPlanType {
  trial,
  paid,
  enterprise,
  unlimited
}

export interface RatelimitCTAModalProps extends SimpleDialogProps {
  user?: User
  /**
   * Different content will be shown depending on this.
   * See component for more details.
   */
  userPlanType: UserPlanType
  refreshUser: () => void
}

interface PlanSelectorWithTrackingProps {
  user?: User
  refreshUser: () => void
}
const PlanSelectorWithTracking: React.FC<PlanSelectorWithTrackingProps> = (
  props
) => {
  const { user, refreshUser } = props
  useEffect(() => {
    const _fetchAndTrack = async () => {
      const planTierList = await apiFetchPlanTierListObject()
      const planListId = planTierList.plan_list_id
      handleTrackPricePlanModalOpen({
        planListId: planListId,
        elementId: 'rate-limit-price-plans-modal',
        pathname: window.location.pathname
      })
    }

    _fetchAndTrack()
  }, [])

  return (
    <PlanSelector
      user={user}
      refreshUser={refreshUser}
      showPaidPlansOnly
      trackingId="rate-limit-price-plans-modal"
    />
  )
}

export const RatelimitCTAModal: React.FC<RatelimitCTAModalProps> = (props) => {
  const { user, userPlanType, onClose, refreshUser, ...restDialogProps } = props
  const dialogProps = { onClose, ...restDialogProps }

  const theme = useTheme()
  const isTinyScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const { showNewMessages: openIntercom } = useIntercom()

  const planSelector = (
    <PlanSelectorWithTracking user={user} refreshUser={refreshUser} />
  )

  // If the user is in trial, we ask them to subscribe to a plan
  const trialUserHeader = (
    <Typography
      variant="h3"
      style={{ fontWeight: theme.typography.fontWeightMedium }}
    >
      You've reached your free trial limit.
    </Typography>
  )
  const trialUserCTA = (
    <>
      <Typography variant="h4">
        Subscribe to a plan to continue generating great content.
      </Typography>
      {planSelector}
    </>
  )

  // If the user is not in trial, we ask them to upgrade to a higher tier
  const paidUserHeader = (
    <Typography
      variant="h3"
      style={{ fontWeight: theme.typography.fontWeightMedium }}
    >
      You're generating a bit too quickly!
    </Typography>
  )
  const paidUserCTA = (
    <>
      <Typography variant="h4">
        Come back tomorrow, or upgrade your plan to unlock more great content.
      </Typography>
      {planSelector}
    </>
  )

  const unlimitedPlanUserHeader = (
    <Typography
      variant="h3"
      style={{ fontWeight: theme.typography.fontWeightBold }}
    >
      You've reached your content generation limit
    </Typography>
  )

  const unlimitedPlanUserCTA = (
    <>
      <Lottie
        loop={false}
        animationData={humanHandsUpErrorAnimation}
        style={{
          height: '60%',
          width: '50%',
          marginTop: '-10%',
          marginLeft: '10%'
        }}
      />
      <Typography
        variant="h5"
        style={{
          fontWeight: theme.typography.fontWeightBold,
          paddingBottom: theme.spacing(1)
        }}
      >
        You’ve reached your content generation limit
      </Typography>
      <Typography
        variant="h6"
        style={{ fontWeight: theme.typography.fontWeightLight }}
      >
        Hey Hypo User! We love helping you create content, but to ensure a fair
        experience for everyone, there's a limit to how much content can be
        generated in a given time according to our Fair Use Policy. <br />
        <br />
        You've reached your generation limit for now. Please wait a while before
        generating more content, or explore our other features. <br />
        <br />
        In the meantime, consider optimizing your content strategy or{' '}
        <Link href={CONTACT_US_LINK}>upgrading to an Enterprise plan</Link> for
        higher limits.
      </Typography>
    </>
  )

  // If the user is an enterprise user, we ask them to contact us
  const enterpriseUserHeader = (
    <Typography
      variant="h3"
      style={{ fontWeight: theme.typography.fontWeightMedium }}
    >
      You've reached your limit for this billing cycle.
    </Typography>
  )
  const enterpriseUserCTA = (
    <>
      <Typography variant="h4">
        Come back after your next billing refresh.
        <br></br>
        <br></br>
        Or, contact us for a higher limit.
      </Typography>
      <Button
        size="large"
        variant="contained"
        color="primary"
        style={{
          fontSize: theme.typography.h4.fontSize,
          fontWeight: theme.typography.fontWeightRegular
        }}
        onClick={() => {
          onClose?.()
          // For now, we just open the chat widget. Eventually we may want to create a dedicated event for this
          openIntercom()
        }}
      >
        Contact Us
      </Button>
    </>
  )
  const header = {
    [UserPlanType.enterprise]: enterpriseUserHeader,
    [UserPlanType.paid]: paidUserHeader,
    [UserPlanType.trial]: trialUserHeader,
    [UserPlanType.unlimited]: unlimitedPlanUserHeader
  }[userPlanType]
  const callToAction = {
    [UserPlanType.enterprise]: enterpriseUserCTA,
    [UserPlanType.paid]: paidUserCTA,
    [UserPlanType.trial]: trialUserCTA,
    [UserPlanType.unlimited]: unlimitedPlanUserCTA
  }[userPlanType]
  return (
    <SimpleDialog
      fullScreen={isTinyScreen}
      {...dialogProps}
      topDivider={userPlanType === UserPlanType.unlimited ? false : true}
      maxWidth={userPlanType === UserPlanType.unlimited ? 'sm' : false}
    >
      {userPlanType !== UserPlanType.unlimited && [
        <DialogTitle disableTypography>
          <Box textAlign="center">{header}</Box>
        </DialogTitle>,
        <Divider variant="middle" light />
      ]}
      <DialogContent>
        <Stack
          py={userPlanType === UserPlanType.unlimited ? 4 : 2}
          spacing={userPlanType === UserPlanType.unlimited ? 1 : 4}
          alignItems="center"
          textAlign="center"
          style={
            userPlanType === UserPlanType.unlimited
              ? {
                  paddingLeft: theme.spacing(10),
                  paddingRight: theme.spacing(10)
                }
              : {}
          }
        >
          {callToAction}
        </Stack>
      </DialogContent>
      <Divider variant="middle" light />
    </SimpleDialog>
  )
}
