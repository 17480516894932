import React from 'react'

import {
  Container,
  createStyles,
  makeStyles,
  useTheme
} from '@material-ui/core'

import BaseModal, {
  ModalContent
} from '@hypotenuse/common/src/components/BaseModal'
import ToolLibrary, {
  ToolLibraryHeader
} from '@hypotenuse/common/src/components/ToolLibrary'

import { IToolCardGroup } from './CardGroups'

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      margin: 0
    },
    left: {
      textAlign: 'left'
    },
    paperWidth: {
      maxWidth: '1280px'
    }
  })
)

interface ToolLibraryModalProps {
  open: boolean
  onClose: () => void
  onOpenChat?: () => void
  /**
   * A list of card groups to display.
   * If not provided, the default list of card groups will be used.
   */
  cardGroups?: readonly IToolCardGroup[]
}

export const ToolLibraryModal = (props: ToolLibraryModalProps) => {
  const classes = useStyles()
  const { open, onClose, onOpenChat, cardGroups } = props
  const theme = useTheme()
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      scroll="paper"
      classes={{ paperWidthXs: classes.paperWidth }}
      PaperProps={{
        // Keep the modal at full height even when there's not enough content
        style: { height: '100%' }
      }}
    >
      <ModalContent>
        <div
          style={{
            backgroundColor: theme.palette.background.paper,
            zIndex: 2,
            top: 0,
            padding: theme.spacing(2, 2, 0, 2),
            position: 'sticky'
          }}
        >
          <ToolLibraryHeader />
        </div>
        <Container
          maxWidth="lg"
          style={{
            paddingTop: theme.spacing(4)
          }}
        >
          <ToolLibrary onOpenChat={onOpenChat} cardGroups={cardGroups} />
        </Container>
      </ModalContent>
    </BaseModal>
  )
}
