import React from 'react'

import { Image } from 'semantic-ui-react'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import BugFixingImgSrc from '../../assets/bug_fixing.svg'

const useStyles = makeStyles((_theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ctaButton: {
    margin: '15px'
  },
  ctaButtonInner: {
    display: 'flex',
    alignItems: 'center'
  },
  centreText: {
    textAlign: 'center'
  },
  header: {
    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
    fontWeight: 'bold',
    fontSize: '56px',
    lineHeight: '74px',
    textAlign: 'center',
    letterSpacing: '0.05rem'
  },
  body: {
    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
    fontSize: '24px',
    lineHeight: '32px',
    textAlign: 'center',
    maxWidth: '700px',
    padding: '5px 0px'
  }
}))

interface Props {
  handleCta: Function
  textCta: string
  textBody: string
  textHeader: string
}

function GeneralError(props: Props) {
  const classes = useStyles()

  return (
    <div className={classes.box}>
      <div className={classes.message}>
        <Image src={BugFixingImgSrc} size="medium" />
        <span className={`${classes.centreText} ${classes.header}`}>
          {props.textHeader}
        </span>
        <span className={`${classes.centreText} ${classes.body}`}>
          {props.textBody}
        </span>
      </div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.ctaButton}
        onClick={() => props.handleCta()}
      >
        <div className={classes.ctaButtonInner}>{props.textCta}</div>
      </Button>
    </div>
  )
}

export default GeneralError
