import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'

import { StripeBillingInfo } from '../components/utils/Interfaces'

export const fetchUserBillingInfo = (): Promise<StripeBillingInfo> => {
  return apiClient.get('/billing_info').then((resp) => resp.data)
}

export const handleCreateStripeCustomerPortal = (): Promise<string> => {
  return apiClient
    .post('/create_stripe_customer_portal_session')
    .then((resp) => {
      return resp.data.portal_url
    })
}

export const handleCreateStripeCheckoutSession = (
  plan_id: string
): Promise<string> => {
  return apiClient
    .post(`/create_stripe_checkout_session/${plan_id}`)
    .then((resp) => {
      return resp.data.session_id
    })
}

/**
 * This function is used to create a Shopify checkout session.
 * This is only being used for the reviewer to go through the Shopify installation process.
 */
export const handleCreateShopifyCheckoutSession = () => {
  return apiClient
    .post(`/external-integrations/shopify/create_shopify_checkout_session`)
    .then((resp) => {
      return resp.data.confirmation_url
    })
    .then((confirmationUrl) => {
      window.location.href = confirmationUrl as string
    })
    .catch((err) => {
      if (err.response.status === 403) {
        // This happens because the user uninstalls our app on Shopify and does not have an access token to link to Shopify
        snackbar.show(
          'Access token for Shopify is missing. Please reinstall the app on Shopify to subscribe to a Shopify plan.',
          { variant: 'error' }
        )
        return
      } else {
        snackbar.show(
          'An error occurred while creating your Shopify subscription. Please try again.',
          { variant: 'error' }
        )
      }
    })
}

export const handleCancelShopifySubscription = () => {
  return apiClient.post('/external-integrations/shopify/cancel-subscription')
}

export const apiGetStripeInvoicePaymentLink = (
  invoice_id: string
): Promise<string> => {
  return apiClient
    .get('/stripe-invoice-payment-link', {
      params: { invoice_id }
    })
    .then((resp) => {
      return resp.data
    })
}
