import React from 'react'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Box, useTheme } from '@material-ui/core'

import { User } from '../../utils/Interfaces'

import GeneralModal from '../../GeneralModal'
import { PlanSelector } from '../../pages/settings/pricing/PricingPage'

interface TrialEndedModalProps {
  user: User
  refreshUser: () => void
}

export const TrialEndedModal = (props: TrialEndedModalProps) => {
  const { user, refreshUser } = props

  const theme = useTheme()
  const headerBackgroundColor = theme.palette.text.primary

  return (
    <GeneralModal
      isOpen // Cannot be closed
      size="large"
      header={
        <Typography
          align="center"
          variant="h4"
          style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1rem' }}
        >
          Your free trial has ended
        </Typography>
      }
      headerStyle={{ backgroundColor: headerBackgroundColor }}
      body={
        <Box width={'85%'}>
          <Typography align="center" variant="h6" style={{ fontWeight: 400 }}>
            Upgrade now to keep generating more high-conversion copy and
            content.
          </Typography>
          <Box pt={4}>
            <PlanSelector
              user={user}
              showPaidPlansOnly
              refreshUser={refreshUser}
              trackingId="trial-ended-modal"
            />
          </Box>
        </Box>
      }
      bodyStyle={{ display: 'flex', justifyContent: 'center' }}
      overrideModalActions={<></>}
      onClose={() => {}}
      closeIconDisabled={true}
    />
  )
}
