import {
  CatalogueOverviewResponse,
  GlobalAppState
} from '../../components/utils/Interfaces'

import { GlobalAppAction } from '../Interfaces'
import { GLOBAL_APP_ACTION } from '../actions/GlobalAppContext'

export const initialState: GlobalAppState = {
  catalogues: {},
  cataloguesList: [],
  catTopThreeProducts: {},
  catalogues_loading: false,
  defaultDownvoteReasons: [],
  brandPlaceholderText: '',
  brandHelperText: '',
  blogToneOptions: [],
  blogLengthOptions: [],
  globalFeatureFlags: {}
}

export const globalAppReducer = (
  state: GlobalAppState,
  action: GlobalAppAction
) => {
  const sortBy = (
    a: CatalogueOverviewResponse,
    b: CatalogueOverviewResponse
  ) => {
    // sortBy creationDate in v1
    const dateA = new Date(a.createdAt)
    const dateB = new Date(b.createdAt)
    return dateB.getTime() - dateA.getTime()
  }
  let cat = { ...state.catalogues }
  switch (action.type) {
    case GLOBAL_APP_ACTION.CLEAR_CATALOGUES:
      return {
        ...state,
        catalogues: {},
        cataloguesList: []
      }
    case GLOBAL_APP_ACTION.UPDATE_CATALOGUES:
      cat = {
        ...cat,
        ...action.payload
      }
      return {
        ...state,
        catalogues: {
          ...cat
        },
        cataloguesList: Object.values(cat).sort(sortBy)
      }
    case GLOBAL_APP_ACTION.DELETE_CATALOGUES:
      delete cat[action.payload]
      return {
        ...state,
        catalogues: {
          ...cat
        },
        cataloguesList: Object.values(cat).sort(sortBy)
      }
    case GLOBAL_APP_ACTION.UPDATE_TOP_THREE_PRODUCTS:
      return {
        ...state,
        catTopThreeProducts: {
          ...state.catTopThreeProducts,
          ...action.payload
        }
      }
    case GLOBAL_APP_ACTION.GLOBAL_FEATURE_FLAGS:
      return {
        ...state,
        globalFeatureFlags: {
          ...action.payload
        }
      }
    default:
      return {
        ...state,
        [action.type]: action.payload
      }
  }
}
