import React, { useEffect, useState } from 'react'

import { Search } from '@styled-icons/feather/Search'

import palette from '../atoms/Colors'
import { Box, IconButton, InputAdornment } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'

interface Props {
  handleOnSearch: Function
  searchTerm: string
  placeholder: string
  margin?: 'normal' | 'none' | 'dense'
  variant?: 'filled' | 'standard' | 'outlined'
  size?: 'medium' | 'small'
  fullWidth?: boolean
}

const useStyles = makeStyles((_theme) => ({
  outerDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'middle',
    justifyContent: 'space-between'
  },
  noMargin: {
    marginTop: 0,
    marginBottom: 0
  }
}))

export const whiteBackgroundInputStyles = createStyles({
  root: {
    '& .MuiInputLabel-root': {
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"
    },
    backgroundColor: 'white',
    borderRadius: '8px',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    }
  }
})
const StyledTextField = withStyles(whiteBackgroundInputStyles)(TextField)

export default function SearchTab(props: Props) {
  // prev search is used to prevent on blur from being accidentally called during a click
  const {
    searchTerm,
    placeholder,
    margin,
    variant,
    size,
    fullWidth,
    handleOnSearch
  } = props
  const [prevSearch, setPrevSearch] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')
  const classes = useStyles()

  useEffect(() => {
    setSearchText(searchTerm)
  }, [searchTerm])

  return (
    <StyledTextField
      className={classes.noMargin}
      label={placeholder}
      margin={margin}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      onKeyPress={(event: any) => {
        if (event.key === 'Enter' && searchText.length > 0) {
          handleOnSearch(searchText)
          setPrevSearch(searchText)
        }
      }}
      onBlur={() => {
        if (searchText === '' && searchText !== prevSearch) {
          handleOnSearch(searchText)
          setPrevSearch(searchText)
        }
      }}
      InputProps={{
        type: 'search',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                handleOnSearch(searchText)
                setPrevSearch(searchText)
              }}
            >
              <Search />
            </IconButton>
          </InputAdornment>
        )
      }}
      value={searchText}
      onChange={(event: any) => setSearchText(event.target.value)}
    />
  )
}
SearchTab.defaultProps = {
  variant: 'outlined',
  margin: 'normal',
  size: 'small',
  fullWidth: true
}

export interface DebouncedSearchProps extends Props {
  debouncedTiming: number
}

export function SearchBarWithDebounce(props: DebouncedSearchProps) {
  // prev search is used to prevent on blur from being accidentally called during a click
  const {
    searchTerm,
    placeholder,
    margin,
    variant,
    size,
    fullWidth,
    handleOnSearch,
    debouncedTiming
  } = props
  const [prevSearch, setPrevSearch] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')
  // Set timeout on search catalogue input (debouncing)
  const [searchTimer, setSearchTimer] = useState<ReturnType<
    typeof setTimeout
  > | null>(null)
  const classes = useStyles()

  useEffect(() => {
    setSearchText(searchTerm)
  }, [searchTerm])

  return (
    <StyledTextField
      className={classes.noMargin}
      placeholder={placeholder}
      margin={margin}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      onKeyPress={(event: any) => {
        if (event.key === 'Enter') {
          handleOnSearch(searchText)
          setPrevSearch(searchText)
        }
      }}
      onBlur={() => {
        if (searchText === '' && searchText !== prevSearch) {
          handleOnSearch(searchText)
          setPrevSearch(searchText)
        }
      }}
      InputProps={{
        type: 'search',
        startAdornment: (
          <Box mr={1}>
            <Search
              size={20}
              style={{ color: palette.gray[500] }}
              strokeWidth={2}
            />
          </Box>
        )
      }}
      value={searchText}
      onChange={(event: any) => {
        setSearchText(event.target.value)
        if (searchTimer) {
          clearTimeout(searchTimer)
        }
        setSearchTimer(
          setTimeout(() => {
            handleOnSearch(event.target.value)
            setPrevSearch(event.target.value)
          }, debouncedTiming)
        )
      }}
    />
  )
}

SearchBarWithDebounce.defaultProps = {
  variant: 'outlined',
  margin: 'normal',
  size: 'small',
  fullWidth: true
}
