import React from 'react'

import Stack, { StackProps } from '../atoms/Stack'
import { Dialog, DialogProps } from '@material-ui/core'

export interface ModalProps extends DialogProps {
  /**
   * Spacing between elements at the top level of the modal
   */
  spacing?: StackProps['spacing']
  /**
   * Padding around modal content
   */
  padding?: StackProps['padding']
  /**
   * Other props to be passed to the internal stack component
   */
  stackProps?: StackProps
}

/**
 * Reusable Modal component.
 * Prefer this over GeneralModal (which uses Semantic UI)
 */
export const BaseModal: React.FC<ModalProps> = React.memo((props) => {
  const { spacing, children, padding, stackProps, ...rest } = props
  return (
    <Dialog {...rest}>
      <Stack padding={padding} spacing={spacing} {...stackProps}>
        {children}
      </Stack>
    </Dialog>
  )
})
BaseModal.defaultProps = {
  spacing: 4,
  padding: 3,
  maxWidth: 'xs',
  fullWidth: true
}
