import { apiClient } from '../utils/ApiClient'
import { UserOnboardingInfoForm } from '../utils/Interfaces'
import snackbar from '../utils/Snackbar'

export const getUserOnboardInfo = async (): Promise<UserOnboardingInfoForm> => {
  const response = await apiClient.get<UserOnboardingInfoForm>(
    '/user/onboarding_info'
  )
  return response.data
}

export const apiUpdateUserServiceConfigs = async (key: string, value: any) => {
  try {
    await apiClient.put('/user/service-config', {
      key: key,
      value: value
    })
    return true
  } catch (e) {
    snackbar.show('Failed to update service config', { variant: 'error' })
    return false
  }
}
