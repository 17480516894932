import { useMemo } from 'react'

import useSWR from 'swr'

import { apiFetchPlanTierListObject } from '@hypotenuse/common/src/api/PlanTier'
import { TrialBannerCopy } from '@hypotenuse/common/src/utils/Interfaces'
import { useUserContext } from '@hypotenuse/common/src/utils/context/UserContext'

import usePremiumPlanTier from '../../../../hooks/usePremiumPlanTier'

import { ShopifyPlanListId } from '../../../../utils/Constants'
import { isWordsEnabled } from '../../../../utils/Functions'
import { PlanType } from '../../../utils/Interfaces'

import { TrialBannerBaseProps } from './TrialBannerBase'

const MILLI_TO_HOURS = 1000 * 60 * 60
const NUM_HOURS_IN_A_DAY = 24

const getBannerCopy = (
  isWordsFeatureEnabled: boolean,
  trialDaysLeft?: number,
  trialBannerCopy?: TrialBannerCopy
) => {
  // NOTE: Take care not to allow `undefined` to appear in the banner's content
  if (trialBannerCopy) {
    const bannerTitle = trialDaysLeft
      ? `Your trial ends in ${
          trialDaysLeft === 1 ? '1 day.' : `${trialDaysLeft} days.`
        } ${trialBannerCopy.trial_title}`
      : trialBannerCopy.post_trial_title

    const bannerSubtitle = trialDaysLeft
      ? trialBannerCopy.trial_subtitle
      : trialBannerCopy.post_trial_subtitle
    return { bannerTitle, bannerSubtitle }
  } else {
    const bannerTitle = trialDaysLeft
      ? `Your trial ends in ${
          trialDaysLeft === 1 ? '1 day.' : `${trialDaysLeft} days.`
        }`
      : 'Speed up your writing with AI'

    const bannerSubtitle = trialDaysLeft
      ? `To get more ${
          isWordsFeatureEnabled ? 'words' : 'credits'
        } and continue generating content, subscribe to a plan`
      : `Subscribe to a plan to get ${
          isWordsFeatureEnabled ? 'words' : 'credits'
        } and unlock more powerful features.`
    return { bannerTitle, bannerSubtitle }
  }
}

export const useTrialBanner = (): TrialBannerBaseProps & { show: boolean } => {
  const { data: premiumPlanTier } = usePremiumPlanTier()

  const { data: planTierListObject } = useSWR(
    'planTierListObject',
    apiFetchPlanTierListObject
  )

  const { trial_banner_copy: trialBannerCopy } = planTierListObject ?? {}

  const { user } = useUserContext()
  const isWordsFeatureEnabled = isWordsEnabled(user)
  const {
    plan_type,
    trial_plan_start_datetime,
    trial_plan_length: trialLengthInDays
  } = premiumPlanTier ?? {}
  const isFreeUser = plan_type === PlanType.free

  /**
   * If the user is on the Shopify plan tier list, we don't show the trial banner.
   */
  const isUserOnShopifyPlanTierList =
    planTierListObject?.plan_list_id === ShopifyPlanListId

  /**
   * The number of days left in the user's trial.
   * Equals 1 if the user's trial has less than 24 hours left.
   * Undefined if the user is not on a trial or if the trial has expired.
   */
  const trialDaysLeft = useMemo(() => {
    // If the user is not on a trial, return undefined
    if (!trial_plan_start_datetime || !trialLengthInDays) return

    /**
     * The timestamp (in milliseconds) when the user's trial started.
     * I've cast this to Date (even though it's already typed as Date) because
     * the backend sends this as a string, and I don't want this to break if the
     * axios interceptor that converts strings to dates stops working.
     */
    const trialStartTimestamp = new Date(trial_plan_start_datetime).getTime()
    /**
     * Length of the trial in milliseconds
     */
    const trialLengthInMilliseconds =
      trialLengthInDays * NUM_HOURS_IN_A_DAY * MILLI_TO_HOURS
    /**
     * Milliseconds since the user's trial started.
     */
    const timeSinceTrialStart = Date.now() - trialStartTimestamp
    /**
     * Milliseconds left in the user's trial.
     */
    const trialMillisecondsLeft =
      trialLengthInMilliseconds - timeSinceTrialStart

    // If the user's trial is determined to have expired, return undefined
    if (trialMillisecondsLeft <= 0) return

    // Rounding up to 1 day if the user's trial has less than 24 hours left
    return Math.ceil(
      trialMillisecondsLeft / MILLI_TO_HOURS / NUM_HOURS_IN_A_DAY
    )
  }, [trialLengthInDays, trial_plan_start_datetime])

  const showBanner = isFreeUser && !isUserOnShopifyPlanTierList

  const { bannerTitle, bannerSubtitle } = getBannerCopy(
    isWordsFeatureEnabled,
    trialDaysLeft,
    trialBannerCopy
  )

  const ctaButtonLabel = 'Unlock'

  return {
    show: showBanner,
    bannerTitle,
    bannerSubtitle,
    ctaButtonLabel,
    elementId: !!trialDaysLeft ? 'trial-banner' : 'post-trial-banner'
  }
}
