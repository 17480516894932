import React, { useCallback } from 'react'

import { useIntercom } from 'react-use-intercom'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton
} from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import errorIcon from '@hypotenuse/common/src/assets/error.svg'
import { useIsMobile } from '@hypotenuse/common/src/hooks/UseIsMobile'
import {
  CURRENCY_SYMBOL_MAPPING,
  Currency
} from '@hypotenuse/common/src/utils/Interfaces'

import usePremiumPlanTier from '../../../hooks/usePremiumPlanTier'
import useUserCountryCode from '../../../hooks/useUserCountryCode'

interface PaymentFailureModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainBanner: {
      borderColor: palette.error[300],
      backgroundColor: palette.error[50]
    },
    content: {
      color: palette.error[600]
    },
    planName: {
      color: palette.error[800]
    },
    updateBtn: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white
      }
    }
  })
)

const PayInvoiceModal = (props: PaymentFailureModalProps) => {
  const { isOpen, setIsOpen } = props
  const classes = useStyles()
  const isMobile = useIsMobile()
  const {
    data: premiumPlanTier,
    latestUnpaidInvoicePaymentLink
  } = usePremiumPlanTier()
  const { showNewMessages: openIntercom } = useIntercom()

  // use the calculation from backend instead of recomputing again
  const overdueDays = premiumPlanTier?.payment_failure_info?.overdueDays ?? 0

  const { userCountryCode } = useUserCountryCode()

  const amountDue =
    premiumPlanTier?.plan_price_mapping?.[userCountryCode]?.price ??
    premiumPlanTier?.plan_price ??
    0

  /**
   * Get the currency of the plan tier
   * plan_price_mapping is an attribute specifically for user in the promotion countries
   * Other users will use plan_currency in lower case since CURRENCY_SYMBOL_MAPPING accepts
   * lowercase currency
   */
  const currency: Currency =
    premiumPlanTier?.plan_price_mapping?.[userCountryCode]?.currency ??
    premiumPlanTier?.currency ??
    Currency.usd

  /**
   * Get the currency symbol of the plan tier
   * Default to USD if plan tier does not have currency
   */
  const currencySymbol = CURRENCY_SYMBOL_MAPPING[currency] ?? '$'

  const planName = premiumPlanTier?.plan_display_name

  const declineMessage = useCallback(() => {
    return (
      <Typography
        variant="body1"
        style={{
          whiteSpace: 'pre-line',
          color: palette.gray[500]
        }}
      >
        Uh oh! It looks like there was an issue with your recent card payment.
        <br />
        <br />
        To proceed with the plan change, we kindly ask you to clear the
        outstanding invoice. Get in touch with your card issuer for more details
        or use a different card to make the payment.
      </Typography>
    )
  }, [])

  const planBanner = useCallback(
    () => (
      <Stack
        direction="row"
        className={classes.mainBanner}
        borderRadius={8}
        border={1}
        p={1}
        spacing={1}
        width="100%"
        alignContent="center"
      >
        <Box>
          <img src={errorIcon} alt="error" style={{ margin: 'auto' }} />
        </Box>
        <Stack justifyContent="center">
          <Typography variant="body1" className={classes.content}>
            {planName} Plan {currencySymbol} {amountDue}/month
          </Typography>
          <Typography variant="body1" className={classes.content}>
            {overdueDays < 1
              ? 'Payment is due today'
              : overdueDays === 1
              ? 'Payment due 1 day ago'
              : `Payment due ${overdueDays} days ago`}
          </Typography>
        </Stack>
      </Stack>
    ),
    [
      amountDue,
      classes.content,
      classes.mainBanner,
      currencySymbol,
      overdueDays,
      planName
    ]
  )

  const dialogContent = useCallback(
    () => (
      <>
        <Typography variant="h6">
          Clear outstanding invoice to change plan
        </Typography>
        {declineMessage()}
        <Stack direction="row">
          <Typography
            variant="body1"
            style={{
              color: palette.gray[500]
            }}
          >
            If this invoice is incorrect, &nbsp;
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: palette.blue[500],
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => openIntercom()}
          >
            please reach out to our support team.
          </Typography>
        </Stack>
      </>
    ),
    [declineMessage, openIntercom]
  )

  /**
   * Prevent enterprise user from seeing the payment failure modal
   */
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={isMobile ? 'xs' : 'sm'}
      disableEnforceFocus
    >
      <DialogContent>
        <Stack spacing={2} pb={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <img src={errorIcon} alt="error" style={{ margin: 'auto' }} />
            </Box>
            <IconButton
              onClick={() => {
                setIsOpen(false)
              }}
              style={{ height: '10%', padding: '5px' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {dialogContent()}
          {planBanner()}
          <Button
            variant="contained"
            color="primary"
            className={classes.updateBtn}
            component="a"
            href={latestUnpaidInvoicePaymentLink}
          >
            View invoice
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default PayInvoiceModal
