import { useEffect, useRef } from 'react'

/**
 * Hook that returns true on a component's first render after mount & false on subsequent renders.
 * Note that if a component is unmounted & remounted, this hook will return true again.
 */
export const useIsFirstRender = (): boolean => {
  const isFirstRender = useRef(true)
  useEffect(() => {
    isFirstRender.current = false
    return () => {
      // This is required in StrictMode because the component will be unmounted & remounted
      // by React to check for side effects.
      isFirstRender.current = true
    }
  }, [])
  return isFirstRender.current
}
