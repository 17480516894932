/**
 * Low-level interface to the content generation API.
 * Avoid using these functions directly. Instead, prefer the higher
 * level generation API hook or HOC.
 */
import { AxiosRequestConfig } from 'axios'

import {
  AdTextFolderSearchQuery,
  AdTextType
} from '../interfaces/AdvertisingText'
import {
  ContentGenerationType,
  GenerationInputType
} from '../interfaces/ContentGeneration'
import {
  CommandType,
  HighlightCommandTrigger
} from '../interfaces/HighlightCommand'
import {
  Description,
  ProductDescriptionGenerationResponse,
  ProductSearchQuery
} from '../interfaces/Products'
import { ToneFields } from '../interfaces/Tone'

import { apiClient } from '../utils/ApiClient'
import { SERVER } from '../utils/Constants'
import { CountryDetail } from '../utils/Interfaces'

import { EntityReference } from '../components/entityReference/types'

/**
 * Wrapper around AxiosRequestConfig to add typing for the `params` property
 */
export interface IGenerationAPIRequestConfig<Payload = void, Params = void>
  extends Omit<AxiosRequestConfig<Payload>, 'params'> {
  params?: Params
}

// ===== Generation API =====
/**
 * URL Query params for the content generation API endpoint
 */
export interface IGenerationAPIParams {
  // TODO: Specifying both generation_type and copy_type is redundant - unify.
  /**
   * The type of content to generate
   */
  generation_type: ContentGenerationType
  /**
   * Ad text copy type (to be deprecated)
   * @deprecated
   */
  copy_type?: AdTextType
  /**
   * Product ID to be sent as query parameter to escape (starlette's) FastAPI's issue with escape sequencing
   * See: https://github.com/encode/starlette/issues/826
   */
  productId: string

  /**
   * Number of outputs to generate
   */
  num_outputs?: number
  /**
   * Whether to split the output into two
   * Currently only used for meta description generation
   *
   * TODO: Remove this once we deprecated the old meta description generation
   */
  split_output?: boolean
  /**
   * Whether user has enabled SEO mode
   */
  is_seo_mode_enabled?: boolean
  is_referencing_knowledge_base?: boolean
  is_rewriter_generation?: boolean
}

/**
 * Request body for the content generation API endpoint
 */
export interface IGenerationAPIPayload {
  stepper_tuples?: [number, number][]
  /**
   * List of entities to be referenced
   */
  entity_references?: readonly EntityReference[]
  /**
   * List of headers to be outlined
   */
  outline_headers?: string[]
  /**
   * Link provided by user to scrape
   */
  reference_links?: string[]
  /**
   * ID of file provided by user to be used for generation
   */
  reference_file_ids?: string[]
  /**
   * Country provided by user for the article to be centered around
   */
  seo_region?: CountryDetail
  catalogue_template_name?: string
}

/**
 * Call the content generation API.
 * Take note that this function doesn't handle any errors that the endpoint may return.
 * Errors are currently handled in the GenerationAPIProvider hook where this fn is used. If you're
 *  calling this function directly, make sure you handle errors such as rate-limiting, credit exhaustion, etc.
 */
export const apiGenerateContent = async (
  requestConfig: IGenerationAPIRequestConfig<
    IGenerationAPIPayload,
    IGenerationAPIParams
  >
) => {
  const { data, ...config } = requestConfig
  return await apiClient.post<void>(
    '/describe/product/', // productId as a query parameter in finalConfig.params
    data,
    config
  )
}

/**
 * Generates an entire blog article
 */
export const apiGenerateBlogAdText = async (
  requestConfig: IGenerationAPIRequestConfig<
    IGenerationAPIPayload,
    IGenerationAPIParams
  >
) => {
  const { data, ...config } = requestConfig
  return await apiClient.post<void>(
    '/describe/blog-adtext/', // productId as a query parameter in finalConfig.params
    data,
    config
  )
}

// ==== Write more API =====
/**
 * Context for content generation endpoint
 */
export interface WriteMoreGenerationContext {
  prefix: string
  suffix?: string
  should_continue_line: boolean
}

/**
 * Body for the write more API endpoint
 */
export type IWriteMoreAPIPayload = WriteMoreGenerationContext

/**
 * Axios request config for the write more API endpoint. Has a different payload
 * from the normal request config.
 */
export interface IWriteMoreAPIRequestConfig
  extends AxiosRequestConfig<IWriteMoreAPIPayload> {
  params: IGenerationAPIParams
}

export interface ComposeContext {
  prefix: string
  suffix?: string
  title?: string
  document_id: string
}

export type IComposeAPIPayload = ComposeContext

export interface IComposeAPIRequestConfig
  extends AxiosRequestConfig<IComposeAPIPayload> {}

/**
 * New compose API. This API can be used by all content types
 * which has an editor
 */
export const apiCompose = async (requestConfig: IComposeAPIRequestConfig) => {
  const { data, ...config } = requestConfig
  let body = {
    document_id: data?.document_id,
    context: {
      prefix: data?.prefix,
      suffix: data?.suffix,
      title: data?.title
    }
  }
  return await apiClient.post<Description>('/compose', body, config)
}

export interface HighlightCommandContext {
  // TODO: Finalise shape of payload / decide if we are sending only selection to LM
  prefix?: string
  selection: string
  suffix?: string
  title?: string
  document_id: string
  commandType: CommandType
  customPrompt?: string
  additionalPromptInputs?: Record<string, string>
  commandTrigger?: HighlightCommandTrigger
}

export type IHighlightCommandAPIPayload = HighlightCommandContext
export interface IHighlightCommandAPIRequestConfig
  extends AxiosRequestConfig<IHighlightCommandAPIPayload> {}

/**
 * Highlight commands API. This API can be used by all content types
 * which has an editor
 */
export const apiHighlightCommand = async (
  requestConfig: IHighlightCommandAPIRequestConfig
) => {
  const { data, ...config } = requestConfig
  let body = {
    document_id: data?.document_id,
    command_type: data?.commandType,
    command_trigger: data?.commandTrigger,
    custom_prompt: data?.customPrompt,
    context: {
      prefix: data?.prefix,
      selection: data?.selection,
      suffix: data?.suffix,
      title: data?.title,
      additional_prompt_inputs: data?.additionalPromptInputs
    }
  }
  return await apiClient.post<Description>('/highlight_command', body, config)
}

export interface HighlightResearchCommandContext {
  prefix?: string
  selection: string
  suffix?: string
  title?: string
  document_id: string
  commandType: CommandType
  customPrompt?: string
  commandTrigger?: HighlightCommandTrigger
}

export type HighlightResearchCommandAPIPayload = HighlightResearchCommandContext
export interface HighlightResearchCommandAPIRequestConfig
  extends AxiosRequestConfig<HighlightResearchCommandAPIPayload> {}

export const apiHighlightResearchCommand = async (
  requestConfig: HighlightResearchCommandAPIRequestConfig
) => {
  const { data, ...config } = requestConfig
  let body = {
    document_id: data?.document_id,
    command_type: data?.commandType,
    command_trigger: data?.commandTrigger,
    custom_prompt: data?.customPrompt,
    context: {
      prefix: data?.prefix,
      selection: data?.selection,
      suffix: data?.suffix,
      title: data?.title
    }
  }
  return await apiClient.post<Description>('/highlight-research', body, config)
}

export interface ToneAnalyzerContext {
  inputType: GenerationInputType
  inputValue: string
}

export type IToneAnalyzerAPIPayload = ToneAnalyzerContext

export interface IToneAnalyzerAPIRequestConfig
  extends AxiosRequestConfig<IToneAnalyzerAPIPayload> {}

export const apiAnalyzeTone = async (
  requestConfig: IToneAnalyzerAPIRequestConfig
) => {
  const { data, ...config } = requestConfig
  let body = {
    input_type: data?.inputType,
    input_value: data?.inputValue
  }
  return await apiClient.post<ToneFields>('/tone/analyze', body, config)
}

// TODO: Standardize batch generation across Phoenix & Tako.

export interface GenerateBatchInRequest {
  productIds?: string[]
  prodSearchQuery?: ProductSearchQuery
  adTextQuery?: AdTextFolderSearchQuery
  jobName: string | undefined
  generationType: ContentGenerationType
  splitOutput?: boolean
  catalogueTemplateName?: string
}

//  Currently blocked by differing interfaces/flows.
export const apiGenerateBatch = async (
  requestConfig: GenerateBatchInRequest
) => {
  let body = {
    product_ids: requestConfig.productIds,
    prod_desc_query: requestConfig.prodSearchQuery,
    ad_text_query: requestConfig.adTextQuery,
    job_name: requestConfig.jobName,
    generation_type: requestConfig.generationType,
    split_output: requestConfig.splitOutput
  }
  return apiClient.post('/describe/products', body)
}

export const apiCheckContentFollowsBrandGuidelines = async (
  descriptions: string[],
  brands: string[]
) => {
  const response = await apiClient.post<boolean[]>(`/check-brand-guidelines`, {
    descriptions: descriptions,
    brands: brands
  })
  return response.data
}

/**
 * Pipeline-based product description generation endpoint.
 *
 * @note This endpoint uses callback method to generate product descriptions.
 * It is functionally close to `/describe/product/` endpoint but uses a different
 * method to generate descriptions.
 *
 * @param productId Product ID for which to generate a product description
 */
export const apiGenerateProductDescriptionWithCallback = async (
  productId: string,
  toneIds: string[],
  retailerId?: string,
  catalogueTemplateName?: string
) => {
  const response = await apiClient.post<ProductDescriptionGenerationResponse>(
    `${SERVER}/api/v2/generation/product-description`,
    {
      product_id: productId,
      tone_ids: toneIds,
      retailer_id: retailerId,
      catalogue_template_name: catalogueTemplateName
    }
  )
  return response.data
}
