import React from 'react'

import { Box, BoxProps } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export interface StackProps
  extends Omit<BoxProps, 'display' & 'flexDirection'> {
  direction?: BoxProps['flexDirection']
  spacing?: number
}

/**
 * Polyfill for MUI v5's `Stack` component.
 * @deprecated Switch to MUI v5's native component ASAP.
 */
const Stack = React.forwardRef<HTMLElement, StackProps>((props, ref) => {
  const { direction = 'column', spacing = 0, style, ...restProps } = props
  const theme = useTheme()

  return (
    // Passing ref to Box is not supported in MUI v4's type system, so we use a typescript hack to do this for now
    // See https://github.com/mui/material-ui/issues/17010#issuecomment-615577360
    <Box
      display="flex"
      flexDirection={direction}
      style={{ gap: theme.spacing(spacing), ...style }}
      {...({ ref } as any)}
      {...restProps}
    />
  )
})

export default Stack
