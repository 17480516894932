import React, { HTMLAttributes } from 'react'

import { Image } from 'semantic-ui-react'
import { ImageProps } from 'semantic-ui-react/dist/commonjs/elements/Image/Image'

import LogoTextDarkSvg from '../assets/hypotenuse_logo_text_dark.svg'
import LogoTextLightSvg from '../assets/hypotenuse_logo_text_light.svg'
import { ReactComponent as LogoSvg } from '../assets/logo.svg'
import LogoLegacyPng from '../assets/logo_100x100.png'
import LogoStackedPng from '../assets/logo_stacked.png'

/**
 * The new, solid logo (square image, no text)
 */
export const Logo = LogoSvg
Logo.defaultProps = {
  style: { maxWidth: '22px', imageRendering: '-webkit-optimize-contrast' }
}

/**
 * The old logo (with the gradient)
 */
export function LogoLegacy() {
  return <img src={LogoLegacyPng} alt="Logo" />
}

export interface LogoTextProps extends HTMLAttributes<HTMLImageElement> {
  className?: string
  light?: boolean
  alt_text?: string
  height?: string
}

/**
 * Logo on the left, name on the right
 */
export const LogoText: React.FC<LogoTextProps> = (props: LogoTextProps) => {
  const { className, light, height = '22px', ...rest } = props
  return (
    <img
      src={light ? LogoTextLightSvg : LogoTextDarkSvg}
      alt="Hypotenuse AI logo"
      className={className}
      style={{
        imageRendering: '-webkit-optimize-contrast',
        height: height,
        display: 'inline-block',
        verticalAlign: 'middle'
      }}
      {...rest}
    />
  )
}

/**
 * Logo on top, name below
 */
export const LogoStacked: React.FC<Omit<ImageProps, 'src'>> = (props) => {
  return <Image src={LogoStackedPng} {...props} />
}
LogoStacked.defaultProps = { size: 'small' }

/**
 * Logo on top, name below
 */
export const CustomLogo: React.FC<ImageProps> = (props) => {
  return <Image {...props} />
}
CustomLogo.defaultProps = { size: 'small' }
