import React, { useEffect, useRef, useState } from 'react'

import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { useLocation } from 'react-router-dom'

import { SpinnerOnDimmer } from '../Loaders'

interface RequiresAPIAccessTokenProps {
  /**
   * Whether the user's authentication status is loading
   */
  isAuthLoading: boolean
  /**
   * Whether the user is authenticated.
   */
  isAuthenticated: boolean
  /**
   * Callback to fetch an API access token.
   */
  getAccessToken: () => Promise<string>
  /**
   * Callback to redirect to login page.
   * This is called if the user is not authenticated.
   */
  redirectToLogin: () => void
  /**
   * The axios instance for which to perform authentication.
   * This component will automatically set the `Authorization` header
   * for this client's requests to the user's `access_token` for the API.
   */
  apiClient: AxiosInstance
  /**
   * If true, this component is a no-op/pass-through.
   */
  disabled?: boolean
}

/**
 * Wrapper that requires the user to be
 * authenticated AND have an access token for an API.
 *
 * Attempts to fetch the user's access token for the API and adds a request
 * interceptor to set the Authorization header for all subsequent API requests.
 *
 * Redirects the user to login if the user is not authenticated.
 */
export const RequiresAPIAccessToken: React.FC<RequiresAPIAccessTokenProps> = (
  props
) => {
  const {
    disabled,
    isAuthLoading,
    isAuthenticated,
    getAccessToken,
    redirectToLogin,
    apiClient,
    children
  } = props

  // If the user is not authenticated, redirect to the login page.
  const shouldRedirectToLogin = !disabled && !isAuthenticated && !isAuthLoading
  const location = useLocation()
  useEffect(() => {
    if (shouldRedirectToLogin) {
      redirectToLogin()
    }
  }, [
    shouldRedirectToLogin,
    redirectToLogin,
    location.pathname,
    location.search
  ])

  // Keep track of the interceptor ID and whether we've added it yet.
  const authInterceptorRef = useRef<number | undefined>(undefined)
  // hasAuthInterceptor is state because the component should re-render (and
  // deny access) if the user no longer has an auth interceptor.
  const [hasAuthInterceptor, setHasAuthInterceptor] = useState<boolean>(false)
  // If the user is authenticated but we don't have an interceptor yet, add one.
  const shouldAddAuthInterceptor =
    !disabled && isAuthenticated && !hasAuthInterceptor
  useEffect(() => {
    const removeAuthInterceptor = () => {
      if (authInterceptorRef.current) {
        apiClient.interceptors.request.eject(authInterceptorRef.current)
        setHasAuthInterceptor(false)
        authInterceptorRef.current = undefined
      }
    }
    const setAuthInterceptor = (): number => {
      // If we've already added an interceptor, remove the old one.
      removeAuthInterceptor()
      // Add a new interceptor to the API client.
      const interceptor = async (config: AxiosRequestConfig) => {
        const token = await getAccessToken()
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        }
        return config
      }
      return apiClient.interceptors.request.use(interceptor)
    }

    if (shouldAddAuthInterceptor) {
      const id = setAuthInterceptor()
      setHasAuthInterceptor(true)
      authInterceptorRef.current = id
    }

    return () => {
      // On unmount, remove the interceptor from the API client.
      removeAuthInterceptor()
    }
  }, [shouldAddAuthInterceptor, apiClient.interceptors.request, getAccessToken])

  // If bearer auth is disabled, this component is a no-op.
  if (disabled) return <>{children}</>

  // If the user is authenticated AND we've set the interceptor,
  //  then render the children. Otherwise, show a spinner
  const showChildren = isAuthenticated && hasAuthInterceptor

  return showChildren ? <>{children}</> : <SpinnerOnDimmer />
}
