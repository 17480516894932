import { ToolCardWithMetadata } from './cards/BaseToolCard'
import { BlankDocumentCardWithMetadata } from './cards/BlankDocumentCard'
import {
  BlogArticleCardWithMetadata,
  IMDASpeechArticleCardWithMetadata
} from './cards/BlogArticleCard'
import { HypoDocCardWithMetadata } from './cards/HypoDocCard'
import { HypoChatCardWithMetadata } from './cards/HypochatCard'
import { ImageGeneratorCardWithMetadata } from './cards/ImageGeneratorCard'
import { ProductDescriptionCardWithMetadata } from './cards/ProductDescriptionCard'
import { ProductDescriptionRewriterCardWithMetadata } from './cards/ProductDescriptionRewriterCard'
// Individual tool card components
import {
  AskAiAnythingCardWithMetadata,
  BioWriterCardWithMetadata,
  CategoryPageCardWithMetadata,
  Eli5CardWithMetadata,
  EmailCardWithMetadata,
  FacebookAdsCardWithMetadata,
  GoogleAdsCardWithMetadata,
  HeadlineCardWithMetadata,
  IMDALinkedinCardWithMetadata,
  InstagramCaptionsCardWithMetadata,
  LandingPageCardWithMetadata,
  LinkedinPostCardWithMetadata,
  MarketingAnglesCardWithMetadata,
  MetaDescCardWithMetadata,
  PressReleaseCardWithMetadata,
  RewriteForSeoCardWithMetadata,
  RewriterCardWithMetadata,
  SummarizerCardWithMetadata,
  VideoIntroCardWithMetadata,
  YoutubeTitleCardWithMetadata
} from './cards/TemplateCard'
import { ToolCardGroupType } from './mappings'

export interface IToolCardGroup {
  id: ToolCardGroupType
  title: string
  subTitle: string
  cards: readonly ToolCardWithMetadata[]
}

const FeaturedCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured tools',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [
    ProductDescriptionRewriterCardWithMetadata,
    ProductDescriptionCardWithMetadata,
    BlogArticleCardWithMetadata,
    ImageGeneratorCardWithMetadata,
    HypoChatCardWithMetadata
  ] as const
}
const GeneralCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.general,
  title: 'General',
  subTitle: 'Improve your writing and repurpose content easily.',
  cards: [
    HypoDocCardWithMetadata,
    AskAiAnythingCardWithMetadata,
    RewriterCardWithMetadata,
    SummarizerCardWithMetadata,
    MarketingAnglesCardWithMetadata,
    Eli5CardWithMetadata,
    BlankDocumentCardWithMetadata
  ] as const
}
const SocialAdsCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.ads_social,
  title: 'Ads & social',
  subTitle: 'Create compelling copy for your next campaign.',
  cards: [
    InstagramCaptionsCardWithMetadata,
    GoogleAdsCardWithMetadata,
    FacebookAdsCardWithMetadata,
    LinkedinPostCardWithMetadata,
    YoutubeTitleCardWithMetadata,
    VideoIntroCardWithMetadata
  ] as const
}
const SiteCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.site,
  title: 'Site',
  subTitle: 'Transform your website into a content powerhouse.',
  cards: [
    HeadlineCardWithMetadata,
    LandingPageCardWithMetadata,
    MetaDescCardWithMetadata,
    CategoryPageCardWithMetadata,
    BioWriterCardWithMetadata
  ] as const
}
const NewslettersCommsCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.newsletters_comms,
  title: 'Newsletters & comms',
  subTitle: 'Craft effective messaging for your audience.',
  cards: [EmailCardWithMetadata, PressReleaseCardWithMetadata] as const
}
export const DEFAULT_CARD_GROUPS: readonly IToolCardGroup[] = [
  FeaturedCardGroup,
  GeneralCardGroup,
  SocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]

const HomePageFeaturedCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured tools',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [
    ProductDescriptionRewriterCardWithMetadata,
    ProductDescriptionCardWithMetadata,
    BlogArticleCardWithMetadata,
    ImageGeneratorCardWithMetadata,
    HypoChatCardWithMetadata
  ] as const
}

const LiveFeedPageFeaturedCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured tools',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [
    BlogArticleCardWithMetadata,
    LinkedinPostCardWithMetadata,
    EmailCardWithMetadata
  ] as const
}

const SuggestionFeedFeaturedCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured tools',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [
    BlogArticleCardWithMetadata,
    LinkedinPostCardWithMetadata,
    EmailCardWithMetadata
  ] as const
}

const SuggestionFeedGeneralCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.general,
  title: 'General',
  subTitle: 'Improve your writing and repurpose content easily.',
  cards: [
    HypoDocCardWithMetadata,
    AskAiAnythingCardWithMetadata,
    RewriterCardWithMetadata,
    SummarizerCardWithMetadata,
    MarketingAnglesCardWithMetadata,
    Eli5CardWithMetadata
  ] as const
}

// A list of card groups specifically for the homepage
export const HOMEPAGE_CARD_GROUPS: readonly IToolCardGroup[] = [
  HomePageFeaturedCardGroup,
  GeneralCardGroup,
  SocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]

export const LIVE_FEED_CARD_GROUPS: readonly IToolCardGroup[] = [
  LiveFeedPageFeaturedCardGroup,
  GeneralCardGroup,
  SocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]

export const SUGGESTION_FEED_CARD_GROUPS: readonly IToolCardGroup[] = [
  SuggestionFeedFeaturedCardGroup,
  SuggestionFeedGeneralCardGroup,
  SocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]

export const HomePageFeaturedCardGroupWithSeoRewriter: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured tools',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [
    ProductDescriptionRewriterCardWithMetadata,
    ProductDescriptionCardWithMetadata,
    BlogArticleCardWithMetadata,
    RewriteForSeoCardWithMetadata,
    ImageGeneratorCardWithMetadata,
    HypoChatCardWithMetadata
  ] as const
}

/**
 * A special featured card group for specifically for papa plan id user's homepage
 */
const PapaHomePageFeaturedCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured tools',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [BlogArticleCardWithMetadata, FacebookAdsCardWithMetadata] as const
}

export const HOMEPAGE_CARD_GROUPS_WITH_SEO_REWRITER: readonly IToolCardGroup[] = [
  HomePageFeaturedCardGroupWithSeoRewriter,
  GeneralCardGroup,
  SocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]

// A list of card groups customised for the papa plan id user's homepage
export const PAPA_HOMEPAGE_CARD_GROUPS: readonly IToolCardGroup[] = [
  PapaHomePageFeaturedCardGroup,
  GeneralCardGroup,
  SocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]

/**
 * A special featured card group for IMDA
 */
const IMDAHomePageFeaturedCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured tools',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [
    IMDASpeechArticleCardWithMetadata,
    IMDALinkedinCardWithMetadata
  ] as const
}

const IMDADocumentOverviewPageFeaturedCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured tools',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [
    BlankDocumentCardWithMetadata,
    IMDASpeechArticleCardWithMetadata,
    IMDALinkedinCardWithMetadata
  ] as const
}

const IMDASocialAdsCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.ads_social,
  title: 'Ads & social',
  subTitle: 'Create compelling copy for your next campaign.',
  cards: [
    InstagramCaptionsCardWithMetadata,
    GoogleAdsCardWithMetadata,
    FacebookAdsCardWithMetadata,
    IMDALinkedinCardWithMetadata,
    YoutubeTitleCardWithMetadata,
    VideoIntroCardWithMetadata
  ] as const
}

const IMDALiveFeedPageFeaturedCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [
    IMDASpeechArticleCardWithMetadata,
    IMDALinkedinCardWithMetadata
  ] as const
}

const IMDASuggestionFeedFeaturedCardGroup: IToolCardGroup = {
  id: ToolCardGroupType.featured,
  title: 'Featured tools',
  subTitle: 'Explore our most popular and highly-rated tools.',
  cards: [
    IMDASpeechArticleCardWithMetadata,
    IMDALinkedinCardWithMetadata
  ] as const
}

export const IMDA_HOMEPAGE_CARD_GROUPS: readonly IToolCardGroup[] = [
  IMDAHomePageFeaturedCardGroup,
  GeneralCardGroup,
  IMDASocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]

export const IMDA_DOCUMENT_OVERVIEW_CARD_GROUPS: readonly IToolCardGroup[] = [
  IMDADocumentOverviewPageFeaturedCardGroup,
  GeneralCardGroup,
  IMDASocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]

export const IMDA_LIVE_FEED_CARD_GROUPS: readonly IToolCardGroup[] = [
  IMDALiveFeedPageFeaturedCardGroup,
  GeneralCardGroup,
  IMDASocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]

export const IMDA_SUGGESTION_FEED_CARD_GROUPS: readonly IToolCardGroup[] = [
  IMDASuggestionFeedFeaturedCardGroup,
  SuggestionFeedGeneralCardGroup,
  IMDASocialAdsCardGroup,
  SiteCardGroup,
  NewslettersCommsCardGroup
]
