import React from 'react'

import { Link } from 'react-router-dom'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Box, Button } from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { handleTrackPremiumViewPlans } from '@hypotenuse/common/src/api/Analytics'
import { useIsMobile } from '@hypotenuse/common/src/hooks/UseIsMobile'
import { toMaterialShade } from '@hypotenuse/common/src/utils/ColorUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'left',
      borderColor: palette.primary[300],
      borderStyle: 'solid',
      borderWidth: '1px',
      backgroundColor: palette.primary[25]
    },
    updateBtn: {
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      height: 'min-content',
      backgroundColor: 'transparent',
      border: `1px solid ${palette.primary[300]}`,
      color: palette.primary[700],
      '&:hover': {
        backgroundColor: toMaterialShade(palette.primary[500], 100),
        // text color override on hover is required because semantic-ui takes precedence otherwise
        color: toMaterialShade(palette.primary[500], 800)
      },
      fontSize: theme.spacing(1.75)
    },
    subtitleText: {
      color: palette.primary[700],
      fontWeight: 400
    },
    titleText: {
      color: palette.primary[700],
      fontweight: 600,
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(0.75)
    }
  })
)

export interface TrialBannerBaseProps {
  /**
   * The banner's title
   */
  bannerTitle: React.ReactNode
  /**
   * Banner's subtitle
   */
  bannerSubtitle: React.ReactNode
  /**
   * Text for the CTA button in the banner
   */
  ctaButtonLabel: React.ReactNode
  /**
   * If true, the CTA button will be hidden (e.g. on the pricing page)
   */
  hideCtaButton?: boolean
  /**
   * Represents what the banner is being used for and is used for tracking purposes
   */
  elementId: string
}

/**
 * Banner shown to free users.
 * This is a dumb component that has no state (only props), provided for easier snapshot testing.
 * It is meant to be used within a wrapper component that handles the state.
 */
export const TrialBannerBase: React.FC<TrialBannerBaseProps> = (props) => {
  const {
    bannerTitle,
    bannerSubtitle,
    ctaButtonLabel,
    hideCtaButton,
    elementId
  } = props
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <Box className={clsx(classes.container)} borderRadius={8} py={1.5} px={1.5}>
      <Stack spacing={1} direction="row">
        <Stack
          flexGrow={1}
          spacing={1}
          alignItems={isMobile ? 'stretch' : 'center'}
          flexDirection={isMobile ? 'column ' : 'row'}
          justifyContent="space-between" // Add this line
        >
          <Stack>
            <Typography variant="h6" className={classes.titleText}>
              {bannerTitle}
            </Typography>
            <Typography variant="body1" className={classes.subtitleText}>
              {bannerSubtitle}
            </Typography>
          </Stack>
          {!hideCtaButton && (
            <Button
              component={Link}
              onClick={() => {
                handleTrackPremiumViewPlans(window.location.pathname, elementId)
              }}
              to="/settings/pricing"
              variant={'contained'}
              className={classes.updateBtn}
            >
              {ctaButtonLabel}
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
