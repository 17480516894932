import React from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean
  dark?: boolean
}

const Overlay: React.FC<Props> = (props) => {
  const { open, dark, style, ...restProps } = props
  const overlayColor = dark ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'
  return (
    <div
      style={{
        display: open ? 'flex' : 'none',
        backgroundColor: overlayColor,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        ...style
      }}
      {...restProps}
    />
  )
}

export default Overlay
