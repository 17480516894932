import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import hypochat_logo from '../assets/v2/hypochat.svg'

import { BaseToolCard, ToolCardWithMetadata } from './BaseToolCard'

const TITLE = 'HypoChat'
const SUBTITLE =
  'Real time web AI chat for research brainstorm ideas, multi-purpose content creation.'

const HypoChatCard = () => {
  const history = useHistory()
  const onClick = useCallback(async () => {
    history.push('/hypochat')
  }, [history])

  return (
    <BaseToolCard
      title={TITLE}
      subtitle={SUBTITLE}
      onClick={onClick}
      logoSrc={hypochat_logo}
    />
  )
}

const metadata = {
  id: 'hypodoc',
  searchTerms: [TITLE, SUBTITLE]
}

export const HypoChatCardWithMetadata: ToolCardWithMetadata = {
  card: <HypoChatCard />,
  metadata
}
