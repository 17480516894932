import React from 'react'

import { Redirect } from 'react-router-dom'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'

import {
  SimpleDialog,
  SimpleDialogProps
} from '@hypotenuse/common/src/components/molecules/SimpleDialog'

import { useIsMobile } from '../../hooks/UseIsMobile'

export interface SimpleRedirectDialogProps extends SimpleDialogProps {
  open: boolean
  title?: string
  icon_src?: string
  message: string
  redirectLink?: string
  rightButtonText?: string
  onRedirect?: () => void
  onClose?: () => void
}

export default function SimpleRedirectDialog(props: SimpleRedirectDialogProps) {
  const {
    title,
    icon_src,
    message,
    redirectLink,
    rightButtonText,
    onRedirect,
    onClose
  } = props
  const [isRedirect, setIsRedirect] = React.useState<boolean>(false)

  const isMobile = useIsMobile()

  if (isRedirect && redirectLink) {
    return <Redirect to={redirectLink} />
  }

  const handleRedirect = () => {
    if (redirectLink) {
      setIsRedirect(true)
    } else if (onRedirect) {
      onRedirect()
    }
    onClose?.()
  }

  return (
    <SimpleDialog
      maxWidth={isMobile ? 'md' : 'xl'}
      topDivider={false}
      disableTopBox
      {...props}
    >
      <Stack textAlign="center">
        <DialogTitle>
          {title && <Typography variant="h6">{title}</Typography>}
        </DialogTitle>
        <DialogContent>
          {icon_src && (
            <img
              src={icon_src}
              alt="plan_cancellation_icon"
              style={{ margin: 'auto' }}
            />
          )}
          <Typography variant="h6">{message}</Typography>
        </DialogContent>
      </Stack>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Stack direction={'row'} spacing={2}>
          <Button
            variant="contained"
            color="default"
            onClick={onClose}
            style={{
              borderRadius: '10px',
              marginBottom: '5%'
            }}
          >
            <Typography variant="h6">Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirect}
            style={{ borderRadius: '10px', marginBottom: '5%' }}
          >
            <Typography variant="h6">
              {rightButtonText ?? 'Try Again'}
            </Typography>
          </Button>
        </Stack>
      </DialogActions>
    </SimpleDialog>
  )
}
