import * as Sentry from '@sentry/browser'
import sanitizeFileName from 'sanitize-filename'

import snackbar from '../../utils/Snackbar'

/**
 * Downloads an asset to the user's local device.
 *
 * The asset must be downloaded by bypassing the cache, otherwise,
 * the browser might prevent the image from being downloaded.
 *
 * @param downloadLink The URL to download the asset from.
 * @param fileName The full name of the file.
 *
 * @see https://stackoverflow.com/a/42518434
 */
export const downloadAsset = async (downloadLink: string, fileName: string) => {
  try {
    // Download a resource with cache busting, to bypass the cache completely
    const response = await fetch(downloadLink, { cache: 'no-store' })
    if (!response.ok) {
      throw response.statusText
    }

    const fileBlob = await response.blob()
    const fileURL = URL.createObjectURL(fileBlob)

    const link = document.createElement('a')
    link.href = fileURL
    link.download = sanitizeFileName(fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    snackbar.show('An error occurred while downloading your images.', {
      variant: 'error'
    })
    Sentry.captureException(error)
  }
}

export const copyAssetLinkToClipboard = async (downloadLink: string) => {
  await navigator.clipboard.writeText(downloadLink)
  snackbar.show('Copied asset link to clipboard!', {
    variant: 'success',
    autoHideDuration: 2000
  })
}

export const userLocale = navigator.languages?.[0] ?? 'en-us'
