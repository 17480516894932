/**
 * This file is part of our internal design system/component library and should not be updated directly.
 */
import React, { ElementType, useMemo } from 'react'

import {
  // eslint-disable-next-line no-restricted-imports
  Typography as MUITypography,
  TypographyProps as MUITypographyProps
} from '@material-ui/core'

import palette from './Colors'

export enum VariantEnum {
  heading1 = 'heading1',
  heading2 = 'heading2',
  heading3 = 'heading3',
  paragraph1Bold = 'paragraph1Bold',
  paragraph1Reg = 'paragraph1Reg',
  paragraph2Bold = 'paragraph2Bold',
  paragraph2Med = 'paragraph2Med',
  paragraph2Reg = 'paragraph2Reg',
  captionBold = 'captionBold',
  captionReg = 'captionReg'
}

type Variant = keyof typeof VariantEnum

const isCustomVariant = (variant?: string): variant is Variant =>
  variant !== undefined && variant in VariantEnum

export interface TypographyProps
  extends Omit<
    MUITypographyProps<ElementType, { component?: ElementType }>,
    'variant'
  > {
  variant?: Variant | MUITypographyProps['variant']
}

/**
 * The default styling that should apply each Typography component (unless overriden)
 */
const DEFAULT_STYLE: React.CSSProperties = {
  color: palette.gray[700]
}

/**
 * Contains the styling specific to each specific custom typography variant
 */
export const VARIANT_MAP: Record<
  Variant,
  Partial<MUITypographyProps<ElementType, { component?: ElementType }>>
> = {
  heading1: {
    style: {
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: '40px'
    },
    component: 'h1'
  },
  heading2: {
    style: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '32px'
    },
    component: 'h2'
  },
  heading3: {
    style: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '28px'
    },
    component: 'h3'
  },
  paragraph1Bold: {
    style: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px'
    },
    component: 'span'
  },
  paragraph1Reg: {
    style: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px'
    },
    component: 'span'
  },
  paragraph2Bold: {
    style: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px'
    },
    component: 'span'
  },
  paragraph2Med: {
    style: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px'
    },
    component: 'span'
  },
  paragraph2Reg: {
    style: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px'
    },
    component: 'span'
  },
  captionBold: {
    style: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px'
    },
    component: 'span'
  },
  captionReg: {
    style: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px'
    },
    component: 'span'
  }
}

/**
 * This is a wrapper around the MUI Typography component, which aims to support both the default MUI Typography variants
 * and our custom design system Typography variants.
 *
 * This should be used in all places where Typography is used in the app in place of MUI Typography.
 */
const Typography: React.FC<TypographyProps> = React.forwardRef<
  HTMLElement,
  TypographyProps
>((props: TypographyProps, ref) => {
  const { variant, style: styleOverrides, ...restProps } = props
  const defaultProps = useMemo(() => {
    if (isCustomVariant(variant)) {
      const { style: variantStyle, ...variantPropsRest } = VARIANT_MAP[variant]
      return {
        style: { ...variantStyle, ...DEFAULT_STYLE },
        ...variantPropsRest
      }
    }
    return { style: undefined }
  }, [variant])

  const { style: defaultStyle, ...defaultPropsRest } = defaultProps

  const propsOverride = isCustomVariant(variant)
    ? { ...defaultPropsRest, ...restProps }
    : { variant, ...defaultPropsRest, ...restProps }

  return (
    <MUITypography
      ref={ref}
      style={{ ...defaultStyle, ...styleOverrides }}
      {...propsOverride}
    />
  )
})

export default Typography
