import { useLocalStorage } from '@hypotenuse/common/src/hooks/useLocalStorage'
import { TEAM_INVITE_ID } from '@hypotenuse/common/src/utils/Constants'

/**
 * Wrapper around useLocalStorage to get and set a team invite ID.
 * Same API as useState – returns [state, setState].
 */
export const useTeamInviteId = (): [
  string | undefined,
  (inviteId?: string) => void
] => {
  const [
    teamInviteIdInLocalStorage,
    setTeamInviteIdInLocalStorage
  ] = useLocalStorage<string | undefined>(TEAM_INVITE_ID, undefined)

  return [teamInviteIdInLocalStorage, setTeamInviteIdInLocalStorage]
}
