import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

import {
  CreatedReferral,
  EarnedAndPendingCreditsInResponse,
  EarnedAndPendingWordsInResponse
} from '../interfaces/Referrals'

export const createReferral = async () => {
  return apiClient.post('/referrals/')
}

export const getReferralMessage = async (
  referralCode: string,
  refereeName: string,
  recipientName: string,
  recipientEmail: string
) => {
  let config = {
    params: {
      ref: referralCode,
      referee_name: refereeName,
      recipient_name: recipientName,
      recipient_email: recipientEmail
    }
  }
  return apiClient.get('/referrals/message', config)
}

export const redeemReferral = async (referralCode: string) => {
  let config = {
    params: {
      ref: referralCode
    }
  }
  return apiClient.get('/referrals/redeem', config)
}

export const copyReferral = async (referralCode: string) => {
  let config = {
    params: {
      ref: referralCode
    }
  }
  return apiClient.get('/referrals/copy', config)
}

export const sendReferral = async (
  referralCode: string,
  refereeName: string,
  recipientName: string,
  recipientEmail: string
) => {
  let config = {
    params: {
      ref: referralCode,
      referee_name: refereeName,
      recipient_name: recipientName,
      recipient_email: recipientEmail
    }
  }
  return apiClient.post('/referrals/send', {}, config)
}

export const getReferralConfig = async () => {
  return apiClient.get('/referrals/me')
}

export const getReferralDetails = (): Promise<CreatedReferral> => {
  return apiClient
    .get('/referrals/referral_details')
    .then((resp) => resp.data.created_referral)
}

export const getCreditsEarnedAndPending = async (): Promise<EarnedAndPendingCreditsInResponse> => {
  return await apiClient
    .get<EarnedAndPendingCreditsInResponse>(
      '/referrals/credits_earned_and_pending'
    )
    .then((resp) => resp.data)
}

export const getWordsEarnedAndPending = async (): Promise<EarnedAndPendingWordsInResponse> => {
  return await apiClient
    .get<EarnedAndPendingWordsInResponse>('/referrals/words_earned_and_pending')
    .then((resp) => resp.data)
}
