import React from 'react'

import { Button, ButtonProps } from '@material-ui/core'

interface Props extends ButtonProps {}

/**
 * Primary action button for use in BaseModal
 */
export const PrimaryButton: React.FC<Props> = React.memo((props) => {
  return <Button {...props} />
})
PrimaryButton.defaultProps = {
  variant: 'contained',
  color: 'primary'
}
