export enum DocumentType {
  /**
   * A blog post
   */
  blog = 'blog',

  /**
   * A general document
   */
  general = 'general',

  /**
   * Instagram captions document
   */
  instagram = 'instagram',

  /**
   * Google ads document
   */
  google = 'google',

  /**
   * LinkedIn post document
   */
  linkedin = 'linkedin',

  /*
   * Facebook ads document
   */
  facebook = 'facebook',

  /*
   * Headline document
   */
  headline = 'headline',

  /*
   * Rewriter document
   */
  rewriter = 'rewriter',

  /*
   * Category page descriptions document
   */
  category_page = 'category_page',

  /*
   * Landing page content document
   */
  landing_page = 'landing_page',

  /*
   * Simplify language document
   */
  eli5 = 'eli5',

  /*
   * Email document
   */
  email = 'email',

  /*
   * Video intro document
   */
  video_intro = 'video_intro',

  /*
   * Meta title and description document
   */
  meta_desc = 'meta_desc',

  /*
   * Marketing angles document
   */
  marketing_angles = 'marketing_angles',

  /*
   * Press release document
   */
  press_release = 'press_release',

  /*
   * Summarise content document
   */
  summarise = 'summarise',

  /*
   * YouTube title document
   */
  youtube_title = 'youtube_title',

  /*
   * Bio writer document
   */
  bio_writer = 'bio_writer',

  /*
   * Ask anything document
   */
  ask_anything = 'ask_anything',
  /**
   * HypoChat document
   */
  chat = 'chat',
  /**
   * Folder document. Not really a document where you can edit content, but a folder to organize documents
   */
  folder = 'folder',
  /**
   * File document. Not really a document where you can edit content, but a user uploaded file
   */
  file = 'file',
  /**
   * Uploaded files folder. The default folder where user uploaded files are stored
   */
  uploaded_files_folder = 'uploaded_files_folder',
  /**
   * Product description document, it does not exist on backend, but we use it so that we can reuse the same logic for document type
   */
  product_description = 'product_description',
  /**
   * Rewrite for SEO document
   */
  rewrite_for_seo = 'rewrite_for_seo'
}

export type folderTypes =
  | DocumentType.folder
  | DocumentType.uploaded_files_folder

/**
 * Labels for document types
 */
export const DocumentTypeLabels: Record<DocumentType, string> = {
  [DocumentType.blog]: 'Blog article',
  [DocumentType.general]: 'General',
  [DocumentType.instagram]: 'Instagram captions',
  [DocumentType.google]: 'Google ads',
  [DocumentType.linkedin]: 'LinkedIn posts',
  [DocumentType.facebook]: 'Facebook ads',
  [DocumentType.headline]: 'Website headlines',
  [DocumentType.rewriter]: 'Rewriter',
  [DocumentType.category_page]: 'Category page',
  [DocumentType.landing_page]: 'Landing page',
  [DocumentType.eli5]: 'Simplify language',
  [DocumentType.email]: 'Email',
  [DocumentType.video_intro]: 'Video intro',
  [DocumentType.meta_desc]: 'Meta title and description',
  [DocumentType.marketing_angles]: 'Marketing angles',
  [DocumentType.press_release]: 'Press release',
  [DocumentType.summarise]: 'Summarise content',
  [DocumentType.youtube_title]: 'YouTube title',
  [DocumentType.bio_writer]: 'Bio writer',
  [DocumentType.ask_anything]: 'Ask AI anything',
  [DocumentType.chat]: 'Chat',
  [DocumentType.folder]: 'Folder',
  [DocumentType.file]: 'Uploaded file',
  [DocumentType.uploaded_files_folder]: 'Uploaded files folder',
  [DocumentType.product_description]: 'Product description',
  [DocumentType.rewrite_for_seo]: 'SEO content optimizer'
}

export const documentRoutes: Partial<Record<DocumentType, string>> = {
  [DocumentType.facebook]: '/advertising/facebook',
  [DocumentType.google]: '/advertising/google',
  [DocumentType.instagram]: '/advertising/instagram',
  [DocumentType.headline]: '/advertising/headline',
  [DocumentType.linkedin]: '/advertising/linkedin',
  [DocumentType.rewriter]: '/advertising/rewriter',
  [DocumentType.category_page]: '/advertising/category_page',
  [DocumentType.landing_page]: '/advertising/landing_page',
  [DocumentType.eli5]: '/advertising/eli5',
  [DocumentType.email]: '/advertising/email_copy',
  [DocumentType.video_intro]: '/advertising/video_intro',
  [DocumentType.meta_desc]: '/advertising/meta_desc',
  [DocumentType.marketing_angles]: '/advertising/marketing_angles',
  [DocumentType.press_release]: '/advertising/press_release',
  [DocumentType.summarise]: '/advertising/summarise_copy',
  [DocumentType.youtube_title]: '/advertising/youtube_title',
  [DocumentType.bio_writer]: '/advertising/bio_writer',
  [DocumentType.ask_anything]: '/advertising/ask_anything',
  [DocumentType.blog]: '/blog-post',
  [DocumentType.general]: '/documents',
  [DocumentType.chat]: '/hypochat',
  [DocumentType.folder]: '/documents/folder',
  [DocumentType.uploaded_files_folder]: '/documents/folder',
  [DocumentType.product_description]: '/catalog',
  [DocumentType.rewrite_for_seo]: '/advertising/rewrite_for_seo'
}
/**
 * A document
 */
export interface IDocument {
  /**
   * Unique ID
   */
  id: string
  /**
   * The organization that created the document
   */
  organization_id: string
  /**
   * The user that created the document
   */
  user_id?: string
  /**
   * Title of the document
   */
  title?: string
  /**
   * Content
   */
  content: string
  /**
   * When the document was created
   */
  created_at: number
  /**
   * When the document was last updated
   */
  updated_at: number
  /**
   * When the content was last updated
   */
  content_updated_at?: number

  /**
   * The type of document
   * @default DocumentType.general
   */
  type: DocumentType
  /**
   * Whether the document has been starred
   */
  starred?: boolean
  /**
   * Whether the document has been deleted (soft delete)
   */
  deleted?: boolean
  /**
   * When the document was deleted
   */
  deleted_at?: string
  /**
   * ID of the content item the document is linked to
   * @default null
   */
  linked_item_id?: string
  /**
   * ID of the parent document/folder the document is linked to.
   * @default null
   */
  folder_id?: string
  /**
   * Type of the parent document/folder the document is linked to.
   * @default null
   */
  folder_type?: string
  /**
   * Whether the document jas been deleted (hard delete).
   * NOTE: Hard delete means hiding the document from the trash bin.
   * It has not actually been deleted from the database.
   */
  hard_deleted?: boolean
  /**
   * When the document was hard deleted
   */
  hard_deleted_at?: string
  /**
   * Whether the document is hidden.
   * Defaults to true upon creation, but is updated to false once the document or its linked item is updated.
   */
  is_hidden?: boolean

  /**
   * Whether the document is private
   */
  is_private?: boolean
}

export interface DocumentWithWordCount extends IDocument {
  /**
   * The number of words in the document
   */
  word_count: number
}

export interface DocumentQuery {
  /**
   * Start index for pagination
   * @default 0
   */
  start?: number

  /**
   * End index for pagination
   * @default 10
   */
  end?: number

  /**
   * Search term for query
   */
  search?: string

  /**
   * Search term using the document folder id for the query
   * Searches for all documents that are children of the folder id
   */
  folder_id?: string

  /**
   * Type of the parent document/folder the document is linked to.
   */
  folder_type?: string

  /**
   * Sort by list of fields
   */
  sort_by?: string[]

  /**
   * Filter by types of documents
   * @default []
   */
  types?: DocumentType[]
}

/**
 * Enum for document sort fields in document management page
 */
export enum DocumentOverviewSortBy {
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  CONTENT_UPDATED_AT = 'content_updated_at',
  SCORE = '_score',
  ID = 'id',
  TITLE = 'title'
}

export interface Comment {
  /**
   * Unique ID
   */
  id: string
  /**
   * The document that the comment is linked to
   */
  document_id: string
  /**
   * ID of user who authored the comment
   */
  user_id: string
  /**
   * Content of the comment
   */
  content: string
  /**
   * When the comment was created
   */
  created_at: number
  /**
   * When the comment was last updated
   */
  updated_at: number
  /**
   * Whether the comment has been deleted (soft delete)
   */
  deleted?: boolean
  /**
   * When the comment was deleted
   */
  deleted_at?: string
}
