import React from 'react'

import type { TypographyProps } from '../../../atoms/Typography'
import Typography from '../../../atoms/Typography'

import { ArticleOpenMode, HelpArticle } from '../types'
import { useBeacon } from '../useBeacon'

export interface Props extends TypographyProps {
  /**
   * Article definition. This should contain the URL and ID of the article.
   */
  article: HelpArticle
  /**
   * How/where to open the article
   */
  openMode?: ArticleOpenMode
}

const HelpArticleLink: React.FC<Props> = (props) => {
  const {
    children,
    article: { url: href, id: articleId },
    openMode,
    style,
    ...rest
  } = props

  const { beacon, isBeaconLoaded, disableBeaconLinks } = useBeacon()

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!isBeaconLoaded) return

    // Prevent default behavior (opening link in a new tab)
    e.preventDefault()

    // Open the Help Scout article
    beacon?.('article', articleId, {
      type: openMode
    })
    // TODO: Track clicks on link.
    //  Currently unable to track because Analytics is defined separately in
    //  /platform and /shopify, and cannot be imported into /common.
  }

  if (disableBeaconLinks) return null

  return (
    <>
      <Typography style={{ verticalAlign: 'inherit', ...style }} {...rest}>
        {/* Until beacon SDK is loaded, we render a fallback link that opens in a new tab. */}
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          onClick={isBeaconLoaded ? handleClick : undefined}
        >
          {children}
        </a>
      </Typography>
    </>
  )
}

HelpArticleLink.defaultProps = {
  openMode: 'sidebar'
}

export default HelpArticleLink
