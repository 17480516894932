import React, { useEffect } from 'react'

import Lottie from 'lottie-react'
import { Link } from 'react-router-dom'

import { palette } from '@hypotenuse/common/src/atoms'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Button, DialogActions, DialogContent } from '@material-ui/core'

import {
  handleTrackPremiumViewPlans,
  handleTrackWordCountLimitModalOpen
} from '@hypotenuse/common/src/api/Analytics'
import { SimpleDialog } from '@hypotenuse/common/src/components/molecules/SimpleDialog'
import { useGenerationAPI } from '@hypotenuse/common/src/hooks/useGenerationAPI'
import humanHandsUpErrorAnimation from '@hypotenuse/common/src/lottie/human-hands-up-error.json'

export interface InsufficientWordsSimpleDialogProps {
  /**
   * Disables the button that redirects the user to the pricing page or whatever
   */
  disableRedirectButton?: boolean
}

const PRIMARY_TEXT = 'View Plans'
const REDIRECT_TO = '/settings/pricing'
const MODAL_MESSAGE = `Oops! Looks like you've reached your plan's word count limit. No worries, simply upgrade your subscription to increase your word limit and continue generating great product descriptions.`

/**
 * Wrapper around the generic InsufficientWordsModal to customize it for the Premium app.
 *
 * Also serves as a workaround for the fact that useGenerationAPI() is not currently usable
 * directly inside the `App` component due to its Provider being further down in the tree.
 */
export const PremiumInsufficientWordsModal: React.FC<InsufficientWordsSimpleDialogProps> = (
  props
) => {
  const { disableRedirectButton } = props

  /**
   * Structures the modal content
   */
  const content = (
    <>
      <Lottie
        loop={false}
        animationData={humanHandsUpErrorAnimation}
        style={{
          height: '60%',
          width: '50%',
          marginTop: '-10%',
          marginLeft: '10%'
        }}
      />
      <Typography
        variant="h5"
        style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '-4%' }}
      >
        You’re out of words :{'('}
      </Typography>
      {MODAL_MESSAGE.split('\n').map((str) => (
        <Typography
          key={str}
          color="textSecondary"
          variant="body1"
          align="center"
        >
          {str}
        </Typography>
      ))}
    </>
  )

  const {
    showInsufficientWordsModal,
    setShowInsufficientWordsModal
  } = useGenerationAPI()

  const [isRedirect, setIsRedirect] = React.useState<boolean>(false)
  if (isRedirect) {
    window.open(REDIRECT_TO, '_blank')
  }

  useEffect(() => {
    if (showInsufficientWordsModal) {
      handleTrackWordCountLimitModalOpen({
        elementId: 'insufficient-words-modal',
        pathname: window.location.pathname
      })
    }
  }, [showInsufficientWordsModal])

  const handleRedirect = React.useCallback(() => {
    setIsRedirect(true)
    // TODO: Potential issue - the component might(?) unmount before onClose is called
    handleTrackPremiumViewPlans(
      window.location.pathname,
      'insufficient-words-modal'
    )
    setShowInsufficientWordsModal(false)
  }, [setIsRedirect, setShowInsufficientWordsModal])

  return (
    <SimpleDialog
      maxWidth="xs"
      topDivider={false}
      closeButtonSize={'medium'}
      open={showInsufficientWordsModal}
      onClose={() => setShowInsufficientWordsModal(false)}
      {...props}
    >
      <DialogContent>
        <Stack spacing={1} py={4} alignItems="center" textAlign="center">
          {content}
        </Stack>
      </DialogContent>
      {!disableRedirectButton && (
        <DialogActions
          disableSpacing
          style={{ justifyContent: 'center', marginBottom: 5 }}
        >
          <Stack width="80%" justifyContent="center" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRedirect}
              style={{ borderRadius: '10px' }}
            >
              <Typography variant="h6">{PRIMARY_TEXT}</Typography>
            </Button>
            <Button
              component={Link}
              variant="text"
              to="/free-words"
              rel="noreferrer"
              target="_blank"
            >
              <Typography
                style={{
                  color: palette.primary[500],
                  fontWeight: 600
                }}
              >
                Want to earn more words?
              </Typography>
            </Button>
          </Stack>
        </DialogActions>
      )}
    </SimpleDialog>
  )
}
