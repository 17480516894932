import React from 'react'

import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'

import palette from './Colors'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: palette.white,
      backgroundColor: palette.gray[900],
      borderColor: palette.gray[900],
      '&:hover': {
        backgroundColor: palette.gray[800],
        borderColor: palette.gray[800],
        boxShadow: 'none'
      }
    }
  })
)

interface Props extends ButtonProps {}

/**
 * Secondary action button (for actions like "Cancel")
 */
export const BlackButton: React.FC<Props> = React.memo((props) => {
  const classes = useStyles()
  const { className, ...rest } = props
  return <Button className={clsx(classes.root, className)} {...rest} />
})
BlackButton.defaultProps = {
  variant: 'contained'
}
