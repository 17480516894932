import { useEffect, useRef } from 'react'

export default function usePrevious<T>(value: T): T {
  const ref: any = useRef<T>(value)
  // Store current value in ref (runs asynchronously)
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}
