import React from 'react'

import Stack, { StackProps } from '../atoms/Stack'

/**
 * Container for modal content in BaseModal
 */
export const ModalContent: React.FC<StackProps> = React.memo((props) => {
  return <Stack {...props} />
})
ModalContent.defaultProps = {
  spacing: 2.5
}
