import { apiClient } from '../utils/ApiClient'
import {
  CountryDetail,
  GlobalAppData,
  IncludeExcludeLinksResponse,
  TranslationConfig,
  TranslationConfigUpdateForm,
  TranslationLanguages
} from '../utils/Interfaces'

export const getGlobalAppData = async (): Promise<GlobalAppData> => {
  const resp = await apiClient.get('/global_app_data')
  return resp.data
}
export const apiGetCountryList = async (): Promise<CountryDetail[]> => {
  const resp = await apiClient.get('/country-list')
  return resp.data
}
export const fetchAvailableTranslationLanguages = async (): Promise<TranslationLanguages> => {
  const resp = await apiClient.get<TranslationLanguages>(
    '/translation_languages'
  )
  return resp.data
}
export const fetchTranslationConfig = () => {
  return apiClient
    .get<TranslationConfig>('/translation_config')
    .then((resp) => resp.data)
}
export const handleTranslationConfigChange = (
  updateForm: TranslationConfigUpdateForm
) => {
  return apiClient.put<void>('/translation_config', null, {
    params: updateForm
  })
}

export const apiSaveWebEnrichmentIncludeExcludeLinks = async ({
  includeLinks,
  excludeLinks
}: {
  includeLinks: string[]
  excludeLinks: string[]
}): Promise<void> => {
  return apiClient.post(`/organization/save-include-exclude-links`, {
    include_links: includeLinks,
    exclude_links: excludeLinks
  })
}

export const apiGetIncludeLinksFromFile = async (
  file: File
): Promise<string[]> => {
  const formData = new FormData()
  formData.append('file', file)

  const response = await apiClient.post(
    '/organization/add-include-links',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return response.data
}

export const apiGetExcludeLinksFromFile = async (
  file: File
): Promise<string[]> => {
  const formData = new FormData()
  formData.append('file', file)

  const response = await apiClient.post(
    '/organization/add-exclude-links',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return response.data
}

export const apiGetIncludeExcludeLinks = async (): Promise<IncludeExcludeLinksResponse> => {
  const response = await apiClient.get('/organization/include-exclude-links')
  return response.data
}
