import useSWR from 'swr'

import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

const fetchAppVersion = (): Promise<string> => {
  return apiClient.get('/ping').then((resp) => resp.data)
}

/**
 * Fetches the latest version of Phoenix deployed to production
 */
const useAppVersion = () => {
  const result = useSWR<string>('app_version', fetchAppVersion, {
    // trigger revalidation every 10 seconds
    refreshInterval: 10 * 1000
  })
  return result.data ?? ''
}

export default useAppVersion
