import React, { useState } from 'react'

import * as Sentry from '@sentry/react'
import { CreditCard } from '@styled-icons/feather/CreditCard'
import { Warning } from '@styled-icons/ionicons-outline/Warning'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Box, Button, CircularProgress } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import snackbar from '@hypotenuse/common/src/utils/Snackbar'

import usePremiumPlanTier from '../../../hooks/usePremiumPlanTier'

import { handleCreateStripeCustomerPortal } from '../../../api/Billing'

import { PlanType } from '../../utils/Interfaces'

const useStyles = makeStyles(
  createStyles({
    mainBanner: {
      borderColor: palette.red[300],
      backgroundColor: palette.red[25]
    },
    updateBtn: {
      backgroundColor: palette.red[600],
      color: palette.red[25],
      '&:hover': {
        backgroundColor: palette.red[200],
        color: palette.red[600]
      },
      width: 'fit-content'
    },
    heading: {
      color: palette.red[700],
      width: '100%',
      textAlign: 'left'
    },
    content: {
      color: palette.red[600],
      width: '100%',
      textAlign: 'left'
    },
    icon: {
      color: palette.red[600]
    }
  })
)

const PaymentFailureBanner = () => {
  const classes = useStyles()
  const {
    data: premiumPlanTier,
    latestUnpaidInvoicePaymentLink
  } = usePremiumPlanTier()

  const [loading, setLoading] = useState<boolean>(false)

  const redirectToStripeCustomerPortal = (): void => {
    setLoading(true)
    handleCreateStripeCustomerPortal()
      .then((portalUrl: string) => {
        window.location.href = portalUrl
      })
      .catch((error) => {
        setLoading(false)
        snackbar.show(
          "Sorry there's an error, please contact our support team via chat or email for assistance.",
          { variant: 'error' }
        )
        Sentry.captureException(error)
      })
  }

  /**
   * Prevent enterprise user from seeing the payment failure banner
   */
  return premiumPlanTier &&
    premiumPlanTier.plan_type !== PlanType.enterprise &&
    !!premiumPlanTier?.payment_failure_info?.invoice_id ? (
    <Stack
      className={classes.mainBanner}
      borderRadius={8}
      border={1}
      py={3}
      px={2}
      my={2}
      spacing={2}
      direction="row"
    >
      <Box>
        <Warning size="1.5rem" className={classes.icon} />
      </Box>
      <Stack spacing={1.5}>
        <Stack>
          <Typography variant="h6" className={classes.heading}>
            Your payment for Hypotenuse AI was unsuccessful
          </Typography>
          <Typography variant="body1" className={classes.content}>
            To avoid losing access to your account and content, please update
            your card details now.
          </Typography>
        </Stack>
        {latestUnpaidInvoicePaymentLink ? (
          <Button
            component="a"
            href={latestUnpaidInvoicePaymentLink}
            variant={'contained'}
            className={classes.updateBtn}
          >
            Pay invoice
          </Button>
        ) : (
          <Button
            disabled={loading}
            onClick={redirectToStripeCustomerPortal}
            variant={'contained'}
            className={classes.updateBtn}
            endIcon={
              loading ? (
                <CircularProgress color="inherit" size={14} />
              ) : (
                <CreditCard size="1.5rem" />
              )
            }
          >
            Update card
          </Button>
        )}
      </Stack>
    </Stack>
  ) : null
}

export default PaymentFailureBanner
