/**
 * Taken from https://github.com/smartmuki/sse-ts/blob/2eba7d9d000310d77bed18a26985b55ed99261ea/lib/sse.ts, which itself is a fork from https://github.com/mpetazzoni/sse.js
 *
 * Additional modifications had to be manually made to smartmuki's code as it had diverged from the upstream.
 * These changes were made by comparing with the upstream state here: https://github.com/mpetazzoni/sse.js/blob/cfe70a36d49009e403e1cd2419b097497ab693da/lib/sse.js
 *
 */

const FIELD_SEPARATOR = ':'

enum XHRStates {
  INITIALIZING = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSED = 2
}

export type CustomEventWithReadyStateChange = CustomEvent & {
  readyState: XHRStates
}

export type CustomEventWithError<E> = CustomEvent & {
  status?: number
  message: E
}

export type CustomEventWithData<T> = CustomEvent & {
  data: T // Represents a JSON object
  id: string
}

export enum SSEOptionsMethod {
  GET = 'GET',
  POST = 'POST'
}

export enum EventType {
  message = 'message',
  error = 'error'
}

export type CustomEventType<T, E> =
  | CustomEvent
  | CustomEventWithData<T>
  | CustomEventWithReadyStateChange
  | CustomEventWithError<E>

export interface SSEOptions {
  /**
   * A dictionary of key-value pairs.
   * Like: { "Content-type": "application/json" }
   */
  headers?: { [key: string]: string }

  /**
   * The XMLHttpRequest.withCredentials property is a boolean value
   * that indicates whether or not cross-site Access-Control requests
   * should be made using credentials such as cookies,
   * authorization headers or TLS client certificates.
   * Setting withCredentials has no effect on same-site requests.
   */
  withCredentials?: boolean

  /**
   * The HTTP method (currently only GET and POST are supported)
   * [Required].
   */
  method: SSEOptionsMethod

  /**
   * The JSON stringified payload representing the request body.
   */
  payload?: string
}

// Only two types of Callbacks defined for now, one for each EventType
export type MessageCallback<T> = (e: CustomEventWithData<T>) => void
export type ErrorCallback<E> = (e: CustomEventWithError<E>) => void
export type Callback<T, E> = MessageCallback<T> | ErrorCallback<E>

export class SSE<T, E> {
  private listeners: { [key: string]: Callback<T, E>[] } = {}
  private readyState: XHRStates = XHRStates.INITIALIZING
  private chunk = ''
  private progress = 0
  private xhr?: XMLHttpRequest

  constructor(private url: string, private options: SSEOptions) {
    if (!url) {
      throw new Error('url cannot be null')
    }

    if (!options || !options.method) {
      throw new Error('Method is mandatory in `options`')
    }
  }

  // only 2 overloads defined currently, one for each EventType
  public addEventListener(
    type: EventType.message,
    listener: MessageCallback<T>
  ): void
  public addEventListener(
    type: EventType.error,
    listener: ErrorCallback<E>
  ): void
  public addEventListener(type: EventType, listener: Callback<T, E>): void {
    this.listeners[type] = this.listeners[type] || []

    if (this.listeners[type].indexOf(listener) === -1) {
      this.listeners[type].push(listener)
    }
  }

  public removeEventListener(type: EventType, listener: Callback<T, E>) {
    if (!this.listeners[type]) {
      return
    }

    const filteredListeners = this.listeners[type].filter(
      (lis) => lis !== listener
    )
    if (!filteredListeners.length) {
      delete this.listeners[type]
    } else {
      this.listeners[type] = filteredListeners
    }
  }

  public stream() {
    this._setReadyState(XHRStates.CONNECTING)

    this.xhr = new XMLHttpRequest()
    this.xhr.onreadystatechange = () => this._checkStreamClosed()
    this.xhr.onprogress = (evt) => this._onStreamProgress(evt)
    this.xhr.onload = (evt) => this._onStreamLoaded(evt)
    this.xhr.onerror = (evt) => this._onStreamFailure(evt)
    this.xhr.onabort = () => this._onStreamAbort()

    this.xhr.open(this.options.method, this.url)

    for (const header in this.options.headers) {
      const headerValue = this.options.headers[header]
      if (headerValue) {
        this.xhr.setRequestHeader(header, headerValue)
      }
    }

    this.xhr.withCredentials = !!this.options.withCredentials
    this.xhr.send(this.options.payload)
  }

  close() {
    if (this.readyState === XHRStates.CLOSED) {
      return
    }

    this.xhr?.abort()
    this.xhr = undefined
    this._setReadyState(XHRStates.CLOSED)
  }

  private dispatchEvent(e?: CustomEventType<T, E>) {
    if (!e) {
      return true
    }

    // e.source = this; Dont expose the SSE object

    const onHandler = 'on' + e.type
    if (this.hasOwnProperty(onHandler)) {
      ;(this as any)[onHandler].call(this, e)
      if (e.defaultPrevented) {
        return false
      }
    }

    if (this.listeners[e.type]) {
      return this.listeners[e.type]?.every((callback: any) => {
        callback(e)
        return !e.defaultPrevented
      })
    }

    return true
  }

  private _setReadyState(state: XHRStates) {
    const event = new CustomEvent(
      'readystatechange'
    ) as CustomEventWithReadyStateChange
    event.readyState = state
    this.readyState = state
    this.dispatchEvent(event)
  }

  _onStreamFailure(e: ProgressEvent) {
    const event = e as ProgressEvent<XMLHttpRequest>
    const failureEvent = new CustomEvent('error') as CustomEventWithError<E>
    failureEvent.status = event.target?.status
    failureEvent.message = JSON.parse(event.target?.response || '{}')
    this.dispatchEvent(failureEvent)
    this.close()
  }

  _onStreamAbort() {
    const abortEvent = new CustomEvent('abort')
    this.dispatchEvent(abortEvent)
    this.close()
  }

  _onStreamProgress(e: ProgressEvent) {
    if (!this.xhr) {
      return
    }

    if (this.xhr.status !== 200) {
      this._onStreamFailure(e)
      return
    }

    if (this.readyState === XHRStates.CONNECTING) {
      const openEvent = new CustomEvent('open')
      this.dispatchEvent(openEvent)
      this._setReadyState(XHRStates.OPEN)
    }

    const data = this.xhr.responseText.substring(this.progress)
    this.progress += data.length

    data.split(/(\r\n|\r|\n){2}/g).forEach((part: string) => {
      if (part.trim().length === 0) {
        const chunkEvent = this._parseEventChunk(this.chunk.trim())
        this.dispatchEvent(chunkEvent)
        this.chunk = ''
      } else {
        this.chunk += part
      }
    })
  }

  private _onStreamLoaded(e: ProgressEvent) {
    this._onStreamProgress(e)

    const streamData = this._parseEventChunk(this.chunk)

    // Parse the last chunk.
    this.dispatchEvent(streamData)
    this.chunk = ''
  }

  /**
   * Parse a received SSE event chunk into a constructed event object.
   */
  private _parseEventChunk(chunk: string) {
    if (!chunk || !chunk.length) {
      return
    }

    const e: any = { id: null, retry: null, data: '', event: 'message' }

    chunk.split(/\n|\r\n|\r/).forEach((line: string) => {
      line = line.trimRight()
      const index = line.indexOf(FIELD_SEPARATOR)
      if (index <= 0) {
        // Line was either empty, or started with a separator and is a comment.
        // Either way, ignore.
        return
      }

      const field = line.substring(0, index)
      if (!(field in e)) {
        return
      }

      const value = line.substring(index + 1).trimLeft()
      if (field === 'data') {
        e[field] += value
      } else {
        e[field] = value
      }
    })

    const event = new CustomEvent(e.event) as CustomEventWithData<T>
    event.id = e.id

    /**
     * If the event is a ping, the default data is an empty string.
     * In this case, we should not parse the data as JSON.
     */
    if (e.data.length <= 0) {
      return
    }

    try {
      event.data = JSON.parse(e.data)
    } catch (error: unknown) {
      // If event data is string date, it means that the backend has sent a ping
      // to check if the connection is still alive.
      const isPingDate =
        typeof event.data === 'string' && !isNaN(Date.parse(event.data))
      if (isPingDate) return

      console.error('Error parsing event data', error)
      return
    }

    return event
  }

  private _checkStreamClosed() {
    if (!this.xhr) {
      return
    }

    if (this.xhr.readyState === XMLHttpRequest.DONE) {
      this._setReadyState(XHRStates.CLOSED)
    }
  }
}
