import { ContentGenerationType } from '../../interfaces/ContentGeneration'

import { DocumentType } from '../documents/types'

/**
 * Type of topic recommendation.
 */
export enum TopicRecommendationType {
  /**
   * Recommendation for live feed based on the user's
   * past generated content and knowledge base.
   *
   * It is based on relevant topics from scraped webpages.
   */
  live_feed = 'live_feed',
  /**
   * Recommendation for suggestion feed.
   *
   * Suggestion feed is purely based on the user's past
   * generated content.
   */
  suggestion_feed = 'suggestion_feed'
}

export enum TopicRecommendationTriggerSource {
  /**
   * The recommendation was generated based on the user's home page.
   */
  home_page_suggest = 'home_page_suggest',

  /**
   * Onboarding page
   * The recommendation was generated based on the user's onboarding page.
   */
  onboarding = 'onboarding',
  /**
   * The recommendation was generated based on the blog
   */
  blog_article = 'blog_article'
}

/**
 * A recommendation for a topic that a user may want to generate content about.
 */
export interface TopicRecommendation {
  /**
   * Unique ID of this topic recommendation.
   */
  id: string
  /**
   * The title of this topic recommendation.
   */
  title: string
  /**
   * Short description of this topic recommendation.
   */
  description: string
  /**
   * Type of topic recommendation.
   */
  type: TopicRecommendationType
  /**
   * Optional URL associated with this topic recommendation.
   */
  url?: string
  /**
   * The ID of the source item that this recommendation was generated from.
   * This is used to track the provenance of the recommendation.
   * For example, if the recommendation was generated from a scraped webpage,
   * this field would contain the ID of that scraped webpage.
   */
  source_item_id?: string
  /**
   * The collection that this topic recommendation belongs to.
   */
  collection_id: string
  /**
   * The timestamp when this topic recommendation was created.
   */
  created_at: number
  /**
   * The rank of this topic recommendation within its collection.
   * May be used to sort recommendations.
   */
  rank: number
  /**
   * Keywords related to the topic.
   * Used in "What's next?" feed
   */
  keywords?: string[]
  /**
   * Reasons for suggesting this topic.
   * Used in "What's next?" feed
   */
  reason_for_suggestion?: string
  content_type?: ContentGenerationType
  input_field_values?: Record<string, any>
  is_url_used_for_recommendation: boolean

  generation_trigger_source?: TopicRecommendationTriggerSource
}

/**
 * The type of topic recommendation that the collection contains.
 */
export enum TopicRecommendationCollectionType {
  /**
   * Recommendation for live feed based on the user's
   * past generated content and knowledge base.
   *
   * It is based on relevant topics from scraped webpages.
   */
  live_feed = 'live_feed',
  /**
   * Recommendation for suggestion feed.
   *
   * Suggestion feed is purely based on the user's past
   * generated content.
   */
  suggestion_feed = 'suggestion_feed',

  content_feed = 'content_feed',
  /**
   * Recommendation used for blog content try example
   */
  blog_content_feed = 'blog_content_feed'
}

/**
 * A collection of topic recommendations
 */
export interface TopicRecommendationCollection {
  /**
   * A list of recommended topics.
   */
  recommendations: TopicRecommendation[]
  /**
   * Unique ID of this collection
   */
  id: string
  /**
   * The organization that this collection belongs to.
   */
  organization_id: string
  /**
   * The timestamp when this collection was created.
   */
  created_at: number
  /**
   * The type of objects that this collection contains.
   */
  type: TopicRecommendationCollectionType
  /**
   * A message that may be displayed to the user.
   * Commonly used to display a message when there are no recommendations.
   */
  message: string
}

/**
 * A collection of topic recommendations
 */
export interface ContentSuggestion {
  /**
   * Unique ID of this topic recommendation.
   */
  id: string
  /**
   * The title of this topic recommendation.
   */
  title: string
  /**
   * Short description of this topic recommendation.
   */
  description: string
  /**
   * Type of topic recommendation.
   */
  type: TopicRecommendationType
  /**
   * Optional URL associated with this topic recommendation.
   */
  url?: string
  /**
   * The ID of the source item that this recommendation was generated from.
   * This is used to track the provenance of the recommendation.
   * For example, if the recommendation was generated from a scraped webpage,
   * this field would contain the ID of that scraped webpage.
   */
  source_item_id?: string
  /**
   * The collection that this topic recommendation belongs to.
   */
  collection_id: string
  /**
   * The timestamp when this topic recommendation was created.
   */
  created_at: number
  /**
   * The rank of this topic recommendation within its collection.
   * May be used to sort recommendations.
   */
  rank: number
  /**
   * Keywords related to the topic.
   * Used in "What's next?" feed
   */
  keywords?: string[]
  /**
   * Reasons for suggesting this topic.
   * Used in "What's next?" feed
   */
  reason_for_suggestion?: string
  content_type: ContentGenerationType
  input_field_values: Record<string, string>
}

/**
 * Settings used to generate topic recommendations.
 */
export interface TopicRecommenderSettings {
  /**
   * The organization that these settings belong to.
   */
  organization_id: string
  /**
   * The list of websites that will be analyzed to generate topic recommendations.
   */
  root_urls: string[]
}

export interface SuggestedTopic {
  /**
   * Unique ID of this topic recommendation.
   */
  id: string
  /**
   * The title of this topic recommendation.
   */
  title: string
  /**
   * Short description of this topic recommendation.
   */
  description: string
  /**
   * List of keywords related to the topic
   */
  keywords?: string[]
  /**
   * Reason for suggesting this topic
   */
  reason_for_suggestion?: string
}

export enum CrawlJobStatus {
  /**
   * The crawl job is in progress.
   */
  in_progress = 'in_progress',
  /**
   * The crawl job has completed successfully.
   */
  success = 'success',
  /**
   * The crawl job has failed.
   */
  failed = 'failed',
  /**
   * The crawl job not yet started.
   */
  not_started = 'not_started'
}
export interface CrawlJobInfo {
  /**
   * Timestamp when the crawl job was created.
   */
  last_crawled_at: number
  /**
   * Status of the crawl job.
   */
  status: CrawlJobStatus
}

/**
 * An enum of special domains with different sorting rules.
 */
export enum SpecialDomain {
  imda_press_release = 'imda.gov.sg/resources/press-releases-factsheets-and-speeches',
  sgpc = 'sgpc.gov.sg'
}

/**
 * An enum of sorting criteria for topic recommendations.
 */
export enum SortCriteria {
  recency = 'recency',
  relevance = 'relevance'
}

export interface ExistingContent {
  id: string
  title: string
  created_at: number
  type: DocumentType
}
