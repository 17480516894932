import { Theme, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

/**
 * Definition of the mobile breakpoint.
 * @param {Theme} theme
 * @return {string}
 */
export const getMobileBreakpoint = (theme: Theme) =>
  theme.breakpoints.down('sm')

/**
 * Centralized utility hook for checking whether the viewport width
 *  is equal to or smaller than the 'mobile' breakpoint.
 */
export const useIsMobile = (): boolean => {
  const theme = useTheme()
  return useMediaQuery(getMobileBreakpoint(theme))
}
