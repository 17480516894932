import React from 'react'

import { Header, Modal } from 'semantic-ui-react'

import { Grid, withStyles } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'

interface Props {
  isOpen: boolean
  header: string | React.ReactNode
  headerStyle?: React.CSSProperties
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'
  body: React.ReactNode
  bodyStyle?: React.CSSProperties
  trigger?: React.ReactNode
  secondaryText?: React.ReactNode
  secondaryIcon?: React.ReactNode
  secondaryFunc?: Function
  secondaryDisabled?: boolean
  primaryText?: React.ReactNode
  primaryIcon?: React.ReactNode
  primaryDisabled?: boolean
  primaryFunc?: Function
  closeIconDisabled?: boolean
  onClose: Function
  disabled?: boolean
  noBodyPadding?: boolean
  dimmer?: true | 'blurring' | 'inverted'
  'data-testid'?: string
  overrideModalActions?: React.ReactNode
}

const Button = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto'
    }
  }
})(MuiButton)

export default function GeneralModal(props: Props) {
  return (
    <Modal
      closeIcon={!props.closeIconDisabled}
      trigger={props.trigger}
      dimmer={props.dimmer ?? true}
      size={props.size}
      open={props.isOpen}
      onClose={props.disabled ? () => {} : () => props.onClose()}
    >
      <Header style={props.headerStyle} content={props.header} />
      {props.noBodyPadding ? (
        props.body
      ) : (
        <Modal.Content style={props.bodyStyle}>{props.body}</Modal.Content>
      )}
      <Modal.Actions>
        {props.overrideModalActions ? (
          <>{props.overrideModalActions}</>
        ) : (
          <Grid container spacing={1} direction="row" justifyContent="flex-end">
            <Grid item>
              <Button
                disabled={props.disabled || props.secondaryDisabled}
                startIcon={props.secondaryIcon}
                onClick={() => props.secondaryFunc && props.secondaryFunc()}
                data-testid={`${props['data-testid']}__cancel`}
              >
                {props.secondaryText}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={props.disabled || props.primaryDisabled}
                startIcon={props.primaryIcon}
                onClick={() => props.primaryFunc && props.primaryFunc()}
                data-testid={`${props['data-testid']}__confirm`}
                style={
                  props.disabled || props.primaryDisabled
                    ? { pointerEvents: 'auto' }
                    : {}
                }
              >
                {props.primaryText}
              </Button>
            </Grid>
          </Grid>
        )}
      </Modal.Actions>
    </Modal>
  )
}
