import React, { useEffect, useRef } from 'react'

import snackbar from '../../utils/Snackbar'

import GeneralError from './GeneralError'

interface UnexpectedErrorProps {
  translationEnabled?: boolean
}

const UnexpectedError: React.FC<UnexpectedErrorProps> = (props) => {
  const { translationEnabled } = props
  const hasShownTranslationSnackbar = useRef<boolean>(!translationEnabled) // Prevent snackbar spam; shows once per render

  // Display a simple snackbar to prompt users to disable their translation service
  // This may not fix the unexpected error but it might be worth their try
  useEffect(() => {
    if (!hasShownTranslationSnackbar.current) {
      snackbar.show(
        'If you have translation enabled, try disabling it and reloading the page.',
        {
          variant: 'info'
        }
      )
      hasShownTranslationSnackbar.current = true
    }
  }, [hasShownTranslationSnackbar])

  return (
    <GeneralError
      textHeader="We're fixing it"
      textBody="This page is experiencing hiccups. Our engineers know about this problem and we're working to get this back to normal quickly."
      textCta="Refresh"
      handleCta={() => {
        // Hard redirect to trigger a full page reload, just to be safe
        window.location.reload()
      }}
    />
  )
}

export default UnexpectedError
