import React, { useCallback, useMemo } from 'react'

import { StringParam, useQueryParam } from 'use-query-params'

import { useLocalStorage } from '../../../hooks/useLocalStorage'

import {
  AdTextType,
  adHeaders,
  adSubHeaders
} from '../../../interfaces/AdvertisingText'

import {
  ADTEXT_PING_DOT_LIST,
  PAPA_PLAN_FACEBOOK_TEMPLATE_CARD_SUBTITLE,
  PAPA_PLAN_FACEBOOK_TEMPLATE_CARD_TITLE
} from '../../../utils/Constants'
import { useUserContext } from '../../../utils/context/UserContext'

import {
  EntityReferenceItem,
  ReferenceableEntityType
} from '../../entityReference/types'
import { marketingRoutes, toolLogos } from '../mappings'

import { BaseToolCard, ToolCardWithMetadata } from './BaseToolCard'

interface Props {
  adTextType: AdTextType
  title: string
  subtitle: string
  iconSrc: string
}

const TemplateCard = (props: Props) => {
  const { adTextType, title, subtitle, iconSrc } = props
  const { user } = useUserContext()

  const isPapaPlanUser = !!user?.service_configs?.feature_flags?.is_papa_user

  // TODO: Move this to the DocumentOverviewPage
  // folder id is the id of which the adtext is created in, from the folders
  // in the documents page
  const [currentFolderId] = useQueryParam('folderId', StringParam)
  const [currentTopicRecommendationId] = useQueryParam(
    'topicRecommendationId',
    StringParam
  )

  const [pingsToRender, setPingsToRender] = useLocalStorage<string[]>(
    ADTEXT_PING_DOT_LIST,
    []
  )

  const href = useMemo(() => {
    if (adTextType === AdTextType.meta_desc) return 'collections/meta_desc'

    const referencedItems: EntityReferenceItem[] = []

    if (currentTopicRecommendationId) {
      referencedItems.push({
        id: currentTopicRecommendationId,
        type: ReferenceableEntityType.topicRecommendation
      })
    }

    let _href = `${marketingRoutes[adTextType]}`

    const isHidden =
      currentTopicRecommendationId && currentTopicRecommendationId.length > 0
        ? 0
        : 1
    _href += `?isHidden=${isHidden}`

    const referencedItemsEncoded = encodeURIComponent(
      JSON.stringify(referencedItems)
    )
    if (referencedItems.length)
      _href += `&referencedItems=${referencedItemsEncoded}`

    if (currentFolderId) _href += `&folderId=${currentFolderId}`

    return _href
  }, [adTextType, currentFolderId, currentTopicRecommendationId])

  const onClick = useCallback(async () => {
    setPingsToRender([])
  }, [setPingsToRender])

  return (
    <BaseToolCard
      title={
        isPapaPlanUser && adTextType === AdTextType.facebook
          ? PAPA_PLAN_FACEBOOK_TEMPLATE_CARD_TITLE
          : title
      }
      subtitle={
        isPapaPlanUser && adTextType === AdTextType.facebook
          ? PAPA_PLAN_FACEBOOK_TEMPLATE_CARD_SUBTITLE
          : subtitle
      }
      href={href}
      onClick={onClick}
      logoSrc={iconSrc}
      isPingRendered={pingsToRender.includes(adTextType)}
    />
  )
}

/**
 * A helper function to generate a ToolCardWithMetadata object for a given ad text type.
 */
export const makeCardWithMetadata = (
  adTextType: AdTextType,
  isIMDA?: boolean
): ToolCardWithMetadata => {
  const title =
    isIMDA && adTextType === AdTextType.linkedin
      ? 'IMDA LinkedIn'
      : adHeaders[adTextType]
  const subtitle = adSubHeaders[adTextType]
  const iconSrc = toolLogos[adTextType]

  const metadata = {
    id: adTextType,
    searchTerms: [title, subtitle]
  }

  return {
    card: (
      <TemplateCard
        adTextType={adTextType}
        title={title}
        subtitle={subtitle}
        iconSrc={iconSrc}
      />
    ),
    metadata
  }
}

// ===== Cards for each ad text type =====
export const AskAiAnythingCardWithMetadata = makeCardWithMetadata(
  AdTextType.ask_anything
)
export const RewriterCardWithMetadata = makeCardWithMetadata(
  AdTextType.rewriter
)
export const SummarizerCardWithMetadata = makeCardWithMetadata(
  AdTextType.summarise
)
export const InstagramCaptionsCardWithMetadata = makeCardWithMetadata(
  AdTextType.instagram
)
export const GoogleAdsCardWithMetadata = makeCardWithMetadata(AdTextType.google)
export const FacebookAdsCardWithMetadata = makeCardWithMetadata(
  AdTextType.facebook
)
export const LinkedinPostCardWithMetadata = makeCardWithMetadata(
  AdTextType.linkedin
)
export const HeadlineCardWithMetadata = makeCardWithMetadata(
  AdTextType.headline
)
export const LandingPageCardWithMetadata = makeCardWithMetadata(
  AdTextType.landing_page
)
export const MetaDescCardWithMetadata = makeCardWithMetadata(
  AdTextType.meta_desc
)
export const CategoryPageCardWithMetadata = makeCardWithMetadata(
  AdTextType.category_page
)
export const MarketingAnglesCardWithMetadata = makeCardWithMetadata(
  AdTextType.marketing_angles
)
export const Eli5CardWithMetadata = makeCardWithMetadata(AdTextType.eli5)
export const EmailCardWithMetadata = makeCardWithMetadata(AdTextType.email)
export const YoutubeTitleCardWithMetadata = makeCardWithMetadata(
  AdTextType.youtube_title
)
export const VideoIntroCardWithMetadata = makeCardWithMetadata(
  AdTextType.video_intro
)
export const BioWriterCardWithMetadata = makeCardWithMetadata(
  AdTextType.bio_writer
)
export const PressReleaseCardWithMetadata = makeCardWithMetadata(
  AdTextType.press_release
)
export const RewriteForSeoCardWithMetadata = makeCardWithMetadata(
  AdTextType.rewrite_for_seo
)

// IMDA cards
export const IMDALinkedinCardWithMetadata = makeCardWithMetadata(
  AdTextType.linkedin,
  true
)
