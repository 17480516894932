import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import hypodoc_logo from '../assets/v2/hypodoc.svg'

import { BaseToolCard, ToolCardWithMetadata } from './BaseToolCard'

const TITLE = 'HypoDoc'
const SUBTITLE =
  'Upload a technical document and ask AI to analyze, answer questions, summarize and more.'

const HypoDocCard = () => {
  const history = useHistory()
  const onClick = useCallback(async () => {
    history.push('/hypochat')
  }, [history])

  return (
    <BaseToolCard
      title={TITLE}
      subtitle={SUBTITLE}
      onClick={onClick}
      logoSrc={hypodoc_logo}
    />
  )
}

const metadata = {
  id: 'hypodoc',
  searchTerms: [TITLE, SUBTITLE]
}

export const HypoDocCardWithMetadata: ToolCardWithMetadata = {
  card: <HypoDocCard />,
  metadata
}
