import React, { useMemo } from 'react'

import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  Theme,
  createStyles,
  makeStyles
} from '@material-ui/core'
import {
  CSSProperties,
  ClassNameMap
} from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'

import {
  ButtonColor,
  ButtonSize,
  Color,
  ICON_SIZE_MAP,
  Size
} from './interfaces'

import palette from '../Colors'
import Icon, { Props as IconProps } from '../Icon'
import { VARIANT_MAP as TypographyVariantMap, VariantEnum } from '../Typography'

enum ButtonVariant {
  contained = 'contained',
  outlined = 'outlined',
  plain = 'plain'
}

type Variant = keyof typeof ButtonVariant

/**
 * Map of the the custom variant to any MUIButtonProps
 */
const VARIANT_MAP: Record<ButtonVariant, Partial<MUIButtonProps>> = {
  contained: {
    variant: 'contained'
  },
  outlined: {
    variant: 'outlined'
  },
  plain: {
    variant: 'text'
  }
}

const LABEL_VARIANT_NAME = 'label'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Button styles for size
     */
    [ButtonSize.xxs]: {
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.spacing(0.5)
    },
    [ButtonSize.xs]: {
      padding: theme.spacing(0.75, 1.5),
      borderRadius: theme.spacing(0.75)
    },
    [ButtonSize.sm]: {
      padding: theme.spacing(1, 1.5),
      borderRadius: theme.spacing(1)
    },
    [ButtonSize.md]: {
      padding: theme.spacing(1.25, 1.5),
      borderRadius: theme.spacing(1)
    },
    [ButtonSize.lg]: {
      padding: theme.spacing(1.25, 1.5),
      borderRadius: theme.spacing(1)
    },
    /**
     * Button styles for type + color combination
     */
    [`${ButtonVariant.contained}-${ButtonColor.primary}`]: {
      backgroundColor: palette.primary[600],
      '&:hover': {
        backgroundColor: palette.primary[300]
      }
    },
    [`${ButtonVariant.outlined}-${ButtonColor.primary}`]: {
      border: 0,
      outline: `1px solid ${palette.primary[300]}`,
      backgroundColor: palette.primary[25],
      '&:hover': {
        backgroundColor: palette.primary[50]
      }
    },
    [`${ButtonVariant.plain}-${ButtonColor.primary}`]: {
      '&:hover': {
        backgroundColor: palette.gray[100]
      }
    },
    [`${ButtonVariant.contained}-${ButtonColor.red}`]: {
      backgroundColor: palette.red[500],
      '&:hover': {
        backgroundColor: palette.red[300]
      }
    },
    [`${ButtonVariant.outlined}-${ButtonColor.red}`]: {
      border: 0,
      outline: `1px solid ${palette.red[300]}`,
      backgroundColor: palette.white,
      '&:hover': {
        backgroundColor: palette.red[50]
      }
    },
    [`${ButtonVariant.plain}-${ButtonColor.red}`]: {
      '&:hover': {
        backgroundColor: palette.red[50]
      }
    },
    [`${ButtonVariant.contained}-${ButtonColor.blue}`]: {
      backgroundColor: palette.blue[500],
      '&:hover': {
        backgroundColor: palette.blue[300]
      }
    },
    [`${ButtonVariant.outlined}-${ButtonColor.blue}`]: {
      border: 0,
      outline: `1px solid ${palette.blue[500]}`,
      backgroundColor: palette.white,
      '&:hover': {
        backgroundColor: palette.blue[50]
      }
    },
    [`${ButtonVariant.plain}-${ButtonColor.blue}`]: {
      '&:hover': {
        backgroundColor: palette.blue[50]
      }
    },
    [`${ButtonVariant.contained}-${ButtonColor.gray}`]: {
      backgroundColor: palette.gray[900],
      '&:hover': {
        backgroundColor: palette.gray[500]
      }
    },
    [`${ButtonVariant.outlined}-${ButtonColor.gray}`]: {
      border: 0,
      outline: `1px solid ${palette.gray[300]}`,
      backgroundColor: palette.white,
      '&:hover': {
        backgroundColor: palette.gray[100]
      }
    },
    [`${ButtonVariant.plain}-${ButtonColor.gray}`]: {
      '&:hover': {
        backgroundColor: palette.gray[100]
      }
    },
    /**
     * Label styles for size (typography for text buttons)
     */
    [`${LABEL_VARIANT_NAME}-${ButtonSize.xxs}`]: {
      ...(TypographyVariantMap[VariantEnum.captionBold].style as CSSProperties)
    },
    [`${LABEL_VARIANT_NAME}-${ButtonSize.xs}`]: {
      ...(TypographyVariantMap[VariantEnum.paragraph2Med]
        .style as CSSProperties)
    },
    [`${LABEL_VARIANT_NAME}-${ButtonSize.sm}`]: {
      ...(TypographyVariantMap[VariantEnum.paragraph2Bold]
        .style as CSSProperties)
    },
    [`${LABEL_VARIANT_NAME}-${ButtonSize.md}`]: {
      ...(TypographyVariantMap[VariantEnum.paragraph2Bold]
        .style as CSSProperties)
    },
    [`${LABEL_VARIANT_NAME}-${ButtonSize.lg}`]: {
      ...(TypographyVariantMap[VariantEnum.paragraph1Bold]
        .style as CSSProperties)
    },
    /**
     * Label styles for variant + color combination
     */
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.contained}-${ButtonColor.primary}`]: {
      color: palette.white
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.contained}-${ButtonColor.red}`]: {
      color: palette.white
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.contained}-${ButtonColor.gray}`]: {
      color: palette.white
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.outlined}-${ButtonColor.primary}`]: {
      color: palette.primary[700]
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.outlined}-${ButtonColor.red}`]: {
      color: palette.red[700]
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.outlined}-${ButtonColor.gray}`]: {
      color: palette.gray[700]
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.plain}-${ButtonColor.primary}`]: {
      color: palette.primary[700]
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.plain}-${ButtonColor.red}`]: {
      color: palette.red[700]
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.plain}-${ButtonColor.gray}`]: {
      color: palette.gray[700]
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.outlined}-${ButtonColor.blue}`]: {
      color: palette.blue[600]
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.plain}-${ButtonColor.blue}`]: {
      color: palette.blue[600]
    },
    [`${LABEL_VARIANT_NAME}-${ButtonVariant.contained}-${ButtonColor.blue}`]: {
      color: palette.blue[600]
    },

    /**
     * Start / end icon styling
     */
    startIcon: {
      marginLeft: 0,
      aspectRatio: '1/1',
      /**
       * `.MuiButton-iconSize[Medium/Large]` is a class added by MUI
       * to the icon component when the size is medium or large.
       *
       * This introduces offset in the icon size, which we need to
       * reset to inherit the font size of the button.
       */
      fontSize: 'inherit'
    },
    endIcon: {
      marginRight: 0,
      aspectRatio: '1/1',
      /**
       * `.MuiButton-iconSize[Medium/Large]` is a class added by MUI
       * to the icon component when the size is medium or large.
       *
       * This introduces offset in the icon size, which we need to
       * reset to inherit the font size of the button.
       */
      fontSize: 'inherit'
    },
    /**
     * Disabled styles for color
     */
    disabledInternalStyling: {
      '&:disabled': {
        outline: `1px solid ${palette.gray[300]}`,
        border: 0,
        color: palette.gray[400]
      }
    },
    /**
     * Ensure label inherits the color defined in root.
     * This is because disabled styling is handled in root, and affects
     * the color of text and icons. We need to ensure the disabled color
     * styling is inherited by the label
     */
    inheritLabelStyling: {
      color: 'inherit'
    }
  })
)

interface Props
  extends Omit<
    MUIButtonProps,
    'variant' | 'size' | 'color' | 'startIcon' | 'endIcon'
  > {
  /**
   * The variant of the button.
   */
  variant: Variant
  /**
   * The size of the button.
   */
  size: Size
  /**
   * The color of the button.
   */
  color: Color
  /**
   * The component type for the start icon.
   */
  startIcon?: React.ComponentType
  /**
   * Additional props for the start icon
   */
  startIconProps?: Omit<IconProps, 'icon'>
  /**
   * The component type for the end icon.
   */
  endIcon?: React.ComponentType
  /**
   * Additional props for the end icon
   */
  endIconProps?: Omit<IconProps, 'icon'>
}

/**
 * Button component with custom variants, sizes, and colors.
 * Based off MUI Button component
 *
 * @component
 * @example
 * ```tsx
 * // Basic usage
 * import { Button } from './Button';
 *
 * <Button variant="contained" size="md" color="primary">
 *   Click Me
 * </Button>
 * ```
 *
 * @example
 * ```tsx
 * // Button with start and end icons
 * import { Button } from './Button';
 * import { ReactComponent as StartIcon } from './start-icon.svg';
 * import { ReactComponent as EndIcon } from './end-icon.svg';
 *
 * <Button
 *   variant="outlined"
 *   size="lg"
 *   color="red"
 *   startIcon={StartIcon}
 *   endIcon={EndIcon}
 * >
 *   Click Me
 * </Button>
 * ```
 *
 * @example
 * ```tsx
 * // Button with custom icon props
 * import { Button } from './Button';
 * import { ReactComponent as StartIcon } from './start-icon.svg';
 *
 * <Button
 *   variant="plain"
 *   size="sm"
 *   color="gray"
 *   startIcon={StartIcon}
 *   startIconProps={{ 'aria-label': 'Start Icon' }}
 * >
 *   Click Me
 * </Button>
 * ```
 *
 * @param {Variant} variant - The variant of the button.
 * @param {Size} size - The size of the button.
 * @param {Color} color - The color of the button.
 * @param {React.ComponentType} [startIcon] - The component type for the start icon.
 * @param {Omit<IconProps, 'icon'>} [startIconProps] - Additional props for the start icon.
 * @param {React.ComponentType} [endIcon] - The component type for the end icon.
 * @param {Omit<IconProps, 'icon'>} [endIconProps] - Additional props for the end icon.
 */
const Button: React.FC<Props> = React.memo(
  React.forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
    const {
      variant,
      size,
      color,
      startIcon,
      startIconProps,
      endIcon,
      endIconProps,
      classes: classesOverrides,
      children,
      ...buttonProps
    } = props
    const classes: ClassNameMap<string> = useStyles()

    const classRootNames = useMemo(
      () =>
        [
          size, // padding and width depending on size
          [variant, color].join('-'), // background color + hover styling
          [LABEL_VARIANT_NAME, variant, color].join('-') // typography color for label
        ].map((name) => classes[name]),
      [variant, color, size, classes]
    )

    const classLabelNames = useMemo(
      () =>
        [
          [LABEL_VARIANT_NAME, size].join('-') // typography styling for text
        ].map((name) => classes[name]),
      [size, classes]
    )

    const {
      root: classesRoot,
      label: classesLabel,
      startIcon: classesStartIcon,
      endIcon: classesEndIcon,
      ...classesRest
    } = classesOverrides ?? {}

    return (
      <MUIButton
        startIcon={
          startIcon && (
            <Icon
              icon={startIcon}
              size={ICON_SIZE_MAP[size]}
              {...startIconProps}
            />
          )
        }
        endIcon={
          endIcon && (
            <Icon icon={endIcon} size={ICON_SIZE_MAP[size]} {...endIconProps} />
          )
        }
        ref={ref}
        classes={{
          root: clsx(
            ...classRootNames,
            classes.disabledInternalStyling,
            classesRoot
          ),
          label: clsx(
            classes.inheritLabelStyling,
            ...classLabelNames,
            classesLabel
          ),
          startIcon: clsx(classes.startIcon, classesStartIcon),
          endIcon: clsx(classes.endIcon, classesEndIcon),
          ...classesRest
        }}
        {...VARIANT_MAP[variant]}
        {...buttonProps}
      >
        {children}
      </MUIButton>
    )
  })
)

export default Button
