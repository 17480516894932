export enum TourType {
  helphero = 'helphero'
}
export interface Tour {
  id: string
  name: string
  type: TourType
}
export interface TourProgress {
  step?: string
  is_complete: boolean
  version: string
  is_exit: boolean
  timestamp?: Date
}
