import { ECommercePlatforms } from '@hypotenuse/common/src/components/digitalShelfAnalytics/interfaces'
import {
  Description,
  ProductImportSource
} from '@hypotenuse/common/src/interfaces/Products'
import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

import {
  IntegrationConnectionSource,
  IntegrationEventType
} from '../../../common/src/interfaces/Integration'

import { GenerationStatusFilterKey } from '../components/utils/Interfaces'

export const handleTrackUserSession = (): Promise<void> => {
  return apiClient.post<void>('/analytics/session').then((_resp) => {})
}

export const handleTrackDescriptionEdit = (
  description: Description,
  productId: string,
  catalogueTemplateName?: string
): Promise<void> => {
  return apiClient
    .post('/analytics/product/description_edit', {
      description: description,
      product_id: productId,
      catalogue_template_name: catalogueTemplateName
    })
    .then((_resp) => {})
}

export const handleTrackProductsImportSelectSource = (
  importSource: ProductImportSource,
  catalogueTemplateName?: string
): Promise<void> => {
  return apiClient
    .post('/analytics/product/import_select_source', null, {
      params: {
        import_source: importSource,
        catalogue_template_name: catalogueTemplateName
      }
    })
    .then((_resp) => {})
}

export const handleTrackAddSmartSuggestedKeyword = (
  productId: string,
  title: string,
  brand: string,
  searchPlatform: ECommercePlatforms,
  suggestedSmartKeywords: string[],
  rootKeyword?: string,
  productDetails?: string[],
  enrichmentContent?: string[]
): Promise<void> => {
  return apiClient.post('/analytics/product/add_smart_suggested_keyword', {
    product_id: productId,
    title: title,
    brand: brand,
    search_platform: searchPlatform,
    suggested_smart_keywords: suggestedSmartKeywords,
    product_details: productDetails,
    enrichment_content: enrichmentContent,
    root_keyword: rootKeyword
  })
}

export const handleTrackSettingsButtonClick = (): Promise<void> => {
  return apiClient.post<void>('/analytics/settings').then((_resp) => {})
}

export const handleTrackProductCardTabClick = (
  productId: string,
  tab: string
) => {
  return apiClient.post<void>('/analytics/product_card_tab', null, {
    params: {
      product_id: productId,
      tab: tab
    }
  })
}

export const handleTrackDescriptionDownvote = (
  description: Description,
  product_id: string,
  catalogueTemplateName?: string
) => {
  const data = {
    description: description,
    product_id: product_id,
    catalogue_template_name: catalogueTemplateName
  }
  return apiClient.post<void>('/analytics/product/description_downvote', data)
}

export const handleTrackDescriptionDownvoteReason = (
  description: Description,
  product_id: string,
  reasons: string[],
  available_reasons: string[],
  explanation: string
) => {
  const data = {
    description: description,
    product_id: product_id,
    reasons: reasons,
    available_reasons: available_reasons,
    explanation: explanation
  }
  return apiClient.post<void>(
    '/analytics/product/description_downvote_reason',
    data
  )
}

export const handleTrackGenerationStatusFilter = (
  generationStatus: GenerationStatusFilterKey,
  catalogueTemplateName?: string
) => {
  return apiClient.post<void>(
    '/analytics/product/generation_status_filter',
    null,
    {
      params: {
        generation_status: generationStatus,
        catalogue_template_name: catalogueTemplateName
      }
    }
  )
}

export const handleTrackIntegrationConnection = (
  connectionSource: IntegrationConnectionSource,
  eventType: IntegrationEventType
) => {
  return apiClient.post<void>('/analytics/integration-page', null, {
    params: {
      connection_source: connectionSource,
      event_type: eventType
    }
  })
}
