import React from 'react'
import { useCallback } from 'react'

import { handleUserClick } from '../../../api/Analytics'

import productDescriptionBulkIcon from '../assets/v2/product_description_bulk.svg'

import { BaseToolCard, ToolCardWithMetadata } from './BaseToolCard'

const TITLE = 'Product Descriptions (Bulk)'
const SUBTITLE =
  'Create thousands of SEO optimized product descriptions in bulk.'

const ProductDescriptionCard = () => {
  const onClick = useCallback(async () => {
    handleUserClick(
      'marketing-dashboard-product-description-card',
      window.location.pathname
    )
  }, [])
  return (
    <BaseToolCard
      title={TITLE}
      subtitle={SUBTITLE}
      onClick={onClick}
      logoSrc={productDescriptionBulkIcon}
      href="/product-descriptions?openCreateCatalogueModal=1"
    />
  )
}

const metadata = {
  id: 'product_description',
  searchTerms: [TITLE, SUBTITLE]
}

export const ProductDescriptionCardWithMetadata: ToolCardWithMetadata = {
  card: <ProductDescriptionCard />,
  metadata
}
