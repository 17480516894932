import React from 'react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'

import LabelFlag from '@hypotenuse/common/src/components/LabelFlag'
import { CURRENCY_SYMBOL_MAPPING } from '@hypotenuse/common/src/utils/Interfaces'

interface PlanPricingRowProps {
  isCustom?: boolean
  price: number
  /**
   * If provided, this string will be displayed instead of the price.
   */
  priceDisplayString?: string
  /**
   * If provided, this string will be displayed as a prefix to price or priceDisplayString
   */
  pricePrefix?: string
  currency?: string
  isYearly?: boolean
  isActive?: boolean
  isPaused?: boolean
  isCancelAtPeriodEnd?: boolean
  /**
   * Whether the plan is an unlimited plan.
   */
  isUnlimitedPlan?: boolean
  /**
   * Whether the plan is an unlimited plan but also under the starter plan.
   * NOTE: As of Oct 2023, this is a hacky way to display the unlimited plan under the starter plan,
   *   will be deprecated in the future
   */
  isUnlimitedDifferentPlan?: boolean
  /**
   * Whether to display the price in light text
   */
  lightText?: boolean
  shouldShowPriceForEnterprisePlan?: boolean
}

/**
 * Displays the price of a plan along with the billing period.
 */
export const PlanPrice = (props: PlanPricingRowProps) => {
  const {
    isCustom,
    price,
    priceDisplayString,
    pricePrefix,
    currency = 'usd',
    isYearly,
    isActive,
    isPaused,
    isCancelAtPeriodEnd,
    isUnlimitedPlan,
    isUnlimitedDifferentPlan,
    lightText,
    shouldShowPriceForEnterprisePlan
  } = props
  const currencySymbol = CURRENCY_SYMBOL_MAPPING[currency] || '$'

  /* Separate the price by command for every 3 digits
     We use toLocaleString() to format the price in US format, since it is the most common format
  */
  const formattedPrice = price.toLocaleString('en-US')

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {isCustom && !shouldShowPriceForEnterprisePlan ? (
        <Typography variant="heading1" style={{ color: 'white' }}>
          Custom
        </Typography>
      ) : priceDisplayString ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            variant="paragraph1Reg"
            style={{ color: lightText ? palette.white : palette.gray[600] }}
          >
            {pricePrefix}
          </Typography>
          <Typography
            variant="heading1"
            style={{ color: lightText ? palette.white : palette.gray[600] }}
          >
            {priceDisplayString}
          </Typography>
          <Typography
            style={{ color: lightText ? palette.white : palette.gray[600] }}
          >
            {isUnlimitedPlan && !isUnlimitedDifferentPlan
              ? '/user/month'
              : isYearly
              ? '/month, billed annually'
              : '/month'}
          </Typography>
        </Stack>
      ) : (
        <>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              variant="paragraph1Reg"
              style={{ color: lightText ? palette.white : palette.gray[600] }}
            >
              {pricePrefix}
            </Typography>
            <Typography
              variant="heading1"
              style={{ color: lightText ? palette.white : palette.gray[600] }}
            >
              {currencySymbol}
              {formattedPrice}
            </Typography>
          </Stack>
          <Typography
            style={{ color: lightText ? palette.white : palette.gray[600] }}
          >
            {isUnlimitedPlan && !isUnlimitedDifferentPlan
              ? '/user/month'
              : isYearly
              ? '/month, billed annually'
              : '/month'}
          </Typography>
        </>
      )}
      {isActive &&
        (isPaused ? (
          <LabelFlag
            content="Paused"
            variant="regular"
            color={palette.yellow[800]}
            bgcolor={palette.yellow[100]}
            typographyProps={{ style: { fontWeight: 500 } }}
          />
        ) : isCancelAtPeriodEnd ? (
          <LabelFlag
            content="Cancelled"
            variant="regular"
            color={palette.red[800]}
            bgcolor={palette.red[100]}
            typographyProps={{ style: { fontWeight: 500 } }}
          />
        ) : (
          <LabelFlag
            content="Active"
            variant="regular"
            color={palette.green[800]}
            bgcolor={palette.green[100]}
            typographyProps={{ style: { fontWeight: 500 } }}
          />
        ))}
    </Stack>
  )
}
