import React, { useEffect } from 'react'

import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Container, useTheme } from '@material-ui/core'

import { PrimaryButton } from '@hypotenuse/common/src/components/BaseModal'

import { LOGIN_PAGE_PATH } from '../../../utils/Constants'

/**
 * Generic error page displayed when an authentication error occurs.
 * This page contains no Hypotenuse branding because it may be
 * shown to white-label users.
 */
const AuthErrorPage = () => {
  const theme = useTheme()
  const history = useHistory()

  useEffect(() => {
    // Log error to Sentry on page load
    Sentry.captureMessage('Authentication error page shown to user', 'warning')
  }, [])

  return (
    <Container maxWidth="md" style={{ height: '100%' }}>
      <Stack height="100%" justifyContent="center" alignItems="center">
        <Stack
          spacing={2}
          alignItems="center"
          p={4}
          borderRadius={theme.shape.borderRadius}
        >
          <Typography variant="h4" align="center">
            Sorry, an error occurred
          </Typography>
          <PrimaryButton
            onClick={() => {
              /**
               * redirecting to sign up rather than login
               * since it's relatively uncommon for an existing user to have to login
               * so a user landing on this page is likely to have come from
               * this signup page
               */
              history.replace(`${LOGIN_PAGE_PATH}?sign_up=true`)
            }}
          >
            Try again
          </PrimaryButton>
        </Stack>
      </Stack>
    </Container>
  )
}

export default AuthErrorPage
