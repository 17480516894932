import React, { useState } from 'react'

import * as Sentry from '@sentry/react'
import { useIntercom } from 'react-use-intercom'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton
} from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import errorIcon from '@hypotenuse/common/src/assets/error.svg'
import { useIsMobile } from '@hypotenuse/common/src/hooks/UseIsMobile'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'

import usePremiumPlanTier from '../../../hooks/usePremiumPlanTier'

import { handleCreateStripeCustomerPortal } from '../../../api/Billing'

import { PlanType } from '../../utils/Interfaces'

import {
  DeclineCode,
  finalWarningMessages,
  warningMessages
} from './PaymentInterfaces'

interface PaymentFailureModalProps {
  markModalAsSeen: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainBanner: {
      borderColor: palette.error[300],
      backgroundColor: palette.error[50]
    },
    content: {
      color: palette.error[600]
    },
    planName: {
      color: palette.error[800]
    },
    updateBtn: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white
      }
    }
  })
)

const SupportEmailReactElement = React.memo(() => {
  return <a href="mailto:support@hypotenuse.ai">support@hypotenuse.ai</a>
})

const PaymentFailureModal = (props: PaymentFailureModalProps) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const {
    data: premiumPlanTier,
    latestUnpaidInvoicePaymentLink
  } = usePremiumPlanTier()
  const { showNewMessages: openIntercom } = useIntercom()

  const decline_code = premiumPlanTier?.payment_failure_info?.decline_code ?? ''

  // use the calculation from backend instead of recomputing again
  const overdueDays = premiumPlanTier?.payment_failure_info?.overdueDays ?? 0

  const amountDue = premiumPlanTier?.plan_price ?? 0
  const planName = premiumPlanTier?.plan_display_name

  /** State variables to control the opening of modal and loading status */
  const [openModal, setOpenModal] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  /** Opens stripe customer portal for users to update their payment method */
  const redirectToStripeCustomerPortal = (): void => {
    setLoading(true)
    props.markModalAsSeen()
    handleCreateStripeCustomerPortal()
      .then((portalUrl: string) => {
        window.location.href = portalUrl
      })
      .catch((error) => {
        setLoading(false)
        snackbar.show(
          "Sorry there's an error, please contact our support team via chat or email for assistance.",
          { variant: 'error' }
        )
        Sentry.captureException(error)
      })
  }

  const declineMessage = () => {
    const finalWarning = overdueDays > 28

    const correctMessageMap = finalWarning
      ? finalWarningMessages
      : warningMessages

    const code = Object.values(DeclineCode).includes(
      decline_code as DeclineCode
    )
      ? (decline_code as DeclineCode)
      : 'default'

    return (
      <Typography
        variant="body1"
        style={{
          whiteSpace: 'pre-line',
          color: palette.gray[500]
        }}
      >
        {correctMessageMap[code]}
        {finalWarning && <SupportEmailReactElement />}
      </Typography>
    )
  }

  const planBanner = (
    <Stack
      direction="row"
      className={classes.mainBanner}
      borderRadius={8}
      border={1}
      p={1}
      spacing={1}
      width="100%"
      alignContent="center"
    >
      <Box>
        <img src={errorIcon} alt="error" style={{ margin: 'auto' }} />
      </Box>
      <Stack justifyContent="center">
        <Typography variant="body1" className={classes.content}>
          {planName} Plan ${amountDue}/month
        </Typography>
        <Typography variant="body1" className={classes.content}>
          {overdueDays < 1
            ? 'Payment is due today'
            : overdueDays === 1
            ? 'Payment due 1 day ago'
            : `Payment due ${overdueDays} days ago`}
        </Typography>
      </Stack>
    </Stack>
  )

  const dialogContent = (
    <>
      <Typography variant="h6">Your payment has failed</Typography>
      {declineMessage()}
      <Stack direction="row">
        <Typography
          variant="body1"
          style={{
            color: palette.gray[500],
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
          onClick={() => openIntercom()}
        >
          Contact us
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: palette.gray[500]
          }}
        >
          &nbsp;if you need any support.
        </Typography>
      </Stack>
    </>
  )

  /**
   * Prevent enterprise user from seeing the payment failure modal
   */
  return premiumPlanTier &&
    premiumPlanTier.plan_type !== PlanType.enterprise &&
    !!premiumPlanTier?.payment_failure_info?.invoice_id ? (
    <Dialog
      open={openModal}
      fullWidth
      maxWidth={isMobile ? 'xs' : 'sm'}
      disableEnforceFocus
    >
      <DialogContent>
        <Stack spacing={2} pb={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <img src={errorIcon} alt="error" style={{ margin: 'auto' }} />
            </Box>
            <IconButton
              onClick={() => {
                props.markModalAsSeen()
                setOpenModal(false)
              }}
              style={{ height: '10%', padding: '5px' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {dialogContent}
          {planBanner}
          {latestUnpaidInvoicePaymentLink ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.updateBtn}
              component="a"
              href={latestUnpaidInvoicePaymentLink}
            >
              Pay invoice
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.updateBtn}
              endIcon={
                loading && <CircularProgress color="inherit" size={14} />
              }
              disabled={loading}
              onClick={redirectToStripeCustomerPortal}
            >
              Update payment details
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  ) : null
}

export default PaymentFailureModal
