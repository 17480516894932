import React from 'react'

import { useHistory } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import { apiCreateDocument } from '../../../api/Documents'

import snackbar from '../../../utils/Snackbar'

import blank_document_logo from '../assets/blank_document.svg'

import { BaseToolCard, ToolCardWithMetadata } from './BaseToolCard'

const TITLE = 'Blank document'
const SUBTITLE =
  'Start crafting fresh and engaging content from scratch in a blank page.'

const BlankDocumentCard = () => {
  const history = useHistory()

  // TODO: Move this to the DocumentOverviewPage
  // folder id is the id of which the adtext is created in, from the folders
  // in the documents page
  const [currentFolderId] = useQueryParam('folderId', StringParam)

  const onClick = async () => {
    try {
      const newDocument = await apiCreateDocument({
        folderId: currentFolderId || undefined,
        isHidden: true
      })
      history.push(
        `/document?id=${newDocument.id}${
          newDocument?.folder_id ? `&folderId=${newDocument?.folder_id}` : ''
        }`
      )
    } catch (error) {
      console.error(error)
      snackbar.show(
        'Failed to create document. Please contact our support team.',
        {
          variant: 'error'
        }
      )
    }
  }

  return (
    <BaseToolCard
      title={TITLE}
      subtitle={SUBTITLE}
      onClick={onClick}
      logoSrc={blank_document_logo}
    />
  )
}

const metadata = {
  id: 'blank_document',
  searchTerms: [TITLE, SUBTITLE]
}

export const BlankDocumentCardWithMetadata: ToolCardWithMetadata = {
  card: <BlankDocumentCard />,
  metadata
}
