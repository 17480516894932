import {
  Analytics as CommonAnalytics,
  TrackEventParams
} from '@hypotenuse/common/src/analytics/Analytics'

/**
 * Backward compatibility layer to match existing signature of `trackEvent` on premium.
 * Eventually, we should port all usages of `trackEvent` on premium to match the common signature and remove this class.
 * @deprecated Prefer the `useAnalytics` hook inside function components, and prefer `CommonAnalytics` otherwise.
 */
const Analytics = {
  ...CommonAnalytics,
  trackEvent: (event: string, params?: TrackEventParams) =>
    // TODO: 'User' is a placeholder; need to take it as a param instead
    CommonAnalytics.trackEvent('User', event, params)
}

export default Analytics
