import React from 'react'

import Lottie from 'lottie-react'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { palette } from '../atoms'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Button, DialogActions, DialogContent } from '@material-ui/core'

import {
  SimpleDialog,
  SimpleDialogProps
} from '@hypotenuse/common/src/components/molecules/SimpleDialog'

import humanHandsUpErrorAnimation from '../lottie/human-hands-up-error.json'

export interface InsufficientCreditsModalProps extends SimpleDialogProps {
  isOpen?: boolean
  modalMessage: string
  redirectTo: string
  /**
   * Disables the button that redirects the user to the pricing page or whatever
   */
  disableRedirectButton?: boolean
  primaryText: string
}

export const InsufficientCreditsModal = (
  props: InsufficientCreditsModalProps
) => {
  const {
    onClose,
    modalMessage,
    redirectTo = '/pricing',
    disableRedirectButton,
    primaryText
  } = props

  /**
   * Structures the modal content
   */
  const content = (
    <>
      <Lottie
        loop={false}
        animationData={humanHandsUpErrorAnimation}
        style={{
          height: '60%',
          width: '50%',
          marginTop: '-10%',
          marginLeft: '10%'
        }}
      />
      <Typography
        variant="h5"
        style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '-4%' }}
      >
        You've run out of credits :{'('}
      </Typography>
      {modalMessage.split('\n').map((str) => (
        <Typography
          key={str}
          color="textSecondary"
          variant="body1"
          align="center"
        >
          {str}
        </Typography>
      ))}
    </>
  )

  const [isRedirect, setIsRedirect] = React.useState(false)
  if (isRedirect) {
    return <Redirect to={redirectTo} />
  }
  const handleRedirect = () => {
    setIsRedirect(true)
    // TODO: Potential issue - the component might(?) unmount before onClose is called
    onClose?.()
  }

  return (
    <SimpleDialog
      maxWidth="xs"
      topDivider={false}
      closeButtonSize={'medium'}
      {...props}
    >
      <DialogContent>
        <Stack spacing={1} py={4} alignItems="center" textAlign="center">
          {content}
        </Stack>
      </DialogContent>
      {!disableRedirectButton && (
        <DialogActions
          disableSpacing
          style={{ justifyContent: 'center', marginBottom: 5 }}
        >
          <Stack width="80%" justifyContent="center" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRedirect}
              style={{ borderRadius: '10px' }}
            >
              <Typography variant="h6">{primaryText}</Typography>
            </Button>
            <Button
              component={Link}
              variant="text"
              to="/free-credits"
              rel="noreferrer"
              target="_blank"
            >
              <Typography
                style={{
                  color: palette.primary[500],
                  fontWeight: 600
                }}
              >
                Want to earn more credits?
              </Typography>
            </Button>
          </Stack>
        </DialogActions>
      )}
    </SimpleDialog>
  )
}
