import React, { DependencyList, EffectCallback, useEffect, useRef } from 'react'

import { format, parseISO } from 'date-fns'
import moment from 'moment'
import { OptionsObject, SnackbarMessage } from 'notistack'
import TurndownService from 'turndown'

import { Button } from '@material-ui/core'

import { Description } from '../interfaces/Products'

import { HAS_SEEN_PAPERCUPS, MIN_EXPECTED_OUTLINES } from './Constants'
import { AppFeatures } from './Interfaces'
import snackbar from './Snackbar'

// ==== Ad Descriptions ====
export const constructAdDescription = (
  title: string,
  description: string
): string => {
  return `${title}\nDescription:${description}`
}

interface parsedDescription {
  title: string
  description: string
}

export const parseAdDescription = (
  description: Description
): parsedDescription => {
  const parts = description.content.split('\nDescription:', 2)
  if (parts.length === 0) return { title: '', description: '' }
  else if (parts.length === 1) return { title: parts[0] ?? '', description: '' }
  else if (parts.length > 2)
    return { title: parts[0] ?? '', description: parts.slice(1).join('') }

  const [title, desc] = parts
  // Do not trim white space at the end of description. This will cause a bug where the user cannot type any spaces
  // when editing their descriptions at the end.
  return {
    title: title ?? '',
    description: desc ? desc.trimStart() : ''
  }
}

// ==== Snackbar ====
interface ShowWithSnackbarOptions<
  ClickType = any,
  EnterType = any,
  ExitType = any
> {
  onClick?: () => ClickType
  onEnter?: () => EnterType
  onExit?: () => ExitType
  // TODO: Confirm button?
  buttonText?: string
  snackbarOptions?: Exclude<OptionsObject, 'onEnter' | 'onClose' | 'action'>
}

/**
 * Display a snackbar with:
 * - an 'enter' callback when the snackbar is first displayed
 * - an 'exit' callback when it closes 'naturally'
 * - a 'click' callback when it's closed programmatically (i.e. with a button click)
 * @param {SnackbarMessage} message
 * @param {ShowWithSnackbarOptions} options
 */
export const showWithSnackbar = function <
  ClickType = any,
  EnterType = any,
  ExitType = any
>(
  message: SnackbarMessage,
  options: ShowWithSnackbarOptions<ClickType, EnterType, ExitType>
): ClickType | EnterType | ExitType | undefined {
  let result: ClickType | EnterType | ExitType | undefined

  snackbar.show(message, {
    ...options.snackbarOptions,
    onEnter: (_node, _key) => {
      result = options.onEnter?.()
    },
    onClose: (_event, reason) => {
      // When close is not triggered programmatically (i.e. user clicked cancel), trigger exit
      if (reason !== 'instructed' && reason !== 'clickaway') {
        result = options.onExit?.()
      }
    },
    action: options.onClick
      ? (snackbarKey) => (
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              result = options.onClick?.()
              snackbar.close(snackbarKey)
            }}
            style={{ fontWeight: 'bold' }}
          >
            {options.buttonText ?? 'Cancel'}
          </Button>
        )
      : undefined
  })

  return result
}

// ==== Papercups ====
export const setHasSeenPapercups = (hasSeen: boolean): void => {
  localStorage.setItem(HAS_SEEN_PAPERCUPS, hasSeen.toString())
}

export const getHasSeenPapercups = (): boolean => {
  return localStorage.getItem(HAS_SEEN_PAPERCUPS) === 'true'
}

// ==== Misc ====
export const argSort = <T extends any = any>(
  unsortedArr: T[],
  compareFn: (a: T, b: T) => number
): number[] =>
  unsortedArr
    // Convert to an object containing the original value and its index
    .map((item, index) => ({ item, index }))
    // Sort the array of objects by the original value
    .sort((a, b) => compareFn(a.item, b.item))
    // Map to sorted indices
    .map(({ index }) => index)

export const isStringUrl = (possible_url: string) => {
  // Basic check to see if string is URL. Can be more robust, but for now just need a basic one
  const url_prefixes = ['www.', 'https:', 'http:']
  return url_prefixes.some((prefix) => possible_url.includes(prefix))
}

// Functions to count characters
const stripHtml = (s: string) => {
  s = s.replace(/<[^>]*>?/gm, '')
  return s
}
export const countWords = (s: string) => {
  /**
   * Counts the number of words within a string.
   *
   * Reference: https://stackoverflow.com/questions/32311081/check-for-special-characters-in-string
   */
  const format = /^[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/

  s = stripHtml(s)
  const s_split = s
    .replace(/&nbsp;/g, ' ')
    .trim()
    .split(/[\s\r\n]+/)
  const s_split_remove_empty = s_split
    .map((s) => s.trim())
    .filter((s) => s !== '' && !s.match(format))
  return s_split_remove_empty.length
}

export const countCharacters = (s: string) => {
  s = stripHtml(s)
  s = s.replace(/&nbsp;/g, '') // Newline will be represented as &nbsp leading to wrong number of chars so we need to strip it
  s = s.trimEnd() // Trim off any whitespace at the end
  return s.length
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// ==== Dates ====
const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object')
    return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    // Note: The Z at the back of parseISO is necessary because we do UTC now.
    // We can remove the Z if we migrate our python datetime to use the ISO8601 spec.
    // See more here: https://stackoverflow.com/questions/19654578/python-utc-datetime-objects-iso-format-doesnt-include-z-zulu-or-zero-offset
    if (isIsoDateString(value)) body[key] = parseISO(`${value}Z`)
    else if (typeof value === 'object') handleDates(value)
  }
}

export function getDatetimeSince(date: Date): string {
  const currDate = new Date()
  let diffDays = currDate.getDate() - date.getDate()
  let diffMonths = currDate.getMonth() - date.getMonth()
  let diffYears = currDate.getFullYear() - date.getFullYear()

  // If date is today or yesterday, display time since (eg 4 hours ago, 2 minutes ago)
  if (diffYears === 0 && diffDays < 2 && diffMonths === 0) {
    return moment(date).fromNow()
  }
  // Hide year if within current year
  if (diffYears === 0) {
    return formatDateString(date, 'dd MMM')
  }
  return formatDateString(date, 'dd MMM yyyy')
}

export function formatDateString(date: Date, dateFormat: string) {
  return format(date, dateFormat)
}

export const formatDate = (date: Date): string => {
  const monthName = getMonthName(date)
  return `${date.getDate()} ${monthName} ${date.getFullYear()}`
}

export const getMonthName = (date: Date): string => {
  return date.toLocaleString('default', { month: 'long' })
}

// An custom React Hook to run callback functions after delay using setInterval
// See https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay === null) {
      return
    }
    const id = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(id)
  }, [delay])
}

// ==== Scroll ====
export const scrollToElementById = (elementId: string) => {
  const element = document.getElementById(elementId)
  element?.scrollIntoView({ behavior: 'smooth' })
}

// A useEffect that runs only during the second call.
// This is especially useful when you dont want useEffect to be called during the initial render of the component.
export const useNonInitialEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const initialRender = useRef(true)
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      effect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, deps)
}

export const padArray = <T,>(array: T[], minLen: number, fillValue: T) => {
  return minLen > array.length
    ? array.concat(Array(minLen - array.length).fill(fillValue))
    : array
}

// ==== Blog ====
// Pads the outline array with empty strings to reach the minimum length
export const padOutlinesArray = (arr: string[]) => {
  return padArray(arr, MIN_EXPECTED_OUTLINES, '')
}

/**
 * Trim whitespace from the beginning and end of a string.
 */
export const trimNewlineAndWhitespace = (s: string) => {
  return s.replace(/^\s+|\s+$/g, '')
}

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  document.body.removeChild(link)
}

/**
 * Wrapper method that takes a `Promise<string>` and copies the resolved string to the clipboard.
 * This is a workaround for Safari as clipboard.writeText fails when called in asynchronous contexts.
 * Thus, the invocation of this method should not follow an await call.
 * Stick to using navigator.clipboard.writeText for simple synchronous text copying.
 * Reference: https://wolfgangrittner.dev/how-to-use-clipboard-api-in-safari/
 */

export const copyPromisedStringToClipboard = async (text: Promise<string>) => {
  // handle Safari
  if (typeof ClipboardItem && navigator.clipboard.write) {
    const clipboardItem = new ClipboardItem({
      'text/plain': text.then(
        (result) => new Blob([result], { type: 'text/plain' })
      )
    })
    await navigator.clipboard.write([clipboardItem])
  } else {
    const result = await text
    await navigator.clipboard.writeText(result)
  }
}

/**
 * Converts a number of bytes to a human readable format
 * @param bytes The number of bytes to be converted
 * @param decimals The number of decimal places to be displayed
 * @returns A string representing the number of bytes in human readable format (eg. 1.23 MB)
 *
 * Reference: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 */
export const parseBytesToHumanReadable = (
  bytes: number,
  decimals: number = 2
): string => {
  if (!+bytes) return '0 Bytes'

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

// Error messages for link validation
export const INVALID_LINK_ERR_MSG =
  'Please check if the link you have entered is valid'
export const INVALID_LINK_TYPE_ERR_MSG =
  "We currently don't support links to PDFs or images"

/**
 * Validates whether string passed in is a valid link
 */
export const validateLinkIsValid = (text: string) => {
  const isURLValidRegex = /^(https:\/\/www\.|www\.|https?:\/\/|)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/gm
  return isURLValidRegex.test(text)
}

/**
 * Validates that string passed in is not a media link
 */
export const isMediaLink = (text: string) => {
  const isURLMediaRegex = /^\b\S+\.(?:jpg|jpeg|gif|png|bmp|webp|svg|pdf)[\S]*\b$/gm
  return isURLMediaRegex.test(text)
}

/**
 * Validates whether the string passed in is a valid non-media link
 */
export const validateLink = (text: string) => {
  return validateLinkIsValid(text) && !isMediaLink(text)
}

// https://github.com/mixmark-io/turndown conforms to CommonMark spec by default
const turndownService = new TurndownService({
  headingStyle: 'atx',
  codeBlockStyle: 'fenced'
})
// Adding some custom rules to match GitHub Flavoured Markdown and add commonly supported functionality
// https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax#styling-text
turndownService
  .addRule('strikethrough', {
    filter: ['del', 's'],
    replacement: (content) => '~~' + content + '~~'
  })
  .keep('mark') // show <mark> tags as is since most markdown viewers display it as highlighted text
  .keep('u') // show <u> tags as is since most markdown viewers display it as underlined text

export const htmlToMarkdown = (html: string) => {
  return turndownService.turndown(html)
}

export const htmlContentToHtmlFile = (
  htmlContentWithTitle: string,
  title: string
) => {
  const styles = `
    .tableWrapper {
      table {
        border-collapse: collapse;
        margin: 0;
        overflow: hidden;
        table-layout: fixed;
        width: 100%;
        border-style: hidden;
        td, th {
          border: 1px solid #ced4da;
          box-sizing: border-box;
          min-width: 1em;
          padding: 3px 5px;
          position: relative;
          vertical-align: top;
          > * {
            margin-bottom: 0;
          }
        }
        th {
          background-color: #f1f3f5;
          font-weight: bold;
          text-align: left;
        }
        .selectedCell:after {
          background: rgba(214, 200, 255, 0.1);
          border: 1px solid #6E63FA;
          content: "";
          left: -1px; /* Adjusted to span the border */
          right: -1px; /* Adjusted to span the border */
          top: -1px; /* Adjusted to span the border */
          bottom: -1px; /* Adjusted to span the border */
          pointer-events: none;
          position: absolute;
        }
        .column-resize-handle {
          background-color: #6E63FA;
          bottom: -2px;
          position: absolute;
          right: -1px;
          pointer-events: none;
          top: 0;
          width: 2px;
        }
        p {
          margin: 0;
        }
      }
    }
    .tableWrapper {
      overflow-x: auto;
      border: 1px solid #ced4da;
      border-radius: 4px;
    }
    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  `

  // Use a regular expression to find all <table> elements
  const tableRegex = /(<table[\s\S]*?<\/table>)/g

  // This is done as somehow editor.getHtml() in the getEditorContentAndTitleInHtml function does not return the tables with
  // the div that usually encloses it, which has the class 'tableWrapper'. This is necessary for the table to be styled properly
  const wrappedContent = htmlContentWithTitle.replace(
    tableRegex,
    '<div class="tableWrapper">$1</div>'
  )

  return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${title}</title>
    <style>${styles}</style>
</head>
<body>
${wrappedContent}
</body>
</html>`
}

/**
 * Replaces empty `<p>` tags with `<br>` tags to preserve new lines when exporting.
 *
 * This is necessary as whenever we hit "Enter" in Tiptap, it creates a `<p>` tag with a `<br>` tag inside it (`<p><br></p>`).
 * However this `<br>` tag is actually a ProseMirror decoration
 * so it is not returned by `editor.getHTML()` (https://github.com/ueberdosis/tiptap/issues/412#issuecomment-515689655)
 *
 * Call this function before exporting html to one of our output file types
 * @param content
 * @returns `content` with empty `<p>` tags replaced with `<br>` tags
 */
export const replaceEmptyParagraphsWithLineBreaks = (
  content: string
): string => {
  //  use regex to replace empty <p> tags with <br> tags
  const regex = /<p><\/p>/g
  return content.replace(regex, '<br>')
}

/**
 * Gives the number of words that the user will be rewarded for generating a piece of content
 * Based on the feature that the user is generating content for.
 * @param feature - the feature that the user is generating content for
 * @returns the number of words that the user will be rewarded for generating a piece of content
 */
export const getAppFeatureToWordsReward = (feature: AppFeatures): string => {
  switch (feature) {
    case AppFeatures.blog_feature:
      return '2,000 words'
    default:
      return '1,000 words'
  }
}
