import React from 'react'

import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions
} from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'

export interface Auth0ProviderWithHistoryProps extends Auth0ProviderOptions {
  /**
   * Flag to quickly disable FE bearer auth
   */
  disabled?: boolean
}

/**
 * Wrapper around Auth0Provider that handles redirects for react-router.
 */
const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = (
  props
) => {
  const {
    children,
    redirectUri = window.location.origin,
    disabled,
    ...restProps
  } = props

  const history = useHistory()

  const onRedirectCallback = (appState: AppState) => {
    const returnTo = appState?.returnTo
    // Add an entry to the history stack so that pressing the back button
    // doesn't immediately take the user to the Auth0 error page.
    history.push('/')

    if (!returnTo) {
      console.log('Post-login callback: no returnTo, will not redirect')
      return
    }

    console.log('Post-login callback: redirecting to', returnTo)
    try {
      // returnTo isn't guaranteed to be a valid URL, so
      // we need to check its validity first
      const absoluteUrl = new URL(returnTo)
      try {
        // If returnTo on a different domain, log error and don't redirect
        if (absoluteUrl.origin !== window.location.origin) {
          console.error('Redirect URL origin does not match current origin.', {
            redirectUrl: returnTo,
            origin: window.location.origin
          })
          return
        }
        // Construct the relative URL to redirect to by stripping the origin
        const relativeRedirectPath = absoluteUrl
          .toString()
          .substring(absoluteUrl.origin.length)
        history.push(relativeRedirectPath)
      } catch (error) {
        console.error('Failed to redirect.', {
          returnTo,
          error
        })
      }
    } catch (error) {
      // If the URL is invalid, log the error and don't redirect.
      console.error('Invalid redirect URL.', { returnTo, error })
      return
    }
  }

  if (disabled) return <>{children}</>

  return (
    <Auth0Provider
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      {...restProps}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
