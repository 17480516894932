import React, { useContext, useEffect } from 'react'

import * as Sentry from '@sentry/react'

import {
  handleTrackCustomerContactUs,
  handleTrackCustomerLearnMore
} from '@hypotenuse/common/src/api/Analytics'
import { makePropInjectorHOCFromHook } from '@hypotenuse/common/src/hooks/hocify'
import { useLocalStorage } from '@hypotenuse/common/src/hooks/useLocalStorage'
import {
  IMPORT_CONTACT_US,
  IS_FIRST_MARK_AS_DONE,
  IS_HIDE_EMPTY_METADATA_ROWS,
  PREMIUM_HAS_CONTACTED_US
} from '@hypotenuse/common/src/utils/Constants'

import usePremiumPlanTier from '../../hooks/usePremiumPlanTier'

import { PlanType } from '../../components/utils/Interfaces'

import Analytics from '../../analytics/Analytics'

export interface TrialUserProps {
  isTrial: boolean
  isEnterpriseUser: boolean
  isUnlimitedUser: boolean
  premiumHasContactedUs: boolean
  handlePremiumContactUs: (isContactedUs: boolean, pathname?: string) => void
  importContactUs: boolean
  setImportContactUs: React.Dispatch<React.SetStateAction<boolean>>
  isHideEmptyMetadataRows: boolean
  setIsHideEmptyMetadataRows: React.Dispatch<React.SetStateAction<boolean>>
  isFirstMarkAsDone: boolean
  setIsFirstMarkAsDone: React.Dispatch<React.SetStateAction<boolean>>
  /**
   * Track when a user clicks 'learn more' on a feature
   * @param {string} feature Feature the user clicked 'learn more' on
   */
  handleTrackLearnMore: (feature?: string) => void
  refreshTrialStatus: () => void
}

export const TrialUserContext = React.createContext<TrialUserProps>({
  isTrial: false,
  isEnterpriseUser: false,
  isUnlimitedUser: false,
  premiumHasContactedUs: false,
  handlePremiumContactUs: () => {},
  importContactUs: false,
  setImportContactUs: () => {},
  isHideEmptyMetadataRows: true,
  setIsHideEmptyMetadataRows: () => {},
  isFirstMarkAsDone: true,
  setIsFirstMarkAsDone: () => {},
  handleTrackLearnMore: () => {},
  refreshTrialStatus: () => {}
})

export const useTrialUserContext = () => useContext(TrialUserContext)

export const TrialUserProvider: React.FC = (props) => {
  const {
    data: userPlanTier,
    mutate: refreshTrialStatus
  } = usePremiumPlanTier()

  const isTrial = userPlanTier?.plan_type === PlanType.free ?? false
  const isEnterpriseUser =
    userPlanTier?.plan_type === PlanType.enterprise ?? false
  const isUnlimitedUser = userPlanTier?.plan_type === PlanType.unlimited
  const [
    premiumHasContactedUs,
    setPremiumHasContactedUs
  ] = useLocalStorage<boolean>(PREMIUM_HAS_CONTACTED_US, false)
  const [importContactUs, setImportContactUs] = useLocalStorage<boolean>(
    IMPORT_CONTACT_US,
    false
  )
  const [
    isHideEmptyMetadataRows,
    setIsHideEmptyMetadataRows
  ] = useLocalStorage<boolean>(IS_HIDE_EMPTY_METADATA_ROWS, true)

  const [isFirstMarkAsDone, setIsFirstMarkAsDone] = useLocalStorage<boolean>(
    IS_FIRST_MARK_AS_DONE,
    true
  )

  const handleTrackLearnMore = (feature?: string) => {
    handleTrackCustomerLearnMore(feature)
      .then((_response) => {
        Analytics.trackEvent('Trial user learn more')
      })
      .catch((_error) => {
        Sentry.captureMessage('Track trial user learn more failed', 'warning')
      })
  }

  // We abstract out the tracking event for these two following methods to
  // prevent copy pasting of the same code in multiple places
  const handlePremiumContactUs = (isContactedUs: boolean) => {
    handleTrackCustomerContactUs()
      .then((_response) => {
        Analytics.trackEvent('Trial user contact us')
      })
      .catch((_error) => {
        Sentry.captureMessage('Track trial user contact us failed', 'warning')
      })
    setPremiumHasContactedUs(isContactedUs)
  }

  // When context is instantiated, update the status of whether the use is on trial
  useEffect(() => {
    refreshTrialStatus()
  }, [refreshTrialStatus])

  return (
    <TrialUserContext.Provider
      value={{
        refreshTrialStatus,
        isTrial,
        isEnterpriseUser,
        isUnlimitedUser,
        premiumHasContactedUs,
        handlePremiumContactUs,
        importContactUs,
        setImportContactUs,
        isHideEmptyMetadataRows,
        setIsHideEmptyMetadataRows,
        isFirstMarkAsDone,
        setIsFirstMarkAsDone,
        handleTrackLearnMore
      }}
    >
      {props.children}
    </TrialUserContext.Provider>
  )
}

/**
 * HOC wrapper for the context hook.
 */
export const withTrialUserContext = makePropInjectorHOCFromHook(
  useTrialUserContext
)
