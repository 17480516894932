import { DependencyList, useCallback, useRef, useState } from 'react'

import useMountedState from './useMountedState'

export type FunctionReturningPromise = (...args: any[]) => Promise<any>
export type PromiseType<P extends Promise<any>> = P extends Promise<infer T>
  ? T
  : never

export type AsyncState<T> =
  | {
      loading: boolean
      error?: undefined
      value?: undefined
    }
  | {
      loading: true
      error?: Error | undefined
      value?: T
    }
  | {
      loading: false
      error: Error
      value?: undefined
    }
  | {
      loading: false
      error?: undefined
      value: T
    }

type StateFromFunctionReturningPromise<
  T extends FunctionReturningPromise
> = AsyncState<PromiseType<ReturnType<T>>>

export type AsyncFnReturn<
  T extends FunctionReturningPromise = FunctionReturningPromise
> = [StateFromFunctionReturningPromise<T>, T]

/**
 * React hook that returns state (e.g. loading, error, value) and an async callback for any async function.
 * Use this hook whenever you need to know the status of an async function's execution, e.g. when displaying a loading
 * icon after a user clicks a button that triggers an API call.
 *
 * NOTE: You'll very likely need to specify the function as a dependency because this hook wraps it in a `useCallback` hook.
 *
 * Usage: {@link https://github.com/streamich/react-use/blob/90e72a5340460816e2159b2c461254661b00e1d3/docs/useAsyncFn.md}
 *
 * Referenced from {@link https://github.com/streamich/react-use/blob/90e72a5340460816e2159b2c461254661b00e1d3/src/useAsyncFn.ts}
 */
export default function useAsyncFn<T extends FunctionReturningPromise>(
  fn: T,
  deps: DependencyList = [],
  initialState: StateFromFunctionReturningPromise<T> = { loading: false }
): AsyncFnReturn<T> {
  const lastCallId = useRef(0)
  const isMounted = useMountedState()
  const [state, set] = useState<StateFromFunctionReturningPromise<T>>(
    initialState
  )

  const callback = useCallback((...args: Parameters<T>): ReturnType<T> => {
    const callId = ++lastCallId.current
    set((prevState) => ({ ...prevState, loading: true }))

    return fn(...args).then(
      (value) => {
        isMounted() &&
          callId === lastCallId.current &&
          set({ value, loading: false })

        return value
      },
      (error) => {
        isMounted() &&
          callId === lastCallId.current &&
          set({ error, loading: false })

        throw error
      }
    ) as ReturnType<T>
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, deps)

  return [state, (callback as unknown) as T]
}
