import React, { useCallback } from 'react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'

import {
  BaseModal,
  ModalActions,
  ModalContent,
  ModalTitle,
  PrimaryButton
} from '@hypotenuse/common/src/components/BaseModal'

import celebrate from './celebrate.svg'

interface WelcomeMemberModalProps {
  open: boolean
  onClose: () => void
}

export const WelcomeMemberModal = (props: WelcomeMemberModalProps) => {
  const { open, onClose } = props

  const handleConfirm = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <BaseModal open={open} onClose={onClose}>
      <ModalContent alignItems="center">
        <img width={'48px'} src={celebrate} alt="welcome"></img>
        <ModalTitle>You've been added to the team!</ModalTitle>
        <Typography variant="body2" style={{ color: palette.gray[500] }}>
          Thanks for accepting the invite — you've now been added to the team.
        </Typography>
      </ModalContent>
      <ModalActions>
        <PrimaryButton onClick={handleConfirm} style={{ width: '100%' }}>
          Get started
        </PrimaryButton>
      </ModalActions>
    </BaseModal>
  )
}
