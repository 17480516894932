// import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { apiGetStripeInvoicePaymentLink } from '../api/Billing'
import { fetchPremiumPlanTier } from '../api/PlanTier'

import { PlanType, PremiumPlanTier } from '../components/utils/Interfaces'

export interface PremiumPlanTierWithPlanType extends PremiumPlanTier {
  isFreePlan: boolean
  isIndividualPlan: boolean
  isTeamPlan: boolean
  isEnterprisePlan: boolean
  isUnlimitedPlan: boolean
}

/**
 * Fetches the premium plan tier of the current user
 */
const usePremiumPlanTier = () => {
  const result = useSWR<PremiumPlanTier>(
    'active_plan_tier',
    fetchPremiumPlanTier
  )
  const invoiceId = result.data?.payment_failure_info?.invoice_id

  const { data: latestUnpaidInvoicePaymentLink } = useSWR<string | undefined>(
    invoiceId ? `stripe-invoice-payment-link/${invoiceId}` : null,
    invoiceId
      ? async () => {
          return await apiGetStripeInvoicePaymentLink(invoiceId)
        }
      : null
  )

  return {
    ...result,
    data: result.data
      ? {
          ...result.data,
          // For convenient access to the user's plan type.
          // This is probably not the best way to do this, but it works for now.
          isFreePlan: result.data?.plan_type === PlanType.free,
          isIndividualPlan: result.data?.plan_type === PlanType.starter,
          isTeamPlan: result.data?.plan_type === PlanType.premium,
          isEnterprisePlan: result.data?.plan_type === PlanType.enterprise,
          isUnlimitedPlan: result.data?.plan_type === PlanType.unlimited
        }
      : undefined,
    latestUnpaidInvoicePaymentLink
  }
}

export default usePremiumPlanTier
