import React, { useEffect, useState } from 'react'

import { Image } from 'semantic-ui-react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Box, Button, Grid, Hidden } from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { getReferralDetails } from '@hypotenuse/common/src/api/Referrals'
import { CreatedReferral } from '@hypotenuse/common/src/interfaces/Referrals'

import LetterAvatar from '../utils/LetterAvatar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 'bold'
    },
    itemPadding: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    getStartedButton: {
      fontSize: theme.spacing(2),
      width: theme.spacing(20)
    },
    invitedByText: {
      fontSize: theme.spacing(1.5),
      color: palette.gray['600']
    }
  })
)

export default function ReferralLandingPage() {
  const [referrerName, setReferrerName] = useState<string>('')

  useEffect(() => {
    getReferralDetails().then((createdReferral: CreatedReferral) => {
      setReferrerName(createdReferral.referee_username)
    })
  }, [])

  const GET_STARTED_TEXT = 'Get Started'
  const NO_REFERRER_TEXT = 'No Referral'

  const renderReferrerDetails = () => {
    return (
      <Box display="flex" flexDirection="column">
        <Typography className={classes.invitedByText}>Invited By:</Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gridGap="5px"
        >
          <LetterAvatar
            firstName={referrerName}
            width="20px"
            height="20px"
            fontSize="12px"
          />
          <Typography variant="caption" style={{ fontWeight: 'bold' }}>
            {referrerName}
          </Typography>
        </Box>
      </Box>
    )
  }

  const stepsData = [
    {
      headerText: 'Sign Up',
      description:
        'Get started with a 7 day free trial. No credit card required.'
    },
    {
      headerText: 'Purchase a Plan',
      description:
        'Browse and choose from a few plans to supercharge your copywriting experience'
    },
    {
      headerText: 'Get Rewarded',
      description:
        'You and your referrer gets 50 extra credits. Get 50 credits for every new user you refer.'
    }
  ]

  const classes = useStyles()
  return (
    <Box mx={8}>
      <Grid container alignItems="center" lg>
        <Grid item lg>
          <Box flex flexDirection={'column'} p={5}>
            <Typography
              variant="h3"
              className={clsx(classes.bold, classes.itemPadding)}
            >
              You’ve been invited to join Hypotenuse AI
            </Typography>
            <Typography
              variant={'h6'}
              style={{ fontWeight: 'normal' }}
              className={classes.itemPadding}
            >
              Automatically generate your copywriting content with the power of
              AI. Claim your <b> 50 free credits.</b>
            </Typography>
            <Box
              className={classes.itemPadding}
              display="flex"
              flexDirection="row"
              gridGap="12px"
              alignItems="flex-start"
            >
              <Button
                href="/"
                variant="contained"
                color="primary"
                disabled={referrerName === ''}
                className={classes.getStartedButton}
              >
                {referrerName !== '' ? GET_STARTED_TEXT : NO_REFERRER_TEXT}
              </Button>
              {referrerName !== '' && renderReferrerDetails()}
            </Box>
          </Box>
        </Grid>
        <Grid item lg>
          <Hidden mdDown>
            <Box p={5}>
              <Image
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                size={'huge'}
                src="/assets/referral/referral_landing_page.svg"
              />
            </Box>
          </Hidden>
        </Grid>
      </Grid>
      <Box py={5} display="flex" justifyContent="center">
        <Typography variant={'h4'} style={{ fontWeight: 'bold' }}>
          Claim your credits in 3 steps
        </Typography>
      </Box>
      <Grid
        container
        spacing={4}
        justify={'center'}
        style={{
          padding: '0px, 30px, 0px, 30px'
        }}
      >
        {stepsData.map((data, index) => {
          return (
            <Grid item md={4} key={`referral_steps_${index}`}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gridGap="10px"
              >
                <LetterAvatar
                  overrideBackgroundColor={palette.primary[500]}
                  firstName={(index + 1).toString()}
                  height={'40px'}
                  width={'40px'}
                />
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', textAlign: 'center' }}
                >
                  {data.headerText}
                </Typography>
                <Typography style={{ textAlign: 'center' }}>
                  {data.description}
                </Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
