import React, { useMemo } from 'react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Box,
  Tab,
  Tabs,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

import LabelFlag from '@hypotenuse/common/src/components/LabelFlag'
import { PlanBillingPeriod } from '@hypotenuse/common/src/utils/Interfaces'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsRoot: {
      minHeight: theme.spacing(4.5),
      maxHeight: theme.spacing(4.5)
    },
    tabButton: {
      fontSize: '1rem',
      minWidth: 0,
      padding: theme.spacing(1, 1.5),
      zIndex: 1,
      minHeight: theme.spacing(4.5),
      maxHeight: theme.spacing(4.5)
    },
    indicator: {
      padding: theme.spacing(1, 1.5)
    },
    flexContainer: {
      position: 'relative',
      padding: `0px ${theme.spacing(0.5)}px`,
      zIndex: 1,
      gap: theme.spacing(1),
      height: theme.spacing(5)
    }
  })
)

interface PlanDurationTabsProps {
  currentTab: PlanBillingPeriod
  setCurrentTab: (tab: PlanBillingPeriod) => void
  discountPercentage?: number
}

const PlanDurationTabs = (props: PlanDurationTabsProps) => {
  const { currentTab, setCurrentTab, discountPercentage = null } = props
  const classes = useStyles()
  const theme = useTheme()

  const iconAnnualBadge = useMemo(
    () => (
      <LabelFlag
        content={
          <p>Save {discountPercentage === null ? '17' : discountPercentage}%</p>
        }
        typographyProps={{ variant: 'subtitle1' }}
        variant="regular"
        color={palette.green['900']}
        bgcolor={palette.green['100']}
      />
    ),
    [discountPercentage]
  )

  const iconAnnual = useMemo(
    () => (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h6">Annual</Typography>
        {iconAnnualBadge}
      </Stack>
    ),
    [iconAnnualBadge]
  )

  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      position="relative"
      bgcolor={palette.gray[200]}
      borderRadius={theme.spacing(1)}
      p={0.5}
      width={isMediumScreen ? '39%' : '95%'}
    >
      <Tabs
        key={isMediumScreen ? 'desktop' : 'mobile'}
        onChange={(_event, value: PlanBillingPeriod) => {
          setCurrentTab(value)
        }}
        classes={{ root: classes.tabsRoot, indicator: classes.indicator }}
        value={currentTab}
        TabIndicatorProps={{
          style: {
            backgroundColor: 'white',
            height: '100%',
            borderRadius: theme.spacing(0.75)
          }
        }}
        centered
        variant="fullWidth"
        style={{ gap: theme.spacing(1) }}
      >
        <Tab
          label="Monthly"
          value={PlanBillingPeriod.monthly}
          className={classes.tabButton}
        />
        <Tab
          icon={iconAnnual}
          value={PlanBillingPeriod.yearly}
          className={classes.tabButton}
        />
      </Tabs>
    </Box>
  )
}

export default PlanDurationTabs
