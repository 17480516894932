import React, { useEffect, useMemo, useState } from 'react'

import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { Subtract } from 'utility-types'

import { useNonInitialEffect } from '@hypotenuse/common/src/utils/Functions'

export enum SIZES {
  XL = 'XL',
  LG = 'LG',
  MD = 'MD',
  SM = 'SM'
}

const DEVICE_SIZE = {
  [SIZES.XL]: 1200,
  [SIZES.LG]: 992,
  [SIZES.MD]: 768,
  [SIZES.SM]: 576
}

const DEVICE_SIZE_TO_ITEM_COUNT_MAP = {
  [SIZES.XL]: 40,
  [SIZES.LG]: 30,
  [SIZES.MD]: 20,
  [SIZES.SM]: 10
}

export interface PaginateByWindowSizeOutput {
  numPerPage: number
  page: number
  setPage: (currPage: number) => void
  index: { start: number; end: number }
  totalItems: number
  setTotalItems: React.Dispatch<React.SetStateAction<number>>
  numPages: number
}

export interface WithPaginateByWindowSizeProps {
  paginateProps: PaginateByWindowSizeOutput
}

export type SizeToCountMap = { [key in SIZES]: number }
const getNumPerPage = (sizeToCount: SizeToCountMap): number => {
  return window.innerWidth > DEVICE_SIZE[SIZES.XL]
    ? sizeToCount[SIZES.XL]
    : window.innerWidth > DEVICE_SIZE[SIZES.LG]
    ? sizeToCount[SIZES.LG]
    : window.innerWidth > DEVICE_SIZE[SIZES.MD]
    ? sizeToCount[SIZES.MD]
    : sizeToCount[SIZES.SM]
}

const getStartAndEndIndex = (
  numPerPage: number,
  currPage: number
): { start: number; end: number } => {
  let start = 0
  let end = numPerPage
  if (currPage && currPage > 1) {
    start = (currPage - 1) * numPerPage
    end = start + numPerPage
  }
  return { start, end }
}

/**
 * Handles pagination state.
 *
 * @param numPerPage Number of items to show per page
 * If a dynamic number of items depending on screen size is needed, use usePaginateByWindowSize instead.
 *
 * Returns the following:
 *  - numPerPage -> how many items to display on page
 *  - page -> current page
 *  - setPage -> method to change current page and set new query param for page
 *  - index -> { start, end } of the pagination
 *  - totalItems -> the total number of items to be paginated
 *  - setTotalItems -> sets value of totalItems
 *  - numPages -> total number of pages
 */
export const usePaginate = (props: {
  numPerPage: number
}): PaginateByWindowSizeOutput => {
  const { numPerPage = 10 } = props
  const [totalItems, setTotalItems] = useState<number>(0)

  const [page, setPage] = useState(1)
  const index = useMemo(() => getStartAndEndIndex(numPerPage, page), [
    numPerPage,
    page
  ])
  const numPages = Math.max(1, Math.ceil(totalItems / numPerPage))

  /**
   * If current page exceeds total pages due to change in pagination or deletion of products,
   * bring user to last page
   */
  useNonInitialEffect(() => {
    if (page > numPages) {
      setPage(numPages)
    }
  }, [numPages, page])

  return useMemo(
    () => ({
      numPerPage,
      page,
      setPage,
      index,
      totalItems,
      setTotalItems,
      numPages
    }),
    [index, numPages, numPerPage, page, setPage, totalItems]
  )
}

/**
 * Handles pagination state based on screen width.
 *
 * @param deviceSizeToItemCountMap Optional size to number per page interface. Will use default mapping if not provided
 *
 * Returns the following:
 *  - numPerPage -> how many items to display on page
 *  - page -> current page
 *  - setPage -> method to change current page and set new query param for page
 *  - index -> { start, end } of the pagination
 *  - totalItems -> the total number of items to be paginated
 *  - setTotalItems -> sets value of totalItems
 *  - numPages -> total number of pages
 */
export const usePaginateByWindowSize = (props: {
  deviceSizeToItemCountMap: SizeToCountMap
}): PaginateByWindowSizeOutput => {
  const { deviceSizeToItemCountMap = DEVICE_SIZE_TO_ITEM_COUNT_MAP } = props
  const [totalItems, setTotalItems] = useState<number>(0)
  const [numPerPage, setNumPerPage] = useState<number>(
    getNumPerPage(deviceSizeToItemCountMap)
  )
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
  const index = useMemo(() => getStartAndEndIndex(numPerPage, page), [
    numPerPage,
    page
  ])
  const numPages = Math.max(1, Math.ceil(totalItems / numPerPage))

  /**
   * If current page exceeds total pages due to change in pagination or deletion of products,
   * bring user to last page
   */
  useNonInitialEffect(() => {
    if (page > numPages) {
      setPage(numPages)
    }
  }, [numPages, page])

  useEffect(() => {
    /**
     * Set number per page
     */
    const updateSize = () => {
      setNumPerPage(getNumPerPage(deviceSizeToItemCountMap))
    }
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [deviceSizeToItemCountMap])

  return useMemo(
    () => ({
      numPerPage,
      page,
      setPage,
      index,
      totalItems,
      setTotalItems,
      numPages
    }),
    [index, numPages, numPerPage, page, setPage, totalItems]
  )
}

/**
 * This HOC allows your component to manage pagination.
 * @param WrappedComponent component to wrap of React.ComponentType
 */
export function withPaginateByWindowSize<
  P extends WithPaginateByWindowSizeProps
>(
  WrappedComponent: React.ComponentType<P>,
  deviceSizeToItemCountMap: SizeToCountMap = DEVICE_SIZE_TO_ITEM_COUNT_MAP
) {
  const ComponentWithWindowSize: React.FunctionComponent<
    Subtract<P, WithPaginateByWindowSizeProps>
  > = (props) => {
    const paginateProps = usePaginateByWindowSize({
      deviceSizeToItemCountMap
    })

    WrappedComponent.displayName = `WithPaginationByWindowSize(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`
    return <WrappedComponent {...(props as P)} paginateProps={paginateProps} />
  }
  return ComponentWithWindowSize
}
