import React from 'react'

import { TrialBannerBase, TrialBannerBaseProps } from './TrialBannerBase'
import { useTrialBanner } from './useTrialBanner'

interface Props extends Partial<TrialBannerBaseProps> {}

/**
 *
 * Wrapper component around TrialBannerBase for convenience.
 * This pattern allows us to snapshot test the UI of the component
 * by separating it from the state & logic.
 */
export const TrialBanner: React.FC<Props> = ({ ...overrideProps }) => {
  const { show, ...trialBannerProps } = useTrialBanner()
  return show ? (
    <TrialBannerBase {...trialBannerProps} {...overrideProps} />
  ) : null
}
